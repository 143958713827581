import { Text } from '../../components';
import { Role } from '../../constants';
import { LabelValueItem } from './label-value';

export const SignedBy = ({
  type,
  name,
  email,
}: {
  type?: Role.CLIENT | Role.CONTRACTOR | Role.TEAM_MEMBER;
  name?: string;
  email?: string;
}) => {
  return (
    <LabelValueItem label={<>Signed by {type}</>}>
      <Text bold theme="light">
        {name}
      </Text>
      <span> </span>
      <Text theme="light">{email}</Text>
    </LabelValueItem>
  );
};
