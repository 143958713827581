import React from 'react';

const wrapMatches = function (string: string, matches: [number, number][]) {
  const substrings = [];
  let previousEnd = 0;

  for (let [start, end] of matches) {
    const prefix = string.substring(previousEnd, start);
    const match = <b>{string.substring(start, end)}</b>;

    substrings.push(prefix, match);
    previousEnd = end;
  }

  // add the part of the string after the final match, which will be the
  // whole string if there are no matches
  substrings.push(string.substring(previousEnd));

  // toArray() automatically adds keys to the array items
  return React.Children.toArray(substrings);
};

export const Highlight = ({
  string,
  matches,
}: {
  string: string;
  matches: [number, number][];
}) => {
  return <span>{wrapMatches(string, matches)}</span>;
};
