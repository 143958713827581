import { useState, useMemo } from 'react';
import { groupBy, map } from 'lodash';
import { useHistory } from 'react-router';
import { Switch } from '@blueprintjs/core';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractorType } from '../../../constants';
import {
  Alert,
  Button,
  CountrySelect,
  DocumentEditor,
  FormGroup,
  Input,
  PlaceholderGroup,
} from '../../../components';
import { usePlaceholders } from '../../../hooks';
import { ContentTabs } from './content-tabs';
import './form-inputs.scss';

export const FormInputs = () => {
  const history = useHistory();
  const placeholders = usePlaceholders();
  const { control, register, errors, watch } = useFormContext();
  const useSameContent = watch('same_content');
  const [selectedTab, setSelectedTab] = useState<string>(
    ContractorType.INDIVIDUAL as string,
  );

  const groupedPlaceholders: any = useMemo(() => {
    return groupBy(placeholders, 'group');
  }, [placeholders]);

  const goBack = () => history.goBack();

  return (
    <div className="row justify-content-between">
      <div className="col-12 col-xl-8">
        <FormGroup label="Template Name" errors={errors?.name} required>
          <Input
            name="name"
            ref={register({
              required: true,
            })}
          />
        </FormGroup>

        <FormGroup
          label="Country"
          labelFor="country_id"
          errors={errors.country_id}
        >
          <Controller
            name="country_id"
            control={control}
            defaultValue=""
            render={(props) => (
              <CountrySelect
                value={props.value}
                onChange={props.onChange}
                isClearable
              />
            )}
          />
        </FormGroup>

        <Alert
          theme="info"
          className="d-flex justify-content-between align-items-center"
        >
          <p className="mb-0">
            Use the same template for both {ContractorType.INDIVIDUAL} and{' '}
            {ContractorType.ENTITY} contractors
          </p>

          <Controller
            name="same_content"
            control={control}
            defaultValue={true}
            render={(props) => (
              <Switch
                className="mb-0"
                checked={props.value}
                onChange={(e: any) => props.onChange(e.target.checked)}
                innerLabelChecked="Yes"
                innerLabel="No"
                alignIndicator="right"
                large
              />
            )}
          />
        </Alert>

        {useSameContent ? (
          <FormGroup label="Template Content" errors={errors?.content} required>
            <Controller
              name="content"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={(props) => (
                <DocumentEditor value={props.value} onChange={props.onChange} />
              )}
            />
          </FormGroup>
        ) : (
          <ContentTabs
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        )}

        <div className="px-4 col-12">
          <Button theme="light" onClick={goBack}>
            Cancel
          </Button>

          <Button type="submit">Save</Button>
        </div>
      </div>

      <div className="placeholdersWrapper col-12 col-xl-4">
        <Alert theme="light">Hint: Click on a placeholder to copy!</Alert>
        <Alert theme="light">
          <p>
            You can use the following placeholders to be replaced with actual
            data:
          </p>

          <div>
            {map(groupedPlaceholders, (values, key) => (
              <PlaceholderGroup key={key} title={key} placeholders={values} />
            ))}
          </div>
        </Alert>
      </div>
    </div>
  );
};
