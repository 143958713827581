import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Layout, Loader, ProtectedRoute } from './components';
import { Sidebar } from './components/sidebar';
import {
  ModalsContainer,
  useAuthStore,
  useIframe,
  useModalStore,
} from './hooks';
import { ScrollTop } from './components/scroll-top';
import { routes } from './routes';
import './app.scss';
import { useToasterStore } from './hooks/use-toaster';
import Storage from './shared/db/storage';

export interface IAppContext {
  authStore: ReturnType<typeof useAuthStore>;
  modalStore: ReturnType<typeof useModalStore>;
  toasterStore: ReturnType<typeof useToasterStore>;
}

export const AppContext = React.createContext<IAppContext>({} as IAppContext);

const RootComponent = () => {
  const { isIframe } = useIframe();
  const authStore = useAuthStore();
  const modalStore = useModalStore();
  const toasterStore = useToasterStore();
  const appContext = React.useMemo(
    () => ({
      authStore,
      modalStore,
      toasterStore,
    }),
    [authStore, modalStore, toasterStore],
  );

  return authStore?.completed ? (
    <AppContext.Provider value={appContext}>
      <ScrollTop />

      <Layout
        className={
          authStore.isAuthenticated && !isIframe ? 'pg-Layout--has-sidebar' : ''
        }
        sidebar={authStore.isAuthenticated && !isIframe ? <Sidebar /> : null}
      >
        <Switch>
          {routes.map(({ protect, ...rest }, index) =>
            protect ? (
              <ProtectedRoute key={index} {...rest} />
            ) : (
              <Route key={index} {...rest} />
            ),
          )}
        </Switch>
      </Layout>

      <ModalsContainer />

      {toasterStore.component}
    </AppContext.Provider>
  ) : (
    <Loader fullscreen force />
  );
};

export const App = () => {
  const [mounted, setMounted] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (Storage.isRunningInIframe()) {
      // Make sure to clear the storage if running in iframe
      // to avoid sending requests with any old tokens

      Storage.clear();
    }

    setMounted(true);
  }, []);

  return mounted ? (
    <Router>
      <RootComponent />
    </Router>
  ) : (
    <Loader fullscreen force />
  );
};
