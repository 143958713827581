import { useState } from '@hookstate/core';
import { useHistory } from 'react-router-dom';
import { Invoice } from './index';
import { LabelValue, Button } from '../../components';
import { currencyFormatter } from '../../shared/helpers';
import { ProfileService } from '../../shared/services';
import { useService } from '../../shared/services/use-service';
import './approval.scss';
import { BsFillExclamationCircleFill } from 'react-icons/bs';
import { useApi, useCommonData } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';

export const Approval = ({
  total = 0,
  currency,
  selected = [],
  invoices = [],
}: {
  showButton?: boolean;
  showTitle?: boolean;
  total?: number;
  currency?: string;
  selected?: number[];
  invoices?: Invoice[];
}) => {
  const api = useApi();
  const toast = useToaster();
  const history = useHistory();
  const commonData = useCommonData();
  const { value } = useState(useService(ProfileService).state);

  const approve = async () => {
    const approvedStatus = commonData?.invoice_statuses?.find(
      ({ name }) => name.toLowerCase() === 'approved',
    );
    const draftInvoices = selected.filter(
      (id) =>
        invoices.find((item) => item.id === id)?.status.toLowerCase() !==
        'approved',
    );
    try {
      await Promise.all(
        draftInvoices.map((id) =>
          api.post(
            `/api/client/invoices/${id}/change-status/${approvedStatus?.id}`,
            {},
          ),
        ),
      );
      history.push(
        `/invoices/pay${selected.length ? `?ids=${selected?.join(',')}` : ''}`,
      );
    } catch (e) {
      toast.danger({ message: 'Something went wrong' });
    }
  };

  return (
    <>
      <h5>Hey {value?.first_name}</h5>
      <p>Please take a moment to review the payroll before approving it.</p>
      <p>
        Once you've approved the payroll you can no longer make salary changes,
        add additional compensation, bonuses, or make new hires for the upcoming
        payroll.
      </p>

      <div className="pg-ApprovalBox">
        <div>
          <div className="pg-ApprovalBox__Total">
            Total Payroll {currencyFormatter(total, currency)}
          </div>
          <p>
            <BsFillExclamationCircleFill className="pg-ApprovalBox__Icon" />
            You can approve your payroll now, or we'll have it auto-approved for
            you on May 30, 2021.
          </p>
        </div>
        <div>
          <Button disabled={!total} onClick={() => approve()}>
            Approve Payroll
          </Button>
        </div>
      </div>
    </>
  );
};

export const ApprovalModal = ({
  total = 0,
  currency,
}: {
  total?: number;
  currency?: string;
}) => {
  return (
    <>
      <p>Please take a moment to review the payroll before approving it.</p>
      <p>
        Once you've approved the payroll you can no longer make salary changes,
        add additional compensation, bonuses, or make new hires for the upcoming
        payroll.
      </p>
      <div>
        <LabelValue label="Total Payroll">
          {currencyFormatter(total, currency)}
        </LabelValue>
      </div>
      <p>
        You can approve your payroll now, or we'll have it auto-approved for you
        on May 30, 2021.
      </p>
    </>
  );
};
