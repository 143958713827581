import { useCallback, useMemo } from 'react';
import { useCommonData } from '..';

export const useInvoiceTimeFrame = (typeId?: number, timeFrameId?: number) => {
  const common = useCommonData();

  const getInvoicingTimeFrame = useCallback(
    (typeId?: number, timeFrameId?: number) => {
      return common?.contract_types
        ?.find((contract) => contract.id === typeId)
        ?.invoicing_time_frames.find(
          (timeFrame) => timeFrame.id === timeFrameId,
        )?.name;
    },
    [common],
  );

  const timeFrame = useMemo(() => {
    return getInvoicingTimeFrame(typeId, timeFrameId);
  }, [getInvoicingTimeFrame, typeId, timeFrameId]);

  return {
    value: timeFrame,
    getInvoicingTimeFrame,
  };
};
