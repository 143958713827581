import { endpoints } from './endpoints';
import { get } from 'lodash';

export const resolveEndpoint = (key: string, bindings: any = {}) => {
  const endpoint = get(endpoints, key, null);

  if (!endpoint) {
    throw new Error('Invalid API endpoint');
  }

  return substituteBindings(endpoint, bindings);
};

const substituteBindings = (uri: string, bindings: any = {}) => {
  const regex = new RegExp(/:\w+/g);

  const matches = Array.from(uri.matchAll(regex));

  let replacedStr = uri;

  for (const match of matches) {
    const [binding] = match;

    const bindingKey = binding.replaceAll(':', '');

    const bindingValue = bindings[bindingKey];

    // Binding is not set in the function parameters
    // that's a dev-error, so throw an error
    if (typeof bindingValue === 'undefined') {
      throw new Error('Missing required route binding value!');
    }
    // Binding is set but with wrong value
    // Silently ignore error as this will
    // throw an error on the AJAX request level
    else if (!bindingValue) {
      continue;
    }

    replacedStr = replacedStr.replaceAll(binding, bindingValue);
  }

  return replacedStr;
};
