import React, { useContext } from 'react';
import { AppContext } from '../../app';
import { Toaster, ToasterTheme } from '../../components/toaster';

interface IToaster {
  close: () => void;
  message: React.ReactNode;
  title?: React.ReactNode;
  action?: React.ReactNode;
  theme?: ToasterTheme;
}

type ToasterContent = {
  message: React.ReactNode;
  title?: React.ReactNode;
  action?: React.ReactNode;
  theme?: ToasterTheme;
  timeout?: number;
};

const ToasterContainer = ({ toasters }: { toasters: IToaster[] }) => {
  return (
    <div className="pg-ToasterContainer">
      {toasters.map((toaster, index) => (
        <Toaster
          key={index}
          onClose={toaster.close}
          title={toaster.title}
          theme={toaster.theme}
        >
          {toaster.message}
        </Toaster>
      ))}
    </div>
  );
};

export function useToasterStore() {
  const [toasters, setToasters] = React.useState<IToaster[]>([]);

  function open<T>({
    message,
    title,
    action,
    theme,
    timeout = 4000,
  }: ToasterContent) {
    let resolve: (value?: T | PromiseLike<T>) => void;

    const proimse = new Promise<T>((res, rej) => {
      resolve = res as any;
    });

    const toaster = {
      close: (data?: T) => {
        resolve(data);
        close(toaster);
      },
      message,
      title,
      action,
      theme,
    };

    if (timeout) {
      setTimeout(() => {
        close(toaster);
      }, timeout);
    }

    setToasters((toasters) => [...toasters, toaster]);

    return {
      result: proimse,
      toaster,
    };
  }

  function close(toaster: IToaster) {
    setToasters((toasters) => {
      const remainingToasters = toasters.filter((item) => item !== toaster);
      return remainingToasters;
    });
  }

  function success(settings: ToasterContent) {
    return open({ theme: 'success', ...settings });
  }
  function danger(settings: ToasterContent) {
    return open({ theme: 'danger', ...settings });
  }
  function info(settings: ToasterContent) {
    return open({ theme: 'info', ...settings });
  }
  function warning(settings: ToasterContent) {
    return open({ theme: 'warning', ...settings });
  }
  function primary(settings: ToasterContent) {
    return open({ theme: 'primary', ...settings });
  }

  return {
    component: <ToasterContainer toasters={toasters} />,
    open,
    success,
    danger,
    info,
    warning,
    primary,
    close,
  };
}

export function useToaster() {
  const { toasterStore } = useContext(AppContext);
  return toasterStore;
}
