import React from 'react';

export interface ApiQueryParams {
  page: number;
  pageSize: number;
  sortBy: string;
  sortDir: string;
  filters: string;
  search: string;
  toString: () => string;
}

const defaultState: ApiQueryParams = {
  page: 0,
  pageSize: 10,
  sortBy: 'id',
  sortDir: 'desc',
  filters: '',
  search: '',
  toString: () => `page=1&perPage=10&sortBy=id&sortDir=desc&filters=&search=`,
};

/**
 * A custom hook to manage API endpoint
 * `filtering`, `sorting`, and `pagination`.
 */
export function useFilters(initialState?: ApiQueryParams) {
  const [params, setParams] = React.useState<ApiQueryParams>(
    initialState || defaultState,
  );

  const filters: ApiQueryParams = React.useMemo(
    () => ({
      ...params,
      toString: () => filterToQuery(params),
    }),
    [params],
  );

  const setFilters = React.useCallback((newParams: Partial<ApiQueryParams>) => {
    setParams((prev: ApiQueryParams) => ({
      ...prev,
      ...newParams,
    }));
  }, []);

  return [filters, setFilters] as const;
}

function filterToQuery(params: ApiQueryParams): string {
  return (
    `page=${params.page + 1}&` +
    `perPage=${params.pageSize}&` +
    `sortBy=${params.sortBy}&` +
    `sortDir=${params.sortDir}&` +
    `filters=${params.filters}&` +
    `search=${params.search}`
  );
}
