import React from 'react';
import {
  CommonDataResponse,
  PublicCommonDataResponse,
} from '../../hooks/use-api';
import { CommonDataService } from '../../shared/services/common-data';
import { useService } from '../../shared/services/use-service';

export const CommonDataDisplay = ({
  type,
  id,
  dataKey = 'name',
  render,
}: {
  type: keyof CommonDataResponse | keyof PublicCommonDataResponse;
  id: number;
  dataKey?: string;
  render?: (value: string) => React.ReactNode;
}) => {
  const commonData = useService(CommonDataService);
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    commonData.get().then((data) => {
      if (type === 'timezones') {
        setValue(`${id}`);
      } else if (type === 'countries') {
        setValue(
          (data?.[type] as any[])?.find((item) => item.id === id).country_name,
        );
      } else {
        const item = (data?.[type] as any[])?.find((item) => item.id === id);
        const val = item?.[dataKey];
        setValue(val);
      }
    });
  }, [commonData, type, id, dataKey]);

  return (
    <>{render ? render(value) : <React.Fragment>{value}</React.Fragment>}</>
  );
};

export * from './invoicing-time-frame';
export * from './rate-time-frame';
