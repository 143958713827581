import { IoBusiness, IoPerson } from 'react-icons/io5';
import { useHistory } from 'react-router';
import { Role } from '../../constants';
import { HOME_PATH } from '../../pages';
import { useProfileService } from '../../shared/services';
import './index.scss';

export const ContractorToggle = () => {
  const {
    accountType,
    toggleAccountType,
    actAsClient,
    load: loadProfile,
  } = useProfileService();
  const history = useHistory();

  const toggleAccount = () => {
    toggleAccountType();

    loadProfile();

    history.replace(HOME_PATH);
  };

  return (
    <div className="pg-ContractorToggle" onClick={toggleAccount}>
      <div
        className={`pg-ContractorToggle__Item ${
          actAsClient ? 'pg-ContractorToggle__Item--active' : ''
        }`}
      >
        <IoBusiness />
      </div>
      <div
        className={`pg-ContractorToggle__Item ${
          accountType === Role.CONTRACTOR
            ? 'pg-ContractorToggle__Item--active'
            : ''
        }`}
      >
        <IoPerson />
      </div>
    </div>
  );
};
