import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GridRowParams } from '@mui/x-data-grid';
import {
  Box,
  Button,
  PageTitle,
  CustomTable,
  LayoutContent,
  ContractTypeFilter,
  ContractStatusFilter,
} from '../../../components';
import { Contract } from '..';
import { useApi } from '../../../hooks/use-api';
import { ContractStatus } from '../../../constants';
import { useCompany } from '../../../hooks/use-company';
import { ClientContractsColumns } from '../../../table-definition';
import { ApiQueryParams, useFilters } from '../../../hooks/use-filters';

export const ClientContracts = () => {
  const api = useApi();
  const history = useHistory();
  const { selectedCompany } = useCompany();
  const [filters, setFilters] = useFilters();
  const [isLoading, setIsLoading] = useState(true);
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [totalRowCount, setTotalRowCount] = React.useState(0);

  const view = (params: GridRowParams) => {
    const contractId = params.row?.id;
    const contractStatus = params.row?.status;
    const isUserSelectingText =
      (window.getSelection()?.toString()?.length || 0) >= 1;

    if (isUserSelectingText || !contractId) return;

    if (contractStatus === ContractStatus.DRAFT)
      return history.push(`/edit-contract/${contractId}/general-info`);

    history.push(`/contracts/${contractId}`);
  };

  const fetchContracts = (params: ApiQueryParams) => {
    setIsLoading(true);

    const filtersQuery = params.toString();

    api
      .get<{ data: Contract[]; meta: any }>(
        `/api/client/company/${selectedCompany?.company_id}/contracts?${filtersQuery}`,
      )
      .then(({ data, meta }) => {
        setTotalRowCount(meta?.total || 0);

        setContracts(data);
      })
      .finally(() => setIsLoading(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = React.useMemo(() => debounce(fetchContracts, 300), []);

  const columns = React.useMemo(() => ClientContractsColumns(), []);

  React.useEffect(() => {
    debouncedFetch(filters);
  }, [debouncedFetch, filters]);

  return (
    <>
      <PageTitle>Contracts</PageTitle>

      <LayoutContent size="large">
        <div className="text-end">
          <Button onClick={() => history.push('/create-contract')}>
            New contract
          </Button>
        </div>

        <Box className="py-3">
          <CustomTable
            loading={isLoading}
            rows={contracts}
            columns={columns}
            onRowClick={view}
            serverSide
            filters={filters}
            setFilters={setFilters}
            totalRowCount={totalRowCount}
            customFilters={[ContractTypeFilter, ContractStatusFilter]}
          />
        </Box>
      </LayoutContent>
    </>
  );
};
