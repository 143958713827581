import React from 'react';
import { useApi } from '../../../hooks/use-api';
import { useCompany } from '../../../hooks/use-company';
import { PlaidButton } from '../../../components/plaid-button';
import { Box } from '../../../components';
import { PaymentMethod } from '../../../constants';

const AchPayment = ({ ids }: { ids: string[] }) => {
  const api = useApi();
  const { selectedCompany } = useCompany();

  const pay = (token: string) => {
    api
      .post(
        `/api/client/company/${selectedCompany?.company_id}/payments/transaction`,
        {
          invoice_id: ids,
          billable_id: selectedCompany?.company_id,
          billable_type: 'company',
          payment_method_type: PaymentMethod.STRIPE_BANK,
          payment_method_id: token,
        },
        'json',
      )
      .then(({ data }) => {});
  };

  return (
    <Box>
      <p>To proceed, please select an option below.</p>

      <PlaidButton onSuccessCallback={pay} />
    </Box>
  );
};

export default AchPayment;
