import React from 'react';
import { Input } from '../';
import './index.scss';

// Import will not work as the package doesn't have any types
const trimCanvas = require('trim-canvas').default;

/**
 * Utility method to trime the canvas's
 * background whitespace and to keep only
 * the signature text content.
 */
const trimSignature = (
  canvas: HTMLCanvasElement | null,
): HTMLCanvasElement | null => {
  if (!canvas) return null;

  // copy the canvas to avoid messing with the original canvas
  const copy: HTMLCanvasElement = document.createElement('canvas');

  copy.width = canvas?.width || 0;

  copy.height = canvas?.height || 0;

  copy.getContext('2d')?.drawImage(canvas, 0, 0);

  // then trim it
  return trimCanvas(copy);
};

export const TextSignature = ({
  onChange,
}: {
  onChange?: (data: any) => any;
}) => {
  const [fontSize, setFontSize] = React.useState(48);
  const textContainmentRef = React.createRef<HTMLDivElement>();
  const textSignatureRef = React.createRef<HTMLCanvasElement>();
  const textContainmentContentRef = React.createRef<HTMLDivElement>();
  const [textSignature, setTextSignature] = React.useState<string>('');

  const setSignature = React.useCallback(
    (txt: string) => {
      const hasSignature = !!txt.length;

      if (!hasSignature) {
        return onChange?.(undefined);
      }

      const base64 = trimSignature(textSignatureRef.current)?.toDataURL(
        'image/png',
        1,
      );

      if (base64) {
        onChange?.(base64);
      }
    },
    [onChange, textSignatureRef],
  );

  /**
   * Try to calculate the best-fit font size
   * suitable for displaying inside the canvas
   */
  const calculateFontSize = React.useCallback(
    (size: number) => {
      const containment = textContainmentRef.current;
      const content = textContainmentContentRef.current;
      let value = size;

      if (containment && content) {
        const isLarger = (fs: number) => {
          content.style.fontSize = `${fs}px`;
          let containmentBounds = containment.getBoundingClientRect();
          let contentBounds = content.getBoundingClientRect();
          return contentBounds.width > containmentBounds.width;
        };

        if (isLarger(value)) {
          value = calculateFontSize(value - 1);
        } else if (value < 48 && !isLarger(value + 1)) {
          value = calculateFontSize(value + 1);
        }
      }

      return value;
    },
    [textContainmentContentRef, textContainmentRef],
  );

  /**
   * Draw the signature text into the canvas
   * for a visual feedback to the user
   */
  const drawSignatureTextToCanvas = React.useCallback(
    async (value: string) => {
      const canvasMargin = 0.9;
      const ratio = window.devicePixelRatio || 1.75;
      let canvas = textSignatureRef.current;
      let ctx = canvas?.getContext('2d');

      if (canvas && ctx) {
        const canvasWidth = canvas.clientWidth * ratio;
        const canvasHeight = canvas.clientHeight * ratio;

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        const xPosition = canvasWidth / 2;

        const yPosition = canvasHeight / 2;

        ctx.clearRect(0, 0, canvasWidth, canvasHeight);

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.font = `normal normal normal ${fontSize * ratio}px Kristi`;

        ctx.fillText(
          value,
          xPosition,
          yPosition,
          canvasWidth * canvasMargin, // Prevent text overflowing the canvas width
        );
      }
    },
    [fontSize, textSignatureRef],
  );

  const adjustDisplayFontSize = React.useCallback(() => {
    const size = calculateFontSize(fontSize);

    setFontSize(size);
  }, [calculateFontSize, fontSize]);

  const signatureTextUpdated = (val: string) => {
    setTextSignature(val);
    adjustDisplayFontSize();
    drawSignatureTextToCanvas(val);
    setSignature(val);
  };

  // React.useEffect(() => {}, [
  //   adjustDisplayFontSize,
  //   drawSignatureTextToCanvas,
  //   textSignature,
  // ]);

  return (
    <div className="pg-SignatureText">
      <canvas
        className="pg-SignatureText__Canvas"
        width={360}
        height={127}
        ref={textSignatureRef}
      ></canvas>

      <Input
        className="pg-SignatureText__Input mt-2"
        value={textSignature}
        placeholder="Your name"
        onChange={(event: any) => signatureTextUpdated(event.target.value)}
      />

      <div ref={textContainmentRef} className="pg-SignatureText__Containment">
        <span
          ref={textContainmentContentRef}
          className="pg-SignatureText__Content"
          style={{ fontSize: `${fontSize}px` }}
        >
          {textSignature}
        </span>
      </div>
    </div>
  );
};
