import React, { useCallback } from 'react';
import { useParams } from 'react-router';
import { BiPlus } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { PRESETS_PATH } from '../all';
import { Role } from '../../../constants';
import { RouteConfigItem } from '../../../routes';
import { useToaster } from '../../../hooks/use-toaster';
import { CREATE_PRESET_GROUP_PATH } from '../../preset-group';
import { useApi, useEndpoint, useModal } from '../../../hooks';
import { PresetGroup } from '../../../components/preset-group';
import { ErrorList, PageTitle, Loader, BackButton } from '../../../components';
import {
  OrderMap as IOrderMap,
  Preset as IPreset,
  PresetGroup as IPresetGroup,
} from '../../../interfaces/presets';

import './index.scss';

export const SHOW_PRESET_PATH = '/presets/:id';

export const SHOW_PRESET_ROUTE: RouteConfigItem = {
  exact: true,
  protect: true,
  path: SHOW_PRESET_PATH,
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  render: () => <ShowPreset />,
};

export const ShowPreset = () => {
  const api = useApi();
  const toaster = useToaster();
  const endpoint = useEndpoint();
  const modalStore = useModal();
  const { id } = useParams<{ id?: string }>();
  const [loading, setLoading] = React.useState(false);
  const [preset, setPreset] = React.useState<IPreset | null>(null);

  const isEditable: boolean = React.useMemo(
    () => Boolean(preset?.id) && !preset?.is_default,
    [preset?.id, preset?.is_default],
  );

  const handleErrors = (e: any) => {
    const errors: string[] = Object.values(e?.errors || []);

    toaster.danger({
      message: <ErrorList errors={errors} />,
    });
  };

  const onGroupDelete = (groupId: number) => {
    modalStore
      .confirmModal({
        theme: 'danger',
        confirmBtn: 'Remove',
        message: `Are you sure you want to remove this document?`,
      })
      .result.then(() => {
        api
          .delete(endpoint('preset_group.delete', { id: groupId }))
          .then(fetchPreset)
          .catch(handleErrors);
      })
      .catch(() => {});
  };

  const fetchPreset = useCallback(() => {
    setLoading(true);

    api
      .get(endpoint('presets.get', { id }))
      .then(({ data }) => {
        setPreset(data?.preset);
      })
      .catch((e) => {
        const errors: string[] = Object.values(e?.errors || []);

        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, endpoint]);

  const saveOrder = useCallback(
    (orderMap: IOrderMap[]) => {
      api
        .put(endpoint('preset_group_template.reorder'), { orderMap }, 'json')
        .catch((e) => {
          const errors: string[] = Object.values(e?.errors || []);

          toaster.danger({
            message: <ErrorList errors={errors} />,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, endpoint],
  );

  const updateGroupSettings = (groupId: number, key: string, value: any) => {
    api
      .put(
        endpoint('preset_group.update', { id: groupId }),
        {
          [key]: value,
          preset_id: preset?.id,
        },
        'json',
      )
      .catch((e) => {
        const errors: string[] = Object.values(e?.errors || []);

        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      });
  };

  React.useEffect(() => {
    fetchPreset();
  }, [fetchPreset]);

  if (loading) {
    return <Loader fullscreen />;
  }

  return (
    <>
      <BackButton to={PRESETS_PATH} />

      <div className="pg-ShowPreset">
        <PageTitle>{preset?.name}</PageTitle>

        <div className="pg-ShowPreset__Groups">
          {preset?.groups?.map((group: IPresetGroup) => (
            <PresetGroup
              key={group.id}
              group={group}
              onDelete={onGroupDelete}
              saveOrder={saveOrder}
              updateSettings={updateGroupSettings}
              isEditable={isEditable}
            />
          ))}
        </div>

        {isEditable && (
          <Link
            to={`${CREATE_PRESET_GROUP_PATH}?presetId=${preset?.id}`}
            className="pg-ShowPreset__Groups__New"
          >
            <BiPlus />
            <span>Add Document</span>
          </Link>
        )}
      </div>
    </>
  );
};
