import { Role } from '../constants';
import Storage from '../shared/db/storage';
import { Middleware } from './Middleware';

export class CompanyMustBeOnboarded extends Middleware {
  handle(): boolean {
    const isCompany =
      Storage.getItem('accountType') === Role.CLIENT ||
      Storage.getItem('accountType') === Role.TEAM_MEMBER;

    const isCompanyOnboarded =
      Storage.getItem('selectedCompanyOnboarded') || 'false';

    return isCompany && !JSON.parse(isCompanyOnboarded) ? false : true;
  }

  redirect(): string {
    return '/onboarding';
  }
}
