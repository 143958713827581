import { startCase } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';
import { Tab, Tabs } from '@blueprintjs/core';
import { ContractorType } from '../../../constants';
import { DocumentEditor } from '../../../components';

export const ContentTabs = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string;
  setSelectedTab: (arg0: string) => void;
}) => {
  const { control } = useFormContext();

  return (
    <Tabs
      id="ContentTabs"
      onChange={(to) => setSelectedTab(to as string)}
      selectedTabId={selectedTab}
      className="col-12"
    >
      <Tab
        id={ContractorType.INDIVIDUAL}
        title={`Template Content (${startCase(ContractorType.INDIVIDUAL)})`}
        panel={
          <Controller
            name="content_individual"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={(props) => (
              <DocumentEditor value={props.value} onChange={props.onChange} />
            )}
          />
        }
      />

      <Tab
        id={ContractorType.ENTITY}
        title={`Template Content (${startCase(ContractorType.ENTITY)})`}
        panel={
          <Controller
            name="content_entity"
            control={control}
            rules={{ required: true }}
            defaultValue=""
            render={(props) => (
              <DocumentEditor value={props.value} onChange={props.onChange} />
            )}
          />
        }
      />
    </Tabs>
  );
};
