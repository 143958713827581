import { NavLink } from 'react-router-dom';
import './router-tab-item.scss';

export const RouterTabItem = ({
  children,
  to,
}: {
  children?: React.ReactNode;
  to: string;
}) => {
  return (
    <NavLink
      to={to}
      activeClassName="pg-RouterTabItem--active"
      className="pg-RouterTabItem"
    >
      {children}
    </NavLink>
  );
};
