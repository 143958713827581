import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Controller, useForm, UseFormMethods } from 'react-hook-form';
import { Button, SignaturePad } from '../../components';
import { ContractorType } from '../../constants';
import { FormGenerator } from './forms/form-generator';
import { forms } from './forms/forms';
import './tax-info.scss';

const FormDisplay = {
  w9: 'W9',
  w8ben: 'W8-BEN',
  w8bene: 'W8-BEN-E',
};

const FormInstructionLink = {
  w9: 'https://www.irs.gov/pub/irs-pdf/iw9.pdf',
  w8ben: 'https://www.irs.gov/pub/irs-pdf/iw8ben.pdf',
  w8bene: 'https://www.irs.gov/pub/irs-pdf/iw8bene.pdf',
};

export const FormGeneratorContext = React.createContext<
  UseFormMethods | undefined
>(undefined);

export const TaxInfo = ({
  value,
  renderForm,
  onChange,
  onClose,
  onSubmit,
  entityType = ContractorType.INDIVIDUAL,
}: {
  value?: any;
  renderForm: 'w9' | 'w8ben' | 'w8bene' | undefined;
  onChange?: (value: any) => any;
  onClose?: () => any;
  onSubmit?: (value: any) => any;
  entityType?: ContractorType;
}) => {
  const formHooks = useForm({ defaultValues: value });
  const context = formHooks;
  const [hasSignature, setHasSignature] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const submit = (value: any) => {
    onSubmit?.(value);
  };

  const updateSignature = (
    formData: FormData | undefined | null,
    callback: (...event: any[]) => void,
  ) => {
    const hasValidSignature = formData?.has('signature_file') || false;

    setHasSignature(hasValidSignature);

    callback(hasValidSignature ? formData?.get('signature_file') : null);
  };

  useEffect(() => {
    if (context?.errors?.signature_file && hasSignature) {
      context.clearErrors('signature_file');
    } else if (
      context.formState.isSubmitted &&
      !context?.errors?.signature_file &&
      !hasSignature
    ) {
      context.setError('signature_file', {
        type: 'manual',
        message: 'Signature is required',
      });
    }
  }, [context, hasSignature]);

  if (!renderForm) {
    return null;
  }

  return (
    <FormGeneratorContext.Provider value={context}>
      <form className="pg-TaxForm" onSubmit={context.handleSubmit(submit)}>
        <h5 className="pg-TaxForm__Type">{FormDisplay[renderForm]}</h5>
        <FormGenerator
          entityType={entityType}
          onChange={onChange}
          form={forms[renderForm]}
        />
        <Controller
          rules={{ required: true }}
          control={context.control}
          name="signature_file"
          defaultValue=""
          render={(props) => (
            <div>
              <SignaturePad
                onChange={(formData) =>
                  updateSignature(formData, props.onChange)
                }
              />

              {!hasSignature && isSubmitted ? (
                <p className="text-danger mb-3">Signature is required</p>
              ) : null}
            </div>
          )}
        />

        <div className="pg-TaxForm__Instructions">
          For detailed instructions on how to fill {FormDisplay[renderForm]} tax
          form,{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={FormInstructionLink[renderForm]}
          >
            click here
          </a>
          .
        </div>

        <div className="modal-footer">
          <Button theme="secondary" outline onClick={onClose}>
            Back
          </Button>
          <Button
            type="submit"
            onClick={() => setIsSubmitted(true)}
            disabled={!isEmpty(context.errors)}
          >
            Submit &amp; continue
          </Button>
        </div>
      </form>
    </FormGeneratorContext.Provider>
  );
};
