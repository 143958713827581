import classNames from 'classnames';
import { FaRegCheckCircle } from 'react-icons/fa';
import { RiCheckboxBlankCircleLine } from 'react-icons/ri';
import { MdOutlineRadioButtonChecked } from 'react-icons/md';
import './index.scss';

export type Step = {
  name: string;
};

export const Steps = ({
  steps,
  activeStep = 0,
}: {
  steps: Step[];
  activeStep?: number;
}) => {
  return (
    <div className={classNames('pg-Onboarding__Steps')}>
      {steps.map((item, index) => (
        <div
          key={index}
          className={classNames('pg-StepItem', {
            'pg-StepItem--next': activeStep < index,
            'pg-StepItem--completed': activeStep > index,
            'pg-StepItem--active': activeStep === index,
          })}
        >
          <div className="pg-StepItem__Icon">
            {activeStep === index ? (
              <MdOutlineRadioButtonChecked />
            ) : activeStep > index ? (
              <FaRegCheckCircle />
            ) : (
              <RiCheckboxBlankCircleLine />
            )}
          </div>
          <div className="pg-StepItem__Name">{item.name}</div>
        </div>
      ))}
    </div>
  );
};
