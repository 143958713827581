import { Tag } from '../index';
import { useContractStatus } from '../../hooks';
import { ContractStatus } from '../../constants';

export const ContractStatusTag = ({ status }: { status: ContractStatus }) => {
  const contractStatus = useContractStatus(status);

  return (
    <Tag
      tooltip={contractStatus.tooltip}
      icon={contractStatus.icon}
      status={contractStatus.status}
    >
      {contractStatus.label}
    </Tag>
  );
};
