import { createState } from '@hookstate/core';
import { head, uniqBy } from 'lodash';
import { singleton } from 'tsyringe';
import Storage from '../db/storage';
import { ProfileService } from './profile';

export interface Company {
  company_id: string;
  company_legal_name: string;
  company_team_name: string;
  role_name: string;
  deactivated: boolean;
  started: boolean;
  onboarded: boolean;
  filled_legal_info: boolean;
  invited_team_members: boolean;
}

export interface CompanyUser {
  email: string;
  first_name: string;
  full_name: string;
  last_login: string;
  last_name: string;
  role_name: string;
  title?: string | null;
  user_id: number;
  token: string | null;
}

export interface CompanySettings {
  id: number;
  company_id: number;
  auto_invite: boolean;
  auto_sign: boolean;
  send_invite_email: boolean;
  sign_redirect_url: string | null;
  signature_url?: string | null;
  signature_file_id?: number | null;
  created_at: string;
  updated_at: string;
}

export interface CompanyDetails {
  city: string;
  company_type_id: number | null;
  country_id: number | null;
  created_at: string;
  creator_user_id: number | null;
  id: number;
  incorporation_state_id: number | null;
  name: string;
  registration_number: string;
  vat_id: string;
  notice_email: string;
  state_id: number | null;
  street: string;
  team_name: string;
  updated_at: string;
  users: CompanyUser[];
  zip_code: string;
  started: boolean;
  onboarded: boolean;
  filled_legal_info: boolean;
  invited_team_members: boolean;
  settings: CompanySettings;
}

interface CompanyState {
  companies?: Company[];
  selected: Company;
  selectedCompanyId?: string;
}

@singleton()
export class CompanyService {
  state = createState<CompanyState>({} as CompanyState);

  constructor(private profileService: ProfileService) {
    this.profileService.update.subscribe(({ companies }) => {
      this.setCompanyList(companies);
    });

    const { companies = [] } = profileService.state.value || {};
    this.setCompanyList(companies);
  }

  setCompanyList(companies: any = []) {
    this.setList(
      uniqBy(
        [
          ...companies.map((item: any) => ({
            ...item,
            started: Boolean(item?.started) ?? false,
            filled_legal_info: Boolean(item?.filled_legal_info) ?? false,
            invited_team_members: Boolean(item?.invited_team_members) ?? false,
            onboarded: Boolean(item?.onboarded) ?? false,
          })),
        ],
        'company_id',
      ),
    );
  }

  setList(companies?: Company[]) {
    const fallbackCompany: Company = head(companies) || ({} as Company);

    const selected: Company =
      companies?.find(
        ({ company_id }) =>
          company_id ===
          (Storage.getItem('selectedCompany') ||
            this.state.value.selectedCompanyId),
      ) || fallbackCompany;

    const selectedCompanyId = selected?.company_id;
    const selectedCompanyOnboarded = !!selected?.onboarded || false;

    if (selectedCompanyId) {
      Storage.setItem('selectedCompany', `${selectedCompanyId}`);
      Storage.setItem(
        'selectedCompanyOnboarded',
        `${selectedCompanyOnboarded}`,
      );
    } else {
      Storage.removeItem('selectedCompany');
      Storage.removeItem('selectedCompanyOnboarded');
    }

    this.state.set((state: CompanyState) => ({
      ...state,
      companies,
      selected,
      selectedCompanyId,
    }));
  }

  select = (companyId: string) => {
    const selected = this.state.value.companies?.find(
      ({ company_id }) => company_id === companyId,
    );
    if (selected) {
      const selectedCompanyOnboarded = !!selected?.onboarded || false;

      Storage.setItem('selectedCompany', companyId);
      Storage.setItem(
        'selectedCompanyOnboarded',
        `${selectedCompanyOnboarded}`,
      );

      this.state.set((state) => ({
        ...state,
        selected,
        selectedCompanyId: companyId,
      }));
    }
  };
}
