import React from 'react';
import { Input } from '../input';
import './index.scss';

export const SsnInput = ({
  value = new Array(3).fill(undefined),
  onChange,
}: {
  value?: any[];
  onChange?: (value: number[]) => any;
}) => {
  const secondInputRef = React.createRef<HTMLInputElement>();
  const thirdInputRef = React.createRef<HTMLInputElement>();

  const setValue = (index: number, inputValue: string) => {
    const newValue = value.map((item, i) => {
      if (index === i) {
        return inputValue;
      }
      return item;
    });
    onChange?.(newValue);
  };

  const focusOnNext = (inputRef: React.RefObject<HTMLInputElement>) => {
    inputRef.current?.focus();
  };

  return (
    <div className="pg-SsnInput">
      <Input
        className="pg-SsnInput__Input pg-SsnInput__Input--medium"
        type="text"
        value={value[0]}
        maxLength={3}
        onFocus={(e) => {
          e.target.select();
        }}
        onChange={({ target }) =>
          setValue(0, (target as HTMLInputElement).value)
        }
        onKeyUp={(e) => {
          if ((e.target as HTMLInputElement).value.length === 3) {
            focusOnNext(secondInputRef);
          }
        }}
      />
      <Input
        className="pg-SsnInput__Input pg-SsnInput__Input--small"
        type="text"
        value={value[1]}
        maxLength={2}
        onFocus={(e) => {
          e.target.select();
        }}
        ref={secondInputRef}
        onChange={({ target }) =>
          setValue(1, (target as HTMLInputElement).value)
        }
        onKeyUp={(e) => {
          if ((e.target as HTMLInputElement).value.length === 2) {
            focusOnNext(thirdInputRef);
          }
        }}
      />
      <Input
        className="pg-SsnInput__Input pg-SsnInput__Input--large"
        type="text"
        value={value[2]}
        maxLength={4}
        onFocus={(e) => {
          e.target.select();
        }}
        ref={thirdInputRef}
        onChange={({ target }) =>
          setValue(2, (target as HTMLInputElement).value)
        }
      />
    </div>
  );
};
