import { trim } from 'lodash';
import React from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

interface State {
  readonly inputValue: string;
  readonly value: readonly Option[];
}

export const DomainList = ({
  defaultValue,
  placeholder,
  onChange,
}: {
  defaultValue?: string[];
  placeholder?: string;
  onChange: (e: any) => void;
}) => {
  const [error, setError] = React.useState<any>(null);
  const [state, setState] = React.useState<State>({
    inputValue: '',
    value:
      defaultValue?.map((v) => ({
        label: v,
        value: v,
      })) || [],
  });

  const handleChange = (value: any) => {
    setState((prev: any) => ({ ...prev, value }));

    onChange(value);
  };

  const handleInputChange = (inputValue: string) => {
    setState((prev: any) => ({ ...prev, inputValue }));
  };

  const isValidDomainName = (val: string) => {
    const regex = new RegExp(
      /^(http(s)?:\/\/)?[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/,
    );

    return regex.test(trim(val, '/'));
  };

  const handleKeyDown = (event: any) => {
    const { inputValue, value } = state;

    if (!inputValue) return;

    switch (event.key) {
      case 'Enter':
      case 'Tab': {
        event.preventDefault();

        if (isValidDomainName(inputValue)) {
          const newValue = [...value, createOption(inputValue)];

          setState({
            inputValue: '',
            value: newValue,
          });

          onChange(newValue);
        } else {
          setError('Invalid domain name');
        }
      }
    }
  };

  React.useEffect(() => {
    if (state?.inputValue?.length === 0) {
      setError(null);
    }
  }, [state?.inputValue]);

  return (
    <>
      <CreatableSelect
        components={components}
        inputValue={state?.inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder || 'Type something and press enter...'}
        value={state?.value}
      />
      <small className="text-danger py-2">{error || ''}</small>
    </>
  );
};
