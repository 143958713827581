import React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Role } from '../../../constants';
import { RouteConfigItem } from '../../../routes';
import { useApi, useEndpoint } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import { ErrorList, PageTitle } from '../../../components';
import { Button, FormGroup, Input, TextArea } from '../../../components';

export const CREATE_PRESET_PATH = '/presets/create';

export const CREATE_PRESET_ROUTE: RouteConfigItem = {
  exact: true,
  protect: true,
  path: CREATE_PRESET_PATH,
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  render: () => <CreatePreset />,
};

export const CreatePreset = () => {
  const api = useApi();
  const toaster = useToaster();
  const history = useHistory();
  const endpoint = useEndpoint();
  const [loading, setLoading] = React.useState(false);
  const { register, errors, handleSubmit } = useForm();

  const goBack = () => history.goBack();

  const saveChanges = (data: any) => {
    setLoading(true);

    api
      .post(endpoint('presets.store'), data, 'json')
      .then(() => goBack())
      .catch(({ errors }) => {
        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit(saveChanges)}>
        <PageTitle>New Preset</PageTitle>

        <FormGroup label="Name" errors={errors.name} required>
          <Input
            name="name"
            placeholder="Try a unique name"
            ref={register({ required: true })}
          />
        </FormGroup>

        <FormGroup label="Description" errors={errors.description}>
          <TextArea
            placeholder="Enter some descriptive text..."
            name="description"
            ref={register()}
          />
        </FormGroup>

        <Button onClick={goBack} theme="secondary" outline>
          Cancel
        </Button>
        <Button type="submit" loading={loading}>
          Save
        </Button>
      </form>
    </div>
  );
};
