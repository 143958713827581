import './index.scss';

export const Modal = ({
  title,
  subtitle,
  children,
  footer,
  size,
  onClose,
  showClose,
  className = '',
  width,
  closeOnBackdropClick = false,
}: {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  onClose?: (resolveData?: any) => any;
  showClose?: boolean;
  closeOnBackdropClick?: boolean;
  className?: string;
  width?: string;
}) => {
  const handleBackdropClick = (e: any) => {
    if (closeOnBackdropClick && onClose) {
      onClose(e);
    }
  };
  return (
    <div className="pg-Modal modal d-flex" onClick={handleBackdropClick}>
      <div
        className={`pg-Modal__Dialog modal-dialog ${
          size ? `modal-${size}` : ''
        } ${className}`}
        style={width ? { width } : {}}
      >
        <div className="pg-Modal__Content modal-content">
          {title ? (
            <div className="modal-header">
              <div className="modal-title">
                <h4>{title}</h4>
                <span className="modal-subtitle text-muted">{subtitle}</span>
              </div>
              {showClose ? (
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={onClose}
                ></button>
              ) : null}
            </div>
          ) : showClose ? (
            <button
              type="button"
              className="pg-Modal__CloseBtn btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            ></button>
          ) : null}
          <div className="modal-body">{children}</div>
          {footer ? <div className="modal-footer">{footer}</div> : null}
        </div>
      </div>
    </div>
  );
};
