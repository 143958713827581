import React, { useMemo } from 'react';
import ReactSelect from 'react-select';
import { useApi, useEndpoint } from '../../hooks';
import { ContractTemplate } from '../../interfaces';
import { InputPlaceholder } from '../input-placeholder';

interface TemplateOption {
  label: string;
  value: number | string;
}

export const TemplateSelect = ({
  value,
  onChange,
  readOnly,
  isClearable = false,
}: {
  value?: number;
  onChange?: (value?: number | string) => any;
  readOnly?: boolean;
  isClearable?: boolean;
}) => {
  const api = useApi();
  const endpoint = useEndpoint();
  const [templates, setTemplates] = React.useState<ContractTemplate[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const templateOptions: TemplateOption[] = useMemo(() => {
    return templates.map(
      (p: ContractTemplate) =>
        ({
          label: p.name,
          value: p.id,
        } as TemplateOption),
    );
  }, [templates]);

  const fetchTemplates = React.useCallback(() => {
    setLoading(true);

    api
      .get(endpoint('templates.all'))
      .then(({ data }) => {
        setTemplates(data?.templates || []);
      })

      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, endpoint]);

  React.useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  return !readOnly ? (
    <ReactSelect
      isLoading={loading}
      options={templateOptions}
      isClearable={isClearable}
      value={templateOptions.find((p: TemplateOption) => p.value === value)}
      onChange={(selected) => onChange?.(selected?.value)}
    />
  ) : (
    <InputPlaceholder>
      {templateOptions.find((p: TemplateOption) => p.value === value)?.label ||
        'N/A'}
    </InputPlaceholder>
  );
};
