import React from 'react';
import { useApi } from '../../../hooks/use-api';
import { useCompany } from '../../../hooks/use-company';
import { Box, Button } from '../../../components';
import AddCardMethod from './AddCardMethod';
import { useToaster } from '../../../hooks/use-toaster';
import { PaymentMethod } from '../../../constants';
import { StripeCardPaymentMethod } from '../../../interfaces';
import { isEmpty } from 'lodash';

const EmptyCardsList = () => {
  return <div className="text-center p-5 border">No cards added yet.</div>;
};

const CardList = ({
  cards,
  refresh,
}: {
  cards: Array<StripeCardPaymentMethod>;
  refresh: () => void;
}) => {
  const api = useApi();
  const toast = useToaster();

  const removeCard = (hash: string) => {
    api.delete(`/api/payment/methods/${hash}`).then(() => {
      toast.success({ message: 'Card removed successfully' });

      refresh();
    });
  };

  if (isEmpty(cards)) return <EmptyCardsList />;

  return (
    <>
      {cards.map((card) => (
        <Box
          key={card.id}
          bordered
          className="mb-3 py-2 px-4 d-flex justify-content-between align-items-center"
        >
          <div>
            <p className="mb-0 card-num text-dark">
              <span className="hidden-digits">•••• •••• •••• </span>
              <span>{card?.details?.last4 || 'XXXX'}</span>
            </p>
            <p className="text-secondary mb-0">
              <small>
                Expires at: {card?.details?.exp_month || ''}/
                {card?.details?.exp_year || ''}
              </small>
            </p>
          </div>

          <Button outline theme="danger" onClick={() => removeCard(card.hash)}>
            Remove
          </Button>
        </Box>
      ))}
    </>
  );
};

const ListCards = () => {
  const api = useApi();
  const toast = useToaster();
  const { selectedCompany } = useCompany();
  const [cards, setCards] = React.useState<Array<StripeCardPaymentMethod>>([]);
  const [addNew, setAddNew] = React.useState<boolean>(false);

  const toggleAddNew = () => setAddNew((prev) => !prev);

  const fetchCards = React.useCallback(() => {
    api
      .get(
        `/api/payment/methods?payment_method_type=${PaymentMethod.STRIPE_CARD}&billable_type=company&billable_id=${selectedCompany?.company_id}`,
      )
      .then(({ data: { paymentMethods } }) => {
        setCards(paymentMethods);
      });
  }, [api, selectedCompany?.company_id]);

  const onCardAdded = () => {
    toast.success({ message: 'Card added successfully' });
    fetchCards();
    toggleAddNew();
  };

  React.useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  return (
    <div className="pg-card-method-list">
      {!addNew && <CardList cards={cards} refresh={fetchCards} />}

      <AddCardMethod
        isOpen={addNew}
        onToggle={toggleAddNew}
        onCancel={toggleAddNew}
        onCardAdded={onCardAdded}
      />
    </div>
  );
};

export default ListCards;
