import { ThemeOptions } from '@mui/material';
// REQUIRED for MuiDataGrid styles
import type {} from '@mui/x-data-grid/themeAugmentation';

const BryskTheme: ThemeOptions = {
  typography: {
    fontSize: 14,
    fontFamily: [
      'Inter',
      'system-ui',
      '-apple-system',
      'Segoe UI',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'Noto Sans',
      'Liberation Sans',
      'sans-serif',
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji',
    ].join(','),
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',

          '& .MuiFormControl-root.MuiTextField-root': {
            width: '100%',
            padding: 0,
          },

          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0,
            backgroundColor: '#f9f9f9',
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              justifyContent: 'space-between',
            },
            '& .MuiDataGrid-columnHeaderTitleContainerContent': {
              '& .MuiDataGrid-columnHeaderTitle': {
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '12px',
                color: '#6b778c',
              },
            },
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#fff',

            p: {
              marginBottom: 0,
            },
          },
        },
      },
    },
  },
};

export default BryskTheme;
