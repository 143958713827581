/**
 * Each route can have as many dynamic path variables
 * as needed, the only condition is to to be unique
 * across the same endpoint.
 *
 * - Query parameters are not resolved.
 * - All path variables are required!
 *
 * Ex: `/:id`, `/:id/:userId`, ...etc
 */
export const endpoints = {
  common: {
    acceptingCountries: '/api/countries/accepting-clients',
    svbCountries: '/api/countries/svb',
  },

  templates: {
    all: '/api/templates',
    get: '/api/templates/:id',
    store: '/api/templates',
    update: '/api/templates/:id',
    delete: '/api/templates/:id',
  },

  presets: {
    all: '/api/presets',
    get: '/api/presets/:id',
    store: '/api/presets',
    update: '/api/presets/:id',
    delete: '/api/presets/:id',
  },

  preset_group: {
    get: '/api/presets/groups/:id',
    store: '/api/presets/groups',
    update: '/api/presets/groups/:id',
    delete: '/api/presets/groups/:id',
  },

  preset_group_template: {
    reorder: '/api/presets/group-templates/reorder',
    sync: '/api/presets/group-templates/sync',
    desync: '/api/presets/group-templates/desync',
  },

  template_placeholders: {
    all: '/api/template-placeholders',
  },

  document_types: {
    all: '/api/document-types',
  },

  contractor: {
    documents: '/api/contractor/documents',
  },
};
