import _ from 'lodash';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from '../modal';
import { Input } from '../input';
import { Button } from '../button';
import { FormGroup } from '../form-group';
import { useApi } from '../../hooks/use-api';
import { useToaster } from '../../hooks/use-toaster';

import './index.scss';

export const EditOfficialTitle = ({
  close,
  user,
  company,
  title,
}: {
  close: () => any;
  company: string;
  user: number | string;
  title: string | null;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const [loading, setLoading] = useState(false);
  const { errors, control, handleSubmit, reset } = useForm();

  const save = (data: { title: string }) => {
    setLoading(true);

    api
      .patch(`/api/client/company/${company}/team/${user}`, {
        title: data.title,
      })
      .then(() => {
        toaster.success({
          message: 'Official title was updated successfully.',
        });
        close();
      })
      .catch(({ errors }) => {
        const errorMessage = _.chain(errors).values().flatten().uniq().value();

        toaster.danger({ message: errorMessage });

        close();
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    reset({ title: title || '' });
  }, [reset, title]);

  return (
    <form onSubmit={handleSubmit(save)}>
      <Modal
        onClose={close}
        title="Edit Official Title"
        footer={
          <>
            <Button onClick={close} theme="secondary" outline>
              Cancel
            </Button>
            <Button loading={loading} type="submit">
              Save
            </Button>
          </>
        }
      >
        <FormGroup errors={errors.title} label="Official Title" required>
          <Controller
            name="title"
            control={control}
            rules={{
              required: { value: true, message: 'User title is required' },
            }}
            render={(props) => (
              <Input
                placeholder="Official Title"
                name="title"
                className="py-2"
                value={props.value}
                onChange={props.onChange}
              />
            )}
          />
        </FormGroup>
      </Modal>
    </form>
  );
};
