import { PopoverPosition } from '@blueprintjs/core';
import { Tooltip2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import React from 'react';
import './index.scss';

export interface TagProps
  extends Omit<React.HTMLProps<HTMLDivElement>, 'size'> {
  tooltip?: string;
  icon?: React.ReactNode;
  status?:
    | 'default'
    | 'warning'
    | 'success'
    | 'danger'
    | 'primary'
    | 'secondary';
  size?: 'small' | 'medium' | 'large';
}

export const Tag = ({
  tooltip,
  children,
  icon,
  size = 'medium',
  status = 'default',
  ...props
}: TagProps) => {
  const content = (
    <div className="pg-Tag__Content">
      {icon && <span className="pg-Tag__Icon">{icon}</span>}
      <span className="pg-Tag__Label">{children}</span>
    </div>
  );
  return (
    <div
      className={classNames(
        'pg-Tag',
        `pg-Tag--status-${status} pg-Tag--size-${size}`,
      )}
      {...props}
    >
      {tooltip ? (
        <Tooltip2
          position={PopoverPosition.TOP}
          content={tooltip}
          transitionDuration={-1}
        >
          {content}
        </Tooltip2>
      ) : (
        content
      )}
    </div>
  );
};
