import React, { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { Button } from '../../components';
import { Steps } from '../onboarding/partials/steps';
import { OnboardingActions } from './components/actions';
import { OnboardingHeader } from './components/header';
import { OnboardingLayout } from './components/layout';
import { SelectEntity } from './steps/select-entity';
import { EntityDetails } from './steps/entity-details';
import { PaymentDetails } from './steps/payment-details';
import { SignContract } from './steps/sign-contract';

interface ITalentOnboardingContext {
  goToStep: (stepId: string) => any;
  contractId?: string | null;
}

export const TalentOnboardingContext =
  React.createContext<ITalentOnboardingContext>({} as ITalentOnboardingContext);

export const SELECT_ENTITY_STEP = {
  id: 'select-entity',
  name: 'Contractor type',
};
export const ENTITY_DETAILS_STEP = {
  id: 'entity-details',
  name: 'Contractor details',
};
export const PAYMENT_DETAILS_STEP = {
  id: 'payment-details',
  name: 'Payment details',
};
export const SIGN_CONTRACT_STEP = {
  id: 'sign-contract',
  name: 'Sign contract',
};

const steps = [
  SELECT_ENTITY_STEP,
  ENTITY_DETAILS_STEP,
  PAYMENT_DETAILS_STEP,
  SIGN_CONTRACT_STEP,
];

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const Onboarding = () => {
  const query = useQuery();
  const contractId: string | null = query.get('contract_id');
  const storedStepKey = `onboardingStep:${contractId}`;
  const storedFormValueKey = `onboardingForm:${contractId}`;
  const storedStep = localStorage.getItem(storedStepKey);
  const storedForm = JSON.parse(
    localStorage.getItem(storedFormValueKey) || '{}',
  );
  const methods = useForm({
    shouldUnregister: false,
    defaultValues: storedForm,
  });
  const [activeStep, setActiveStep] = useState(storedStep || steps[0].id);

  const nextStep = () => {
    setActiveStep((current) => {
      const index = steps.findIndex(({ id }) => id === current);
      const next = steps[index + 1].id || current;
      localStorage.setItem(
        storedFormValueKey,
        JSON.stringify(methods.getValues()),
      );
      localStorage.setItem(storedStepKey, next);
      return next;
    });
  };

  const prevStep = () => {
    setActiveStep((current) => {
      const index = steps.findIndex(({ id }) => id === current);
      return steps[index - 1].id || current;
    });
  };

  const goToStep = useCallback((stepId: string) => {
    setActiveStep(stepId);
  }, []);

  const activeIndex = useMemo(() => {
    return steps.findIndex(({ id }) => id === activeStep);
  }, [activeStep]);

  return (
    <FormProvider {...methods}>
      <TalentOnboardingContext.Provider value={{ goToStep, contractId }}>
        <OnboardingLayout
          header={
            <div className="pg-Onboarding__Header">
              <OnboardingHeader>Contract details</OnboardingHeader>
              <Steps steps={steps} activeStep={activeIndex} />
            </div>
          }
          sidebar={<Steps steps={steps} activeStep={activeIndex} />}
        >
          <div className="container-fluid">
            {activeStep === SELECT_ENTITY_STEP.id && (
              <div className="row">
                <div className="col-12 col-xl-10 col-xxl-6">
                  <SelectEntity />
                  <OnboardingActions>
                    <div className="w-100 text-end">
                      <Button theme="primary" onClick={nextStep}>
                        Next
                      </Button>
                    </div>
                  </OnboardingActions>
                </div>
              </div>
            )}

            {activeStep === ENTITY_DETAILS_STEP.id && (
              <div className="row">
                <div className="col-12">
                  <EntityDetails onPrev={prevStep} onNext={nextStep} />
                </div>
              </div>
            )}

            {activeStep === PAYMENT_DETAILS_STEP.id && (
              <div className="row">
                <div className="col-12">
                  <PaymentDetails onPrev={prevStep} onNext={nextStep} />
                </div>
              </div>
            )}

            {activeStep === SIGN_CONTRACT_STEP.id && <SignContract />}
          </div>
        </OnboardingLayout>
      </TalentOnboardingContext.Provider>
    </FormProvider>
  );
};
