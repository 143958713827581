import React from 'react';
import {
  isArray,
  isArrayLikeObject,
  isEmpty,
  isNil,
  isObject,
  size,
  uniq,
  startCase,
} from 'lodash';

export const ErrorList = ({
  errors,
  fallback = 'An error occured while processing your request.',
}: {
  errors: string | any[] | null;
  fallback?: string;
}) => {
  const [errorMessages, setErrorMessages] = React.useState<any[]>([]);

  React.useEffect(() => {
    let messages: any[] = [];

    if (isNil(errors) || isEmpty(errors)) messages = [fallback];
    else if (isObject(errors)) {
      messages = Object.values(errors) || [];
    } else if (isArray(errors)) {
      messages = errors || [];
    } else {
      messages = [errors];
    }

    setErrorMessages(uniq(messages));
  }, [errors, fallback]);

  return (
    <div className="px-2 mb-0">
      {errorMessages?.map((e: any, i: number) => (
        <div key={i} className="mb-1">
          {isArrayLikeObject(e)
            ? e?.map((s: any, i: number) => (
                <div key={s}>
                  <p className="mb-0">{startCase(s)}</p>
                  {i < size(e) - 1 ? <br /> : null}
                </div>
              ))
            : e}
        </div>
      ))}
    </div>
  );
};
