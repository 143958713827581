import React from 'react';
import { Link } from 'react-router-dom';
import { CommonDataDisplay, Highlight, RateTimeFrame } from '../../components';
import { Contract } from './index';
import './contract-list-item.scss';
import { ContractStatusTag, ContractTypeTag } from '../../components/contract';
import { ContractStatus } from '../../constants';

export const ContractListItem = ({
  contract,
  matches,
}: {
  contract: Contract;
  matches?: [start: number, end: number][];
}) => {
  return (
    <div className="pg-ContractListItem">
      <Link
        className="pg-ContractListItem__Link"
        to={`/contracts/${contract.id}`}
      >
        <div className="pg-ContractListItem__BasicInfo">
          <div className="pg-ContractListItem__NameContainer">
            <div className="pg-ContractListItem__Name">
              {matches ? (
                <Highlight string={contract.name} matches={matches} />
              ) : (
                contract.name
              )}
            </div>
            <div className="pg-ContractListItem__Type">
              <ContractTypeTag typeId={contract.contract_type_id} />
            </div>
          </div>
          <div className="pg-ContractListItem__Contractor">
            {contract.invitedContractor}
          </div>
        </div>
        <div className="pg-ContractListItem__RightContent">
          <div className="pg-ContractListItem__RateInfo">
            <div className="pg-ContractListItem__Rate">
              {contract.rate}{' '}
              <CommonDataDisplay
                type="currencies"
                dataKey="iso"
                id={contract.currency_id}
              />
            </div>
            <div className="pg-ContractListItem__RateTimeFrame">
              <RateTimeFrame
                contractTypeId={contract.contract_type_id}
                rateTimeFrameId={contract.rate_time_frame_id}
              />
            </div>
          </div>
          <div className="pg-ContractListItem__Status">
            <ContractStatusTag status={contract.status as ContractStatus} />
          </div>
        </div>
      </Link>
    </div>
  );
};
