import classNames from 'classnames';
import { isEqual } from 'lodash';
import './radio-group.scss';

interface Option {
  label?: string | React.ReactNode;
  value?: any;
  key?: string;
}

export interface RadioGroupProps {
  value?: any;
  options?: Option[];
  name?: string;
  mode?: 'horizontal' | 'vertical';
  onChange?: (selected: any) => any;
}

export const OnboardingRadioGroup = ({
  options,
  value,
  name,
  mode = 'horizontal',
  onChange,
}: RadioGroupProps) => {
  return (
    <div
      className={classNames('pg-OnboardingRadioGroup', {
        'pg-OnboardingRadioGroup--vertical': mode === 'vertical',
      })}
    >
      {options?.map((item) => (
        <label
          key={item?.key || item?.value}
          className={classNames('pg-OnboardingRadioGroup__Item', {
            'pg-OnboardingRadioGroup__Item--checked': isEqual(
              value,
              item.value,
            ),
          })}
        >
          <div className="form-check">
            <input
              className="form-check-input pg-OnboardingRadioGroup__ItemInput"
              type="radio"
              name={name}
              checked={isEqual(value, item.value)}
              onChange={(e) =>
                onChange?.(
                  (e.target as HTMLInputElement).checked ? item.value : '',
                )
              }
            />
            <span className="form-check-label pg-OnboardingRadioGroup__ItemLabel">
              {item.label}
            </span>
          </div>
        </label>
      ))}
    </div>
  );
};
