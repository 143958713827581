import { useEffect, useMemo, useState } from 'react';
import { ContractType } from '../../shared/models';
import { CommonDataService } from '../../shared/services/common-data';
import { useService } from '../../shared/services/use-service';

export enum ContractTypes {
  PayAsYouGo,
  Fixed,
}

export function useContractTypes(id?: number) {
  const commonData = useService(CommonDataService);
  const [contractTypes, setContractTypes] = useState<ContractType[]>([]);
  const [selectedContract, setSelectedContract] = useState<ContractType>();

  useEffect(() => {
    setSelectedContract(contractTypes?.find((type) => type.id === Number(id)));
  }, [contractTypes, id]);

  useEffect(() => {
    commonData
      .get()
      .then(({ contract_types }) =>
        setContractTypes(
          contract_types?.filter(
            ({ name }) => name.toLowerCase() !== 'full-time employment',
          ) || [],
        ),
      );
  }, [commonData]);

  const type = useMemo(() => {
    const name = selectedContract?.name.toLowerCase();
    if (name === 'pay as you go') {
      return ContractTypes.PayAsYouGo;
    } else if (name === 'fixed') {
      return ContractTypes.Fixed;
    }
  }, [selectedContract]);

  return { contractTypes, selectedContract, type };
}
