import React, { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { PageTitle } from '../../components';
import { Flag } from '../../components/flag';
import { useProfileService } from '../../shared/services';
import './index.scss';
import { ClientDashboard } from './Client';
import { ContractorDashboard } from './Contractor';
import { RouteWithMiddlewaresProps } from '../../routes';
import { CompanyMustBeOnboarded } from '../../middlewares';
export interface TeamMemberItem {
  members_count: number;
  country_name: string;
  country_code: string;
}

export const HOME_PATH = '/dashboard';

export const HOME_ROUTE: RouteWithMiddlewaresProps = {
  path: HOME_PATH,
  render: () => <Home />,
  middlewares: [CompanyMustBeOnboarded],
};

export const DashboardItem = ({ children }: any) => (
  <div className="pg-DashboardItem">{children}</div>
);

export const DashboardTeamMembers = ({ data }: { data: TeamMemberItem[] }) => {
  const hasTeamMembers = useMemo(() => !isEmpty(data), [data]);

  const total = useMemo(() => {
    return (
      data?.reduce((total, { members_count }) => (total += members_count), 0) ||
      0
    );
  }, [data]);

  const getPercentage = useCallback(
    (count: number = 0) => {
      return `${(count / total) * 100}%`;
    },
    [total],
  );

  return (
    <div className="pg-DashboardTeamMembers">
      <div className="pg-DashboardTeamMembers__Header">
        <p className="pg-DashboardItem__Title">Your Team</p>

        {hasTeamMembers && (
          <span className="pg-DashboardTeamMembers__Count">
            {total} team member{total !== 1 ? 's' : ''}
          </span>
        )}
      </div>

      <div className="pg-DashboardTeamMembers__Content">
        {!hasTeamMembers && (
          <h6 className="pg-DashboardItem__Empty my-3">No team members</h6>
        )}

        {data?.map((item) => (
          <div
            key={item.country_code}
            className="pg-DashboardTeamMembers__Item"
          >
            <span className="pg-DashboardTeamMembers__ItemFlag">
              <Flag country={item.country_code} />
            </span>
            <div className="pg-DashboardTeamMembers__ItemName">
              {item.country_name}
            </div>
            <div className="pg-DashboardTeamMembers__BarContainer">
              <div className="pg-DashboardTeamMembers__Bar">
                <div
                  className="pg-DashboardTeamMembers__Track"
                  style={{
                    width: getPercentage(item.members_count),
                  }}
                ></div>
              </div>
            </div>
            <div className="pg-DashboardTeamMembers__ItemCount">
              {item.members_count}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const DashboardCountItem = ({
  label,
  count,
  additional,
  icon,
  danger = false,
}: {
  label: string;
  count?: number;
  additional?: React.ReactNode;
  icon?: React.ReactNode;
  danger?: boolean;
}) => {
  return (
    <DashboardItem>
      <div className="pg-DashboardCountItem">
        <div
          className={`pg-DashboardCountItem__Label ${danger ? 'danger' : ''}`}
        >
          <div className="pg-DashboardCountItem__Label__Icon">{icon}</div>
          <span>{label}</span>
        </div>

        <div className="pg-DashboardCountItem__Footer">
          <div className="pg-DashboardCountItem__Footer__Count">
            {count ?? 0}
          </div>
          {!!additional && (
            <div className="pg-DashboardCountItem__Footer__Additional">
              {additional}
            </div>
          )}
        </div>
      </div>
    </DashboardItem>
  );
};

export const Home = () => {
  const { actAsClient } = useProfileService();

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      {actAsClient ? <ClientDashboard /> : <ContractorDashboard />}
    </>
  );
};
