import React from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from '../../components';
import { ContractStatus, Role } from '../../constants';
import { useApi } from '../../hooks';
import { CompanyMustBeOnboarded } from '../../middlewares';
import { RouteConfigItem } from '../../routes';
import { useProfileService } from '../../shared/services';
import { ActiveContract } from './active-contract';
import { ClientContractDetails } from './client-contract-details';

export const CONTRACT_DETAILS_PATH = '/contracts/:id';

export const CONTRACT_DETAILS_ROUTE: RouteConfigItem = {
  path: CONTRACT_DETAILS_PATH,
  permissions: ['read.contracts'],
  middlewares: [CompanyMustBeOnboarded],
  render: ({
    match: {
      params: { id },
    },
  }: any) => {
    return <ContractDetailsRoot id={id} />;
  },
};

export const ContractDetailsContext = React.createContext<{
  reload?: () => any;
  contract?: any;
}>({});

export const ContractDetailsRoot = ({ id }: { id: string }) => {
  const api = useApi();
  const history = useHistory();
  const { accountType, hasRole, profile, setAccountType, toggleAccountType } =
    useProfileService();
  const [contract, setContract] = React.useState<any>(null);

  const isContractor = React.useMemo(
    () => accountType === Role.CONTRACTOR && hasRole(Role.CONTRACTOR),
    [accountType, hasRole],
  );

  const isActiveContract = React.useMemo(
    () => contract?.status === ContractStatus.ACTIVATED,
    [contract?.status],
  );

  const fetchContract = React.useCallback(() => {
    api
      .get(`/api/client/contract/${id}`)
      .then(({ data: { contract } }) => {
        if (contract?.status === ContractStatus.DRAFT) {
          return history.replace(`/edit-contract/${contract?.id}`);
        }

        setContract(contract);
      })
      .catch(() => history.replace('/contracts'));
  }, [api, history, id]);

  React.useEffect(() => {
    fetchContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!contract) return;

    if (
      contract?.invitedContractor === profile?.email &&
      hasRole(Role.CONTRACTOR) &&
      accountType !== Role.CONTRACTOR
    ) {
      setAccountType(Role.CONTRACTOR);
    } else if (
      (contract?.invitedContractor !== profile?.email ||
        !hasRole(Role.CONTRACTOR)) &&
      accountType === Role.CONTRACTOR
    ) {
      toggleAccountType();
    }
  }, [
    accountType,
    contract,
    hasRole,
    profile?.email,
    setAccountType,
    toggleAccountType,
  ]);

  if (!contract) return <Loader fullscreen />;

  if (isActiveContract) return <ActiveContract contract={contract} />;

  return (
    <ClientContractDetails
      isContractor={isContractor}
      contract={contract}
      reload={fetchContract}
    />
  );
};
