import React, { useEffect, useState } from 'react';
import { ActivityTable } from '../../components';
import { useApi } from '../../hooks';
import { DashboardItem } from '.';

export const ContractorDashboard = () => {
  const api = useApi();
  const [data, setData] = useState<any>({});

  useEffect(() => {
    api.get(`/api/contractor/dashboard`).then(({ data }) => setData(data));
  }, [api]);

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-xl-6 mb-4">
          <DashboardItem>
            <p className="pg-DashboardItem__Title">Activity</p>

            <div className="pg-DashboardItem__Content">
              <ActivityTable data={data?.activity} />
            </div>
          </DashboardItem>
        </div>
      </div>
    </>
  );
};
