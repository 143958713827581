export enum ContractStatus {
  DRAFT = 'draft',
  PENDING_CLIENT_SIGNATURE = 'pending_client_signature',
  PENDING_CONTRACTOR_INVITE = 'pending_contractor_invite',
  PENDING_CONTRACTOR_SIGNATURE = 'pending_contractor_signature',
  ACTIVATED = 'activated',
  TERMINATED = 'terminated',
}

export enum PermissionArea {
  COMPANIES = 'companies',
  CONTRACTS = 'contracts',
  PAYMENTS = 'payments',
}

export enum InvoiceStatus {
  DRAFT = 'draft',
  WAITING_APPROVAL = 'waiting_approval',
  APPROVED = 'approved',
  PAID = 'paid',
  DECLINED = 'declined',
}

export enum PaymentStatus {
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  REFUNDED = 'refunded',
  FAILED = 'failed',
}

export enum Role {
  CLIENT = 'client',
  CONTRACTOR = 'contractor',
  TEAM_MEMBER = 'team_member',
}

export enum UserInvitationStatus {
  VALID = 'valid',
  UNREGISTERED = 'unregistered',
  EXPIRED = 'expired',
}

export enum PaymentMethod {
  WIRE = 'wire',
  PAYONEER = 'payoneer',
  STRIPE_BANK = 'stripe_bank',
  STRIPE_CARD = 'stripe_card',
}

export enum ContractorType {
  INDIVIDUAL = 'individual',
  ENTITY = 'entity',
}

export enum TaxForm {
  W8BEN = 'w8ben',
  W8BENE = 'w8bene',
  W9 = 'w9',
}

export enum DocumentCategory {
  AGREEMENT = 'agreement',
  STATEMENT = 'statement',
  COMPLIANCE = 'compliance',
  SUPPLEMENT = 'supplement',
}

export enum DocumentType {
  CONSULTING_AGREEMENT = 'consulting_agreement',

  STATEMENT_OF_WORK = 'statement_of_work',

  W9 = 'w9',
  W8BEN = 'w8ben',
  W8BENE = 'w8bene',
  LICENSES_PERMITS = 'licenses_permits',
  IDENTITY_VERIFICATION = 'identity_verification',
  ADDITIONAL_COMPLIANCE = 'additional_compliance',

  EXHIBIT = 'exhibit',
  APPENDIX = 'appendix',
  AMENDMENT = 'amendment',
  ATTACHMENT = 'attachment',
}
