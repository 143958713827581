import React from 'react';
import ReactSelect from 'react-select';
import { isNil } from 'lodash';
import { isAfter, isDate, isBefore } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import {
  FormGroup,
  CurrencySelect,
  Input,
  DatePicker,
  ButtonPicker,
  CurrencySelectContainer,
} from '../../../components';
import { StepControl, StepControlProps } from '../step-control';
import { useContractTypes } from '../../../hooks';
import { StepSectionContainer } from './step-section-container';
import { uniqBy } from 'lodash';
import './extras.scss';
import { useContractContext } from '..';

const defaultValues = {
  rate: '',
  currency_id: 156,
  contract_type_id: 1,
  rate_time_frame_id: '',
  invoicing_time_frame_id: '',
  first_payment_date: '',
};

export const Compensation = ({
  stepControlProps,
}: {
  stepControlProps: StepControlProps;
}) => {
  const { onNext, onPrev, ...stepProps } = stepControlProps;
  const { contractTypes } = useContractTypes();
  const { contractData, updateData } = useContractContext();
  const { control, register, handleSubmit, reset, errors, watch } = useForm({
    defaultValues,
  });

  const formValue = watch();

  const selectedContractType = React.useMemo(
    () =>
      contractTypes?.find(
        (type) => Number(type.id) === Number(formValue.contract_type_id),
      ),
    [contractTypes, formValue.contract_type_id],
  );

  const rateTimeFrames = React.useMemo(
    () =>
      uniqBy(selectedContractType?.rate_time_frames, ({ id }) => id).map(
        (item) => ({
          label:
            selectedContractType?.name.toLowerCase() === 'pay as you go'
              ? `per ${item.name.toLowerCase()}`
              : item.name,
          value: item.id,
        }),
      ),
    [selectedContractType],
  );

  const timeFrameLabel = (name: 'Month' | 'Week') => {
    const labelsMap = {
      Month: 'Monthly',
      Week: 'Weekly',
    };
    return labelsMap[name] || name;
  };

  const invoiceTimeFrames = React.useMemo(
    () =>
      selectedContractType?.id !== 3
        ? uniqBy(
            selectedContractType?.invoicing_time_frames,
            ({ id }) => id,
          ).map((item) => ({
            label: timeFrameLabel(item.name as 'Month' | 'Week'),
            value: item.id,
          }))
        : [],
    [selectedContractType],
  );

  const next = (data: any) => {
    onNext?.(data);
  };

  const prev = () => {
    onPrev?.(formValue);
  };

  const handleChange = (key: string, value: any, callback?: any) => {
    updateData(key, value);

    if (callback) {
      callback(value);
    }
  };

  React.useEffect(() => {
    reset({
      rate: contractData?.rate,
      currency_id: contractData?.currency_id || 156,
      contract_type_id: contractData?.contract_type_id || 1,
      rate_time_frame_id: contractData?.rate_time_frame_id,
      invoicing_time_frame_id: contractData?.invoicing_time_frame_id,
      first_payment_date: contractData?.first_payment_date,
    });
  }, [reset, contractData]);

  return (
    <form onSubmit={handleSubmit(next)}>
      <StepSectionContainer
        actions={<StepControl onPrev={prev} {...stepProps} />}
      >
        <FormGroup
          label="Contract Type"
          errors={errors.contract_type_id}
          required
        >
          <Controller
            name="contract_type_id"
            control={control}
            rules={{
              required: true,
              validate: (value) => {
                return isNil(value) || isNaN(value)
                  ? 'Contract type is required'
                  : true;
              },
            }}
            render={(props) => (
              <ButtonPicker
                value={props.value}
                onChange={(id) =>
                  handleChange('contract_type_id', id, props.onChange)
                }
                options={contractTypes?.map((type) => ({
                  value: type.id,
                  label: type.name,
                }))}
              />
            )}
          />
        </FormGroup>

        <FormGroup
          label="Rate unit"
          required
          errors={errors.rate_time_frame_id}
        >
          <Controller
            name="rate_time_frame_id"
            control={control}
            rules={{ required: true }}
            defaultValue={1}
            render={({ onChange, value }) => (
              <>
                <ButtonPicker
                  options={rateTimeFrames}
                  onChange={(id) =>
                    handleChange('rate_time_frame_id', id, onChange)
                  }
                  value={value}
                />
              </>
            )}
          />
        </FormGroup>

        {invoiceTimeFrames?.length ? (
          <FormGroup
            label="Payment cycle"
            errors={errors.invoicing_time_frame_id}
            required
          >
            <Controller
              name="invoicing_time_frame_id"
              control={control}
              rules={{ required: true }}
              defaultValue={4}
              render={({ onChange, value }) => (
                <ReactSelect
                  isClearable={false}
                  options={invoiceTimeFrames}
                  value={invoiceTimeFrames?.find(
                    (item) => item.value === value,
                  )}
                  onChange={(selected) =>
                    handleChange(
                      'invoicing_time_frame_id',
                      selected?.value,
                      onChange,
                    )
                  }
                />
              )}
            />
          </FormGroup>
        ) : null}

        <FormGroup
          label={
            <div className="d-flex justify-content-between">
              <span>
                Rate <span className="text-danger">*</span>
              </span>
              <span>
                Currency <span className="text-danger">*</span>
              </span>
            </div>
          }
          errors={{ ...errors.currency_id, ...errors.rate }}
        >
          <Controller
            name="currency_id"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <CurrencySelectContainer>
                <Input
                  placeholder="Amount"
                  name="rate"
                  type="number"
                  step="any"
                  ref={register({ required: true, valueAsNumber: true })}
                  onChange={({ target }) => handleChange('rate', target.value)}
                />
                <CurrencySelect
                  value={props.value}
                  onChange={(id) =>
                    handleChange('currency_id', id, props.onChange)
                  }
                />
              </CurrencySelectContainer>
            )}
          />
        </FormGroup>

        <FormGroup
          label="First payment date"
          errors={errors.first_payment_date}
          required
        >
          <Controller
            name="first_payment_date"
            control={control}
            defaultValue=""
            rules={{
              validate: {
                afterStartDate: (value) => {
                  if (isNil(value)) return 'First start date is required';

                  const startDate = new Date(contractData.start_date);
                  const dateValue = new Date(value);

                  return (
                    (isDate(dateValue) && isAfter(dateValue, startDate)) ||
                    `First payment date must be after the start date ${startDate.toLocaleDateString()}`
                  );
                },
                beforeTerminationDate: (value) => {
                  if (isNil(value)) return 'First start date is required';

                  const endDate = new Date(contractData.termination_date);
                  const dateValue = new Date(value);

                  return (
                    (isDate(dateValue) && isBefore(dateValue, endDate)) ||
                    `First payment date must be before the end date ${endDate.toLocaleDateString()}`
                  );
                },
              },
            }}
            render={(props) => (
              <DatePicker
                value={props.value ? new Date(props.value) : null}
                onChange={(id) =>
                  handleChange('first_payment_date', id, props.onChange)
                }
              />
            )}
          />
        </FormGroup>
      </StepSectionContainer>
    </form>
  );
};
