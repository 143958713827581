import { createState, useState } from '@hookstate/core';
import { head, isEmpty, map, size, snakeCase } from 'lodash';
import { useMemo } from 'react';
import { singleton } from 'tsyringe';
import { Role } from '../../constants';
import Storage from '../db/storage';
import { create } from '../helpers';
import { ApiService } from './api';
import { useService } from './use-service';

export interface Company {
  company_id: string;
  company_legal_name: string;
  company_team_name: string;
  role_id: number;
  role_name: string;
  official_title?: string | null;
  deactivated: boolean;
}

export interface GlobalRole {
  id: number;
  key: string;
  name: string;
}

export interface UserProfile {
  companies: Company[];
  country_id: number;
  created_at: string;
  date_of_birth: string;
  email: string;
  first_name: string;
  id: number;
  last_login: string;
  last_name: string;
  name: string;
  phone_number: string;
  timezone_id: number;
  updated_at: string;
  global_roles: any[];
  entities: any[];
  permissions: any[];
  isContractorEntity?: boolean;
  has_pending_identity_verification: boolean;
  city: string;
  state_id: number;
  street: string;
  zip_code: string;
  identity_verified: boolean;
}

export type AccountType = Role.CONTRACTOR | Role.CLIENT | Role.TEAM_MEMBER;

@singleton()
export class ProfileService {
  state = createState<UserProfile | undefined>(undefined);
  accountType = createState<AccountType | undefined>(this.getAccountType());
  update = create<UserProfile>();

  constructor(private apiService: ApiService) {}

  load() {
    return this.apiService
      .get<{ data: { user: UserProfile } }>('/api/profile')
      .then(({ data }) => {
        const userProfile: UserProfile = data.user;
        this.state.set(userProfile);
        this.accountType.set(this.getAccountType(userProfile));
        this.update.emit(userProfile);
        return data;
      })
      .catch(() => {});
  }

  getAccountType(
    userProfile: UserProfile | undefined = this.state.value,
    ignoreStored: boolean = !this.state.get()?.id,
  ): AccountType {
    if (!userProfile) return Role.CONTRACTOR;

    const storedType = Storage.getItem('accountType');

    if (storedType && !ignoreStored) return storedType as AccountType;

    const roles = map(userProfile?.global_roles, 'key') || [];

    let currentRole = head(roles);

    if (isEmpty(roles) || isEmpty(userProfile?.companies)) {
      currentRole = Role.CONTRACTOR;
    }

    Storage.setItem('accountType', currentRole);

    return currentRole as AccountType;
  }

  canSwitchAccountType(
    userProfile: UserProfile | undefined = this.state.value,
  ) {
    return (
      userProfile &&
      this.hasRole(Role.CONTRACTOR) &&
      userProfile?.companies?.length > 0 &&
      userProfile?.global_roles?.length > 1
    );
  }

  setAccountType(type: AccountType) {
    Storage.setItem('accountType', type);
    this.accountType.set(type);
  }

  toggleAccountType() {
    const roles = map(this.state?.value?.global_roles, 'key') || [];

    if (size(roles) < 2) return this.accountType?.value;

    const newType =
      head(roles?.filter((r) => r !== this.accountType?.value)) ||
      this.accountType?.value;

    this.setAccountType(newType);

    return newType;
  }

  clearData() {
    Storage.clear();
  }

  hasRole(
    role: string,
    userProfile: UserProfile | undefined = this.state.value,
  ) {
    return userProfile?.global_roles?.find((r) => r?.key === snakeCase(role))
      ? true
      : false;
  }
}

export const useProfileService = () => {
  const service = useService(ProfileService);
  const { value } = useState(service.state);
  const { value: accountType } = useState(service.accountType);
  const actAsClient = useMemo(
    () =>
      String(accountType) === String(Role.CLIENT) ||
      String(accountType) === String(Role.TEAM_MEMBER),
    [accountType],
  );

  return {
    accountType,
    profile: value,
    actAsClient: actAsClient,
    hasRole: (role: string) => service.hasRole(role),
    can: (operation: string, companyId: any, area: any) =>
      value?.permissions?.[companyId]?.[area]?.[operation],
    canRead: (companyId: any, area: any) =>
      value?.permissions?.[companyId]?.[area]?.read,
    canWrite: (companyId: any, area: any) =>
      value?.permissions?.[companyId]?.[area]?.write,
    canDelete: (companyId: any, area: any) =>
      value?.permissions?.[companyId]?.[area]?.delete,
    canSwitchAccountType: () => {
      return service.canSwitchAccountType();
    },
    load: () => service.load(),
    clearData: () => service.clearData(),
    setAccountType: (type: AccountType) => service.setAccountType(type),
    toggleAccountType: () => service.toggleAccountType(),
  };
};
