import React from 'react';

export const RadioButton = React.forwardRef(
  (
    {
      children,
      onChange,
      ...props
    }: Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> & {
      onChange?: (event: React.ChangeEvent<HTMLInputElement>) => any;
      children?: React.ReactNode;
    },
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    return (
      <div className="form-check">
        <input
          ref={ref}
          className="form-check-input"
          type="radio"
          onChange={onChange}
          {...props}
        />
        {children ? (
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            {children}
          </label>
        ) : null}
      </div>
    );
  },
);
