import classNames from 'classnames';
import React from 'react';
import { FaBars } from 'react-icons/fa';
import { useLayout } from '../layout';
import './index.scss';

export const PageTitle = ({
  className,
  children,
  subtitle,
  actions,
  content,
  bordered = true,
  ...props
}: Omit<React.HTMLProps<HTMLHeadingElement>, 'content'> & {
  subtitle?: React.ReactNode;
  actions?: React.ReactNode;
  content?: React.ReactNode;
  bordered?: boolean;
}) => {
  const { isClosed, setIsClosed } = useLayout();

  return (
    <div
      className={classNames('pg-PageTitle', className, {
        'pg-PageTitle--bordered': bordered,
      })}
    >
      {content ? (
        <>{content}</>
      ) : (
        <>
          <div className="pg-PageTitle__Content">
            <h1 className="pg-PageTitle__Heading" {...props}>
              {children}
            </h1>
            {subtitle ? (
              <div className="pg-PageTitle__Subtitle">{subtitle}</div>
            ) : null}

            {isClosed && (
              <span
                className="pg-PageTitle__Content__SidebarToggle p-2"
                onClick={() => setIsClosed(false)}
              >
                <FaBars size={18} />
              </span>
            )}
          </div>
          <div className="pg-PageTitle__Action">{actions}</div>
        </>
      )}
    </div>
  );
};
