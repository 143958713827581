import { Action } from '../../components';
import { Position, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { IoEllipsisVerticalSharp } from 'react-icons/io5';
import { useHistory } from 'react-router';
import { useApi, useModal, getContractStatus } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';
import { CopyContract } from '../../pages/contracts/copy-contract';
import { LabelValueRow } from '../../pages/contracts/label-value-row';
import { ContractStatus } from '../../constants';
import { Contract } from '../../pages';

export const ContractActions = ({
  contract,
  onDelete,
}: {
  contract: Contract & { matches?: any };
  onDelete: () => any;
}) => {
  const api = useApi();
  const history = useHistory();
  const modalStore = useModal();
  const toasterStore = useToaster();

  const remove = (name: string, id: number) => {
    modalStore
      .confirmModal({
        title: 'Cancel contract',
        message: (
          <>
            <p>Are you sure you want to cancel this contract?</p>
            <LabelValueRow inline label="Name">
              {name}
            </LabelValueRow>
          </>
        ),
        confirmBtn: 'Delete',
      })
      .result.then(() => {
        api
          .delete(`/api/client/contract/${id}`)
          .then(() => {
            onDelete();
            toasterStore.success({ message: 'Contract was canceled.' });
          })
          .catch(({ errors }) => {
            toasterStore.danger({ message: <>{errors.join(<br />)}</> });
          });
      })
      .catch(() => {});
  };

  const copy = (id: number) => {
    modalStore.openModal((close) => (
      <CopyContract onClose={close} contractId={id} />
    ));
  };

  const edit = (id: any) => {
    history.push(`/edit-contract/${id}/general-info`);
  };

  const view = (contract: Contract) => {
    if (getContractStatus(contract) === ContractStatus.DRAFT)
      return edit(contract.id);

    history.push(`/contracts/${contract.id}`);
  };

  return (
    <Popover2
      minimal
      position={Position.BOTTOM_RIGHT}
      transitionDuration={-1}
      content={
        <Menu>
          {getContractStatus(contract) !== ContractStatus.DRAFT && (
            <MenuItem text="View" onClick={() => view(contract)}></MenuItem>
          )}
          {contract?.is_editable && (
            <MenuItem text="Edit" onClick={() => edit(contract.id)}></MenuItem>
          )}
          {getContractStatus(contract) !== ContractStatus.ACTIVATED && (
            <MenuItem
              text="Delete"
              onClick={() => remove(contract.name, contract.id)}
            ></MenuItem>
          )}

          {getContractStatus(contract) !== ContractStatus.DRAFT && (
            <MenuItem text="Copy" onClick={() => copy(contract.id)}></MenuItem>
          )}
        </Menu>
      }
    >
      <Action>
        <IoEllipsisVerticalSharp />
      </Action>
    </Popover2>
  );
};
