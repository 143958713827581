import { useState, useRef } from 'react';
import { useApi } from '../../hooks/use-api';
import { useToaster } from '../../hooks/use-toaster';
import { Button } from '../button';
import { Modal } from '../modal';
import { useService } from '../../shared/services/use-service';
import { ProfileService } from '../../shared/services';
import { CompanyProfileForm } from '../company-profile';

export const CreateCompany = ({
  close,
  cancel,
  isContractorCompany,
}: {
  close: (data?: any) => any;
  cancel?: () => any;
  isContractorCompany?: boolean;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const [loading, setLoading] = useState(false);
  const profileService = useService(ProfileService);
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);

  const save = (data: any) => {
    setLoading(true);
    api
      .post(
        isContractorCompany ? '/api/contractor/company' : '/api/client/company',
        api.createPayload(data),
      )
      .then(() => {
        profileService.load();
        toaster.success({
          message: `${data?.teamName || data?.name} was created successfully.`,
        });
        close(data);
      })
      .catch(() => {
        toaster.danger({
          message: `An error occured while creating the company.`,
        });
      })
      .finally(() => setLoading(false));
  };

  const onClose = () => {
    return cancel ? cancel() : close();
  };

  const triggerFormSubmit = () => {
    buttonSubmitRef?.current?.click();
  };

  return (
    <Modal
      onClose={onClose}
      title="Create company"
      footer={
        <>
          <Button theme="secondary" outline onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={triggerFormSubmit} loading={loading}>
            Save
          </Button>
        </>
      }
    >
      <CompanyProfileForm
        onSubmit={save}
        editable
        isContractorCompany={isContractorCompany}
      >
        <button className="d-none" type="submit" ref={buttonSubmitRef}>
          Submit
        </button>
      </CompanyProfileForm>
    </Modal>
  );
};
