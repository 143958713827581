import React, { useContext, useEffect } from 'react';
import { AppContext } from '../../app';
import { ButtonTheme } from '../../components';
import { ConfirmModal } from '../../components/confirm-modal';

interface Modal<T> {
  close: (data?: T) => void;
  cancel: () => void;
  content: () => React.ReactNode;
}

type ModalContent<T> = (
  close: (resolveData?: T) => any,
  cancel: () => any,
) => React.ReactNode;

export const ModalsContainer = () => {
  const { modals } = useModal();
  useEffect(() => {
    document.body.style.overflow = modals.length ? 'hidden' : 'auto';
  }, [modals]);

  return (
    <div>
      {modals.map((modal, index) => (
        <React.Fragment key={index}>{modal.content()}</React.Fragment>
      ))}
    </div>
  );
};

export function useModalStore() {
  const [modals, setModals] = React.useState<Modal<unknown>[]>([]);

  function openModal<T>(content: ModalContent<T>) {
    let resolve: (value?: T | PromiseLike<T>) => void;
    let reject: () => void;

    const proimse = new Promise<T>((res, rej) => {
      resolve = res as any;
      reject = rej;
    });

    const modal = {
      close: (data?: T) => {
        resolve(data);
        closeModal(modal);
      },
      cancel: () => {
        reject();
        closeModal(modal);
      },
      content: () => content(modal.close, modal.cancel),
    };

    setModals((modals) => {
      return [...modals, modal as Modal<unknown>];
    });

    return {
      result: proimse,
      modal,
    };
  }

  function closeModal<T>(modal: Modal<T>) {
    setModals((modals) => {
      const remainingModals = modals.filter((item) => item !== modal);
      return remainingModals;
    });
  }

  function closeAllModals() {
    setModals([]);
  }

  function confirmModal({
    title = '',
    message = '',
    cancelBtn = 'Cancel',
    confirmBtn = 'Yes',
    theme,
  }: {
    title?: React.ReactNode;
    message?: React.ReactNode;
    cancelBtn?: React.ReactNode;
    confirmBtn?: React.ReactNode;
    theme?: ButtonTheme;
  }) {
    return openModal((close, cancel) => (
      <ConfirmModal
        close={close}
        cancel={cancel}
        message={message}
        title={title}
        cancelBtn={cancelBtn}
        confirmBtn={confirmBtn}
        theme={theme}
      />
    ));
  }

  return {
    modals,
    openModal,
    closeModal,
    confirmModal,
    closeAllModals,
  };
}

export function useModal() {
  const { modalStore } = useContext(AppContext);
  return modalStore;
}
