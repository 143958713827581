import React from 'react';
import {
  PaymentElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from '../../../components';
import './CheckoutFormStyles.scss';
import { PAYMENT_METHODS_PATH } from '../../../pages';
import { PAYMENT_HISTORY_PATH } from '../../payment-history';

export default function CheckoutForm({ company }: { company: any }) {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [isTransactionSuccessfull, setIsTransactionSuccessfull] =
    React.useState<boolean>(false);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (error) {
      // Show error to your customer (for example, insufficient funds, requires auth (declined by user), ...etc)
    } else {
      if (paymentIntent?.status === 'succeeded') {
        setIsTransactionSuccessfull(true);
        history.push(PAYMENT_HISTORY_PATH);
        // Card has been charged
        // No need to send to our server to mark invoice as paid or something
        // This will be done by handling the Stripe webhook on the server when the payment is done
        // Just show a message like "Your payment has been made, you'll recieve a confirmation email once it's deposited...."
      }
    }
  };

  return (
    <div className="pg-checkout-form">
      {!isTransactionSuccessfull && (
        <form onSubmit={handleSubmit}>
          <PaymentElement />

          <p className="info">
            If you want to securly save this card as a payment method for future
            payments, you can{' '}
            <NavLink to={PAYMENT_METHODS_PATH}>
              add it to your payment methods.
            </NavLink>
          </p>

          <Button type="submit" disabled={!stripe}>
            Pay Invoice
          </Button>
        </form>
      )}

      {isTransactionSuccessfull && (
        <h4>Transaction has been sucessfully paid.</h4>
      )}
    </div>
  );
}
