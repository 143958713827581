import React from 'react';
import './form-group.scss';

export const OnboardingFormGroup = ({
  title,
  children,
  actions,
}: {
  title?: React.ReactNode;
  children?: React.ReactNode;
  actions?: React.ReactNode;
}) => (
  <div className="pg-OnboardingFormGroup">
    <div className="row pg-OnboardingFormGroup__FormContent">
      <div className="col-12 col-xl-4 pg-OnboardingFormGroup__Title mb-3">
        {title}
      </div>
      <div className="col-12 col-xl-8">
        <div className="pg-OnboardingFormGroup__Form">{children}</div>
      </div>
    </div>
    <div className="row justify-content-end">
      <div className="col-xl-8">{actions}</div>
    </div>
  </div>
);
