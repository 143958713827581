import React from 'react';
import { useModal } from '../../hooks';
import { CreateCompany } from '../create-company';
import { Dropdown } from '../dropdown';
import { IoChevronDown, IoAddCircleOutline } from 'react-icons/io5';
import { useState } from '@hookstate/core';
import { useService } from '../../shared/services/use-service';
import { CompanyService } from '../../shared/services/company';
import { useHistory } from 'react-router-dom';
import { Button } from '../button';
import './index.scss';
import { useProfileService } from '../../shared/services';
import { Role } from '../../constants';
import { isEmpty } from 'lodash';

export const CompanySidebarSelect = ({
  disabled = false,
}: {
  disabled?: boolean;
}) => {
  const companyService = useService(CompanyService);
  const { accountType } = useProfileService();

  const {
    value: { selected, companies: companiesList },
  } = useState(companyService.state);
  const history = useHistory();
  const modals = useModal();

  const openCreateModal = () => {
    modals.openModal((close) => <CreateCompany close={close} />);
  };

  const canCreateComapny = React.useMemo(
    () => accountType === Role.CLIENT,
    [accountType],
  );

  const companies = React.useMemo(() => {
    return companiesList?.filter(({ deactivated }) => !deactivated);
  }, [companiesList]);

  if (isEmpty(companies)) {
    return canCreateComapny ? (
      <Button
        theme="link"
        disabled={disabled}
        className="pg-CompanySidebarSelect__CreateLink"
        onClick={() => {
          openCreateModal();
        }}
      >
        <IoAddCircleOutline />
        Create company
      </Button>
    ) : null;
  }

  return (
    <Dropdown
      className="pg-CompanySidebarSelect"
      target={
        <>
          <span className="pg-CompanySidebarSelect__Title">Account</span>
          <div className="pg-CompanySidebarSelect__Selected">
            <span>{selected?.company_team_name}</span>
          </div>
          <IoChevronDown />
        </>
      }
    >
      {(close) => (
        <>
          <div className="pg-Sidebar__Companies">
            {companies?.length
              ? companies.map((company) => (
                  <Button
                    theme="link"
                    key={company.company_id}
                    className="pg-CompanySidebarSelect__Item"
                    onClick={() => {
                      close();
                      companyService.select(company.company_id);
                      history.replace('/');
                    }}
                  >
                    {company.company_team_name}
                  </Button>
                ))
              : null}
            {canCreateComapny && (
              <Button
                theme="link"
                disabled={disabled}
                className="pg-CompanySidebarSelect__Item pg-CompanySidebarSelect__CreateItem"
                onClick={() => {
                  openCreateModal();
                  close();
                }}
              >
                <IoAddCircleOutline />
                Create company
              </Button>
            )}
          </div>
        </>
      )}
    </Dropdown>
  );
};
