import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  FormGroup,
  Input,
  Modal,
  IPList,
  DomainList,
  PermissionList,
} from '..';
import { useApi } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';
import { InfoTooltip } from '../info-tooltip';

export const AddAppKeyModal = ({
  close,
  refresh,
}: {
  close: () => any;
  refresh: () => any;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const { register, control, errors, handleSubmit } = useForm();
  const [loading, setLoading] = React.useState(false);

  const submit = (data: { name: string; scopes: any }) => {
    setLoading(true);
    api
      .post('/api/linked-apps', data, 'json')
      .then(() => {
        toaster.success({ message: 'Application key generated.' });
        refresh();
        close();
      })
      .catch(() => {
        toaster.danger({
          message: 'There was an error creating a new app key.',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Modal
        onClose={close}
        title="New Application Key"
        footer={
          <>
            <Button onClick={close} theme="secondary" outline>
              Cancel
            </Button>
            <Button type="submit" loading={loading}>
              Save
            </Button>
          </>
        }
      >
        <FormGroup label="Name" errors={errors.name} required>
          <Input
            name="name"
            placeholder="Application name"
            ref={register({
              required: true,
            })}
          />
        </FormGroup>

        <FormGroup
          label={
            <InfoTooltip
              usePortal={false}
              content="IP Whitelist"
              tooltip="Only used to validate APIs requests"
            />
          }
          errors={errors?.allowed_ips}
        >
          <Controller
            name="allowed_ips"
            defaultValue={[]}
            control={control}
            render={(props) => (
              <IPList
                placeholder="Leave blank to allow all"
                onChange={(values) => {
                  props.onChange(values.map((v: any) => v?.value));
                }}
              />
            )}
          />
        </FormGroup>

        <FormGroup
          label={
            <InfoTooltip
              usePortal={false}
              content="Domain Whitelist"
              tooltip="Only used to validate iframes"
            />
          }
          errors={errors?.allowed_domains}
        >
          <Controller
            name="allowed_domains"
            defaultValue={[]}
            control={control}
            render={(props) => (
              <DomainList
                placeholder="Leave blank to allow all"
                onChange={(values) => {
                  props.onChange(values.map((v: any) => v?.value));
                }}
              />
            )}
          />
        </FormGroup>

        <FormGroup label="Permissions" errors={errors?.scopes}>
          <Controller
            name="scopes"
            defaultValue={[]}
            control={control}
            render={(props) => (
              <PermissionList
                placeholder="Leave blank to allow all"
                onChange={(values) => {
                  props.onChange(values.map((v: any) => v?.value));
                }}
              />
            )}
          />
        </FormGroup>
      </Modal>
    </form>
  );
};
