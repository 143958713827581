import React from 'react';
import ReactSelect from 'react-select';
import { CommonDataService } from '../../shared/services/common-data';
import { useService } from '../../shared/services/use-service';
import { InputPlaceholder } from '../input-placeholder';

export const TimezoneSelect = ({
  value,
  onChange,
  readOnly,
}: {
  value: number;
  onChange: (value?: number) => any;
  readOnly?: boolean;
}) => {
  const commonData = useService(CommonDataService);
  const [timezones, setTimezones] = React.useState<
    { label: string; value: number }[]
  >([]);

  React.useEffect(() => {
    commonData.get().then(({ timezones }) => {
      setTimezones(
        Object.keys(timezones).map((timezone, index) => ({
          label: timezones[timezone],
          value: index,
        })),
      );
    });
  }, [commonData]);

  return !readOnly ? (
    <ReactSelect
      isClearable={false}
      options={timezones}
      value={timezones.find((timezone) => timezone.value === value)}
      onChange={(selected) => onChange(selected?.value)}
    />
  ) : (
    <InputPlaceholder>
      {timezones.find((timezone) => timezone.value === value)?.label || 'N/A'}
    </InputPlaceholder>
  );
};
