import React from 'react';
import { PlaidAccount } from 'react-plaid-link';
import { IoAddCircleOutline, IoTrash } from 'react-icons/io5';
import { useApi } from '../../../hooks/use-api';
import { useCompany } from '../../../hooks/use-company';
import { Box, Button } from '../../../components';
import AddAchMethod from './AddAchMethod';
import { PaymentMethod } from '../../../constants';
import { StripeBankPaymentMethod } from '../../../interfaces';
import { useToaster } from '../../../hooks/use-toaster';
import './ListSources.scss';
import classNames from 'classnames';

const ListSources = () => {
  const api = useApi();
  const toast = useToaster();
  const { selectedCompany } = useCompany();
  const [sources, setSources] = React.useState<Array<StripeBankPaymentMethod>>(
    [],
  );
  const [addNew, setAddNew] = React.useState<boolean>(false);

  const toggleAddNew = () => setAddNew((prev) => !prev);

  const fetchSources = React.useCallback(() => {
    api
      .get(
        `/api/payment/methods?payment_method_type=${PaymentMethod.STRIPE_BANK}&billable_type=company&billable_id=${selectedCompany?.company_id}`,
      )
      .then(({ data: { paymentMethods } }) => {
        setSources(paymentMethods);
      });
  }, [api, selectedCompany?.company_id]);

  const removeSource = (hash: string) => {
    api.delete(`/api/payment/methods/${hash}`).then(() => {
      toast.success({ message: 'Bank account removed successfully' });

      setSources((prev) =>
        prev?.filter((source) => String(source.hash) !== String(hash)),
      );
    });
  };

  const onSuccess = (accountToCharge: PlaidAccount) => {};

  React.useEffect(() => {
    fetchSources();
  }, [fetchSources]);

  return (
    <div className="pg-ach-method-list">
      {!addNew &&
        sources.map((source) => (
          <Box
            key={source.id}
            bordered
            className="mb-3 py-2 px-4 d-flex justify-content-between align-items-center"
          >
            <div>
              <p className="mb-0 card-num text-dark">
                <span className="hidden-digits">•••• •••• •••• </span>
                <span>{source?.details?.last4 || 'XXXX'}</span>
              </p>
              <p className="text-secondary mb-0">
                <small>{source?.details?.bank_name || ''}</small>
              </p>
            </div>

            <Button
              theme="danger"
              outline
              onClick={() => removeSource(source.hash)}
            >
              <IoTrash />
            </Button>
          </Box>
        ))}

      {addNew && <AddAchMethod onSuccess={onSuccess} />}

      <div
        className={classNames('pg-ListSources__Actions', {
          'pg-ListSources__Actions--align-right': addNew,
        })}
      >
        {addNew && (
          <Button onClick={toggleAddNew} theme="secondary" outline>
            Cancel
          </Button>
        )}
        {!addNew && (
          <Button onClick={toggleAddNew}>
            <IoAddCircleOutline />
            <span className="mx-2">Add New</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default ListSources;
