import React, { useCallback, useEffect, useState } from 'react';
import { Loader, PageTitle } from '../../components';
import { useApi, useEndpoint } from '../../hooks';
import { groupBy } from 'lodash';
import { RouteWithRolesProps } from '../../routes';
import {
  AVAILABLE_TAX_FORMS,
  ComplianceDocumentTypes,
  Role,
} from '../../constants';
import { TaxFormsList } from './tax-forms-list';
import { IdentityDocumentsList } from './identity-documents-list';
import { ComplianceDocumentsList } from './compliance-documents-list';
import { DocumentTypeKey } from '../../types';
import { ComplianceDocument } from '../../interfaces';
import './index.scss';
import { TokenService } from '../../shared/services';
import { useService } from '../../shared/services/use-service';

export const COMPLIANCE_DOCUMENTS_PATH = '/compliance-documents';

export const COMPLIANCE_DOCUMENTS_ROUTE: RouteWithRolesProps = {
  path: COMPLIANCE_DOCUMENTS_PATH,
  roles: [Role.CONTRACTOR],
  render: () => <ComplianceDocuments />,
};

export const ComplianceDocuments = () => {
  const api = useApi();
  const endpoint = useEndpoint();
  const { token } = useService(TokenService);
  const [taxDocs, setTaxDocs] = useState<any>([]);
  const [groupedDocs, setGroupedDocs] = useState<
    Record<DocumentTypeKey, ComplianceDocument[]>
  >({
    [ComplianceDocumentTypes.IdentityVerification]: [],
    [ComplianceDocumentTypes.LicensesPermits]: [],
    [ComplianceDocumentTypes.AdditionalCompliance]: [],
  });
  const [identityDocs, setIdentityDocs] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const reload = () => {
    // Clear out the compliance docs to
    // remove deleted items
    setGroupedDocs({
      [ComplianceDocumentTypes.IdentityVerification]: [],
      [ComplianceDocumentTypes.LicensesPermits]: [],
      [ComplianceDocumentTypes.AdditionalCompliance]: [],
    });

    fetchDocuments();
  };

  const fetchDocuments = useCallback(() => {
    setIsLoading(true);

    api
      .get(endpoint('contractor.documents'))
      .then(({ data: { documents, taxForms, identityVerifications } }) => {
        setTaxDocs(
          documents
            ?.filter((doc: any) =>
              AVAILABLE_TAX_FORMS.includes(doc?.document_type_key),
            )
            ?.map((form: any) => ({
              ...form,
              file_url: `${form.file_url}?auth=${token}`,
            })),
        );

        setIdentityDocs(identityVerifications);

        setGroupedDocs((prev) => ({
          ...prev,
          ...groupBy(
            documents
              ?.filter(
                (doc: any) =>
                  !AVAILABLE_TAX_FORMS.includes(doc?.document_type_key),
              )
              ?.map((doc: any) => ({
                id: doc.id,
                name: doc.file_original_name,
                type: doc.document_type_key,
                url: `${doc.file_url}?auth=${token}`,
                createdAt: doc.created_at,
              })),
            'type',
          ),
        }));
      })
      .finally(() => setIsLoading(false));
  }, [api, endpoint, token]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  if (isLoading) return <Loader fullscreen />;

  return (
    <>
      <PageTitle>Compliance Documents</PageTitle>

      <div className="container">
        <div className="row justify-content-center">
          <div className="pg-ComplianceSection shadow-sm col-xl-10 col-lg-12">
            <TaxFormsList docs={taxDocs} />
          </div>

          <div className="pg-ComplianceSection shadow-sm col-xl-10 col-lg-12">
            <IdentityDocumentsList docs={identityDocs} reload={reload} />
          </div>

          <div className="pg-ComplianceSection shadow-sm col-xl-10 col-lg-12">
            <ComplianceDocumentsList docs={groupedDocs} reload={reload} />
          </div>
        </div>
      </div>
    </>
  );
};
