import { Redirect, Route, useRouteMatch } from 'react-router';
import { RouterTabItem } from './router-tab-item';
import './index.scss';
import React, { useMemo } from 'react';

interface RouterTabConfig {
  label: string;
  url: string;
  render: () => React.ReactNode;
}

export const RouterTabs = ({
  items,
  tabsRender,
  contentRender,
}: {
  items: RouterTabConfig[];
  tabsRender?: (tabsComponent: React.ReactNode) => React.ReactNode;
  contentRender?: (contentComponent: React.ReactNode) => React.ReactNode;
}) => {
  const { url } = useRouteMatch();

  const tabs = useMemo(() => {
    return (
      <div className="pg-RouterTabs__Items">
        {items.map((item) => (
          <RouterTabItem key={item.url} to={`${url}${item.url}`}>
            {item.label}
          </RouterTabItem>
        ))}
      </div>
    );
  }, [items, url]);

  const content = useMemo(() => {
    return (
      <div className="pg-RouterTabs__Content">
        {items.map((item) => (
          <Route
            key={item.url}
            path={`${url}${item.url}`}
            render={item.render}
          />
        ))}

        <Route exact path={`${url}/`}>
          <Redirect to={`${url}${items[0].url}`} />
        </Route>
      </div>
    );
  }, [items, url]);

  if (!items.length) {
    return <></>;
  }
  return (
    <>
      <div className="pg-RouterTabs">
        {tabsRender ? tabsRender(tabs) : tabs}
        {contentRender ? contentRender(content) : content}
      </div>
    </>
  );
};
