import React from 'react';
import { isEmpty, startCase } from 'lodash';
import { FaDownload } from 'react-icons/fa';
import { Box } from '../box';
import { TokenService } from '../../shared/services';
import { useService } from '../../shared/services/use-service';

export const DocumentUrlList = ({
  title,
  urls,
}: {
  title: string;
  urls: string[];
}) => {
  const { token } = useService(TokenService);

  if (isEmpty(urls)) {
    return null;
  }

  return (
    <Box className="mb-4">
      <h5 className="mb-2">{startCase(title)}</h5>

      <ul>
        {urls.map((url: string, index: number) => (
          <li>
            <a
              href={`${url}?auth=${token}`}
              target="_blank"
              rel="noreferrer"
              className="py-2 d-flex justify-content-between align-items-center"
            >
              <span>
                {startCase(title)} #{index + 1}
              </span>
              <FaDownload size="1.5rem" />
            </a>
          </li>
        ))}
      </ul>
    </Box>
  );
};
