import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { Role } from '../../../constants';
import { RouteConfigItem } from '../../../routes';
import { useApi, useEndpoint } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import { ErrorList, PageTitle } from '../../../components';
import {
  Button,
  FormGroup,
  Input,
  TextArea,
  BackButton,
} from '../../../components';
import { Preset as IPreset } from '../../../interfaces/presets';
import { PRESETS_PATH } from '../all';
import { SHOW_PRESET_PATH } from '../show';
import { isNil } from 'lodash';

export const EDIT_PRESET_PATH = '/presets/:id/edit';

export const EDIT_PRESET_ROUTE: RouteConfigItem = {
  exact: true,
  protect: true,
  path: EDIT_PRESET_PATH,
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  render: () => <EditPreset />,
};

export const EditPreset = () => {
  const api = useApi();
  const toaster = useToaster();
  const history = useHistory();
  const endpoint = useEndpoint();
  const { id } = useParams<{ id?: string }>();
  const [loading, setLoading] = React.useState(false);
  const { register, errors, handleSubmit } = useForm();
  const [preset, setPreset] = React.useState<IPreset | null>(null);

  const handleErrors = (e: any) => {
    const errors: string[] = Object.values(e?.errors || []);

    toaster.danger({
      message: <ErrorList errors={errors} />,
    });
  };

  const goBack = () => history.goBack();

  const saveChanges = (data: any) => {
    setLoading(true);

    api
      .put(endpoint('presets.update', { id }), data, 'json')
      .then(goBack)
      .catch(handleErrors)
      .finally(() => setLoading(false));
  };

  const fetchPreset = useCallback(() => {
    setLoading(true);

    api
      .get(endpoint('presets.get', { id }))
      .then(({ data }) => {
        if (isNil(data?.preset?.company_id)) {
          return history.replace(
            SHOW_PRESET_PATH.replace(':id', data?.preset.id),
          );
        }

        setPreset(data?.preset);
      })
      .catch(handleErrors)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, endpoint]);

  React.useEffect(() => {
    fetchPreset();
  }, [fetchPreset]);

  return (
    <div className="container">
      <BackButton to={PRESETS_PATH} />

      <form onSubmit={handleSubmit(saveChanges)}>
        <PageTitle>Edit Preset</PageTitle>

        <FormGroup label="Name" errors={errors.name} required>
          <Input
            name="name"
            placeholder="Try a unique name"
            ref={register({ required: true })}
            defaultValue={preset?.name || ''}
          />
        </FormGroup>

        <FormGroup label="Description" errors={errors.description}>
          <TextArea
            placeholder="Enter some descriptive text..."
            name="description"
            ref={register()}
            defaultValue={preset?.description || ''}
          />
        </FormGroup>

        <Button onClick={goBack} theme="secondary" outline>
          Cancel
        </Button>
        <Button type="submit" loading={loading}>
          Save
        </Button>
      </form>
    </div>
  );
};
