import React from 'react';
import { Button } from '../../../../components';
import { useProfileService } from '../../../../shared/services';
import logo from '../../../../images/logo.svg';

export const Initial = ({
  active,
  onNext,
}: {
  active: boolean;
  onNext: () => void;
}) => {
  const { profile } = useProfileService();

  if (!active) return null;

  return (
    <div>
      <h6 className="mb-4">Hello, {profile?.first_name}</h6>

      <h6 className="mb-5">
        Before you start with your activities please fill out your company
        details and add team members that will use the app.
      </h6>

      <div className="App_Logo">
        <img alt="Brysk" src={logo} />
      </div>

      <Button className="my-5 px-5" onClick={onNext}>
        Start
      </Button>
    </div>
  );
};

export default Initial;
