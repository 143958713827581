import classNames from 'classnames';
import React from 'react';
import './step-section-container.scss';

export const StepSectionContainer = ({
  children,
  rightColumn,
  actions,
  alignCenter,
  noPadding = false,
}: {
  children?: React.ReactNode;
  actions?: React.ReactNode;
  rightColumn?: React.ReactNode;
  alignCenter?: boolean;
  noPadding?: boolean;
}) => {
  return (
    <div
      className={classNames('pg-StepSectionContainer', {
        'pg-StepSectionContainer--no-padding': noPadding,
      })}
    >
      <div
        className={classNames('pg-StepSectionContainer__Content row', {
          'align-items-center': alignCenter,
        })}
      >
        <div className="pg-StepSectionContainer__LeftColumn col-md-6">
          {children}
        </div>
        {rightColumn && (
          <div
            className={classNames(
              'pg-StepSectionContainer__RightColumn col-md-6',
              { 'pg-StepSectionContainer__RightColumn--centered': alignCenter },
            )}
          >
            {rightColumn}
          </div>
        )}
      </div>
      {actions && (
        <div className="pg-StepSectionContainer__Actions row">
          <div className="col-md-6">{actions}</div>
        </div>
      )}
    </div>
  );
};
