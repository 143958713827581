import React, { useMemo } from 'react';
import Select from 'react-select';
import { useHistory } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { Role } from '../../../constants';
import { SHOW_PRESET_PATH } from '../../presets';
import { RouteConfigItem } from '../../../routes';
import { useToaster } from '../../../hooks/use-toaster';
import { ErrorList, PageTitle } from '../../../components';
import {
  useApi,
  useDocumentTypes,
  useEndpoint,
  useQuery,
} from '../../../hooks';
import {
  Button,
  FormGroup,
  Input,
  TextArea,
  BackButton,
} from '../../../components';
import { groupBy } from 'lodash';

export const CREATE_PRESET_GROUP_PATH = '/preset-group/create';

export const CREATE_PRESET_GROUP_ROUTE: RouteConfigItem = {
  exact: true,
  protect: true,
  path: CREATE_PRESET_GROUP_PATH,
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  render: () => <CreatePresetGroup />,
};

export const CreatePresetGroup = () => {
  const api = useApi();
  const toaster = useToaster();
  const history = useHistory();
  const endpoint = useEndpoint();
  const { presetId } = useQuery();
  const documentTypes = useDocumentTypes();
  const [loading, setLoading] = React.useState(false);
  const { control, register, errors, handleSubmit } = useForm();

  const documentTypesOptions = useMemo(() => {
    const groups = groupBy(documentTypes, 'category.key');

    return Object.entries(groups)?.map(([group, types]) => ({
      label: `${group} Documents`,
      options: types?.map((t: any) => ({
        label: t.name,
        value: t.id,
      })),
    }));
  }, [documentTypes]);

  const goBack = () =>
    history.replace(SHOW_PRESET_PATH.replace(':id', presetId));

  const saveChanges = (data: any) => {
    setLoading(true);

    api
      .post(
        endpoint('preset_group.store'),
        {
          ...data,
          preset_id: presetId,
          document_type_id: data?.document_type_id?.value,
        },
        'json',
      )
      .then(() => goBack())
      .catch(({ errors }) => {
        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="container">
      <BackButton to={SHOW_PRESET_PATH.replace(':id', presetId)} />

      <form onSubmit={handleSubmit(saveChanges)}>
        <PageTitle>New Document</PageTitle>

        <div className="mb-5">
          <FormGroup label="Name" errors={errors.name} required>
            <Input
              name="name"
              placeholder="Try a unique name"
              ref={register({ required: true })}
            />
          </FormGroup>

          <FormGroup
            label="Document Type"
            errors={errors?.document_type_id}
            required
          >
            <Controller
              name="document_type_id"
              control={control}
              rules={{ required: true }}
              defaultValue={''}
              render={(props) => (
                <Select
                  options={documentTypesOptions}
                  value={props.value}
                  onChange={props.onChange}
                  placeholder="Select document type..."
                />
              )}
            />
          </FormGroup>

          <FormGroup label="Description" errors={errors.description}>
            <TextArea
              placeholder="Enter some descriptive text..."
              name="description"
              ref={register()}
            />
          </FormGroup>

          <FormGroup label="Signature Requirements">
            <Controller
              name="require_client_signature"
              control={control}
              defaultValue={true}
              render={(props) => (
                <div className="form-check form-switch mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="require_client_signature"
                    checked={Boolean(props.value)}
                    onChange={(e) => props.onChange(e.target.checked ? 1 : 0)}
                  />

                  <label
                    className="form-check-label"
                    htmlFor="require_client_signature"
                  >
                    Require Client Signature
                  </label>
                </div>
              )}
            />

            <Controller
              name="require_contractor_signature"
              control={control}
              defaultValue={true}
              render={(props) => (
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="require_contractor_signature"
                    checked={Boolean(props.value)}
                    onChange={(e) => props.onChange(e.target.checked ? 1 : 0)}
                  />

                  <label
                    className="form-check-label"
                    htmlFor="require_contractor_signature"
                  >
                    Require Contractor Signature
                  </label>
                </div>
              )}
            />
          </FormGroup>
        </div>

        <Button onClick={goBack} theme="secondary" outline>
          Cancel
        </Button>
        <Button type="submit" loading={loading}>
          Save
        </Button>
      </form>
    </div>
  );
};
