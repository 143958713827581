import React from 'react';
import { useApi } from '../use-api';
import { useEndpoint } from '../use-endpoint';

export function useDocumentTypes() {
  const api = useApi();
  const endpoint = useEndpoint();

  const [documentTypes, setDocumentTypes] = React.useState([]);

  React.useEffect(() => {
    api
      .get(endpoint('document_types.all'))
      .then(({ data: { types } }) => {
        setDocumentTypes(types);
      });
  }, [api, endpoint]);

  return React.useMemo(() => documentTypes, [documentTypes]);
}
