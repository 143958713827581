import React from 'react';
import { Button, ErrorList, FormGroup, TextArea } from '../../components';
import { useForm, Controller } from 'react-hook-form';
import { Modal, Input } from '../../components';
import { useApi } from '../../hooks/use-api';
import { useToaster } from '../../hooks/use-toaster';
import { maxLength } from '../../shared';

export const InviteModal = ({
  close,
  cancel,
  contractId,
}: {
  close: (email: string) => any;
  cancel: () => any;
  contractId: number;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const { register, handleSubmit, errors, control, watch } = useForm();
  const [loading, setLoading] = React.useState(false);
  const email = watch('email');

  const invite = (data: { email: string; note: string }) => {
    setLoading(true);
    api
      .post(`/api/client/contract/${contractId}/invite`, data)
      .then(({ data: { message } }) => {
        toaster.success({ message });

        close(email);
      })
      .catch((e: any) => {
        const errors: string[] = Object.values(e?.errors || []);

        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(invite)}>
      <Modal
        title="Invite contractor"
        onClose={close}
        footer={
          <>
            <Button theme="secondary" outline onClick={cancel}>
              Cancel
            </Button>
            <Button loading={loading} type="submit">
              Invite
            </Button>
          </>
        }
      >
        <FormGroup
          label="Email"
          className="mt-3"
          errors={errors.email}
          required
        >
          <Input
            name="email"
            type="text"
            ref={register({ required: true, validate: email })}
          />
        </FormGroup>
        <FormGroup label="Note" className="mt-3" errors={errors.note} required>
          <Controller
            name="note"
            control={control}
            rules={{ required: true, maxLength: maxLength(2000) }}
            render={(props) => (
              <TextArea
                onChange={props.onChange}
                value={props.value || ''}
                max={2000}
              />
            )}
          />
        </FormGroup>
      </Modal>
    </form>
  );
};
