import React from 'react';
import { Link } from 'react-router-dom';
import { Role } from '../../../constants';
import { RouteConfigItem } from '../../../routes';
import { Button, ErrorList, PageTitle, Loader } from '../../../components';
import { TemplatesTable } from '../components/templates-table';
import { CREATE_TEMPLATE_PATH } from '../create';
import { useApi, useEndpoint } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import { ContractTemplate } from '../../../interfaces';
import './index.scss';

export const TEMPLATE_BUILDER_PATH = '/templates';

export const TEMPLATE_BUILDER_ROUTE: RouteConfigItem = {
  exact: true,
  protect: true,
  path: TEMPLATE_BUILDER_PATH,
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  render: () => <TemplateBuilder />,
};

export const TemplateBuilder = () => {
  const api = useApi();
  const toaster = useToaster();
  const endpoint = useEndpoint();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [templates, setTemplates] = React.useState<ContractTemplate[]>([]);

  const fetchTemplates = React.useCallback(() => {
    setLoading(true);

    api
      .get(endpoint('templates.all'))
      .then(({ data }) => {
        setTemplates(data?.templates || []);
      })
      .catch((e) => {
        const errors: string[] = Object.values(e?.errors || []);

        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, endpoint]);

  React.useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  if (loading) {
    return <Loader fullscreen />;
  }

  return (
    <>
      <PageTitle>Templates</PageTitle>

      <div className="text-end mb-2">
        <Link to={CREATE_TEMPLATE_PATH} className="text-decoration-none">
          <Button>Add New</Button>
        </Link>
      </div>

      <TemplatesTable templates={templates} refresh={fetchTemplates} />
    </>
  );
};
