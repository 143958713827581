import React from 'react';
import { useApi } from '../use-api';
import { useEndpoint } from '../use-endpoint';

export function usePlaceholders() {
  const api = useApi();
  const endpoint = useEndpoint();

  const [placeholders, setPlaceholders] = React.useState([]);

  React.useEffect(() => {
    api
      .get(endpoint('template_placeholders.all'))
      .then(({ data: { placeholders } }) => {
        setPlaceholders(placeholders);
      });
  }, [api, endpoint]);

  return React.useMemo(() => placeholders, [placeholders]);
}
