import './index.scss';
export const Loader = ({
  fullscreen,
  force,
}: {
  fullscreen?: boolean;
  force?: boolean;
}) => (
  <div
    className={`pg-Loader ${fullscreen ? 'pg-Loader--fullscreen' : ''} ${
      force ? 'pg-Loader--force' : ''
    }`}
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
