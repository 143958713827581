import './index.scss';

export const FormSection = ({
  children,
  actions,
  footerActions,
  title,
  info,
  bordered = true,
}: {
  children?: React.ReactNode;
  actions?: React.ReactNode;
  footerActions?: React.ReactNode;
  title?: React.ReactNode;
  info?: React.ReactNode;
  bordered?: boolean;
}) => {
  return (
    <div
      className={`pg-FormSection ${bordered ? 'pg-FormSection--bordered' : ''}`}
    >
      {(title || info || actions) && (
        <div className="pg-FormSection__Header">
          <div className="pg-FormSection__TitleContainer">
            <h3 className="pg-FormSection__Title">{title}</h3>
            {info ? <div className="pg-FormSection__Info">{info}</div> : null}
          </div>
          <div className="pg-FormSection__Actions">{actions}</div>
        </div>
      )}
      <div className="pg-FormSection__Content">{children}</div>
      {footerActions ? (
        <div className="pg-FormSection__FooterActions">{footerActions}</div>
      ) : null}
    </div>
  );
};
