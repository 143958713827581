import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  CompanyTypeSelect,
  CountrySelect,
  FormGroup,
  Input,
  useStates,
} from '../../components';
import { useApi, useEndpoint } from '../../hooks';
import { Country as ICountry } from '../../interfaces';
import { email } from '../../shared';
import { CompanyDetails } from '../../shared/services/company';
import { StateSelect } from '../state-select';

export const CompanyProfileForm = ({
  company,
  onSubmit,
  editable = false,
  isContractorCompany = false,
  children,
}: {
  company?: CompanyDetails;
  onSubmit: (data: any) => void;
  editable?: boolean;
  isContractorCompany?: boolean;
  children?: any;
}) => {
  const api = useApi();
  const endpoint = useEndpoint();
  const { register, control, handleSubmit, watch, errors, reset } = useForm();
  const country_id = watch('country_id');
  const states = useStates(country_id);
  const companyOwner = React.useMemo(() => {
    return company?.users?.find(
      (user: any) => user.user_id === company?.creator_user_id,
    );
  }, [company]);

  const [acceptingCountries, setAcceptingCountries] = React.useState<
    ICountry[]
  >([]);

  React.useEffect(() => {
    reset({
      ...company,
      first_name: companyOwner?.first_name,
      last_name: companyOwner?.last_name,
    });
  }, [reset, company, companyOwner]);

  React.useEffect(() => {
    api
      .get(endpoint('common.acceptingCountries'))
      .then(({ data: { countries } }) => {
        setAcceptingCountries(countries);
      });
  }, [api, endpoint]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-100">
      {!isContractorCompany && (
        <FormGroup
          label="Company name"
          labelFor="team_name"
          inline
          errors={errors.team_name}
          required
        >
          <Input
            type="text"
            id="team_name"
            name="team_name"
            readOnly={!editable}
            ref={register({ required: true })}
          />
        </FormGroup>
      )}

      <FormGroup
        label="Legal company name"
        labelFor="name"
        inline
        errors={errors.name}
        required
      >
        <Input
          type="text"
          id="name"
          name="name"
          readOnly={!editable}
          ref={register({ required: true })}
        />
      </FormGroup>

      <FormGroup
        label="Notice email"
        labelFor="notice_email"
        inline
        errors={errors.notice_email}
        required
      >
        <Input
          type="text"
          id="notice_email"
          name="notice_email"
          readOnly={!editable}
          ref={register({ required: true, validate: email })}
        />
      </FormGroup>

      <FormGroup
        label="Registration number"
        labelFor="registration_number"
        errors={errors.registration_number}
        inline
      >
        <Input
          type="text"
          id="registration_number"
          name="registration_number"
          readOnly={!editable}
          ref={register({ required: false })}
        />
      </FormGroup>

      <FormGroup label="VAT ID" labelFor="vat_id" inline errors={errors.vat_id}>
        <Input
          type="text"
          id="vat_id"
          name="vat_id"
          readOnly={!editable}
          ref={register({ required: false })}
        />
      </FormGroup>

      <FormGroup
        label="Company type"
        labelFor="company_type_id"
        inline
        errors={errors?.company_type_id}
        required
      >
        <Controller
          name="company_type_id"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={(props) => (
            <CompanyTypeSelect
              value={props.value}
              readOnly={!editable}
              onChange={props.onChange}
            />
          )}
        />
      </FormGroup>

      <FormGroup
        label="Country"
        labelFor="country_id"
        inline
        errors={errors.country_id}
        required
      >
        <Controller
          name="country_id"
          control={control}
          rules={{ required: true }}
          defaultValue=""
          render={(props) => (
            <CountrySelect
              defaultCountries={acceptingCountries}
              readOnly={!editable}
              value={props.value}
              onChange={props.onChange}
            />
          )}
        />
      </FormGroup>

      {!!states.length && (
        <FormGroup
          label="State of Incorporation"
          labelFor="incorporation_state_id "
          inline
          errors={errors?.incorporation_state_id}
          required
        >
          <Controller
            name="incorporation_state_id"
            control={control}
            defaultValue=""
            rules={{ required: !!states.length }}
            render={(props) => (
              <StateSelect
                countryId={country_id}
                value={props.value}
                readOnly={!editable}
                onChange={props.onChange}
              />
            )}
          />
        </FormGroup>
      )}

      <FormGroup
        label="Street"
        labelFor="street"
        inline
        errors={errors.street}
        required
      >
        <Input
          type="text"
          id="street"
          name="street"
          ref={register({ required: true })}
          readOnly={!editable}
        />
      </FormGroup>

      <FormGroup
        label="City"
        labelFor="city"
        inline
        errors={errors.city}
        required
      >
        <Input
          type="text"
          id="city"
          name="city"
          ref={register({ required: true })}
          readOnly={!editable}
        />
      </FormGroup>

      {!!states.length && (
        <FormGroup label="State" labelFor="state_id" inline>
          <Controller
            name="state_id"
            control={control}
            defaultValue=""
            render={(props) => (
              <StateSelect
                countryId={country_id}
                value={props.value}
                readOnly={!editable}
                onChange={props.onChange}
              />
            )}
          />
        </FormGroup>
      )}

      <FormGroup
        label="Zip code/Post code"
        labelFor="zip_code"
        inline
        errors={errors.zip_code}
        required
      >
        <Input
          type="text"
          id="zip_code"
          name="zip_code"
          ref={register({ required: true })}
          readOnly={!editable}
        />
      </FormGroup>

      {children}
    </form>
  );
};
