import React, { useEffect } from 'react';
import { Button } from '../../components';
import { useApi } from '../../hooks/use-api';
import { useToaster } from '../../hooks/use-toaster';
import { FiPaperclip } from 'react-icons/fi';
import './file-upload.scss';
import { IoDocument, IoTrash } from 'react-icons/io5';
import { isNil } from 'lodash';

export const useFileUpload = () => {
  const api = useApi();
  const toaster = useToaster();
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [loading, setLoading] = React.useState(false);

  const upload = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
    ): Promise<{ ids: number[]; files: File[] }> => {
      if (e.target.files?.length) {
        setLoading(true);
        const files = Array.from(e.target.files);
        const formData = new FormData();
        files.forEach((file) => formData.append('files[]', file));

        return api
          .fetch('/api/files', { method: 'POST', body: formData })
          .then(({ data: { ids } }) => {
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
            setLoading(false);
            return { ids, files };
          })
          .catch((e) => {
            setLoading(false);
            toaster.danger({
              message:
                'Unsupported file type. Allowed file types: .doc, .docx and .pdf.',
            });
            throw e;
          });
      } else {
        return Promise.resolve({ ids: [], files: [] });
      }
    },
    [api, fileInputRef, toaster],
  );

  return {
    fileInputRef,
    upload,
    loading,
  };
};

export const UploadedFile = ({
  value,
  onRemove,
}: {
  value?: { name: string; id?: number; original_name?: string };
  onRemove: (id?: number) => any;
}) => {
  return (
    <div className="pg-FileUpload__File">
      <span className="pg-FileUpload__FileName">
        <IoDocument /> {value?.original_name || value?.name}
      </span>
      <Button
        theme="light"
        outline
        onClick={() => onRemove(value?.id)}
        leftContent={<IoTrash />}
      >
        Remove
      </Button>
    </div>
  );
};

export const FileUpload = ({
  value,
  onChange,
  allowFiles = '.doc,.docx,.pdf',
  showAttached = true,
}: {
  value?: File;
  onChange?: (file?: { id: number; name?: string }) => any;
  allowFiles?: string;
  showAttached?: boolean;
}) => {
  const { fileInputRef, upload, loading } = useFileUpload();

  const onInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      upload(e).then(({ ids, files }) => {
        const file = { id: ids[0], name: files?.[0].name };
        onChange?.(file);
      });
    },
    [onChange, upload],
  );

  useEffect(() => {
    if (fileInputRef.current && isNil(value)) {
      fileInputRef.current.value = '';
    }
  }, [fileInputRef, value]);

  const removeFile = () => {
    onChange?.();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <>
      {value?.name && showAttached ? (
        <UploadedFile value={value} onRemove={removeFile} />
      ) : (
        <div className="pg-FileUpload">
          <Button
            loading={loading}
            theme="light"
            outline
            className="pg-FileUpload__Link"
            leftContent={<FiPaperclip />}
          >
            Attach
            <input
              className="pg-FileUpload__Input"
              ref={fileInputRef}
              type="file"
              onChange={onInputChange}
              accept={allowFiles}
            />
          </Button>
        </div>
      )}
    </>
  );
};
