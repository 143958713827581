import './table-header.scss';
import classNames from 'classnames';

export const Th = ({
  isAction,
  className,
  alignRight,
  ...props
}: React.HTMLProps<HTMLTableHeaderCellElement> & {
  isAction?: boolean;
  alignRight?: boolean;
}) => (
  <th
    className={classNames(
      'pg-TableHeader',
      {
        'pg-TableHeader--action': isAction,
        'pg-TableHeader--align-right': alignRight,
      },
      className,
    )}
    {...props}
  />
);
