import React from 'react';
import queryString from 'query-string';
import { ClientRegister } from './client';
import { RouteConfigItem } from '../../routes';
import { ContractorRegister } from './contractor';

export const REGISTER_PATH = '/register';

export const REGISTER_ROUTE: RouteConfigItem = {
  protect: false,
  path: REGISTER_PATH,
  render: (props) => {
    const queryParams = queryString.parse(props?.location?.search);
    const token = (queryParams?.token as string) || '';
    const contract = (queryParams?.contract as string) || '';
    const email = (queryParams?.email as string) || '';
    return <RootRegister token={token} contract={contract} email={email} />;
  },
};

const RootRegister = ({
  token,
  contract,
  email,
}: {
  token?: string;
  contract?: string;
  email?: string;
}) => {
  const isContractor = token || contract;

  return isContractor ? (
    <ContractorRegister token={token} contract={contract} email={email} />
  ) : (
    <ClientRegister />
  );
};
