import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Box,
  Button,
  ErrorState,
  Loader,
  Modal,
  PageTitle,
  Table,
  Tag,
  Td,
  Th,
  Tr,
} from '../../components';
import queryString from 'query-string';
import { useCompany } from '../../hooks/use-company';
import { currencyFormatter } from '../../shared/helpers';
import { Pay } from './pay';
import { InvoiceDetails } from './invoice-details';
import { Approval, ApprovalModal } from './approval';
import { useApi } from '../../hooks/use-api';
import { useAsync, useModal } from '../../hooks';
import { useProfileService } from '../../shared/services';
import { Contract } from '..';
import { InvoiceItems } from '../contracts/active-contract';
import { RouteConfigItem } from '../../routes';
import { ImFileEmpty } from 'react-icons/im';
import _, { isEmpty } from 'lodash';
import { ContractStatus } from '../../constants';
import { useCommonData } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';
import { CompanyMustBeOnboarded } from '../../middlewares';

export const INVOICES_PATH = '/invoices';

export const INVOICES_ROUTE: RouteConfigItem = {
  path: INVOICES_PATH,
  exact: true,
  permissions: ['read.payments'],
  middlewares: [CompanyMustBeOnboarded],
  render: () => <Invoices />,
};

export const PAY_PATH = '/invoices/pay';

export const PAY_ROUTE: RouteConfigItem = {
  path: PAY_PATH,
  exact: true,
  permissions: ['read.payments'],
  middlewares: [CompanyMustBeOnboarded],
  render: (props) => {
    const idsQuery = queryString.parse(props.location.search).ids as string;
    const ids = idsQuery ? idsQuery.split(',') : [];
    return <Pay ids={ids} />;
  },
};

export interface ApiResponse<T extends string, U> {
  data: {
    [key in T]: U;
  };
}

export interface InvoiceItem {
  amount: number;
  id: string;
  invoice_item_type_id: number;
  note: string;
}

export interface Invoice {
  contract_name: string;
  contract_type_name: string;
  contractor_name: string;
  currency_iso: string;
  currency_name: string;
  gross_pay: number;
  gross_salary: number;
  id: number;
  invoice_items: InvoiceItem[];
  other_compensation: number;
  status: string;
}

export const ApprovePayrollModal = ({
  data,
  selected = [],
  cancel,
  close,
}: {
  data: Invoice[];
  selected?: number[];
  cancel: () => any;
  close: () => any;
}) => {
  const api = useApi();
  const toast = useToaster();
  const history = useHistory();
  const commonData = useCommonData();

  const getTotal = (invoices: Invoice[]) => {
    return invoices.reduce(
      (total: number, { gross_pay }: any) => (total += gross_pay),
      0,
    );
  };

  const approve = async () => {
    const approvedStatus = commonData?.invoice_statuses?.find(
      ({ name }) => name.toLowerCase() === 'approved',
    );
    const draftInvoices = selected.filter(
      (id) =>
        data.find((item) => item.id === id)?.status.toLowerCase() !==
        'approved',
    );
    try {
      await Promise.all(
        draftInvoices.map((id) =>
          api.post(
            `/api/client/invoices/${id}/change-status/${approvedStatus?.id}`,
            {},
          ),
        ),
      );
      history.push(
        `/invoices/pay${selected.length ? `?ids=${selected?.join(',')}` : ''}`,
      );
      close();
    } catch (e) {
      toast.danger({ message: 'Something went wrong' });
    }
  };

  return (
    <Modal
      size="md"
      onClose={close}
      title="Approve"
      showClose
      footer={
        <>
          <Button onClick={cancel} theme="secondary" outline>
            Cancel
          </Button>
          <Button
            onClick={() => {
              approve();
            }}
          >
            Approve Payroll
          </Button>
        </>
      }
    >
      <ApprovalModal
        total={getTotal(
          selected.length
            ? data.filter((item) => selected?.includes(item.id))
            : data,
        )}
        currency={data[0].currency_iso}
      />
    </Modal>
  );
};

const EmptyInvoices = (props: any) => (
  <ErrorState title="No invoices found" icon={<ImFileEmpty />} {...props} />
);

export const InvoicesList = ({
  data = [],
  onItemUpdate,
  selected,
  active,
  setSelected,
  setActive,
}: {
  data?: Invoice[];
  onItemUpdate: () => any;
  selected?: number[];
  active?: number;
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  setActive: React.Dispatch<React.SetStateAction<number | undefined>>;
}) => {
  const modal = useModal();

  const selectedInvoices = useMemo(() => {
    return data.filter(({ id }) => selected?.includes(id));
  }, [data, selected]);

  const getTotal = (invoices: any) => {
    return invoices.reduce(
      (total: number, { gross_pay }: any) => (total += gross_pay),
      0,
    );
  };

  const onCheck = (checked: boolean, id: number) => {
    setSelected((selected) => {
      if (!checked) {
        return selected?.filter((item) => item !== id);
      }
      return [...(selected ? selected : []), id];
    });
  };

  const selectAll = (checked: boolean, items: Invoice[]) => {
    setSelected(checked ? items.map(({ id }) => id) : []);
  };

  const selectedInvoice = (data: Invoice[]) =>
    data.find(({ id }: Invoice) => id === active)!;

  const hasEmpty = (data: Invoice[]) => {
    return data.some(({ gross_pay }) => gross_pay === 0);
  };

  const openApprovalModal = (data: Invoice[]) => {
    if (hasEmpty(data)) {
      modal
        .confirmModal({
          title: 'Invalid invoices selection',
          message: `The value for some selected invoices is 0. Exclude the invalid invoices to proceed with payment`,
          confirmBtn: false,
          cancelBtn: 'Close',
        })
        .result.catch(() => {});
      return;
    }
    if (!getTotal(data)) {
      modal
        .confirmModal({
          title: 'Invalid invoices selection',
          message: `The value for the selected invoices is 0. Can't proceed with payment.`,
          confirmBtn: false,
          cancelBtn: 'Close',
        })
        .result.catch(() => {});
      return;
    }
    modal.openModal((close, cancel) => (
      <ApprovePayrollModal
        close={close}
        cancel={cancel}
        data={data}
        selected={selected}
      />
    ));
  };

  return (
    <div className={`row`}>
      {data.length ? (
        <div className="col-lg-12 col-xl-8 mt-3">
          <Box>
            <Approval
              total={getTotal(selectedInvoices)}
              currency={data[0].currency_iso}
              selected={selected}
              invoices={data}
            />
          </Box>
        </div>
      ) : null}
      <div className={`mt-5`}>
        {isEmpty(data) && <EmptyInvoices />}
        {!isEmpty(data) && (
          <div className="row">
            <div className="col-lg-12 col-xl-8">
              <Box>
                <Table>
                  <thead>
                    <Tr>
                      <Th>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={(e) =>
                              selectAll((e.target as any).checked, data)
                            }
                            checked={data.length === selected?.length}
                          />
                        </div>
                      </Th>
                      <Th>Name</Th>
                      <Th>Type</Th>
                      <Th alignRight>Gross Salary</Th>
                      <Th alignRight>Other Compensation</Th>
                      <Th alignRight>Gross Pay</Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {data.map((invoice) => (
                      <Tr
                        key={invoice.id}
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          setActive((active) =>
                            active === invoice.id ? undefined : invoice.id,
                          )
                        }
                      >
                        <Td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => {
                                onCheck((e.target as any).checked, invoice.id);
                              }}
                              checked={selected?.includes(invoice.id)}
                            />
                          </div>
                        </Td>
                        <Td>
                          <b>{invoice.contractor_name}</b>
                          <div>{invoice.contract_name}</div>
                        </Td>
                        <Td>
                          <Tag>{invoice.contract_type_name}</Tag>
                        </Td>
                        <Td alignRight>
                          {currencyFormatter(
                            invoice.gross_salary,
                            invoice.currency_iso,
                          )}
                        </Td>
                        <Td alignRight>
                          {currencyFormatter(
                            invoice.other_compensation,
                            invoice.currency_iso,
                          )}
                        </Td>
                        <Td alignRight>
                          {currencyFormatter(
                            invoice.gross_pay,
                            invoice.currency_iso,
                          )}
                        </Td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-end">
                  <Button
                    onClick={() => openApprovalModal(data)}
                    disabled={!selected?.length}
                  >
                    Next
                  </Button>
                </div>
              </Box>
            </div>
            {active ? (
              <InvoiceDetails
                invoice={selectedInvoice(data)}
                onItemUpdate={onItemUpdate}
                onClose={() => setActive(undefined)}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

const ClientInvoices = () => {
  const api = useApi();
  const { selectedCompany } = useCompany();
  const [active, setActive] = React.useState<number | undefined>();
  const [selected, setSelected] = React.useState<number[]>([]);
  const initial = useRef(false);

  const request = React.useCallback(
    () =>
      api
        .get<ApiResponse<'invoices', Invoice[]>>(
          `/api/client/company/${selectedCompany?.company_id}/invoices`,
        )
        .then(({ data }) => data?.invoices || [])
        .then((data) => {
          if (!initial.current) {
            setSelected(data.map(({ id }) => id));
            initial.current = true;
          }
          return data;
        }),
    [api, selectedCompany.company_id],
  );

  const req = useAsync(request);

  return (
    <>
      <PageTitle>Invoices</PageTitle>

      {req.status === 'error' ? (
        <ErrorState title="Error loading invoices" />
      ) : req.status === 'pending' ? (
        <Loader fullscreen />
      ) : (
        <InvoicesList
          setActive={setActive}
          setSelected={setSelected}
          selected={selected}
          active={active}
          onItemUpdate={() => req.execute()}
          data={req.value}
        />
      )}
    </>
  );
};

type ContractorInvoiceState = {
  contractName: string;
  contractId: string;
  invoices: Invoice[];
  contract: Contract;
}[];

const ContractorInvoices = () => {
  const api = useApi();
  const [data, setData] = useState<ContractorInvoiceState>([]);

  const getTotal = (invoices: Invoice[] = []) => {
    return invoices.reduce(
      (total: number, { gross_pay }: any) => (total += gross_pay),
      0,
    );
  };

  const loadInvoices = React.useCallback(() => {
    return api
      .get<{ data: Contract[] }>(`/api/contractor/contracts`)
      .then(({ data }) => {
        return Promise.all(
          data
            .filter((contract) => contract.status === ContractStatus.ACTIVATED)
            .map((contract) =>
              api
                .get<ApiResponse<'invoices', Invoice[]>>(
                  `/api/client/contract/${contract.id}/invoices`,
                )
                .then(({ data: { invoices } }) => {
                  return {
                    contractId: contract.id,
                    contractName: contract.name,
                    invoices: invoices || [],
                    contract,
                  };
                }),
            ),
        );
      })
      .then((data) => {
        setData(data);
      });
  }, [api]);

  useEffect(() => {
    loadInvoices();
  }, [loadInvoices]);

  return (
    <>
      <PageTitle>Invoices</PageTitle>

      {isEmpty(data) && <EmptyInvoices />}

      {data.map((item) =>
        _.isEmpty(item?.invoices) ? (
          <EmptyInvoices key={item.contractId} />
        ) : (
          <div key={item.contractId}>
            <div className="row justify-content-center">
              <div className="col-lg-12 col-xl-6">
                <h3 style={{ margin: '1rem 0' }}>{item.contractName}</h3>
                <Box>
                  <div className="pg-ActiveContractInvoices__Content">
                    <div className="pg-ActiveContractInvoices__Total">
                      <div className="pg-ActiveContractInvoices__TotalCount">
                        {currencyFormatter(
                          getTotal(item.invoices),
                          item.invoices[0].currency_iso,
                        )}
                      </div>

                      <div className="pg-ActiveContractInvoices__TotalLabel">
                        Total in {item.invoices[0].currency_iso}
                      </div>
                    </div>
                  </div>
                  <div className="pg-ActiveContractInvoices__Details">
                    <h6>Invoice details</h6>
                    <div>
                      {item.invoices.map((invoice) => (
                        <div
                          key={invoice.id}
                          className="pg-ActiveContractInvoices__InvoiceItem"
                        >
                          <div className="pg-ActiveContractInvoices__InvoiceItemSection">
                            <div className="pg-ActiveContractInvoices__InvoiceItemLabel">
                              Gross salary:{' '}
                            </div>
                            <div className="pg-ActiveContractInvoices__InvoiceItemValue">
                              {currencyFormatter(
                                invoice.gross_salary,
                                invoice.currency_iso,
                              )}
                            </div>
                          </div>
                          <div className="pg-ActiveContractInvoices__InvoiceItemSection">
                            <div className="pg-ActiveContractInvoices__InvoiceItemLabel">
                              Other compensation:{' '}
                            </div>
                            <div className="pg-ActiveContractInvoices__InvoiceItemValue">
                              {currencyFormatter(
                                invoice.other_compensation,
                                invoice.currency_iso,
                              )}
                            </div>
                          </div>
                          <div className="pg-ActiveContractInvoices__InvoiceItemSection">
                            <div className="pg-ActiveContractInvoices__InvoiceItemLabel">
                              Gross pay:{' '}
                            </div>
                            <div className="pg-ActiveContractInvoices__InvoiceItemValue">
                              {currencyFormatter(
                                invoice.gross_pay,
                                invoice.currency_iso,
                              )}
                            </div>
                          </div>
                          <InvoiceItems
                            reload={() => loadInvoices()}
                            invoice={invoice}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        ),
      )}
    </>
  );
};

export const Invoices = () => {
  const { actAsClient } = useProfileService();

  return actAsClient ? <ClientInvoices /> : <ContractorInvoices />;
};
