import React from 'react';
import { Input } from '../input';
import './index.scss';

export const EinInput = ({
  value = new Array(2).fill(undefined),
  onChange,
}: {
  value?: any[];
  onChange?: (value: number[]) => any;
}) => {
  const secondInputRef = React.createRef<HTMLInputElement>();

  const setValue = (index: number, inputValue: string) => {
    const newValue = value.map((item, i) => {
      if (index === i) {
        return inputValue;
      }
      return item;
    });
    onChange?.(newValue);
  };

  const focusOnNext = (inputRef: React.RefObject<HTMLInputElement>) => {
    inputRef.current?.focus();
  };

  return (
    <div className="pg-EinInput">
      <Input
        className="pg-EinInput__Input pg-EinInput__Input--prefix"
        type="text"
        value={value[0]}
        maxLength={2}
        onFocus={(e) => {
          e.target.select();
        }}
        onChange={({ target }) =>
          setValue(0, (target as HTMLInputElement).value)
        }
        onKeyUp={(e) => {
          if ((e.target as HTMLInputElement).value.length === 2) {
            focusOnNext(secondInputRef);
          }
        }}
      />

      <Input
        className="pg-EinInput__Input pg-EinInput__Input--number"
        type="text"
        value={value[1]}
        maxLength={7}
        ref={secondInputRef}
        onFocus={(e) => {
          e.target.select();
        }}
        onChange={({ target }) =>
          setValue(1, (target as HTMLInputElement).value)
        }
      />
    </div>
  );
};
