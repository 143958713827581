import { IoWarningOutline } from 'react-icons/io5';
import './index.scss';

export const ErrorState = ({
  title,
  subtitle,
  children,
  icon = <IoWarningOutline />,
}: {
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  icon?: React.ReactNode;
}) => {
  return (
    <div className="pg-ErrorState">
      <div className="pg-ErrorState__Icon">{icon}</div>
      {title ? <h2>{title}</h2> : null}
      {subtitle ? <p>{subtitle}</p> : null}
      <div className="pg-ErrorState__Content">{children}</div>
    </div>
  );
};
