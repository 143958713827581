import React from 'react';
import { useCommonData } from '../../hooks/use-api';

export const RateTimeFrame = ({
  contractTypeId,
  rateTimeFrameId,
}: {
  contractTypeId: number;
  rateTimeFrameId: number;
}) => {
  const common = useCommonData();
  const [value, setValue] = React.useState<string | undefined>('');

  const getRateTimeFrame = React.useCallback(
    (contractTypeId: number, timeFrameId: number) => {
      return common?.contract_types
        ?.find((contract) => contract.id === contractTypeId)
        ?.rate_time_frames.find((timeFrame) => timeFrame.id === timeFrameId)
        ?.name;
    },
    [common?.contract_types],
  );

  React.useEffect(() => {
    setValue(getRateTimeFrame(contractTypeId, rateTimeFrameId));
  }, [contractTypeId, rateTimeFrameId, common, getRateTimeFrame]);

  return <>{value}</>;
};
