import React from 'react';
import { IoChevronBackOutline } from 'react-icons/io5';
import { Link, useHistory } from 'react-router-dom';

import './index.scss';

export const BackButton = ({ to }: { to?: string }) => {
  const history = useHistory();

  const goBack = () => history.goBack();

  if (to) {
    return (
      <Link to={to} className="pg-Back__Button">
        <IoChevronBackOutline />
        <span>Back</span>
      </Link>
    );
  }

  return (
    <button onClick={goBack} className="pg-Back__Button">
      <IoChevronBackOutline />
      <span>Back</span>
    </button>
  );
};
