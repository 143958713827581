import React from 'react';
import { RouteConfigItem } from '../../routes';
import { Loader, PageTitle, PaymentsTable } from '../../components';
import { useApi, useCompany } from '../../hooks';
import { Role } from '../../constants';
import { CompanyMustBeOnboarded } from '../../middlewares';

export const PAYMENT_HISTORY_PATH = '/payment-history';

export const PAYMENT_HISTORY_ROUTE: RouteConfigItem = {
  path: PAYMENT_HISTORY_PATH,
  render: () => <PaymentHistory />,
  permissions: ['read.payments'],
  middlewares: [CompanyMustBeOnboarded],
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
};

export const PaymentHistory = () => {
  const api = useApi();
  const { selectedCompany } = useCompany();
  const [loading, setLoading] = React.useState(false);
  const [payments, setPayments] = React.useState([]);

  React.useEffect(() => {
    setLoading(true);

    api
      .fetch(
        `/api/client/company/${selectedCompany?.company_id}/payments/history`,
      )
      .then(({ data }) => {
        setPayments(data);
      })
      .finally(() => setLoading(false));
  }, [api, selectedCompany.company_id]);

  return (
    <>
      <PageTitle>Payment History</PageTitle>

      {loading ? <Loader fullscreen /> : <PaymentsTable data={payments} />}
    </>
  );
};
