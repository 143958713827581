import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormGroup, Input, Modal } from '..';
import { useApi } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';
import { InfoTooltip } from '../info-tooltip';
import { WebhookEventsList } from '../webhook-events-list';

export const AddWebhookSubscriberModal = ({
  close,
  refresh,
}: {
  close: () => any;
  refresh: () => any;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const { register, control, errors, handleSubmit } = useForm();
  const [loading, setLoading] = React.useState(false);

  const submit = (data: { name: string; scopes: any }) => {
    setLoading(true);
    api
      .post('/api/client/webhook-subscribers', data, 'json')
      .then(() => {
        toaster.success({ message: 'Application key generated.' });
        refresh();
        close();
      })
      .catch(() => {
        toaster.danger({
          message: 'There was an error creating a new app key.',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Modal
        onClose={close}
        title="New Webhook Subscriber"
        footer={
          <>
            <Button onClick={close} theme="secondary" outline>
              Cancel
            </Button>
            <Button type="submit" loading={loading}>
              Save
            </Button>
          </>
        }
      >
        <FormGroup label="Subscriber Name" errors={errors.name}>
          <Input name="name" placeholder="Subscriber name" ref={register()} />
        </FormGroup>

        <FormGroup label="Redirect URL" errors={errors.redirect_url} required>
          <Input
            name="redirect_url"
            placeholder="Redirect URL"
            ref={register({
              required: true,
            })}
          />
        </FormGroup>

        <FormGroup
          label={
            <InfoTooltip
              usePortal={false}
              content="Events"
              tooltip="Webhook events to subscribe"
            />
          }
          errors={errors?.events}
        >
          <Controller
            name="events"
            defaultValue={[]}
            control={control}
            render={(props) => (
              <WebhookEventsList
                placeholder="Select events to subscribe"
                onChange={(values) => {
                  props.onChange(values.map((v: any) => v?.value));
                }}
              />
            )}
          />
        </FormGroup>
      </Modal>
    </form>
  );
};
