import { useState } from '@hookstate/core';
import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../app';
import { LOGIN_PATH } from '../../pages';
import { AuthService, AuthState, TokenPayload } from '../../shared/services';
import { useService } from '../../shared/services/use-service';

export function useAuthStore() {
  const history = useHistory();
  const authService = useService(AuthService);
  const state = useState(authService.state);

  useEffect(() => {
    if (!state.isAuthenticated) {
      history.push(LOGIN_PATH);
    }
  }, [history, state.isAuthenticated]);

  const login = (payload: { username?: string; password?: string }) => {
    return authService.login(payload);
  };

  const logout = () => {
    authService.logout();
    history.push(LOGIN_PATH);
  };

  const setLoginData = (data: TokenPayload) => {
    authService.setLoginData(data);
  };

  const setState = (data: AuthState) => {
    authService.setState(data);
  };

  return {
    completed: state.value.complete,
    isAuthenticated: state.value.isAuthenticated,
    setState,
    setLoginData,
    login,
    logout,
  };
}

export function useAuth() {
  const { authStore } = useContext(AppContext);
  return authStore;
}
