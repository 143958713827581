import React from 'react';
import { TeamMembers } from '../../../../components/team-members-invite';

export const TeamMembersStep = ({
  onNext,
  onSkip,
  active = false,
  ...props
}: {
  active?: boolean;
  nextText?: string;
  cancelText?: string;
  onNext: () => void;
  onSkip: () => void;
}) => {
  return active ? (
    <TeamMembers onNext={onNext} onSkip={onSkip} {...props} />
  ) : null;
};
