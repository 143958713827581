import React from 'react';
import queryString from 'query-string';
import { RouteConfigItem } from '../../routes';
import { Loader } from '../../components';
import { useAuth } from '../../hooks';
import { useHistory } from 'react-router';
import Storage from '../../shared/db/storage';
import { TokenPayload, useProfileService } from '../../shared/services';

export const IFRAME_HANDLER = '/iframe/handler';

export const IFRAME_HANDLER_ROUTE: RouteConfigItem = {
  protect: false,
  path: IFRAME_HANDLER,
  render: (props) => {
    const queryParams = queryString.parse(props?.location?.search);

    const targetQueryParams = queryString.exclude(props?.location?.search, [
      'app_key',
      'redirect_to',
      'access_token',
      'refresh_token',
      'token_type',
      'expires_in',
    ]);

    const appKey = (queryParams?.app_key as string) || '';
    const redirectTo = (queryParams?.redirect_to as string) || '';

    const accessToken = (queryParams?.access_token as string) || '';
    const refreshToken = (queryParams?.refresh_token as string) || '';
    const tokenType = (queryParams?.token_type as string) || 'Bearer';
    const expiresIn = (queryParams?.expires_in as string) || '3600';

    const credentials: TokenPayload = {
      access_token: accessToken,
      refresh_token: refreshToken,
      expires_in: Number(expiresIn),
      token_type: tokenType,
    };

    return (
      <IframeHandler
        appKey={appKey}
        credentials={credentials}
        redirectTo={redirectTo + targetQueryParams}
      />
    );
  },
};

export const IframeHandler = React.memo(
  ({
    appKey,
    credentials,
    redirectTo,
  }: {
    appKey: string;
    credentials: TokenPayload;
    redirectTo: string;
  }) => {
    const auth = useAuth();
    const profile = useProfileService();
    const history = useHistory();
    const [mounted, setMounted] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);

    const handleErrors = React.useCallback(() => {
      Storage.clear();

      setError('Something went wrong!');
    }, []);

    const handleTargetUrl = React.useCallback(async () => {
      setMounted(true);

      Storage.clear();

      auth.setLoginData(credentials);

      auth.setState({
        isAuthenticated: true,
        complete: true,
      });

      const response = await profile.load();

      if (!response) return handleErrors();

      Storage.setItem('appKey', appKey);

      history.replace(redirectTo);
    }, [appKey, auth, credentials, handleErrors, history, profile, redirectTo]);

    React.useEffect(() => {
      if (!mounted) {
        try {
          handleTargetUrl();
        } catch (error) {
          handleErrors();
        }
      }
    }, [handleErrors, handleTargetUrl, mounted]);

    return error ? (
      <h2 className="text-center">{error}</h2>
    ) : (
      <Loader fullscreen />
    );
  },
);
