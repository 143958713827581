import { RouteProps } from 'react-router';
import { PageTitle } from '../../components';

export const TAXES_PATH = '/taxes';

export const TAXES_ROUTE: RouteProps = {
  path: TAXES_PATH,
  render: () => <Taxes />,
};

export const Taxes = () => {
  return <PageTitle>Taxes</PageTitle>;
};
