import React from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { Role } from '../../../constants';
import { RouteConfigItem } from '../../../routes';
import { FormInputs } from '../components/form-inputs';
import { useToaster } from '../../../hooks/use-toaster';
import { ErrorList, PageTitle } from '../../../components';
import { useApi, useEndpoint, useQuery } from '../../../hooks';

export const CREATE_TEMPLATE_PATH = '/templates/create';

export const CREATE_TEMPLATE_ROUTE: RouteConfigItem = {
  exact: true,
  protect: true,
  path: CREATE_TEMPLATE_PATH,
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  render: () => <CreateTemplate />,
};

export const CreateTemplate = () => {
  const api = useApi();
  const methods = useForm();
  const toaster = useToaster();
  const history = useHistory();
  const endpoint = useEndpoint();
  const { presetId, groupId } = useQuery();

  const goBack = () => history.goBack();

  const saveChanges = (data: any) => {
    const payload = {
      ...data,
      document_type: data?.document_type?.value,
      country_id: isEmpty(data?.country_id) ? null : data?.country_id,
    };

    if (presetId) {
      payload['preset_id'] = presetId;
    }

    if (groupId) {
      payload['group_id'] = groupId;
    }

    api
      .post(endpoint('templates.store'), payload, 'json')
      .then(() => goBack())
      .catch(({ errors }) => {
        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      });
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(saveChanges)}>
        <PageTitle>Create Template</PageTitle>

        <FormInputs />
      </form>
    </FormProvider>
  );
};
