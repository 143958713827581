import { useContext } from 'react';
import { FormGroupContext } from '../form-group';
import './index.scss';

export const Select = ({
  className,
  readOnly,
  ...props
}: React.HTMLProps<HTMLSelectElement> & { readOnly?: boolean }) => {
  const { hasErrors } = useContext(FormGroupContext);
  return (
    <select
      className={`pg-Select form-select ${className || ''} ${
        readOnly ? 'pg-Select--readonly' : ''
      } ${hasErrors ? 'is-invalid' : ''}`}
      {...props}
    />
  );
};
