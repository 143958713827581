import classNames from 'classnames';
import { Text } from '../../../../components';
import {
  ContractStatusTag,
  ContractTypeTag,
} from '../../../../components/contract';
import { ContractStatus } from '../../../../constants';
import { Contract } from '../../index';
import './index.scss';

export const ContractDetailsHeader = ({
  contract,
  nav,
  actions,
  className,
}: {
  contract: Contract;
  nav?: React.ReactNode;
  actions?: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={classNames('pg-ContractDetailsHeader', className)}>
      <div className="pg-ContractDetailsHeader__Content">
        <div className="pg-ContractDetailsHeader__Body">
          <Text as="h5" className="pg-ContractDetailsHeader__Title">
            <Text theme="light">Contracts / </Text>
            {contract.name}
          </Text>
          <ContractTypeTag typeId={contract.contract_type_id} />
          <ContractStatusTag status={contract.status as ContractStatus} />
        </div>
        <div className="pg-ContractDetailsHeader__Actions">{actions}</div>
      </div>
      <div className="pg-ContractDetailsHeader__Nav">{nav}</div>
    </div>
  );
};
