import { Tooltip2 } from '@blueprintjs/popover2';
import { AiOutlineInfoCircle } from 'react-icons/ai';

export const InfoTooltip = ({
  content,
  tooltip,
  usePortal = true, // Must be false if included within a modal
}: {
  content: string | JSX.Element;
  tooltip: string | JSX.Element;
  usePortal?: boolean;
}) => {
  return (
    <>
      <span>{content}</span>
      <Tooltip2 content={tooltip} usePortal={usePortal}>
        <span
          className="mx-2 cursor-pointer"
          style={{
            textTransform: 'none',
          }}
        >
          <AiOutlineInfoCircle size={16} />
        </span>
      </Tooltip2>
    </>
  );
};
