import React from 'react';
import { RouteProps, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks';
import { useApi } from '../../hooks/use-api';
import queryString from 'query-string';
import { REGISTER_PATH } from '../register';
import { useProfileService } from '../../shared/services';
import { LOGIN_PATH } from '..';
import logo from '../../images/logo.svg';
import './index.scss';
import { Loader } from '../../components';
import { HOME_PATH } from '../home';
import { Role, UserInvitationStatus } from '../../constants';
import Storage from '../../shared/db/storage';

export const ACCEPT_INVITE_PATH = '/accept-invite';

export const ACCEPT_INVITE_ROUTE: RouteProps & { protect: boolean } = {
  protect: false,
  path: ACCEPT_INVITE_PATH,
  render: () => <AcceptInvite />,
};

export const AcceptInvite = () => {
  const api = useApi();
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();
  const { load } = useProfileService();
  const [expired, setExpired] = React.useState(false);

  const handleUnregisteredUser = React.useCallback(
    (token?: string, email?: string) => {
      Storage.removeItem('redirectTo');

      const url = `${REGISTER_PATH}?token=${token}&email=${email}`;

      history.replace(url);
    },
    [history],
  );

  const handleUnauthenticatedUser = React.useCallback(
    (token?: string, email?: string) => {
      Storage.setItem(
        'redirectTo',
        `${ACCEPT_INVITE_PATH}?token=${token}&email=${email}`,
      );

      const url = `${LOGIN_PATH}?email=${email}`;

      return history.replace(url);
    },
    [history],
  );

  const handleExpiredInvitation = () => {
    setExpired(true);
  };

  const handleInvalidInvitation = React.useCallback(() => {
    Storage.removeItem('redirectTo');

    auth.logout();

    return history.replace(LOGIN_PATH);
  }, [auth, history]);

  const handleAcceptedInvitation = React.useCallback(() => {
    Storage.setItem('accountType', Role.CLIENT);

    load().then(() => history.replace(HOME_PATH));
  }, [history, load]);

  const handleValidInvitation = React.useCallback(
    (token?: string) => {
      api
        .post(`/api/client/user-invitation/${token}`, {})
        .then(({ data }) => {
          const { request_status } = data;

          if (request_status === UserInvitationStatus.VALID)
            return handleAcceptedInvitation();

          handleInvalidInvitation();
        })
        .catch(() => handleInvalidInvitation());
    },
    [api, handleAcceptedInvitation, handleInvalidInvitation],
  );

  const checkInvitation = React.useCallback(
    (token?: string) => {
      if (!token) return handleExpiredInvitation();

      api
        .post('/api/check-invitation', { token })
        .then(({ data }) => {
          const { request_status, email } = data;

          if (request_status === UserInvitationStatus.EXPIRED)
            return handleExpiredInvitation();

          if (request_status === UserInvitationStatus.UNREGISTERED)
            return handleUnregisteredUser(token, email);

          if (!auth?.isAuthenticated)
            return handleUnauthenticatedUser(token, email);

          return handleValidInvitation(token);
        })
        .catch(() => handleInvalidInvitation());
    },
    [
      api,
      auth?.isAuthenticated,
      handleInvalidInvitation,
      handleUnauthenticatedUser,
      handleUnregisteredUser,
      handleValidInvitation,
    ],
  );

  React.useEffect(() => {
    const { token }: { token?: string } = queryString.parse(location?.search);

    checkInvitation(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.search]);

  return expired ? (
    <div className="pg-Accept-invitation">
      <img src={logo} alt="Brysk" />

      <p className="pg-Accept-invitation__text">
        The invitation link has been expired. Please, contact your company
        administrator to send a new invitation.
      </p>
    </div>
  ) : (
    <Loader fullscreen />
  );
};
