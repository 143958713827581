import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { IoAdd } from 'react-icons/io5';
import { Button } from '../../../components';
import { WireNewAccount } from './wire-new-account';
import { WireAccountList } from './wire-account-list';
import { WirePaymentMethod } from '../../../interfaces';

export const WireMethods = ({
  active,
  methods,
  refresh,
}: {
  active: boolean;
  methods: WirePaymentMethod[];
  refresh: () => void;
}) => {
  const [formVisible, setFormVisible] = useState(false);

  const resetState = React.useCallback(() => {
    setFormVisible(false);

    refresh();
  }, [refresh]);

  if (!active) {
    return null;
  }

  return (
    <div>
      {!isEmpty(methods) && (
        <div className="mt-3 col-md-12">
          <WireAccountList wireMethods={methods} />
        </div>
      )}

      <div className="row mx-0">
        {isEmpty(methods) || formVisible ? (
          <WireNewAccount refresh={resetState} />
        ) : (
          <Button
            theme="link"
            className="text-muted text-decoration-none border border-light"
            leftContent={<IoAdd />}
            onClick={() => setFormVisible(true)}
          >
            New Account
          </Button>
        )}
      </div>
    </div>
  );
};
