import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from '../modal';
import { Input } from '../input';
import { Button } from '../button';
import { FormGroup } from '../form-group';
import { useApi } from '../../hooks/use-api';
import { useToaster } from '../../hooks/use-toaster';
import { TeamMemberRoleSelect } from '../member-role-select';

import './index.scss';

export const EditUserInvitation = ({
  close,
  user,
}: {
  close: () => any;
  user: { token: string | null; email: string; title: string; role: number };
}) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const toaster = useToaster();
  const { errors, control, handleSubmit, reset } = useForm();

  React.useEffect(() => {
    reset({ role: user.role, title: user.title });
  }, [reset, user.role, user.title]);

  const save = (data: { title: string; role: string }) => {
    setLoading(true);
    api
      .patch(`/api/client/user-invitation/${user?.token}`, {
        title: data.title,
        role_id: data.role,
      })
      .catch(() =>
        toaster.danger({
          message: 'An error occured while updating the user invitation.',
        }),
      )
      .then(() => {
        toaster.success({ message: 'User invitation was updated.' });
        close();
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(save)}>
      <Modal
        onClose={close}
        title="Edit Invitation"
        subtitle={`${user.email}`}
        footer={
          <>
            <Button onClick={close} theme="secondary" outline>
              Cancel
            </Button>
            <Button loading={loading} type="submit">
              Save
            </Button>
          </>
        }
      >
        <FormGroup errors={errors.title} label="Official Title" required>
          <Controller
            name="title"
            control={control}
            rules={{
              required: { value: true, message: 'User title is required' },
            }}
            render={(props) => (
              <Input
                placeholder="Official Title"
                name="title"
                className="py-2"
                value={props.value}
                onChange={props.onChange}
              />
            )}
          />
        </FormGroup>

        <FormGroup errors={errors.role} label="Role" required>
          <Controller
            name="role"
            control={control}
            rules={{
              required: { value: true, message: 'User role is required' },
            }}
            render={(props) => (
              <TeamMemberRoleSelect
                placeholder="Select role"
                value={props.value}
                onChange={props.onChange}
              />
            )}
          />
        </FormGroup>
      </Modal>
    </form>
  );
};
