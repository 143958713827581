import React from 'react';
import { map } from 'lodash';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';
import { useQuery, ApiQueryParams } from '../../hooks';

import './index.scss';

export const CustomQuickFilter: React.FunctionComponent<{
  customFilters?: React.FunctionComponent[];
  setFilters: ((f: Partial<ApiQueryParams>) => void) | undefined;
}> = ({ customFilters, setFilters }) => {
  const params = useQuery();

  React.useEffect(() => {
    const filters = map(params, (value: string, key: string) => {
      return `${key},is,${value}`;
    }).join(';');

    setFilters?.({
      page: 0,
      filters,
    });
  }, [params, setFilters]);

  return (
    <div className="CustomQuickFilter__Wrapper">
      <div className="CustomQuickFilter__Wrapper__Search">
        <GridToolbarQuickFilter
          variant="outlined"
          size="small"
          placeholder="Enter a keyword to search for..."
          debounceMs={750}
        />
      </div>

      <div className="CustomQuickFilter__Wrapper__Actions">
        {customFilters?.map(
          (CustomFilter: React.FunctionComponent, i: number) => (
            <div
              key={i}
              className="CustomQuickFilter__Wrapper__Actions__Action"
            >
              <CustomFilter />
            </div>
          ),
        )}
      </div>
    </div>
  );
};
