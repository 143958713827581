import classNames from 'classnames';
import './index.scss';

type TextElements =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'span'
  | 'div'
  | 'p'
  | 'li'
  | 'em'
  | 'b'
  | 'strong';

export const TextTheme = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  success: 'success',
  info: 'info',
  warning: 'warning',
  danger: 'danger',
  light: 'light',
  dark: 'dark',
  white: 'white',
} as const;

export interface TextProps extends React.HTMLProps<any> {
  as?: TextElements;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  ellipsis?: boolean;
  align?: 'left' | 'center' | 'right';
  theme?: typeof TextTheme[keyof typeof TextTheme];
}

export const Text = ({
  as = 'span',
  bold,
  italic,
  underline,
  ellipsis,
  className,
  align = 'left',
  theme = TextTheme.dark,
  ...props
}: TextProps) => {
  const Component = as;

  return (
    <Component
      className={classNames(
        'pg-Text',
        {
          'pg-Text--bold': bold,
          'pg-Text--italic': italic,
          'pg-Text--underline': underline,
          'pg-Text--break': !ellipsis,
          'pg-Text--ellipsis': ellipsis,
        },
        `pg-Text--${theme}`,
        `pg-Text--align-${align}`,
        className,
      )}
      {...props}
    />
  );
};
