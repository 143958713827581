import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  FormGroup,
  Input,
  Modal,
  IPList,
  DomainList,
  PermissionList,
} from '..';
import { useApi } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';
import { InfoTooltip } from '../info-tooltip';

interface LinkedApp {
  id: number;
  key: string;
  name: string;
  scopes: string[];
  company_id: number;
  allowed_ips: string[];
  allowed_domains: string[];
  created_at: string;
  updated_at: string;
}

export const EditAppKeyModal = ({
  app,
  close,
  refresh,
}: {
  app: LinkedApp;
  close: () => any;
  refresh: () => any;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const { register, control, errors, handleSubmit } = useForm({
    defaultValues: {
      ...app,
    },
  });
  const [loading, setLoading] = React.useState(false);

  const submit = (data: { name: string }) => {
    setLoading(true);
    api
      .put(`/api/linked-apps/${app.id}`, data, 'json')
      .then(() => {
        toaster.success({ message: 'Application key updated.' });
        refresh();
        close();
      })
      .catch(() => {
        toaster.danger({
          message: 'There was an error updating the app key.',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Modal
        onClose={close}
        title="Edit Application Key"
        footer={
          <>
            <Button onClick={close} theme="secondary" outline>
              Cancel
            </Button>
            <Button type="submit" loading={loading}>
              Save
            </Button>
          </>
        }
      >
        <FormGroup label="Name" errors={errors.name} required>
          <Input
            name="name"
            placeholder="Application name"
            ref={register({
              required: true,
            })}
          />
        </FormGroup>

        <FormGroup
          label={
            <InfoTooltip
              usePortal={false}
              content="IP Whitelist"
              tooltip="Only used to validate APIs requests"
            />
          }
          errors={errors?.allowed_ips}
        >
          <Controller
            name="allowed_ips"
            defaultValue={app?.allowed_ips}
            control={control}
            render={(props) => (
              <IPList
                defaultValue={app?.allowed_ips}
                placeholder="Leave blank to allow all"
                onChange={(values) => {
                  props.onChange(values.map((v: any) => v?.value));
                }}
              />
            )}
          />
        </FormGroup>

        <FormGroup
          label={
            <InfoTooltip
              usePortal={false}
              content="Domain Whitelist"
              tooltip="Only used to validate iframes"
            />
          }
          errors={errors?.allowed_domains}
        >
          <Controller
            name="allowed_domains"
            defaultValue={app?.allowed_domains}
            control={control}
            render={(props) => (
              <DomainList
                defaultValue={app?.allowed_domains}
                placeholder="Leave blank to allow all"
                onChange={(values) => {
                  props.onChange(values.map((v: any) => v?.value));
                }}
              />
            )}
          />
        </FormGroup>

        <FormGroup label="Permissions" errors={errors?.scopes}>
          <Controller
            name="scopes"
            defaultValue={app?.scopes}
            control={control}
            render={(props) => (
              <PermissionList
                defaultValue={app?.scopes}
                placeholder="Leave blank to allow all"
                onChange={(values) => {
                  props.onChange(values.map((v: any) => v?.value));
                }}
              />
            )}
          />
        </FormGroup>
      </Modal>
    </form>
  );
};
