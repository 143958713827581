import React from 'react';
import ReactSelect from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';
import { useContractTypes, useQuery } from '../../hooks';
import './index.scss';

export const ContractTypeFilter = () => {
  const params = useQuery();
  const history = useHistory();
  const location = useLocation();
  const { contractTypes } = useContractTypes();
  const [type, setType] = React.useState<number | undefined>(
    Number(params?.contract_type_id) || undefined,
  );

  const options = React.useMemo(() => {
    return [
      {
        label: 'All Types',
        value: undefined,
      },
      ...contractTypes.map((t) => ({
        label: t.name,
        value: t.id,
      })),
    ];
  }, [contractTypes]);

  const changeType = (newVal?: number) => {
    setType(newVal);

    const params = new URLSearchParams(location.search);

    if (newVal) {
      params.set('contract_type_id', String(newVal || ''));
    } else {
      params.delete('contract_type_id');
    }

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <div className="pg_ContractTypeFilter__Wrapper">
      <ReactSelect
        value={options.find(({ value }) => value === type)}
        options={options}
        onChange={(selected) => changeType(selected?.value)}
        isSearchable={false}
      />
    </div>
  );
};
