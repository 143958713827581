import React from 'react';
import { RouteConfigItem } from '../../routes';
import { useQuery } from '../talent-onboarding/talent';

export const ERROR_PAGE_PATH = '/error';

export const ERROR_PAGE_ROUTE: RouteConfigItem = {
  protect: false,
  path: ERROR_PAGE_PATH,
  render: () => <ErrorPage />,
};

export const ErrorPage = ({
  message = 'Something went wrong!',
}: {
  message?: string;
}) => {
  const query = useQuery();

  return (
    <p className="d-flex align-items-center justify-content-center flex-fill fs-3">
      {query?.get('message') || message}
    </p>
  );
};
