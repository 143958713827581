import { format } from 'date-fns';
import { Box, BoxProps, CommonDataDisplay } from '../../components';
import { Contract } from './index';
import { LabelValueGroup, LabelValueItem } from './label-value';
import { LabelValueRow } from './label-value-row';

export const GeneralInfo2 = ({ contract }: { contract: Contract }) => {
  return (
    <LabelValueGroup>
      <LabelValueItem label="Name">{contract.name}</LabelValueItem>
      <LabelValueItem label="Contract type">
        <CommonDataDisplay
          type="contract_types"
          id={contract.contract_type_id}
        />
      </LabelValueItem>
      <LabelValueItem label="Scope">{contract.scope_of_work}</LabelValueItem>
      <LabelValueItem label="Start date">
        {format(new Date(contract.start_date), 'MMMM do, yyyy')}
      </LabelValueItem>
      <LabelValueItem label="Termination date">
        <div>
          {contract.termination_date
            ? `This contract will be terminated on
${format(new Date(contract.termination_date), 'MMMM do, yyyy')}.`
            : 'This contract has no termination date and will end when either party manually terminates it.'}
        </div>
        <div>
          {contract.days_of_notice
            ? `Either party may terminate this contract by giving ${contract.days_of_notice} days of notice, after which the contract will be terminated.`
            : null}
        </div>
      </LabelValueItem>
    </LabelValueGroup>
  );
};

export const GeneralInfo = ({
  contract,
  boxProps,
}: {
  contract: Contract;
  boxProps?: BoxProps;
}) => {
  return (
    <Box {...boxProps}>
      <h6 className="pg-ContractDetails__ItemTitle">General info</h6>
      <LabelValueRow inline label="Name">
        {contract.name}
      </LabelValueRow>
      <LabelValueRow inline label="Contract type">
        <CommonDataDisplay
          type="contract_types"
          id={contract.contract_type_id}
        />
      </LabelValueRow>
      <LabelValueRow inline label="Scope">
        {contract.scope_of_work}
      </LabelValueRow>

      <LabelValueRow inline label="Start date">
        {format(new Date(contract.start_date), 'MMMM do, yyyy')}
      </LabelValueRow>
      <LabelValueRow inline label="Termination date">
        <p>
          {contract.termination_date
            ? `This contract will be terminated on
${format(new Date(contract.termination_date), 'MMMM do, yyyy')}.`
            : 'This contract has no termination date and will end when either party manually terminates it.'}
        </p>
        <p>
          {contract.days_of_notice
            ? `Either party may terminate this contract by giving ${contract.days_of_notice} days of notice, after which the contract will be terminated.`
            : null}
        </p>
      </LabelValueRow>
    </Box>
  );
};
