import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useApi } from '../../../hooks/use-api';
import { useCompany } from '../../../hooks/use-company';
import SetupForm from './SetupForm';
import './card.scss';
import { Button } from '../../../components';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

const AddCardMethod = ({
  isOpen,
  onToggle,
  onCancel,
  onCardAdded,
}: {
  isOpen?: boolean;
  onToggle: () => any;
  onCancel: () => any;
  onCardAdded: () => any;
}) => {
  const api = useApi();
  const { selectedCompany } = useCompany();
  const setupIntentIdRef = React.useRef('');
  const [clientSecret, setClientSecret] = React.useState('');
  const [setupIntent, setSetupIntent] = React.useState('');
  const [isCardAdded, setIsCardAdded] = React.useState<boolean>(false);

  const options = {
    // passing the client secret obtained in step 2
    clientSecret,
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const createSetupIntent = React.useCallback(() => {
    api
      .get(
        `/api/payment/stripe/create-setup-intent?billable_id=${selectedCompany.company_id}&billable_type=company`,
      )
      .then((response) => {
        setSetupIntent(response.data.setupIntentId);
        setClientSecret(response.data.clientSecret);
      });
  }, [api, selectedCompany.company_id]);

  const revokeSetupIntent = React.useCallback(() => {
    // The SetupIntent is completed, cannot revoke it
    if (isCardAdded || !setupIntentIdRef?.current) return;

    api.get(
      `/api/payment/stripe/revoke-setup-intent?intent_id=${setupIntentIdRef.current}`,
    );
  }, [api, isCardAdded]);

  React.useEffect(() => {
    setupIntentIdRef.current = setupIntent;
  }, [setupIntent]);

  React.useEffect(() => {
    // Replace creating a PaymentIntent from on component mount to a button press or something...
    isOpen && createSetupIntent();

    // Important to revoke the `SetupIntent` on component unmount to avoid having hanging SetupIntents
    return revokeSetupIntent;
  }, [createSetupIntent, isOpen, revokeSetupIntent]);

  return (
    <div className="pg-card-method-add">
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <SetupForm
            isOpen={isOpen}
            onCancel={onCancel}
            isCardAdded={isCardAdded}
            setIsCardAdded={setIsCardAdded}
            onCardAdded={onCardAdded}
          />
        </Elements>
      )}
      {!isOpen && (
        <div className="d-flex pt-2 justify-content-end align-items-center">
          <Button onClick={onToggle}>Add New</Button>
        </div>
      )}
    </div>
  );
};

export default AddCardMethod;
