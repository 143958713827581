import { IoChevronDownOutline, IoChevronUpOutline } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import './sidebar-item.scss';

export const SidebarItem = ({
  icon,
  to,
  label,
  children,
  isOpen,
  isAccordion,
  disabled = false,
  ...props
}: Omit<React.HTMLProps<HTMLDivElement>, 'label'> & {
  icon?: React.ReactNode;
  label?: React.ReactNode;
  to?: string;
  isOpen?: boolean;
  disabled?: boolean;
  isAccordion?: boolean;
}) => {
  return to ? (
    <NavLink
      to={disabled ? '#' : to}
      className={`pg-SidebarItem ${disabled ? '--disabled' : ''}`}
      activeClassName="pg-SidebarItem--active"
    >
      <div className="pg-SidebarItem__Icon">{icon}</div>
      <div className="pg-SidebarItem__Text">{children || label}</div>

      {isAccordion &&
        (isOpen ? (
          <IoChevronUpOutline className="pg-SidebarItem__ToggleIcon" />
        ) : (
          <IoChevronDownOutline className="pg-SidebarItem__ToggleIcon" />
        ))}
    </NavLink>
  ) : (
    <div
      className={`pg-SidebarItem ${disabled ? '--disabled' : ''}`}
      {...props}
    >
      <div className="pg-SidebarItem__Icon">{icon}</div>
      <div className="pg-SidebarItem__Text">{children || label}</div>
      {isAccordion &&
        (isOpen ? (
          <IoChevronUpOutline className="pg-SidebarItem__ToggleIcon" />
        ) : (
          <IoChevronDownOutline className="pg-SidebarItem__ToggleIcon" />
        ))}
    </div>
  );
};
