import React, { useState, useEffect } from 'react';
import { PageTitle, Text } from '../../../components';
import { useApi } from '../../../hooks';
import { Step, DottedSteps } from '../../contract-wrapper/steps';
import { ContractDetailsHeader } from '../components';
import { ContractDetailsContext } from '../contract-details';
import { ContractorSigner } from '../contractor-signer';
import { CountryCompliance2 } from '../country-compliance';
import { GeneralInfo2 } from '../general-info';
import { Contract } from '../index';
import { OtherSpecifics2 } from '../other-specifics';
import { PaymentDetails2 } from '../payment-details';
import { Signer } from '../signer';
import { UploadedDocument } from '../components/uploaded-document';
import { Role } from '../../../constants';
import './index.scss';
import { TokenService } from '../../../shared/services';
import { useService } from '../../../shared/services/use-service';

const steps = [
  { id: 'create', name: 'Create' },
  { id: 'review-and-sign', name: 'Review & Sign' },
  { id: 'invite-contractor', name: 'Invite Contractor' },
  { id: 'contractor-signs', name: 'Contractor signs' },
  { id: 'activated', name: 'Activated!' },
] as const;

export const ClientContractDetails = ({
  contract,
  reload,
  isContractor,
}: {
  contract: Contract;
  reload: any;
  isContractor?: boolean;
}) => {
  const api = useApi();
  const { token } = useService(TokenService);
  const [previewContract, setPreviewContract] = useState<string>();

  const contextValue = React.useMemo(
    () => ({ reload, contract }),
    [reload, contract],
  );

  const getActiveStep = (contractDetails: Contract) => {
    if (contractDetails.contractorSigner) {
      return 4;
    } else if (contractDetails.invitedContractor) {
      return 3;
    } else if (contractDetails.clientSigner) {
      return 2;
    }
    return 1;
  };

  const getContractFilePath = (contract: any) => {
    return contract.userContractFiles && contract.userContractFiles.length
      ? contract.userContractFiles[0].file_url
      : '';
  };

  const getAdditionalFilePath = (contract: any) => {
    return contract.additionalFiles && contract.additionalFiles.length
      ? contract.additionalFiles[0].file_url
      : '';
  };

  const contractLink = React.useMemo(() => {
    const url = previewContract || getContractFilePath(contract);

    return `${url}?auth=${token}`;
  }, [contract, previewContract, token]);

  useEffect(() => {
    if (contract?.id) {
      api
        .get(`/api/client/contract/${contract?.id}/preview`)
        .then(({ data }) => {
          setPreviewContract(data['contract-preview-url'] ?? null);
        });
    }
  }, [api, contract?.id]);

  return (
    <ContractDetailsContext.Provider value={contextValue}>
      <div className="pg-ContractDetails">
        <PageTitle>
          <ContractDetailsHeader contract={contract} />
        </PageTitle>

        <div className="pg-ContractDetails__Body">
          <div className="pg-ContractDetails__Sidebar">
            <DottedSteps
              steps={steps as unknown as Step[]}
              activeStep={getActiveStep(contract)}
            />
          </div>
          <div className="pg-ContractDetails__Content">
            <div className="pg-ContractDetails__Section">
              <Text
                as="h3"
                theme="dark"
                className="pg-ContractDetails__SectionTitle"
              >
                Signatures
              </Text>
              {contract?.company_name ? (
                <p>You are signing this with team "{contract?.company_name}"</p>
              ) : null}
              <Signer contract={contract} type={Role.CLIENT} />
              {isContractor ? (
                <Signer contract={contract} type={Role.CONTRACTOR} />
              ) : (
                <ContractorSigner contract={contract} />
              )}
            </div>
            <div className="pg-ContractDetails__Section">
              <Text
                as="h3"
                theme="dark"
                className="pg-ContractDetails__SectionTitle"
              >
                Contract Details
              </Text>
              <div className="row">
                <div className="col-md-6">
                  <UploadedDocument
                    actions={
                      <>
                        <a target="_blank" rel="noreferrer" href={contractLink}>
                          Download
                        </a>
                      </>
                    }
                  >
                    {contract.use_own
                      ? 'Using custom contract'
                      : 'Using the standard Brysk contract'}
                  </UploadedDocument>
                </div>
                {contract.additionalFiles && contract.additionalFiles.length ? (
                  <div className="col-md-6">
                    <UploadedDocument
                      actions={
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`${getAdditionalFilePath(
                            contract,
                          )}?auth=${token}`}
                        >
                          Download
                        </a>
                      }
                    >
                      Contract Attachment
                    </UploadedDocument>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="pg-ContractDetails__Section">
              <Text
                as="h3"
                theme="dark"
                className="pg-ContractDetails__SectionTitle"
              >
                General Info
              </Text>
              <GeneralInfo2 contract={contract} />
            </div>
            <div className="pg-ContractDetails__Section">
              <Text
                as="h3"
                theme="dark"
                className="pg-ContractDetails__SectionTitle"
              >
                Payment details
              </Text>
              <PaymentDetails2 contract={contract} />
            </div>
            <div className="pg-ContractDetails__Section">
              <Text
                as="h3"
                theme="dark"
                className="pg-ContractDetails__SectionTitle"
              >
                Country compliance
              </Text>
              <CountryCompliance2 contract={contract} />
            </div>
            <div className="pg-ContractDetails__Section">
              <Text
                as="h3"
                theme="dark"
                className="pg-ContractDetails__SectionTitle"
              >
                Other specifics
              </Text>
              <OtherSpecifics2 contract={contract} />
            </div>
          </div>
        </div>
      </div>
    </ContractDetailsContext.Provider>
  );
};
