import { format } from 'date-fns';
import {
  GridAlignment,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { DataGridColumnsFunc } from '../../types';
import { ContractStatusTag, ContractTypeTag } from '../../components/contract';
import { ContractStatus, ContractStatusLabel } from '../../constants';
import { CommonDataDisplay, RateTimeFrame, Text } from '../../components';
import { ContractActions } from '../../components/contract/contract-actions';

export const ClientContractsColumns: DataGridColumnsFunc = () => [
  {
    headerName: 'Contract Title',
    field: 'name',
    flex: 2,
    minWidth: 200,
    filterable: false,
    renderCell: (params: GridRenderCellParams) => {
      const contractorName = params?.row?.contractorSigner?.name || null;
      const contractorEmail =
        params?.row?.contractorSigner?.email ||
        params?.row?.invitedContractor ||
        null;

      return (
        <div>
          <Text ellipsis bold>
            {params.row.name}
          </Text>

          <div>
            <Text theme="light">{contractorName || contractorEmail || ''}</Text>
          </div>
        </div>
      );
    },
  },
  {
    headerName: 'Type',
    field: 'contract_type_id',
    minWidth: 120,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return <ContractTypeTag typeId={params.row.contract_type_id} />;
    },
  },
  {
    headerName: 'Rate',
    field: 'rate',
    type: 'number',
    headerAlign: 'left' as GridAlignment,
    align: 'left' as GridAlignment,
    minWidth: 120,
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <>
          <CommonDataDisplay
            type="currencies"
            dataKey="symbol"
            id={params.row.currency_id}
          />
          {params.row.rate}

          <Text theme="light">
            /
            <RateTimeFrame
              contractTypeId={params.row.contract_type_id}
              rateTimeFrameId={params.row.rate_time_frame_id}
            />
          </Text>
        </>
      );
    },
  },
  {
    headerName: 'Start Date',
    field: 'start_date',
    type: 'date',
    minWidth: 160,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams<Date>) => {
      if (params.value == null) {
        return '';
      }

      return format(new Date(params.value), 'MMMM do, yyyy');
    },
  },
  {
    headerName: 'End Date',
    field: 'termination_date',
    type: 'date',
    minWidth: 160,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams<Date>) => {
      if (params.value == null) {
        return '';
      }

      return format(new Date(params.value), 'MMMM do, yyyy');
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    type: 'singleSelect',
    sortable: false,
    flex: 1,
    minWidth: 120,
    valueOptions: [
      { value: ContractStatus.DRAFT, label: ContractStatusLabel.DRAFT },
      {
        value: ContractStatus.PENDING_CLIENT_SIGNATURE,
        label: ContractStatusLabel.PENDING_CLIENT_SIGNATURE,
      },
      {
        value: ContractStatus.PENDING_CONTRACTOR_INVITE,
        label: ContractStatusLabel.PENDING_CONTRACTOR_INVITE,
      },
      {
        value: ContractStatus.PENDING_CONTRACTOR_SIGNATURE,
        label: ContractStatusLabel.PENDING_CONTRACTOR_SIGNATURE,
      },
      {
        value: ContractStatus.ACTIVATED,
        label: ContractStatusLabel.ACTIVATED,
      },
      {
        value: ContractStatus.TERMINATED,
        label: ContractStatusLabel.TERMINATED,
      },
    ],
    width: 160,
    renderCell: (params: GridRenderCellParams) => (
      <ContractStatusTag status={params.value} />
    ),
  },
  {
    headerName: '',
    field: 'actions',
    type: 'actions',
    width: 60,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <ContractActions contract={params.row} onDelete={() => {}} />
    ),
  },
];
