import React from 'react';
import { CommonDataService } from '../../shared/services/common-data';
import { useService } from '../../shared/services/use-service';
import { InputPlaceholder } from '../input-placeholder';
import { Select } from '../select';

export const InvoiceItemTypeSelect = ({
  value,
  onChange,
  readOnly,
}: {
  value?: number;
  onChange?: (value?: number) => any;
  readOnly?: boolean;
}) => {
  const commonData = useService(CommonDataService);
  const [invoiceItemTypes, setInvoiceItemTypes] = React.useState<
    { label: string; value: number }[]
  >([]);

  React.useEffect(() => {
    commonData.get().then(({ contractor_invoice_items_types }) => {
      setInvoiceItemTypes(
        contractor_invoice_items_types?.map(({ id, name }) => ({
          label: name,
          value: id,
        })) || [],
      );
    });
  }, [commonData]);

  return !readOnly ? (
    <Select
      value={value}
      onChange={({ target }: any) => onChange?.(target.value)}
    >
      <option
        value={undefined}
        disabled
        selected={typeof value === 'undefined'}
      >
        Select type
      </option>
      {invoiceItemTypes.map((item) => (
        <option
          key={item.value}
          value={item.value}
          selected={value === item.value}
        >
          {item.label}
        </option>
      ))}
    </Select>
  ) : (
    <InputPlaceholder>
      {invoiceItemTypes.find((type) => type.value === value)?.label || 'N/A'}
    </InputPlaceholder>
  );
};
