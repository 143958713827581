import { useMemo } from 'react';
import {
  FaPen,
  FaEnvelope,
  FaStopwatch,
  FaCheck,
  FaTimes,
} from 'react-icons/fa';
import { RiDraftLine } from 'react-icons/ri';
import { ContractStatus } from '../../constants';
import { Contract } from '../../pages';

export const getContractStatus = (contract: Contract) => {
  const { status } = contract;

  if (status === ContractStatus.DRAFT) return ContractStatus.DRAFT;
  if (status === ContractStatus.ACTIVATED) return ContractStatus.ACTIVATED;
  if (status === ContractStatus.TERMINATED) return ContractStatus.TERMINATED;
  if (status === ContractStatus.PENDING_CONTRACTOR_SIGNATURE)
    return ContractStatus.PENDING_CONTRACTOR_SIGNATURE;
  if (status === ContractStatus.PENDING_CONTRACTOR_INVITE)
    return ContractStatus.PENDING_CONTRACTOR_INVITE;
  if (status === ContractStatus.PENDING_CLIENT_SIGNATURE)
    return ContractStatus.PENDING_CLIENT_SIGNATURE;

  return ContractStatus.PENDING_CLIENT_SIGNATURE;
};

export const useContractStatus = (status: ContractStatus) => {
  const contractStatus = useMemo(() => {
    const states = {
      [ContractStatus.PENDING_CLIENT_SIGNATURE]: {
        icon: <FaPen />,
        label: 'Client sign',
        tooltip: 'Waiting for client sign',
        status: 'warning' as const,
      },
      [ContractStatus.PENDING_CONTRACTOR_INVITE]: {
        icon: <FaEnvelope />,
        label: 'Contractor invitation',
        tooltip: 'Waiting for contractor invitation',
        status: 'primary' as const,
      },
      [ContractStatus.PENDING_CONTRACTOR_SIGNATURE]: {
        icon: <FaStopwatch />,
        label: 'Contractor sign',
        tooltip: 'Waiting for contractor sign',
        status: 'secondary' as const,
      },
      [ContractStatus.ACTIVATED]: {
        icon: <FaCheck />,
        label: 'Activated',
        tooltip: 'Activated',
        status: 'success' as const,
      },
      [ContractStatus.TERMINATED]: {
        icon: <FaTimes />,
        label: 'Terminated',
        tooltip: 'Terminated',
        status: 'danger' as const,
      },
      [ContractStatus.DRAFT]: {
        icon: <RiDraftLine />,
        label: 'Draft',
        tooltip: 'Waiting to complete required data',
        status: 'default' as const,
      },
    } as const;

    return {
      ...states[status],
      key: status,
    };
  }, [status]);

  return contractStatus;
};
