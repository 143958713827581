import React from 'react';
import classNames from 'classnames';
import './index.scss';

export const MediaObject = ({
  children,
  media,
  className,
  align = 'top',
  ...props
}: Omit<React.HTMLProps<HTMLDivElement>, 'media'> & {
  media?: React.ReactNode;
  align?: 'top' | 'center' | 'bottom';
}) => {
  return (
    <div
      className={classNames(
        'pg-MediaObject',
        { [`pg-MediaObject--align-${align}`]: align },
        className,
      )}
      {...props}
    >
      {media && <div className="pg-MediaObject__Figure">{media}</div>}
      <div className="pg-MediaObject__Body">{children}</div>
    </div>
  );
};
