import { StateMethods, useState } from '@hookstate/core';
import { InjectionToken } from 'tsyringe';
import { container, Services } from './container';

export function useService<T extends Services>(token: InjectionToken<T>) {
  return container.resolve(token);
}

type ExtractGeneric<Type> = Type extends StateMethods<infer X> ? X : never;

export function useServiceState<
  T extends Services & { state: unknown },
  U = T['state'],
>(token: InjectionToken<T>) {
  const service = container.resolve(token);
  const state = useState<ExtractGeneric<U>>(service.state as any);
  return state;
}
