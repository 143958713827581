import classNames from 'classnames';
import React from 'react';
import { getErrorMessages } from '../../shared';
import './index.scss';

export const FormGroupContext = React.createContext<{ hasErrors?: boolean }>(
  {},
);

export const FormGroup = ({
  label,
  errors = {},
  labelFor,
  children,
  inline,
  className,
  required,
  ignoreLabelOnError = false,
  ...rest
}: {
  label?: React.ReactNode;
  errors?: any;
  labelFor?: string;
  children: React.ReactNode;
  inline?: boolean;
  className?: string;
  ignoreLabelOnError?: boolean;
} & Omit<React.HTMLProps<HTMLDivElement>, 'label'>) => {
  const hasErrors = !!Object.keys(errors).length;

  return (
    <FormGroupContext.Provider value={{ hasErrors }}>
      <div
        className={classNames(
          'pg-FormGroup mb-3',
          { 'pg-FormGroup--inline': inline },
          className,
        )}
        {...rest}
      >
        {label ? (
          <label
            className={classNames(
              'pg-FormGroup__Label form-label',
              { 'pg-FormGroup__Label--inline': inline },
              className,
            )}
            htmlFor={labelFor}
          >
            {label}
            {required ? <span className="text-danger"> *</span> : ''}
          </label>
        ) : null}
        <div
          className={classNames('pg-FormGroup__Input', {
            'pg-FormGroup__Input--inline': inline,
          })}
        >
          {children}
          {hasErrors ? (
            <div className="pg-FormGroup__Errors text-danger">
              {getErrorMessages(
                errors,
                ignoreLabelOnError
                  ? undefined
                  : typeof label === 'string'
                  ? label
                  : undefined,
              )}
            </div>
          ) : null}
        </div>
      </div>
    </FormGroupContext.Provider>
  );
};
