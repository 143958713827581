import _ from 'lodash';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from '../modal';
import { Input } from '../input';
import { Button } from '../button';
import { FormGroup } from '../form-group';
import { useApi } from '../../hooks/use-api';
import { useCompany } from '../../hooks/use-company';
import { useToaster } from '../../hooks/use-toaster';
import { TeamMemberRoleSelect } from '../member-role-select';

import './index.scss';

export const EditMember = ({
  close,
  user,
  editRole = false,
}: {
  close: () => any;
  editRole?: boolean;
  user: {
    fullName: string;
    email: string;
    title: string;
    id: number;
    role: number;
  };
}) => {
  const api = useApi();
  const [loading, setLoading] = useState(false);
  const toaster = useToaster();
  const { selectedCompany } = useCompany();
  const { errors, control, handleSubmit, reset } = useForm();

  React.useEffect(() => {
    reset({ role: user.role, title: user.title });
  }, [reset, user.role, user.title]);

  const save = (data: { title: string; role: string }) => {
    setLoading(true);
    api
      .patch(
        `/api/client/company/${selectedCompany?.company_id}/team/${user?.id}`,
        {
          title: data.title,
          role_id: data.role,
        },
      )
      .then(() => {
        toaster.success({ message: 'Team member was updated successfully.' });
        close();
      })
      .catch(({ errors }) => {
        const errorMessage = _.chain(errors).values().flatten().uniq().value();

        toaster.danger({ message: errorMessage });

        close();
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(save)}>
      <Modal
        onClose={close}
        title="Edit Member"
        subtitle={`${user.fullName || user.email}`}
        footer={
          <>
            <Button onClick={close} theme="secondary" outline>
              Cancel
            </Button>
            <Button loading={loading} type="submit">
              Save
            </Button>
          </>
        }
      >
        <FormGroup errors={errors.title} label="Official Title" required>
          <Controller
            name="title"
            control={control}
            rules={{
              required: { value: true, message: 'User title is required' },
            }}
            render={(props) => (
              <Input
                placeholder="Official Title"
                name="title"
                className="py-2"
                value={props.value}
                onChange={props.onChange}
              />
            )}
          />
        </FormGroup>

        <FormGroup errors={errors.role} label="Role" required={editRole}>
          <Controller
            name="role"
            control={control}
            rules={{
              required: { value: true, message: 'User role is required' },
            }}
            render={(props) => (
              <TeamMemberRoleSelect
                placeholder="Select role"
                value={props.value}
                onChange={props.onChange}
                disabled={!editRole}
              />
            )}
          />

          {!editRole && (
            <p className="mt-3 text-center mb-0">
              <small className="text-muted">
                Company owner must always have the Administrator role.
              </small>
            </p>
          )}
        </FormGroup>
      </Modal>
    </form>
  );
};
