import React from 'react';
import ReactSelect from 'react-select';
import { InputPlaceholder } from '../input-placeholder';

const TeamMembersRoles = [
  {
    label: 'Administrator',
    value: 1,
  },
  {
    label: 'Human Resources',
    value: 2,
  },
  {
    label: 'Billing',
    value: 3,
  },
];

export const TeamMemberRoleSelect = ({
  value,
  onChange,
  readOnly = false,
  disabled = false,
  placeholder,
  ...rest
}: {
  value: number;
  onChange: (value?: number) => any;
  readOnly?: boolean;
  disabled?: boolean;
  placeholder?: string;
}) => {
  return !readOnly ? (
    <ReactSelect
      isClearable={false}
      options={TeamMembersRoles}
      value={TeamMembersRoles?.find((role) => role.value === value)}
      onChange={(selected) => onChange(selected?.value)}
      placeholder={placeholder}
      isDisabled={disabled}
      {...rest}
    />
  ) : (
    <InputPlaceholder>
      {TeamMembersRoles?.find((role) => role.value === value)?.label || 'N/A'}
    </InputPlaceholder>
  );
};
