import React, { useState } from 'react';
import { Role } from '../../../constants';
import { RouteConfigItem } from '../../../routes';
import { ErrorList, Loader, PageTitle } from '../../../components';
import { useHistory, useParams } from 'react-router';
import { useApi, useEndpoint } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import { FormProvider, useForm } from 'react-hook-form';
import { FormInputs } from '../components/form-inputs';
import { TEMPLATE_BUILDER_PATH } from '../all';
import { isEmpty, isNil } from 'lodash';
import { SHOW_TEMPLATE_PATH } from '../show';

export const EDIT_TEMPLATE_PATH = '/templates/edit/:id';

export const EDIT_TEMPLATE_ROUTE: RouteConfigItem = {
  exact: true,
  protect: true,
  path: EDIT_TEMPLATE_PATH,
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  render: () => <EditTemplate />,
};

export const EditTemplate = () => {
  const { id } = useParams<{ id?: string }>();
  const api = useApi();
  const toaster = useToaster();
  const history = useHistory();
  const endpoint = useEndpoint();
  const { reset, ...methods } = useForm();
  const [loading, setLoading] = useState(true);

  const goBack = () => history.replace(TEMPLATE_BUILDER_PATH);

  const saveChanges = (data: any) => {
    setLoading(true);

    api
      .put(
        endpoint('templates.update', { id }),
        {
          ...data,
          document_type: data?.document_type?.value,
          country_id: isEmpty(data?.country_id) ? null : data?.country_id,
        },
        'json',
      )
      .then(() => goBack())
      .catch(({ errors }) => {
        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      })
      .finally(() => setLoading(false));
  };

  const fetchTemplate = React.useCallback(() => {
    setLoading(true);

    api
      .get(endpoint('templates.get', { id }))
      .then(({ data: { template } }) => {
        if (isNil(template?.company_id)) {
          return history.replace(
            SHOW_TEMPLATE_PATH.replace(':id', template.id),
          );
        }

        reset({
          name: template?.name,
          same_content: template?.same_content,
          content: template?.content_individual || template?.content_entity,
          content_individual: template?.content_individual,
          content_entity: template?.content_entity,
          country_id: template?.country_id,
        });
      })
      .catch(({ errors }) => {
        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, endpoint, id, reset]);

  React.useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  if (loading) {
    return <Loader fullscreen />;
  }

  return (
    <FormProvider reset={reset} {...methods}>
      <form onSubmit={methods.handleSubmit(saveChanges)}>
        <PageTitle>Edit Template</PageTitle>

        <FormInputs />
      </form>
    </FormProvider>
  );
};
