import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IoTrash, IoRefresh } from 'react-icons/io5';
import { RiFileUploadFill, RiPenNibLine } from 'react-icons/ri';
import { GoMailRead } from 'react-icons/go';
import { BsFillFileEarmarkCheckFill } from 'react-icons/bs';
import { BiListCheck } from 'react-icons/bi';
import { useContractContext } from '..';
import {
  Box,
  FormGroup,
  Button,
  Input,
  TextArea,
  Text,
} from '../../../components';
import { maxLength } from '../../../shared';
import { FileUpload, UploadedFile } from '../file-upload';
import { StepControl, StepControlProps } from '../step-control';
import './compliance.scss';
import { FaRegCalendar } from 'react-icons/fa';
import { isNil } from 'lodash';
import { StepSectionContainer } from './step-section-container';

const defaultValues = {
  days_of_notice: 10,
  special_clause: null,
  additionalFiles: [],
  contractor_require_documents: false,
  contractor_require_id_check: false,
};

export const Compliance = ({
  stepControlProps,
}: {
  stepControlProps: StepControlProps;
}) => {
  const { onNext, onPrev, ...stepProps } = stepControlProps;
  const { contractData, updateData } = useContractContext();
  const { control, register, handleSubmit, reset, errors, watch } = useForm({
    defaultValues,
  });

  const formValue = watch();

  const [showDaysOfNotice, setShowDaysOfNotice] = React.useState(
    !isNil(formValue.days_of_notice),
  );
  const [showSpecialClause, setShowSpecialClause] = React.useState(
    !!formValue.special_clause,
  );

  const next = (data: any) => onNext?.(data);

  const prev = () => onPrev?.(formValue);

  const toggleShowDaysOfNotice = () =>
    setShowDaysOfNotice((current) => !current);

  const toggleShowSpecialClause = () => {
    setShowSpecialClause((current) => !current);
  };

  const resetSpecialClause = () => {
    updateData('special_clause', '');
    toggleShowSpecialClause();
  };

  const resetNoticeDays = () => {
    updateData('days_of_notice', 10);
    toggleShowDaysOfNotice();
  };

  const handleChange = (key: string, value: any, callback?: any) => {
    if (!value) return;

    updateData(key, value);

    if (callback) {
      callback(value);
    }
  };

  React.useEffect(() => {
    reset({
      ...contractData,
      days_of_notice: contractData?.days_of_notice || 10,
    });
  }, [reset, contractData]);

  return (
    <form onSubmit={handleSubmit(next)}>
      <div className="pg-ExtrasContainer">
        <StepSectionContainer noPadding>
          <div className="pg-Extras__ItemContainer pg-Extras__Border">
            <Box className="pg-Extras__Item">
              <h6>
                <BiListCheck size={18} /> Permissions
              </h6>
              <p>Who can submit work?</p>
              <Controller
                name="client_can_submit"
                control={control}
                defaultValue={1}
                render={(props) => (
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="client"
                      checked={props.value}
                      onChange={({ target }) =>
                        handleChange(
                          'client_can_submit',
                          target.checked ? 1 : 0,
                          props.onChange,
                        )
                      }
                    />
                    <label className="form-check-label" htmlFor="client">
                      Client
                    </label>
                  </div>
                )}
              />
              <Controller
                name="contractor_can_submit"
                control={control}
                defaultValue={1}
                render={(props) => (
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="contractor"
                      checked={props.value}
                      onChange={({ target }) =>
                        handleChange(
                          'contractor_can_submit',
                          target.checked ? 1 : 0,
                          props.onChange,
                        )
                      }
                    />
                    <label className="form-check-label" htmlFor="contractor">
                      Contractor
                    </label>
                  </div>
                )}
              />
            </Box>
          </div>
        </StepSectionContainer>

        <StepSectionContainer
          noPadding
          alignCenter
          rightColumn={
            <Box elevated>
              <Text as="div" align="center" theme="light">
                <div>...</div>
                <div>
                  Either party may terminate this contract by giving{' '}
                  {formValue.days_of_notice || 10} days of notice, after which
                  the contract will be terminated.
                </div>
                <div>...</div>
              </Text>
            </Box>
          }
        >
          <div className="pg-Extras__ItemContainer pg-Extras__Border">
            <Box className="pg-Extras__Item">
              <h6>
                <GoMailRead /> Termination notice
              </h6>
              Either party may terminate this contract by giving{' '}
              {formValue.days_of_notice || 10} days of notice, after which the
              contract will be terminated.
              {showDaysOfNotice ? (
                <div className="row mt-3">
                  <div className="col-md-6">
                    <FormGroup>
                      <div className="input-group">
                        <Input
                          name="days_of_notice"
                          type="number"
                          value={formValue.days_of_notice}
                          onChange={({ target }) =>
                            handleChange('days_of_notice', target.value)
                          }
                          onBlur={() =>
                            !formValue.days_of_notice
                              ? updateData('days_of_notice', 10)
                              : null
                          }
                          ref={register({
                            required: true,
                            valueAsNumber: true,
                            min: 1,
                          })}
                          min={1}
                          defaultValue={10}
                        />
                        <span className="input-group-text">days</span>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-md-6">
                    <Button
                      theme="light"
                      outline
                      onClick={resetNoticeDays}
                      leftContent={<IoRefresh />}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="mt-3">
                  <Button
                    theme="light"
                    outline
                    onClick={toggleShowDaysOfNotice}
                    leftContent={<FaRegCalendar />}
                  >
                    Change days of notice
                  </Button>
                </div>
              )}
            </Box>
          </div>
        </StepSectionContainer>

        <StepSectionContainer
          noPadding
          alignCenter
          rightColumn={formValue?.additionalFiles?.map((file: any) => (
            <UploadedFile
              key={file?.id}
              value={file}
              onRemove={() => {
                updateData('additionalFiles', []);
              }}
            />
          ))}
        >
          <div className="pg-Extras__ItemContainer pg-Extras__Border">
            <Box className="pg-Extras__Item">
              <h6>
                <RiFileUploadFill /> Additional Document
              </h6>
              Attach any additional document you may require for the contract.
              <div className="mt-3">
                <Controller
                  name="additionalFiles"
                  defaultValue={[]}
                  control={control}
                  render={(props) => (
                    <FileUpload
                      onChange={(val) => {
                        handleChange('additionalFiles', [val]);
                        props.onChange(val);
                      }}
                      value={props.value}
                      showAttached={false}
                    />
                  )}
                />
              </div>
            </Box>
          </div>
        </StepSectionContainer>

        <StepSectionContainer
          noPadding
          rightColumn={
            formValue.special_clause && (
              <Box elevated>
                <Text as="div" theme="light">
                  <div>...</div>
                  <div>{formValue.special_clause}</div>
                  <div>...</div>
                </Text>
              </Box>
            )
          }
        >
          <div className="pg-Extras__ItemContainer pg-Extras__Border">
            <Box className="pg-Extras__Item">
              <h6>
                <BsFillFileEarmarkCheckFill /> Special Clause
              </h6>
              You may want a special clause on the contract to outline terms of
              a special scenario.
              {showSpecialClause ? (
                <>
                  <FormGroup className="mt-3" errors={errors.special_clause}>
                    <Controller
                      name="special_clause"
                      defaultValue=""
                      control={control}
                      rules={{
                        maxLength: maxLength(10000, 'Special Clause'),
                      }}
                      render={(props) => (
                        <TextArea
                          onChange={(val) =>
                            handleChange('special_clause', val, props.onChange)
                          }
                          value={props.value || ''}
                          max={10000}
                        />
                      )}
                    />
                  </FormGroup>
                  <Button
                    theme="light"
                    outline
                    onClick={resetSpecialClause}
                    leftContent={<IoTrash />}
                  >
                    Remove clause
                  </Button>
                </>
              ) : (
                <div className="mt-3">
                  <Button
                    theme="light"
                    outline
                    onClick={toggleShowSpecialClause}
                    leftContent={<RiPenNibLine />}
                  >
                    Add a special clause
                  </Button>
                </div>
              )}
            </Box>
          </div>
        </StepSectionContainer>

        <StepSectionContainer
          noPadding
          actions={<StepControl onPrev={prev} {...stepProps} />}
        >
          <div className="pg-Extras__ItemContainer">
            <div className="pg-Compliance__CheckList">
              <div className="pg-Compliance__CheckListItem">
                <Controller
                  name="contractor_require_documents"
                  defaultValue={false}
                  control={control}
                  render={(props) => (
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="contractor_require_documents"
                        checked={props.value}
                        onChange={({ target }) => {
                          const val = target.checked ? 1 : 0;
                          props.onChange(val);
                          updateData('contractor_require_documents', val);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="contractor_require_documents"
                      >
                        Require the contractor to upload necessary compliance
                        documents as per their country's labor laws.
                      </label>
                    </div>
                  )}
                />
              </div>
              <div className="pg-Compliance__CheckListItem">
                <Controller
                  name="contractor_require_id_check"
                  defaultValue={false}
                  control={control}
                  render={(props) => (
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="contractor_require_id_check"
                        checked={props.value}
                        onChange={({ target }) => {
                          const val = target.checked ? 1 : 0;
                          props.onChange(val);
                          updateData('contractor_require_id_check', val);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="contractor_require_id_check"
                      >
                        Require the contractor to verify their identity.
                      </label>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </StepSectionContainer>
      </div>
    </form>
  );
};
