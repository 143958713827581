import React from 'react';
import ReactSelect from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';
import { ContractStatusLabel, ContractStatus } from '../../constants';
import './index.scss';
import { useQuery } from '../../hooks';

export const ContractStatusFilter = () => {
  const params = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [type, setType] = React.useState<string | undefined>(
    params?.status || undefined,
  );

  const options = React.useMemo(() => {
    return [
      {
        label: 'All Statuses',
        value: undefined,
      },
      ...Object.entries(ContractStatus || {}).map(([key, value]) => ({
        label: ContractStatusLabel?.[key] || key,
        value: value,
      })),
    ];
  }, []);

  const changeType = (newVal?: string) => {
    setType(newVal);

    const params = new URLSearchParams(location.search);

    if (newVal) {
      params.set('status', String(newVal || ''));
    } else {
      params.delete('status');
    }

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <div className="pg_ContractStatusFilter__Wrapper">
      <ReactSelect
        value={options.find(({ value }) => value === type)}
        options={options}
        onChange={(selected) => changeType(selected?.value)}
        isSearchable={false}
      />
    </div>
  );
};
