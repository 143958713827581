import classNames from 'classnames';
import './radio-box.scss';

export const RadioBox = ({
  children,
  className,
  onChange,
  ...props
}: { children: React.ReactNode; onChange?: (checked: boolean) => any } & Omit<
  React.HTMLProps<HTMLInputElement>,
  'onChange'
>) => {
  return (
    <label
      className={classNames('pg-RadioBox', className, {
        'pg-RadioBox--checked': props.checked,
      })}
    >
      {children}
      <input
        className="pg-RadioBox__Input"
        type="radio"
        onChange={(e) => onChange?.((e.target as HTMLInputElement).checked)}
        {...props}
      />
    </label>
  );
};
