import './index.scss';
import logo from '../../images/logo.svg';
import classNames from 'classnames';

export const AuthPageLayout = ({
  children,
  size = 'small',
}: {
  children: React.ReactNode;
  size?: 'small' | 'large';
}) => {
  return (
    <div className="pg-Auth">
      <h1 className="pg-Auth__Logo">
        <img alt="Brysk" src={logo} />
      </h1>
      <div
        className={classNames('pg-Auth__Content', `pg-Auth__Content--${size}`)}
      >
        {children}
      </div>
    </div>
  );
};
