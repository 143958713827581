import React, { useEffect, useMemo, useState } from 'react';
import { LayoutContent, ActivityTable, PaymentsTable } from '../../components';
import { useApi, useCompany } from '../../hooks';
import { isEmpty, uniqBy } from 'lodash';
import {
  FaUserAlt,
  FaGlobeAmericas,
  FaFileInvoice,
  FaCalendarTimes,
} from 'react-icons/fa';
import { currencyFormatter } from '../../shared/helpers';
import {
  DashboardItem,
  DashboardTeamMembers,
  DashboardCountItem,
  TeamMemberItem,
} from '.';

export const ClientDashboard = () => {
  const api = useApi();
  const { selectedCompany } = useCompany();
  const [data, setData] = useState<any>({});

  const totalPeople = useMemo(() => {
    return (data?.team_members as TeamMemberItem[])?.reduce(
      (total, { members_count }) => (total += members_count),
      0,
    );
  }, [data]);

  const totalCountries = useMemo(() => {
    return uniqBy(
      data?.team_members as TeamMemberItem[],
      ({ country_code }) => country_code,
    ).length;
  }, [data]);

  const totalPendingPaymetns = useMemo(() => {
    return (data?.pending_payments as { count: number }[])?.reduce(
      (total, { count }) => (total += count),
      0,
    );
  }, [data]);

  const overduePaymentsTotal = useMemo(() => {
    return (data?.pending_payments as { total: number }[])?.reduce(
      (total, item) => (total += item.total),
      0,
    );
  }, [data]);

  const overduePaymentsCurrency = useMemo(() => {
    if (data?.pending_payments?.length) {
      return data?.pending_payments[0].currency_code;
    }
    return 'USD';
  }, [data]);

  useEffect(() => {
    api
      .get(`/api/client/company/${selectedCompany.company_id}/dashboard`)
      .then(({ data }) => setData(data));
  }, [api, selectedCompany.company_id]);

  return (
    <>
      <LayoutContent size="small">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-xl-3 mb-4">
            <DashboardCountItem
              label="People"
              count={totalPeople}
              icon={<FaUserAlt />}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-xl-3 mb-4">
            <DashboardCountItem
              label="Countries"
              count={totalCountries}
              icon={<FaGlobeAmericas />}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-xl-3 mb-4">
            <DashboardCountItem
              label="Active contracts"
              count={data?.active_contracts}
              icon={<FaFileInvoice />}
            />
          </div>
          <div className="col-sm-12 col-md-6 col-xl-3 mb-4">
            <DashboardCountItem
              label="Payments overdue"
              count={totalPendingPaymetns}
              additional={`Total of ${currencyFormatter(
                overduePaymentsTotal ?? 0,
                overduePaymentsCurrency,
              )}`}
              icon={<FaCalendarTimes />}
              danger
            />
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-xl-6 mb-4">
            <DashboardItem>
              <p className="pg-DashboardItem__Title">Activity</p>

              <div className="pg-DashboardItem__Content">
                <ActivityTable data={data?.activity} />
              </div>
            </DashboardItem>
          </div>

          <div className="col-sm-12 col-xl-6 mb-4">
            <DashboardItem>
              <p className="pg-DashboardItem__Title">Payments History</p>

              <div className="pg-DashboardItem__Content">
                <PaymentsTable
                  data={data?.payments_history}
                  showMore={!isEmpty(data?.payments_history)}
                />
              </div>
            </DashboardItem>
          </div>

          <div className="col-sm-12 col-xl-6 mb-4">
            <DashboardItem>
              <DashboardTeamMembers
                data={data?.team_members}
              ></DashboardTeamMembers>
            </DashboardItem>
          </div>
        </div>
      </LayoutContent>
    </>
  );
};
