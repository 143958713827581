import classNames from 'classnames';
import './index.scss';

const ButtonThemeClasses = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  success: 'btn-success',
  danger: 'btn-danger',
  warning: 'btn-warning',
  info: 'btn-info',
  light: 'btn-light',
  dark: 'btn-dark',
  link: 'btn-link',
} as const;

export type ButtonTheme = keyof typeof ButtonThemeClasses;

const ButtonThemeOutlineClasses = {
  primary: 'btn-outline-primary',
  secondary: 'btn-outline-secondary',
  success: 'btn-outline-success',
  danger: 'btn-outline-danger',
  warning: 'btn-outline-warning',
  info: 'btn-outline-info',
  light: 'btn-outline-light',
  dark: 'btn-outline-dark',
  link: 'btn-outline-link',
} as const;

export type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
  type?: 'button' | 'submit' | 'reset' | undefined;
  theme?: keyof typeof ButtonThemeClasses;
  outline?: boolean;
  small?: boolean;
  rightContent?: React.ReactNode;
  leftContent?: React.ReactNode;
  loading?: boolean;
  block?: boolean;
};

export const Button = ({
  className,
  theme = 'primary',
  outline,
  small,
  children,
  rightContent,
  leftContent,
  loading,
  disabled,
  type = 'button',
  block,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        'pg-Button btn',
        {
          [ButtonThemeOutlineClasses[theme]]: outline,
          [ButtonThemeClasses[theme]]: !outline,
          'btn-sm': small,
          'd-block w-100': block,
          'pg-Button--loading': loading,
        },
        className,
      )}
      type={type}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <div className="pg-ButtonLoader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : null}
      {leftContent ? (
        <span className="pg-Button__LeftContent">{leftContent}</span>
      ) : null}
      <span className="pg-Button__Content">{children}</span>
      {rightContent ? (
        <span className="pg-Button__RightContent">{rightContent}</span>
      ) : null}
    </button>
  );
};
