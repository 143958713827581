import classNames from 'classnames';
import React from 'react';
import { FaEdit } from 'react-icons/fa';
import './list-item.scss';

export const ListItem = ({
  label,
  labelSize = 'medium',
  children,
  onEdit,
  full,
}: {
  label?: React.ReactNode;
  labelSize?: 'small' | 'medium' | 'large';
  children?: React.ReactNode;
  onEdit?: () => any;
  full?: boolean;
}) => (
  <div className="pg-ListItem">
    <div
      className={classNames(
        'pg-ListItem__Label',
        `pg-ListItem__Label--${labelSize}`,
        {
          'pg-ListItem__Label--full': full,
        },
      )}
    >
      {label}
    </div>
    <div className="pg-ListItem__Content">{children}</div>
    {typeof onEdit === 'function' ? (
      <FaEdit className="pg-ListItem__Edit" onClick={onEdit} />
    ) : null}
  </div>
);
