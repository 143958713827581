import React, { useContext } from 'react';
import { FormGroupContext } from '../form-group';
import './index.scss';

export const TextArea = React.forwardRef(
  (
    {
      value,
      onChange,
      max,
      className,
      ...props
    }: Omit<React.HTMLProps<HTMLTextAreaElement>, 'onChange'> & {
      value?: string;
      onChange?: (value: string) => any;
      max?: number;
    },
    ref: any,
  ) => {
    const { hasErrors } = useContext(FormGroupContext);
    const currentCount = React.useMemo(() => value?.length || 0, [value]);
    const change = (value: string) => {
      onChange?.(value);
    };
    return (
      <div className="pg-TextArea">
        <textarea
          value={value}
          onChange={(e) => change(e.target.value)}
          maxLength={max}
          className={`form-control ${
            hasErrors ? 'is-invalid' : ''
          } ${className}`}
          {...props}
          ref={ref}
        ></textarea>
        {max ? (
          <div className="pg-TextArea__Count">
            {currentCount} / {max}
          </div>
        ) : null}
      </div>
    );
  },
);
