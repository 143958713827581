import {
  IoAlertCircleOutline,
  IoInformationCircleOutline,
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from 'react-icons/io5';
import './index.scss';

export type ToasterTheme =
  | 'default'
  | 'primary'
  | 'success'
  | 'danger'
  | 'info'
  | 'warning';

export const Toaster = ({
  children,
  onClose,
  title,
  info,
  icon,
  theme = 'default',
}: {
  children: React.ReactNode;
  onClose: () => any;
  title?: React.ReactNode;
  info?: React.ReactNode;
  icon?: React.ReactNode;
  theme?: ToasterTheme;
}) => {
  const getIcon = () => {
    const icons = {
      default: () => <></>,
      primary: () => <></>,
      success: () => <IoCheckmarkCircleOutline />,
      danger: () => <IoCloseCircleOutline />,
      info: () => <IoInformationCircleOutline />,
      warning: () => <IoAlertCircleOutline />,
    };

    return icons[theme]();
  };

  return (
    <div
      className={`pg-Toaster pg-Toaster--${theme} toast show ${
        !title ? 'd-flex' : ''
      }`}
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      {title ? (
        <div className="toast-header">
          <strong className="me-auto pg-Toaster__Title">
            {icon ? icon : getIcon()}
            {title}
          </strong>
          <small>{info}</small>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="toast"
            aria-label="Close"
            onClick={onClose}
          ></button>
        </div>
      ) : null}
      <div className="toast-body">{children}</div>
      {!title ? (
        <button
          type="button"
          className="btn-close me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
          onClick={onClose}
        ></button>
      ) : null}
    </div>
  );
};
