import { PaymentMethod, TaxForm } from './enums';

export const IDENTITY_VERIFICATION_ERROR: any = {
  consent_declined: 'You have to accept the consent in order to proceed',
  document_expired: 'The provided identity document has expired',
  document_unverified_other:
    'Stripe couldn’t verify the provided identity document',
  document_type_not_supported:
    'The provided identity document isn’t one of the session’s allowed document types',
};

export const CONTRACTOR_PERMISSIONS_KEY: string = 'self';

export const PAYMENT_METHOD_TYPE: any = {
  [PaymentMethod.WIRE]: 'Wire transfer',
  [PaymentMethod.PAYONEER]: 'Payoneer',
  [PaymentMethod.STRIPE_CARD]: 'Credit card',
  [PaymentMethod.STRIPE_BANK]: 'Bank account',
};

export const ComplianceDocumentTypes = {
  IdentityVerification: 'identity_verification',
  LicensesPermits: 'licenses_permits',
  AdditionalCompliance: 'additional_compliance',
};

export const AVAILABLE_TAX_FORMS = [TaxForm.W9, TaxForm.W8BEN, TaxForm.W8BENE];

export const ContractStatusLabel: { [x: string]: string } = {
  DRAFT: 'Draft',
  PENDING_CLIENT_SIGNATURE: 'Pending Client Signature',
  PENDING_CONTRACTOR_INVITE: 'Pending Contractor Invite',
  PENDING_CONTRACTOR_SIGNATURE: 'Pending Contractor Signature',
  ACTIVATED: 'Activated',
  TERMINATED: 'Terminated',
};
