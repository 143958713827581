import { startCase } from 'lodash';
import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { TemplatePlaceholder } from '../template-placeholder';
import { TemplatePlaceholder as ITemplatePlaceholder } from '../../interfaces';

import './index.scss';

export const PlaceholderGroup = ({
  title,
  placeholders,
}: {
  title: string;
  placeholders: ITemplatePlaceholder[];
}) => {
  const [expand, setExpand] = useState(true);

  return (
    <div className="pg__PlaceholderGroup">
      <p
        className="pg__PlaceholderGroup__Title"
        onClick={() => setExpand((p) => !p)}
      >
        <span>{startCase(title)}</span>
        <span>{expand ? <FaChevronUp /> : <FaChevronDown />}</span>
      </p>

      <div
        className="pg__PlaceholderGroup__Items"
        style={{
          display: expand ? 'block' : 'none',
        }}
      >
        {placeholders.map((placeholder: ITemplatePlaceholder) => (
          <div
            key={placeholder?.id}
            className="pg__PlaceholderGroup__Items__Item"
          >
            <TemplatePlaceholder placeholder={placeholder} />
          </div>
        ))}
      </div>
    </div>
  );
};
