import React from 'react';
import { ExpectionHandler } from '../exception-handler';

interface IProps {}

interface IState {
  hasError: boolean;
  error: Error | null;
}

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: any, info: any) {
    // Log errors if needed
  }

  render() {
    if (this.state.hasError) {
      return <ExpectionHandler error={this.state.error} />;
    }

    return this.props.children;
  }
}
