import _ from 'lodash';
import React from 'react';
import ReactSelect from 'react-select';
import { CommonDataService } from '../../shared/services/common-data';
import { useService } from '../../shared/services/use-service';
import { InputPlaceholder } from '../input-placeholder';

interface State {
  label: string;
  value: number;
}
interface StateRepository {
  [x: string]: {
    states?: State[];
  };
}

export const useStates = (countryId?: number) => {
  const commonData = useService(CommonDataService);
  const [repo, setRepo] = React.useState<StateRepository>();

  React.useEffect(() => {
    if (!countryId) return;

    commonData.get().then((data) => {
      let states =
        data.countries?.find((country) => country.id === countryId)?.states ||
        [];

      setRepo((prev) => ({
        ...prev,
        [countryId]: {
          states: states.map(({ id, state_name }) => ({
            label: state_name,
            value: id,
          })),
        },
      }));
    });
  }, [commonData, countryId]);

  return _.get(repo, [String(countryId), 'states'], []);
};

export const StateSelect = ({
  countryId,
  value,
  onChange,
  readOnly,
}: {
  countryId?: number;
  value?: number;
  onChange?: (value?: number) => any;
  readOnly?: boolean;
}) => {
  const states = useStates(countryId);

  return !readOnly ? (
    <ReactSelect
      isClearable={false}
      options={states}
      value={states.find((state) => state.value === value)}
      onChange={(selected) => onChange?.(selected?.value)}
    />
  ) : (
    <InputPlaceholder>
      {states.find((state) => state.value === value)?.label || 'N/A'}
    </InputPlaceholder>
  );
};
