import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, FormGroup, Loader } from '../../../components';
import { PaymentMethod } from '../../../constants';
import { useApi } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import {
  PayoneerPaymentMethod,
  WirePaymentMethod,
  PaymentMethod as IPaymentMethod,
} from '../../../interfaces';
import { useProfileService } from '../../../shared/services';
import { OnboardingActions } from '../components/actions';
import { OnboardingFormGroup } from '../components/form-group';
import { PayoneerMethods } from '../components/payoneer';
import { OnboardingRadioGroup } from '../components/radio-group';
import { WireMethods } from '../components/wire';

export const PaymentDetails = ({
  onNext,
  onPrev,
}: {
  onNext?: () => any;
  onPrev?: () => any;
}) => {
  const { watch, control, handleSubmit, setValue } = useFormContext();
  const api = useApi();
  const toaster = useToaster();
  const { profile } = useProfileService();
  const option = watch('option');
  const primaryPaymentMethod = watch('selected_payment_method');
  const [loading, setLoading] = React.useState(true);
  const [paymentMethods, setPaymentMethods] = React.useState<IPaymentMethod[]>(
    [],
  );

  const isValid = React.useMemo(
    () => !!option && !!primaryPaymentMethod?.hash,
    [option, primaryPaymentMethod?.hash],
  );

  const payoneerMethods = React.useMemo(
    () =>
      paymentMethods?.filter(
        (pm: IPaymentMethod) => pm?.type === PaymentMethod.PAYONEER,
      ) as PayoneerPaymentMethod[],
    [paymentMethods],
  );

  const wireMethods = React.useMemo(
    () =>
      paymentMethods?.filter(
        (pm: IPaymentMethod) => pm?.type === PaymentMethod.WIRE,
      ) as WirePaymentMethod[],
    [paymentMethods],
  );

  const primaryMethod = React.useMemo(
    () => paymentMethods?.find((pm: IPaymentMethod) => Boolean(pm?.primary)),
    [paymentMethods],
  );

  const onSubmit = async () => {
    try {
      await api.patch(`/api/payment/methods/${primaryPaymentMethod?.hash}`, {
        payment_method_type: primaryPaymentMethod?.type,
        billable_id: profile?.id,
        billable_type: 'user',
        primary: 1,
      });

      onNext?.();
    } catch (e) {
      toaster.danger({ message: 'Something went wrong' });
    }
  };

  const refresh = () => {
    fetchPaymentMethods(false);
  };

  const fetchPaymentMethods = React.useCallback(
    async (showLoader: boolean = true) => {
      if (showLoader) {
        setLoading(true);
      }

      const {
        data,
      }: {
        data: IPaymentMethod[];
      } = await api.get(
        `/api/payment/methods?billable_type=user&billable_id=${profile?.id}`,
      );

      setPaymentMethods(data || []);

      setLoading(false);
    },
    [api, profile?.id],
  );

  React.useEffect(() => {
    fetchPaymentMethods();
  }, [fetchPaymentMethods]);

  React.useEffect(() => {
    setValue('option', primaryMethod?.type);

    setValue('selected_payment_method', primaryMethod);
  }, [primaryMethod, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <OnboardingFormGroup
        title="Add your payment details"
        actions={
          <OnboardingActions>
            <Button theme="light" outline onClick={onPrev}>
              Back
            </Button>
            <Button theme="primary" type="submit" disabled={!isValid}>
              Next
            </Button>
          </OnboardingActions>
        }
      >
        <div className="row">
          <div className="col-md-12">
            <FormGroup required label="Select preferred payment method">
              <Controller
                name="option"
                control={control}
                defaultValue=""
                rules={{ required: 'Preferred option is required' }}
                render={(props) => (
                  <OnboardingRadioGroup
                    name="option"
                    value={props.value || ''}
                    onChange={props.onChange}
                    options={[
                      { label: 'Payoneer', value: PaymentMethod.PAYONEER },
                      { label: 'Wire Transfer', value: PaymentMethod.WIRE },
                    ]}
                  />
                )}
              />
            </FormGroup>
          </div>

          {loading ? (
            <Loader fullscreen />
          ) : (
            <>
              <PayoneerMethods
                methods={payoneerMethods}
                active={option === PaymentMethod.PAYONEER}
                refresh={refresh}
              />

              <WireMethods
                methods={wireMethods}
                active={option === PaymentMethod.WIRE}
                refresh={refresh}
              />
            </>
          )}
        </div>
      </OnboardingFormGroup>
    </form>
  );
};
