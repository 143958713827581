import React from 'react';
import { ClientOnboarding } from './Client';
import { ProtectedRouteProps } from '../../routes';
import { useProfileService } from '../../shared/services';
import './index.scss';

export const ONBOARDING_PATH = '/onboarding';

export const ONBOARDING_ROUTE: ProtectedRouteProps = {
  protect: true,
  path: ONBOARDING_PATH,
  render: () => <Onboarding />,
};

export const Onboarding = () => {
  const { actAsClient } = useProfileService();

  return actAsClient ? <ClientOnboarding /> : null;
};
