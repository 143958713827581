import React from 'react';
import { Box, PageTitle } from '../../components';
import visa from '../../images/visa.png';
import mastercard from '../../images/mastercard.png';
import amex from '../../images/amex.png';
import ListSources from './ACH/ListSources';
import ListCards from './Card/ListCards';
import { RouteConfigItem } from '../../routes';
import { CompanyMustBeOnboarded } from '../../middlewares';
import { Role, PaymentMethod } from '../../constants';

export const PAYMENT_METHODS_PATH = '/payment-methods';

export const PAYMENT_METHODS_ROUTE: RouteConfigItem = {
  path: PAYMENT_METHODS_PATH,
  permissions: ['read.payments'],
  middlewares: [CompanyMustBeOnboarded],
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  render: () => <PaymentMethods />,
};

const PaymentItem = ({
  title,
  icon,
  currency = 'USD',
}: {
  title?: React.ReactNode;
  icon?: React.ReactNode;
  currency?: string;
}) => (
  <div className="pg-PaymentItem">
    <div className="pg-PaymentItem__Provider">
      <div className="pg-PaymentItem__ProviderTitle">
        {icon ? <div className="pg-PaymentItem__Icon">{icon}</div> : null}
        {title}
      </div>
    </div>
  </div>
);

export const PaymentMethods = () => {
  const [selected, setSelected] = React.useState(PaymentMethod.STRIPE_CARD);

  return (
    <>
      <PageTitle>Payment Methods</PageTitle>

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-4">
                <Box>
                  <Box
                    className="mb-3"
                    bordered
                    onClick={() => setSelected(PaymentMethod.STRIPE_CARD)}
                    selected={selected === PaymentMethod.STRIPE_CARD}
                  >
                    <PaymentItem
                      title={
                        <>
                          <p>Credit/debit Cards</p>
                          <div>
                            <img
                              className="pg-CreditCardLogo"
                              src={visa}
                              alt=""
                            />
                            <img
                              className="pg-CreditCardLogo"
                              src={mastercard}
                              alt=""
                            />
                            <img
                              className="pg-CreditCardLogo"
                              src={amex}
                              alt=""
                            />
                          </div>
                        </>
                      }
                    />
                  </Box>
                  <Box
                    className="mb-3"
                    bordered
                    onClick={() => setSelected(PaymentMethod.STRIPE_BANK)}
                    selected={selected === PaymentMethod.STRIPE_BANK}
                  >
                    <PaymentItem
                      icon={<>🇺🇸</>}
                      title="Automated Clearing House (ACH)"
                    />
                  </Box>
                </Box>
              </div>
              <div className="col-sm-8">
                {selected === PaymentMethod.STRIPE_CARD && <ListCards />}
                {selected === PaymentMethod.STRIPE_BANK && <ListSources />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
