import React from 'react';
import {
  Action,
  FormSection,
  Table,
  Tag,
  Td,
  Th,
  Tr,
  ObscuredContent,
  AddWebhookSubscriberModal,
  EditWebhookSubscriberModal,
} from '..';
import { IoPencil, IoTrash } from 'react-icons/io5';
import { useModal } from '../../hooks';
import { useApi } from '../../hooks/use-api';
import { useToaster } from '../../hooks/use-toaster';
import { isEmpty } from 'lodash';
import './index.scss';

const EmptySubscribersList = () => {
  return (
    <Tr>
      <Td colSpan={6}>
        <p className="text-center p-4 text-gray mb-0">
          You don't have any linked subscribers yet.
        </p>
      </Td>
    </Tr>
  );
};

export const WebhookSettings = () => {
  const api = useApi();
  const toaster = useToaster();
  const modalStore = useModal();
  const [subscribers, setSubscribers] = React.useState<any>([]);

  const fetchSubscribers = React.useCallback(() => {
    api
      .get('/api/client/webhook-subscribers')
      .then(({ data: { subscribers } }) => {
        setSubscribers(subscribers);
      });
  }, [api]);

  const addSubscriber = () => {
    modalStore.openModal((close) => (
      <AddWebhookSubscriberModal close={close} refresh={fetchSubscribers} />
    ));
  };

  const revokeSubscriber = (id: string) => {
    api
      .delete(`/api/client/webhook-subscribers/${id}`)
      .then(({ data }) => {
        fetchSubscribers();

        toaster.success({
          message: data,
        });
      })
      .catch(() => {
        toaster.danger({
          message: 'There was an error revoking subscriber key.',
        });
      });
  };

  const handleRevoke = (id: string) => {
    modalStore
      .confirmModal({
        theme: 'danger',
        title: 'Revoke Webhook Subscriber?',
        message:
          'This operation cannot be reversed and all webhook listeners that uses this key will no longer have access.',
        confirmBtn: 'Revoke',
      })
      .result.then(() => {
        revokeSubscriber(id);
      })
      .catch(() => {});
  };

  const handleEdit = (subscriber: any) => {
    modalStore.openModal((close) => (
      <EditWebhookSubscriberModal
        subscriber={subscriber}
        close={close}
        refresh={fetchSubscribers}
      />
    ));
  };

  React.useEffect(() => {
    fetchSubscribers();
  }, [fetchSubscribers]);

  return (
    <FormSection
      title="Webhook Settings"
      info="Configure the webhook callbacks on different system events."
      actions={
        <Action type="button" theme="primary" onClick={addSubscriber}>
          New Subscriber
        </Action>
      }
    >
      <Table>
        <thead>
          <Tr>
            <Th width="25%">Name</Th>
            <Th width="25%">Key</Th>
            <Th width="25%">Redirect URL</Th>
            <Th width="25%">Events</Th>
            <Th></Th>
          </Tr>
        </thead>
        <tbody>
          {isEmpty(subscribers) && <EmptySubscribersList />}

          {subscribers?.map((subscriber: any) => (
            <Tr key={subscriber?.id}>
              <Td>{subscriber?.name}</Td>

              <Td>
                <ObscuredContent>{subscriber?.key}</ObscuredContent>
              </Td>

              <Td>
                {subscriber?.redirect_url ? (
                  <a
                    href={subscriber?.redirect_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {subscriber?.redirect_url}
                  </a>
                ) : (
                  'N/A'
                )}
              </Td>

              <Td>
                {isEmpty(subscriber?.events) && <>N/A</>}
                {subscriber?.events?.map((event: any) => (
                  <Tag key={event.key}>{event.name}</Tag>
                ))}
              </Td>

              <Td isAction>
                <div className="d-flex">
                  <Action tooltip="Edit" onClick={() => handleEdit(subscriber)}>
                    <IoPencil />
                  </Action>

                  <Action
                    tooltip="Revoke"
                    onClick={() => handleRevoke(subscriber?.id)}
                  >
                    <IoTrash />
                  </Action>
                </div>
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </FormSection>
  );
};
