import React from 'react';
import queryString from 'query-string';
import { FcDocument } from 'react-icons/fc';
import { ImDownload } from 'react-icons/im';
import { RouteProps, useHistory } from 'react-router-dom';
import { Box, Button, MediaObject, PageTitle } from '../../components';
import { LabelValueRow } from '../contracts/label-value-row';
import { Steps } from '../contract-wrapper/steps';
import './index.scss';
import { upperFirst } from 'lodash';
import { Role } from '../../constants';
import { useAuth } from '../../hooks';
import { AuthService, useProfileService } from '../../shared/services';
import { useService } from '../../shared/services/use-service';
import Storage from '../../shared/db/storage';

export const CONTRACT_PREVIEW_PATH = '/contract-preview';

export const CONTRACT_PREVIEW_ROUTE: RouteProps & { protect: boolean } = {
  protect: false,
  path: CONTRACT_PREVIEW_PATH,
  render: (props) => {
    const queryParams = queryString.parse(props.location.search);
    const contractId = (queryParams.id as string) || '';
    const email = (queryParams.email as string) || '';
    const name = (queryParams.n as string) || '';
    const action = (queryParams?.action as string) || 'register';
    return (
      <ContractPreview
        name={name}
        id={contractId}
        action={action}
        email={email}
      />
    );
  },
};

export const ContractPreview = ({
  name,
  id,
  email,
  action,
}: {
  name: string;
  id?: string;
  email?: string;
  action: string;
}) => {
  const mounted = React.useRef(false);
  const auth = useAuth();
  const history = useHistory();
  const { hasRole, profile } = useProfileService();
  const authService = useService(AuthService);

  const redirectToAction = React.useCallback(() => {
    Storage.setItem('redirectTo', `/contracts/${id}`);
    Storage.setItem('accountType', Role.CONTRACTOR);

    history.replace(`/${action}?contract=${id}&email=${email}`);
  }, [action, email, history, id]);

  const redirectToContractPreview = React.useCallback(() => {
    Storage.setItem('accountType', Role.CONTRACTOR);

    history.replace(`/contracts/${id}`);
  }, [history, id]);

  React.useEffect(() => {
    if (!auth?.isAuthenticated) return;

    if (profile?.email !== email || !hasRole(Role.CONTRACTOR)) {
      authService.logout();

      redirectToAction();
    }

    mounted.current = true;
  }, [profile, email, auth, hasRole, redirectToAction, authService]);

  React.useEffect(() => {
    if (
      mounted?.current &&
      auth?.isAuthenticated &&
      profile?.email === email &&
      hasRole(Role.CONTRACTOR)
    ) {
      redirectToContractPreview();
    }
  }, [
    auth?.isAuthenticated,
    email,
    hasRole,
    mounted,
    profile?.email,
    redirectToContractPreview,
  ]);

  return (
    <div className="pg-ContractPreview">
      <PageTitle className="pg-ContractPreview__Title">
        <Steps
          steps={[
            { name: 'Lorem' },
            { name: 'Ipsum' },
            { name: 'Ipsum' },
            { name: 'Ipsum' },
          ]}
          activeStep={2}
        />
      </PageTitle>
      <div className="mt-5">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-md-6"
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <h4>Sign {name}</h4>
              <Button onClick={redirectToAction}>{upperFirst(action)}</Button>
            </div>
          </div>
          <div className="pg-ContractPreview__Content row justify-content-center">
            <div className="col-md-6">
              <h5>Signatures</h5>
              <p>You are signing this with team "Lorem Ipsum"</p>
              <Box
                className="mt-3"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>Lorem ipsum dolor sit amet</div>
                <div>
                  <Button>Sign</Button>
                </div>
              </Box>
              <Box
                className="mt-3"
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>Lorem ipsum dolor sit amet</div>
                <div>
                  <Button>Sign</Button>
                </div>
              </Box>

              <h5 className="mt-5">Review the contract details</h5>
              <Box className="mt-3">
                <MediaObject
                  className="pg-ContractDetailsFile"
                  media={
                    <div className="pg-ContractDetailsFile__Icon">
                      <FcDocument />
                    </div>
                  }
                >
                  <div className="pg-ContractDetailsFile__Content">
                    <div className="pg-ContractDetailsFile__Label">
                      Using the standard Brysk contract
                    </div>
                    <div className="pg-ContractDetailsFile__Action">
                      <Button className="btn btn-link pg-ContractDetailsFile__ActionButton">
                        <ImDownload />
                      </Button>
                    </div>
                  </div>
                </MediaObject>
              </Box>
              <Box className="mt-3">
                <MediaObject
                  className="pg-ContractDetailsFile"
                  media={
                    <div className="pg-ContractDetailsFile__Icon">
                      <FcDocument />
                    </div>
                  }
                >
                  <div className="pg-ContractDetailsFile__Content">
                    <div className="pg-ContractDetailsFile__Label">
                      Contract Attachment
                    </div>
                    <div className="pg-ContractDetailsFile__Action">
                      <Button className="btn btn-link pg-ContractDetailsFile__ActionButton">
                        <ImDownload />
                      </Button>
                    </div>
                  </div>
                </MediaObject>
              </Box>
              <Box className="mt-5">
                <h6 className="pg-ContractDetails__ItemTitle">General info</h6>
                <LabelValueRow inline label="Name">
                  {name}
                </LabelValueRow>
                <LabelValueRow inline label="Contract type">
                  Lorem ipsum
                </LabelValueRow>
                <LabelValueRow inline label="Scope">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Doloremque dignissimos est quis laborum non autem doloribus
                  odit qui vero eum velit quos nesciunt, quidem tempore, illo
                  nihil placeat ut pariatur!
                </LabelValueRow>

                <LabelValueRow inline label="Start date">
                  01/12/2021
                </LabelValueRow>
                <LabelValueRow inline label="Termination date">
                  <p>
                    This contract has no termination date and will end when
                    either party manually terminates it.
                  </p>
                  <p>
                    Either party may terminate this contract by giving 10 days
                    of notice, after which the contract will be terminated.
                  </p>
                </LabelValueRow>
              </Box>

              <Box className="mt-5">
                <h6 className="pg-ContractDetails__ItemTitle">
                  Payment details
                </h6>
                <LabelValueRow inline label="Rate">
                  <div>
                    <div className="text-end">123</div>
                    <div>Per week</div>
                  </div>
                </LabelValueRow>
                <LabelValueRow inline label="First payment date">
                  12/12/2021
                </LabelValueRow>
              </Box>

              <Box className="mt-5">
                <h6 className="pg-ContractDetails__ItemTitle">
                  Country compliance
                </h6>
                <LabelValueRow label="Compliance documents">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Deserunt, itaque ipsam? Deleniti quod at magnam animi quia
                  obcaecati est voluptatibus sint necessitatibus placeat culpa
                  cum ea ratione voluptas, labore tenetur.
                </LabelValueRow>
                <LabelValueRow inline label="Contractor's Country">
                  Lorem ipsum
                </LabelValueRow>
              </Box>

              <Box className="mt-5">
                <h6 className="pg-ContractDetails__ItemTitle">
                  Other specifics
                </h6>
                <LabelValueRow
                  inline
                  label={<i>There are no other specifics to display.</i>}
                />
              </Box>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
