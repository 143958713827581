import React from 'react';
import { Tag } from '..';
import { format } from 'date-fns';
import { FaCheckCircle, FaClock, FaTimesCircle } from 'react-icons/fa';
import { RiRefund2Line } from 'react-icons/ri';
import { currencyFormatter } from '../../shared/helpers';
import { PaymentStatus } from '../../constants';
import './index.scss';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { PAYMENT_HISTORY_PATH } from '../../pages';

interface PaymentHistoryItem {
  id: string;
  status: string;
  updated_at: string;
  total_amount: number;
  currency_iso: string;
}

const PaymentHistoryStatus: { [x: string]: any } = {
  [PaymentStatus.PENDING]: {
    label: 'Scheduled',
    icon: <FaClock />,
    color: 'warning',
  },
  [PaymentStatus.SUCCEEDED]: {
    label: 'Paid',
    icon: <FaCheckCircle />,
    color: 'success',
  },
  [PaymentStatus.REFUNDED]: {
    label: 'Refunded',
    icon: <RiRefund2Line />,
    color: 'primary',
  },
  [PaymentStatus.FAILED]: {
    label: 'Failed',
    icon: <FaTimesCircle />,
    color: 'danger',
  },
};

export const PaymentsTable = ({
  data = [],
  showMore = false,
}: {
  data: PaymentHistoryItem[];
  showMore?: boolean;
}) => {
  const hasRecords = React.useMemo(() => !isEmpty(data), [data]);

  return (
    <>
      <div className="c-PaymentHistory">
        <div className="c-PaymentHistory__List">
          {!hasRecords && (
            <h6 className="pg-DashboardItem__Empty mt-5"> No payments</h6>
          )}

          {hasRecords && (
            <table>
              <thead>
                <tr>
                  <th className="font-w-bolder">Date</th>
                  <th className="font-w-bolder">Amount</th>
                  <th className="font-w-bolder">Status</th>
                  <th className="font-w-bolder">PDF</th>
                </tr>
              </thead>

              <tbody>
                {data.map((item: PaymentHistoryItem) => (
                  <tr key={item.id}>
                    <td className="date">
                      {format(new Date(item.updated_at), 'MMMM d, yyyy')}
                    </td>

                    <td className="amount">
                      {currencyFormatter(
                        item.total_amount ?? 0,
                        item.currency_iso,
                      )}
                    </td>
                    <td className="status">
                      <Tag
                        icon={PaymentHistoryStatus[item.status].icon}
                        status={PaymentHistoryStatus[item.status].color}
                      >
                        {PaymentHistoryStatus[item.status].label}
                      </Tag>
                    </td>
                    <td className="pdf">
                      <span>Invoice</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {showMore && (
            <Link
              to={PAYMENT_HISTORY_PATH}
              className="c-PaymentHistory__List__More"
            >
              View more
            </Link>
          )}
        </div>
      </div>
    </>
  );
};
