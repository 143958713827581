import DayPickerInput from 'react-day-picker/DayPickerInput';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { Input } from '../input';
import { DateUtils, LocaleUtils } from 'react-day-picker';
import { InputPlaceholder } from '../input-placeholder';
import React, { useContext } from 'react';
import { FormGroupContext } from '../form-group';
import { Select } from '../select';
import './index.scss';
import { isNil } from 'lodash';

const YearMonthForm = ({
  date = new Date(),
  localeUtils,
  onChange,
}: {
  date: Date;
  localeUtils: LocaleUtils;
  onChange: (value: { year: number; month: number }) => any;
}) => {
  const currentYear = new Date().getFullYear();
  const fromMonth = new Date(currentYear - 100, 0);
  const toMonth = new Date(currentYear + 20, 11);
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = (e: any) => {
    const { year, month } = e.target.form;
    onChange({ year: parseInt(year.value), month: parseInt(month.value) });
  };

  return (
    <div className="DayPicker-Caption">
      <div className="pg-DatePicker__YearMonthForm">
        <Select
          name="month"
          onChange={handleChange}
          value={date.getMonth()}
          className="pg-DatePicker__MonthSelect"
        >
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </Select>
        <Select
          name="year"
          onChange={handleChange}
          value={date.getFullYear()}
          className="pg-DatePicker__YearSelect"
        >
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export const DatePicker = ({
  value,
  onChange,
  readOnly,
  placeholder,
  disabled,
}: {
  value?: any;
  onChange?: (value?: any) => any;
  readOnly?: boolean;
  placeholder?: string;
  disabled?: boolean;
}) => {
  const datePickerRef = React.createRef<DayPickerInput>();
  const { hasErrors } = useContext(FormGroupContext);
  const FORMAT = 'MM/dd/yyyy';

  const parseDate = (str: string, format: string) => {
    const parsed = dateFnsParse(str, format, new Date());
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  };

  const formatDate = (date: Date, format: string) => {
    return dateFnsFormat(date, format);
  };

  const getDefaultDate = () => {
    return !isNil(value) && DateUtils.isDate(new Date(value))
      ? new Date(value)
      : new Date();
  };

  const selectYearMonth = ({
    year,
    month,
  }: {
    year: number;
    month: number;
  }) => {
    const date = getDefaultDate();
    onChange?.(new Date(year, month, date.getDate()));
    datePickerRef.current?.forceUpdate();
  };

  return !readOnly ? (
    <DayPickerInput
      ref={datePickerRef}
      inputProps={{ readOnly, disabled, hasErrors }}
      component={Input}
      formatDate={formatDate}
      format={FORMAT}
      parseDate={parseDate}
      value={value}
      onDayChange={onChange}
      placeholder={placeholder ? placeholder : 'MM/DD/YYYY'}
      dayPickerProps={{
        selectedDays: value,
        navbarElement: () => null,
        month: getDefaultDate(),
        captionElement: ({ localeUtils }) => (
          <YearMonthForm
            date={!DateUtils.isDate(value) ? new Date() : value}
            localeUtils={localeUtils}
            onChange={selectYearMonth}
          />
        ),
      }}
    />
  ) : (
    <InputPlaceholder>
      {value ? dateFnsFormat(value, 'iii, MMM, do yyyy') : 'N/A'}
    </InputPlaceholder>
  );
};
