import React, { useMemo } from 'react';
import ReactSelect from 'react-select';
import { useApi, useEndpoint } from '../../hooks';
import { InputPlaceholder } from '../input-placeholder';
import { Preset as IPreset } from '../../interfaces/presets';

interface PresetOption {
  label: string;
  value: number | string;
}

export const PresetSelect = ({
  value,
  onChange,
  readOnly,
  isClearable = false,
}: {
  value?: number;
  onChange?: (value?: number | string) => any;
  readOnly?: boolean;
  isClearable?: boolean;
}) => {
  const api = useApi();
  const endpoint = useEndpoint();
  const [presets, setPresets] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const presetOptions: PresetOption[] = useMemo(() => {
    return presets.map((p: IPreset) => ({
      label: p.name,
      value: p.id,
    }));
  }, [presets]);

  const fetchPresets = React.useCallback(() => {
    setLoading(true);

    api
      .get(endpoint('presets.all'))
      .then(({ data }) => {
        const allPresets = data?.presets || [];

        const filteredPresets = allPresets?.filter(
          (p: IPreset) => !p.uploaded_only,
        );

        setPresets(filteredPresets || []);
      })
      .finally(() => setLoading(false));
  }, [api, endpoint]);

  React.useEffect(() => {
    fetchPresets();
  }, [fetchPresets]);

  return !readOnly ? (
    <ReactSelect
      isLoading={loading}
      options={presetOptions}
      isClearable={isClearable}
      value={presetOptions.find((p: PresetOption) => p.value === value)}
      onChange={(selected) => onChange?.(selected?.value)}
    />
  ) : (
    <InputPlaceholder>
      {presetOptions.find((p: PresetOption) => p.value === value)?.label ||
        'N/A'}
    </InputPlaceholder>
  );
};
