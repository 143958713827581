import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Invoice } from '.';
import {
  Box,
  MediaObject,
  InvoiceItemTypeSelect,
  Input,
  Button,
  FormGroup,
  TextArea,
} from '../../components';
import { useApi } from '../../hooks/use-api';
import { InvoiceItems } from './invoice-items';
import './invoice-details.scss';
import { BsFillBriefcaseFill, BsFillPersonFill } from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';

export const InvoiceDetails = ({
  invoice,
  onItemUpdate,
  onClose,
}: {
  invoice: Invoice;
  onItemUpdate?: () => any;
  onClose?: () => any;
}) => {
  const api = useApi();
  const { register, errors, handleSubmit, control } = useForm();
  const [adding, setAdding] = React.useState(false);

  React.useEffect(() => {
    setAdding(false);
  }, [invoice]);

  const addNewItem = (data: any) => {
    api
      .post(`/api/client/invoices/${invoice.id}/items`, {
        quantity: parseFloat(data.amount),
        note: data.note,
        invoice_item_type_id: parseInt(data.invoice_item_type_id),
      })
      .finally(() => {
        onItemUpdate?.();
      });
  };
  return (
    <div className="col-xl-4">
      <Box
        style={{
          minHeight: '200px',
          position: 'sticky',
          top: '20px',
        }}
        className="pg-InvoiceDetails"
      >
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a className="pg-InvoiceDetails__Close btn btn-link" onClick={onClose}>
          <IoClose />
        </a>
        <MediaObject
          align="center"
          media={<BsFillPersonFill className="pg-InvoiceDetails__Icon" />}
        >
          <div style={{ fontSize: '18px' }}>{invoice.contractor_name}</div>
        </MediaObject>
        <MediaObject
          align="center"
          media={<BsFillBriefcaseFill className="pg-InvoiceDetails__Icon" />}
        >
          <div>{invoice.contract_name}</div>
        </MediaObject>
        <h5 className="pg-InvoiceDetails__Title">Summary</h5>
        <InvoiceItems invoice={invoice} onItemUpdate={onItemUpdate} />
        <div className="mt-3 mb-4">
          {!adding ? (
            <Button
              className="w-100"
              outline
              theme="secondary"
              onClick={() => setAdding(true)}
            >
              Add pay item
            </Button>
          ) : null}
          {adding ? (
            <form onSubmit={handleSubmit(addNewItem)} className="my-2">
              <div className="row d-flex">
                <div className="col-xl-6">
                  <FormGroup
                    label="Type"
                    errors={errors.invoice_item_type_id}
                    required
                  >
                    <Controller
                      name="invoice_item_type_id"
                      rules={{ required: true }}
                      control={control}
                      render={(props) => (
                        <InvoiceItemTypeSelect
                          onChange={props.onChange}
                          value={props.value}
                        />
                      )}
                    ></Controller>
                  </FormGroup>
                </div>
                <div className="col-xl-6">
                  <FormGroup label="Value" errors={errors.amount} required>
                    <Input name="amount" ref={register({ required: true })} />
                  </FormGroup>
                </div>
                <div className="col-xl-12">
                  <FormGroup label="Note" errors={errors.note} required>
                    <TextArea name="note" ref={register({ required: true })} />
                  </FormGroup>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => setAdding(false)}
                  outline
                  theme="secondary"
                >
                  Cancel
                </Button>
                <Button type="submit">Add</Button>
              </div>
            </form>
          ) : null}
        </div>
      </Box>
    </div>
  );
};
