import React from 'react';
import { Role } from '../../constants';
import { UploadedDocument } from './components';
import { useToaster } from '../../hooks/use-toaster';
import { useApi, useCommonData } from '../../hooks/use-api';
import { Modal, Button, SignaturePad, ErrorList } from '../../components';
import './sign-modal.scss';
import { useService } from '../../shared/services/use-service';
import { TokenService } from '../../shared/services';

export const SignModal = ({
  close,
  cancel,
  contract,
  type,
}: {
  close: () => any;
  cancel: () => any;
  contract: any;
  type?: Role.CLIENT | Role.CONTRACTOR | Role.TEAM_MEMBER;
}) => {
  const api = useApi();
  const { token } = useService(TokenService);
  const toaster = useToaster();
  const common = useCommonData();
  const [loading, setLoading] = React.useState(false);
  const [previewContract, setPreviewContract] = React.useState<string>();
  const [signatureFormData, setSignatureFormData] = React.useState<
    FormData | undefined | null
  >(null);
  const [signatureTypeId, setSignatureTypeId] = React.useState<
    number | undefined
  >();

  const getContractFilePath = React.useCallback(() => {
    return contract.userContractFiles && contract.userContractFiles.length
      ? contract.userContractFiles[0].file_url
      : '';
  }, [contract]);

  const contractLink = React.useMemo(() => {
    const url = previewContract || getContractFilePath();

    return `${url}?auth=${token}`;
  }, [getContractFilePath, previewContract, token]);

  const sign = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!signatureFormData) return;

    setLoading(true);

    const typeId = signatureTypeId?.toString();

    if (typeId) {
      signatureFormData.append('signature_type_id', typeId);
    }

    api
      .fetch(`/api/client/contract/${contract.id}/sign`, {
        method: 'POST',
        body: signatureFormData,
      })
      .then(close)
      .catch(({ errors }) => {
        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    setSignatureTypeId(
      common?.signature_types?.find(
        (signatureType) =>
          signatureType.name.toLowerCase() === `${type} signature`,
      )?.id,
    );
  }, [common, type]);

  React.useEffect(() => {
    api.get(`/api/client/contract/${contract?.id}/preview`).then(({ data }) => {
      setPreviewContract(data['contract-preview-url'] ?? null);
    });
  }, [api, contract?.id]);

  return (
    <form onSubmit={sign}>
      <Modal
        size="lg"
        onClose={close}
        title="Sign contract"
        footer={
          <>
            <Button onClick={cancel} theme="secondary" outline>
              Cancel
            </Button>
            <Button
              type="submit"
              loading={loading}
              disabled={!signatureFormData?.has('signature_file')}
            >
              Agree &amp; Sign
            </Button>
          </>
        }
      >
        {previewContract && (
          <div className="col-12">
            <iframe
              title="Contract Preview"
              style={{
                width: '100%',
                height: '100%',
                border: 'none',
                minHeight: 570,
              }}
              src={`${previewContract}?auth=${token}`}
            />
          </div>
        )}
        <UploadedDocument
          actions={
            <>
              <a rel="noopener" download href={contractLink}>
                Download
              </a>
              <a rel="noreferrer" target="_blank" href={contractLink}>
                Open
              </a>
            </>
          }
        >
          Contract
        </UploadedDocument>

        <SignaturePad onChange={setSignatureFormData} />
      </Modal>
    </form>
  );
};
