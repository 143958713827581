import React from 'react';
import { Box, PageTitle, Tag } from '../../components';
import visa from '../../images/visa.png';
import mastercard from '../../images/mastercard.png';
import amex from '../../images/amex.png';
import './pay.scss';
import { currencyFormatter } from '../../shared/helpers';
import { useApi } from '../../hooks/use-api';
import { useCompany } from '../../hooks/use-company';
import ListCards from './Card/ListCards';
import ListSources from './ACH/ListSources';
import { Invoice } from './index';
import { PaymentMethod } from '../../constants';

const PaymentItem = ({
  title,
  icon,
  fee = 0,
  currency = 'USD',
  amount = 0,
}: {
  title?: React.ReactNode;
  icon?: React.ReactNode;
  fee?: number;
  currency?: string;
  amount?: number;
}) => (
  <div className="pg-PaymentItem">
    <div className="pg-PaymentItem__Provider">
      <div className="pg-PaymentItem__ProviderTitle">
        {icon ? <div className="pg-PaymentItem__Icon">{icon}</div> : null}
        {title}
      </div>
      <div className="pg-PaymentItem__ProviderFee">
        Provider fee: {currencyFormatter(fee, currency)}
      </div>
    </div>
    <div className="pg-PaymentItem__Amount">
      <div className="pg-PaymentItem__AmountLabel">Total amount</div>
      <div className="pg-PaymentItem__AmountValue">
        {currencyFormatter(fee + amount, currency)}
      </div>
    </div>
  </div>
);

const InvoiceInfo = ({ invoice }: { invoice: Invoice }) => {
  return (
    <div className="container">
      <div className="pg-InvoiceInfo">
        <div className="row">
          <h5 className="pg-InvoiceInfo__Title">Contractor info</h5>
          <div className="col-md-6">
            <div className="pg-InvoiceInfo__Item">{invoice.contract_name}</div>
            <div className="pg-InvoiceInfo__Item">
              <Tag size="small">{invoice.contract_type_name}</Tag>
            </div>
          </div>
          <div className="col-md-6">
            <div className="pg-InvoiceInfo__Item">
              {invoice.contractor_name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Pay = ({ ids = [] }: { ids: string[] }) => {
  const api = useApi();
  const { selectedCompany } = useCompany();
  const [selected, setSelected] = React.useState(PaymentMethod.STRIPE_CARD);
  const [invoices, setInvoices] = React.useState<Invoice[]>([]);
  const [amount, setAmount] = React.useState(0);

  React.useEffect(() => {
    api.get(`/api/client/company/${selectedCompany?.company_id}/invoices`).then(
      ({
        data: {
          invoices: { data },
        },
      }) => {
        let items = data;
        if (ids.length) {
          items = data.filter((item: any) => ids.includes(item.id));
        }
        setInvoices(items);
        setAmount(
          items.reduce(
            (total: number, current: any) => total + current.gross_pay,
            0,
          ),
        );
      },
    );
  }, [api, ids, selectedCompany?.company_id]);

  return (
    <>
      <PageTitle>Payment</PageTitle>
      {invoices.length === 1 && <InvoiceInfo invoice={invoices[0]} />}
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-md-7">
                <Box>
                  <Box
                    className="mb-3"
                    bordered
                    onClick={() => setSelected(PaymentMethod.STRIPE_CARD)}
                    selected={selected === PaymentMethod.STRIPE_CARD}
                  >
                    <PaymentItem
                      title={
                        <>
                          <img
                            className="pg-CreditCardLogo"
                            src={visa}
                            alt="Visa logo"
                          />
                          <img
                            className="pg-CreditCardLogo"
                            src={mastercard}
                            alt="Mastercard logo"
                          />
                          <img
                            className="pg-CreditCardLogo"
                            src={amex}
                            alt="Amex logo"
                          />
                        </>
                      }
                      fee={4.65}
                      amount={amount}
                    />
                  </Box>
                  <Box
                    className="mb-3"
                    bordered
                    onClick={() => setSelected(PaymentMethod.STRIPE_BANK)}
                    selected={selected === PaymentMethod.STRIPE_BANK}
                  >
                    <PaymentItem
                      icon={<>🇺🇸</>}
                      title="Automated Clearing House (ACH)"
                      fee={5}
                      amount={amount}
                    />
                  </Box>
                </Box>
              </div>
              <div className="col-md-5">
                {selected === PaymentMethod.STRIPE_CARD && (
                  <ListCards ids={ids} />
                )}
                {selected === PaymentMethod.STRIPE_BANK && (
                  <ListSources ids={ids} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
