import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.scss';

export const DocumentEditor = ({
  value = '',
  onChange,
}: {
  value?: string;
  onChange: () => void;
}) => {
  return <ReactQuill theme="snow" value={value} onChange={onChange} />;
};
