import React from 'react';
import { isAfter, isFuture, isDate } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import { FormGroup, Input, DatePicker, TextArea } from '../../../components';
import { StepControl, StepControlProps } from '../step-control';
import { StepSectionContainer } from './step-section-container';
import { useContractContext } from '..';
import { isNil } from 'lodash';

const defaultValues = {
  job_title: '',
  scope_of_work: '',
  weekly_allocation: '',
  start_date: '',
  termination_date: '',
};

export const PositionDetails = ({
  stepControlProps,
}: {
  stepControlProps: StepControlProps;
}) => {
  const { onNext, onPrev, ...stepProps } = stepControlProps;
  const { isEdit, contractData, updateData } = useContractContext();
  const { control, register, handleSubmit, getValues, reset, errors, watch } =
    useForm({
      defaultValues,
      mode: 'onChange',
    });
  const formValue = watch();

  const next = (data: any) => onNext?.(data);
  const prev = () => onPrev?.(formValue);

  const handleChange = (key: string, value: any, callback?: any) => {
    updateData(key, value);

    if (callback) {
      callback(value);
    }
  };

  React.useEffect(() => {
    reset({
      job_title: contractData?.job_title,
      scope_of_work: contractData?.scope_of_work,
      weekly_allocation: contractData?.weekly_allocation,
      start_date: contractData?.start_date,
      termination_date: contractData?.termination_date,
    });
  }, [reset, contractData]);

  return (
    <form onSubmit={handleSubmit(next)}>
      <StepSectionContainer
        actions={<StepControl onPrev={prev} {...stepProps} />}
      >
        <FormGroup label="Job Title" errors={errors.job_title} required>
          <Input
            placeholder="Ex: Senior Software Engineer"
            name="job_title"
            ref={register({ required: true })}
            onChange={({ target }) => handleChange('job_title', target.value)}
          />
        </FormGroup>

        <FormGroup label="Scope of Work" errors={errors.scope_of_work} required>
          <TextArea
            placeholder="You can start by writing down the role description, responsibilities, and/or deliverables..."
            name="scope_of_work"
            ref={register({ required: true })}
            onChange={(value) => handleChange('scope_of_work', value)}
          />
        </FormGroup>

        <FormGroup
          label="Expected work hours per week"
          errors={errors.weekly_allocation}
          required
        >
          <Input
            placeholder="Ex: 40"
            name="weekly_allocation"
            type="number"
            min={0}
            step="any"
            ref={register({ required: true, valueAsNumber: true })}
            onChange={({ target }) =>
              handleChange('weekly_allocation', target.value)
            }
          />
        </FormGroup>

        <FormGroup label="Start Date" errors={errors.start_date} required>
          <Controller
            name="start_date"
            control={control}
            defaultValue=""
            rules={{
              validate: (value) => {
                if (isNil(value)) return 'Start date is required';
                const dateValue = new Date(value);

                return isEdit
                  ? isDate(dateValue)
                  : (isDate(dateValue) && isFuture(dateValue)) ||
                      'Start date must be after today';
              },
            }}
            render={(props) => (
              <DatePicker
                placeholder="MM/DD/YYYY"
                value={props.value ? new Date(props.value) : null}
                onChange={(startDate) =>
                  handleChange('start_date', startDate, props.onChange)
                }
              />
            )}
          />
        </FormGroup>

        <FormGroup label="End Date" errors={errors.termination_date} required>
          <Controller
            name="termination_date"
            control={control}
            defaultValue=""
            rules={{
              validate: (value) => {
                if (isNil(value)) return 'End date is required';

                const startDate = new Date(getValues('start_date'));
                const dateValue = new Date(value);

                return (
                  (isDate(dateValue) && isAfter(dateValue, startDate)) ||
                  `End date must be after the start date ${startDate.toLocaleDateString()}`
                );
              },
            }}
            render={(props) => (
              <DatePicker
                placeholder="MM/DD/YYYY"
                value={props.value ? new Date(props.value) : null}
                onChange={(endDate) =>
                  handleChange('termination_date', endDate, props.onChange)
                }
              />
            )}
          />
        </FormGroup>
      </StepSectionContainer>
    </form>
  );
};
