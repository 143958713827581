import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useContractContext } from '..';
import {
  FormGroup,
  Input,
  CountrySelect,
  Loader,
  PresetSelect,
} from '../../../components';
import { email } from '../../../shared';
import { StepControlProps, StepControl } from '../step-control';
import { StepSectionContainer } from './step-section-container';
import { UploadedFile, useFileUpload } from '../file-upload';
import { isEmpty, isNil } from 'lodash';

export const ContractFileUpload = ({
  value,
  onChange,
}: {
  value?: { id: number; name: string; original_name?: string };
  onChange: (file?: { id: number; name: string }) => any;
}) => {
  const { fileInputRef, upload, loading } = useFileUpload();
  const { updateData } = useContractContext();

  const onInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      upload(e).then(({ ids, files }) => {
        const file = { id: ids[0], name: files?.[0].name };
        onChange?.(file);
      });
    },
    [onChange, upload],
  );

  const removeFile = () => {
    onChange?.();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    updateData('userContractFiles', []);
  };

  return (
    <div>
      {value?.name || value?.original_name ? (
        <UploadedFile value={value} onRemove={removeFile} />
      ) : (
        <div className="pg-ContractFileUpload">
          {loading ? (
            <Loader />
          ) : (
            <>
              Click here or drag file to upload
              <input
                className="pg-ContractFileUpload__Input"
                ref={fileInputRef}
                type="file"
                onChange={onInputChange}
                accept=".doc,.docx,.pdf"
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

const defaultValues = {
  contractor_name: '',
  contractor_email: '',
  contractor_country_id: null,
  use_own: null,
  preset_id: null,
  userContractFiles: [],
};

export const GeneralInfo = ({
  stepControlProps,
}: {
  stepControlProps: StepControlProps;
}) => {
  const { onNext, onPrev, ...stepProps } = stepControlProps;
  const { contractData, updateData } = useContractContext();
  const { control, register, handleSubmit, errors, reset, watch } = useForm({
    defaultValues,
  });
  const formValue = watch();

  const next = (data: any) => {
    onNext?.(data);
  };

  const prev = () => {
    onPrev?.(formValue);
  };

  const handleChange = (key: string, value: any, callback?: any) => {
    updateData(key, value);

    if (callback) {
      callback(value);
    }
  };

  React.useEffect(() => {
    reset({
      contractor_name: contractData?.contractor_name || '',
      contractor_email: contractData?.contractor_email || '',
      contractor_country_id: contractData?.contractor_country_id,
      use_own: isNil(contractData?.use_own) ? null : contractData?.use_own || 0,
      preset_id: contractData?.preset_id || null,
      userContractFiles: contractData?.userContractFiles || [],
    });
  }, [reset, contractData]);

  return (
    <form onSubmit={handleSubmit(next)}>
      <StepSectionContainer
        actions={<StepControl onPrev={prev} {...stepProps} />}
      >
        <FormGroup
          label="Contractor Name"
          errors={errors.contractor_name}
          required
        >
          <Input
            placeholder="Contractor Name"
            name="contractor_name"
            ref={register({ required: true })}
            onChange={({ target }) =>
              handleChange('contractor_name', target.value)
            }
          />
        </FormGroup>

        <FormGroup
          label="Contractor Email"
          errors={errors.contractor_email}
          required
        >
          <Input
            placeholder="Contractor Email"
            name="contractor_email"
            ref={register({ required: true, validate: email })}
            onChange={({ target }) =>
              handleChange('contractor_email', target.value)
            }
          />
        </FormGroup>

        <FormGroup
          label="Contractor country of residence"
          errors={errors.contractor_country_id}
          required
        >
          <Controller
            name="contractor_country_id"
            control={control}
            defaultValue=""
            rules={{
              required: `Contractor's country of residence is required`,
            }}
            render={(props) => (
              <CountrySelect
                value={props.value}
                onChange={(id) =>
                  handleChange('contractor_country_id', id, props.onChange)
                }
              />
            )}
          />
        </FormGroup>

        <div className="mb-3">
          <p className="mb-0">
            <span>Do you want Brysk to prepare your contract for you?</span>
            <span className="text-danger ms-1">*</span>
          </p>
          <small>
            You can choose to upload your own contract that has been already
            signed.
          </small>
        </div>

        <div className="mb-4">
          <Controller
            control={control}
            name="use_own"
            rules={{
              required: `You have to choose your contract`,
            }}
            render={(props) => (
              <FormGroup errors={errors.use_own} required>
                <div className="pg-Compliance__UseContractToggle">
                  <input
                    type="radio"
                    name="use_own"
                    className="btn-check"
                    id="brysk-contract"
                    checked={isNil(props.value) ? false : !props.value}
                    onChange={({ target }) =>
                      handleChange(
                        'use_own',
                        !target.checked ? 1 : 0,
                        props.onChange,
                      )
                    }
                  />
                  <label
                    className="btn btn-outline-primary pg-Compliance__UseContractToggle__Button"
                    htmlFor="brysk-contract"
                  >
                    Use Brysk Contract
                  </label>
                  <input
                    type="radio"
                    name="use_own"
                    className="btn-check"
                    id="own-contract"
                    checked={isNil(props.value) ? false : !!props.value}
                    onChange={({ target }) =>
                      handleChange(
                        'use_own',
                        target.checked ? 1 : 0,
                        props.onChange,
                      )
                    }
                  />
                  <label
                    className="btn btn-outline-primary pg-Compliance__UseContractToggle__Button"
                    htmlFor="own-contract"
                  >
                    Upload Signed Contract
                  </label>
                </div>
              </FormGroup>
            )}
          />

          {Boolean(formValue?.use_own) && (
            <div className="pg-Compliance__Upload">
              <Controller
                name="userContractFiles"
                control={control}
                render={({ value, onChange }) => (
                  <ContractFileUpload
                    onChange={(val) =>
                      handleChange('userContractFiles', [val], onChange)
                    }
                    value={isEmpty(value ?? {}) ? null : value[0]}
                  />
                )}
              />
            </div>
          )}

          {String(formValue?.use_own) === '0' && (
            <FormGroup label="Select Preset" errors={errors.preset_id} required>
              <Controller
                name="preset_id"
                control={control}
                defaultValue=""
                rules={{
                  required: `Contract preset is required`,
                }}
                render={(props) => (
                  <PresetSelect
                    value={props.value}
                    onChange={(id) =>
                      handleChange('preset_id', id, props.onChange)
                    }
                  />
                )}
              />
            </FormGroup>
          )}
        </div>
      </StepSectionContainer>
    </form>
  );
};
