import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AuthPageLayout, ErrorList, FormGroup, Input } from '../../components';
import { Button } from '../../components/button';
import { useAuth } from '../../hooks';
import { useForm } from 'react-hook-form';
import { email, match, maxLength, password } from '../../shared';
import { useApi } from '../../hooks/use-api';
import { useToaster } from '../../hooks/use-toaster';
import { LOGIN_PATH } from '../login';
import { HOME_PATH } from '../home';
import Storage from '../../shared/db/storage';

export const ClientRegister = () => {
  const auth = useAuth();
  const toaster = useToaster();
  const api = useApi();
  const history = useHistory();
  const { register, handleSubmit, watch, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const passwordField = watch('password');

  React.useEffect(() => {
    if (auth?.isAuthenticated) {
      history.push(HOME_PATH);
    }
  }, [auth?.isAuthenticated, history]);

  const onSubmit = async (data: {
    email: string;
    password: string;
    passwordConfirmation: string;
    firstName: string;
    lastName: string;
    companyName: string;
  }) => {
    setLoading(true);

    const redirectTo = Storage.getItem('redirectTo') || HOME_PATH;

    api
      .post('/api/register', api.createPayload({ ...data }))
      .then(() => {
        auth
          .login({ username: data.email, password: data.password })
          .then(() => {
            history.replace(redirectTo);
          });
      })
      .catch(({ errors }) => {
        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthPageLayout size="large">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="pg-AuthTabs">
          <li className="pg-AuthTabs__Item">
            <Link to={LOGIN_PATH} className="pg-AuthTabs__ItemLink">
              Login
            </Link>
          </li>
          <li className="pg-AuthTabs__Item">
            <span
              className="pg-AuthTabs__ItemLink pg-AuthTabs__ItemLink--active"
              aria-current="page"
            >
              Register
            </span>
          </li>
        </ul>

        <div className="row">
          <div className="col-md-12">
            <FormGroup
              label="Company Name"
              labelFor="companyName"
              errors={errors.companyName}
              required
            >
              <Input
                type="text"
                id="companyName"
                name="companyName"
                placeholder="Your company name"
                ref={register({
                  required: true,
                  maxLength: maxLength(255, 'Company Name'),
                })}
              />
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <FormGroup
              label="First name"
              labelFor="firstName"
              errors={errors.firstName}
              required
            >
              <Input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="Your first name"
                ref={register({
                  required: true,
                  maxLength: maxLength(255, 'First name'),
                })}
              />
            </FormGroup>
          </div>
          <div className="col-md-6">
            <FormGroup
              label="Last name"
              labelFor="lastName"
              errors={errors.lastName}
              required
            >
              <Input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Your last name"
                ref={register({
                  required: true,
                  maxLength: maxLength(255, 'Last name'),
                })}
              />
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <FormGroup
              label="Email"
              labelFor="email"
              errors={errors.email}
              required
            >
              <Input
                type="text"
                id="email"
                name="email"
                placeholder="example@domain.com"
                autoComplete="username"
                ref={register({
                  required: true,
                  maxLength: maxLength(255, 'Email'),
                  validate: email,
                })}
              />
            </FormGroup>
            <FormGroup
              label="Password"
              labelFor="password"
              errors={errors.password}
              required
            >
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                autoComplete="new-password"
                ref={register({ ...password, required: true })}
              />
            </FormGroup>
            <FormGroup
              label="Confirm password"
              labelFor="passwordConfirmation"
              errors={errors.passwordConfirmation}
              required
            >
              <Input
                type="password"
                id="passwordConfirmation"
                name="passwordConfirmation"
                placeholder="Confirm password"
                autoComplete="new-password"
                ref={register({
                  required: true,
                  validate: (value) =>
                    match(value, passwordField, 'The passwords do not match'),
                })}
              />
            </FormGroup>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <Button loading={loading} type="submit">
            Register
          </Button>
        </div>
      </form>
    </AuthPageLayout>
  );
};
