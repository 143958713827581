import React from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useApi } from '../../../hooks/use-api';
import CheckoutForm from './CheckoutForm';
import { PaymentMethod } from '../../../constants';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

export default function CardPayment({ company }: { company: any }) {
  const api = useApi();
  const location = useLocation();
  const [clientSecret, setClientSecret] = React.useState('');
  const [paymentIntentId, setPaymentIntentId] = React.useState('');
  const paymentIntentIdRef = React.useRef('');
  const { ids } = queryString.parse(location.search);
  const [error, setError] = React.useState<string | null>(null);

  const options = React.useMemo(
    () => ({
      clientSecret,
      // Fully customizable with appearance API.
      // @see https://stripe.com/docs/payments/accept-a-payment?platform=web&ui=elements#customize-appearance
      appearance: {
        /*...*/
      },
    }),
    [clientSecret],
  );

  const createPaymentIntent = React.useCallback(() => {
    if (!ids) return;

    const iterableIds = Array.isArray(ids) ? ids : ids.split(',');

    api
      .post(
        `/api/payment/stripe/create-payment-intent`,
        {
          invoice_id: iterableIds,
          billable_id: company,
          billable_type: 'company',
          payment_method_type: PaymentMethod.STRIPE_CARD,
        },
        'json',
      )

      .then(({ data }) => {
        setPaymentIntentId(data.paymentIntentId);
        setClientSecret(data.clientSecret);
      })
      .catch(({ errors }) => {
        setError(errors?.message || errors?.[0] || 'Something went wrong');
      });
  }, [api, company, ids]);

  const revokePaymentIntent = React.useCallback(() => {
    if (!paymentIntentIdRef.current) return;

    const revokeUrl = `/api/payment/stripe/revoke-payment-intent?intent_id=${paymentIntentIdRef.current}`;

    api.get(revokeUrl);
  }, [api]);

  React.useEffect(() => {
    createPaymentIntent();

    // Important to revoke the `PaymentIntent` on component unmount to avoid having hanging paymentIntents
    return revokePaymentIntent;
  }, [createPaymentIntent, revokePaymentIntent]);

  React.useEffect(() => {
    paymentIntentIdRef.current = paymentIntentId;
  }, [paymentIntentId]);

  return (
    <>
      {/* Replace this with dismissable alerts */}
      {error && (
        <p className="error-wrapper" onClick={() => setError(null)}>
          {error}
        </p>
      )}

      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm company={company} />
        </Elements>
      )}
    </>
  );
}
