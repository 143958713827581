import { RouteProps } from 'react-router';
import './index.scss';

export const NOT_FOUND_PATH = 'not-found';

export const NOT_FOUND_ROUTE: RouteProps = {
  path: '*',
  render: () => <NotFound />,
};

export const NotFound = () => {
  return (
    <div className="pg-NotFound">
      <div className="pg-NotFound__Content">
        <h1>404</h1>
        <div>Page not found</div>
      </div>
    </div>
  );
};
