import React from 'react';
import { BiPlus } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Role } from '../../../constants';
import { CREATE_PRESET_PATH } from '../create';
import { RouteConfigItem } from '../../../routes';
import { Preset } from '../../../components/preset';
import { useApi, useEndpoint, useModal } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import { Preset as IPreset } from '../../../interfaces/presets';
import { ErrorList, PageTitle, Loader } from '../../../components';
import './index.scss';

export const PRESETS_PATH = '/presets';

export const PRESETS_ROUTE: RouteConfigItem = {
  exact: true,
  protect: true,
  path: PRESETS_PATH,
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  render: () => <Presets />,
};

export const Presets = () => {
  const api = useApi();
  const toaster = useToaster();
  const modalStore = useModal();
  const endpoint = useEndpoint();
  const [presets, setPresets] = React.useState([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const handleErrors = (e: any) => {
    const errors: string[] = Object.values(e?.errors || []);

    toaster.danger({
      message: <ErrorList errors={errors} />,
    });
  };

  const fetchPresets = React.useCallback(() => {
    setLoading(true);

    api
      .get(endpoint('presets.all'))
      .then(({ data }) => {
        setPresets(data?.presets || []);
      })
      .catch(handleErrors)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, endpoint]);

  const deletePreset = (presetId: number) => {
    modalStore
      .confirmModal({
        theme: 'danger',
        confirmBtn: 'Remove',
        message: `Are you sure you want to remove this preset?`,
      })
      .result.then(() => {
        api
          .delete(endpoint('presets.delete', { id: presetId }))
          .then(({ data }) => {
            const deletedPreset = data?.preset;

            if (deletedPreset?.id) {
              setPresets((prev) =>
                prev.filter((p: IPreset) => p.id !== deletedPreset.id),
              );
            }

            toaster.success({ message: 'Preset was removed.' });
          })
          .catch(handleErrors);
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    fetchPresets();
  }, [fetchPresets]);

  if (loading) {
    return <Loader fullscreen />;
  }

  return (
    <>
      <PageTitle>Presets</PageTitle>

      {presets?.map((preset: IPreset) => (
        <Preset key={preset.id} preset={preset} onDelete={deletePreset} />
      ))}

      <Link to={CREATE_PRESET_PATH} className="pg-Presets__New">
        <BiPlus />
        <span>Add Preset</span>
      </Link>
    </>
  );
};
