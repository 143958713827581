import React from 'react';
import classNames from 'classnames';
import './index.scss';

export interface BoxProps extends React.HTMLProps<HTMLDivElement> {
  bordered?: boolean;
  selected?: boolean;
  elevated?: boolean;
}

export const Box = ({
  bordered,
  selected,
  elevated,
  className,
  onClick,
  ...props
}: BoxProps) => (
  <div
    className={classNames(
      'pg-Box',
      {
        'pg-Box--bordered': bordered,
        'pg-Box--elevated': elevated,
        'pg-Box--selected': selected,
        'pg-Box--clickable': typeof onClick !== 'undefined',
      },
      className,
    )}
    onClick={onClick}
    {...props}
  />
);
