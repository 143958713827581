import { TaxFormItem } from './form-generator';
import { ContractorType } from '../../../constants';

export const forms = {
  w9: [
    {
      name: 'name',
      label:
        'Enter your name (as shown on your income tax return). If you work through a company, enter your individual name if it’s a single-person company or your entity’s name otherwise.',
      type: 'string',
      required: true,
    },
    {
      name: 'business_name',
      label:
        'If you have a business name, trade name, DBA name, or disregarded entity name, enter it here.',
      type: 'string',
    },
    {
      name: 'federal_tax_classification',
      label:
        'Check the appropriate box for federal tax classification of the person/entity whose name is entered above.',
      type: 'radio_group',
      required: true,
      children: [
        {
          name: 'individual_proprietor',
          label: 'Individual/sole proprietor or single-member LLC',
        },
        {
          name: 'c_corporation',
          label: 'C Corporation',
        },
        {
          name: 's_corporation',
          label: 'S Corporation',
        },
        {
          name: 'partnership',
          label: 'Partnership',
        },
        {
          name: 'trust_estate',
          label: 'Trust/estate',
        },
        {
          name: 'limited_liability_company',
          label: 'Limited liability company (LLC)',
        },
        {
          name: 'other',
          label: 'Other (see instructions) ',
        },
      ],
    },
    {
      name: 'tax_classification',
      label:
        'Enter the tax classification (C=C corporation, S=S corporation, P=Partnership). For single-member LLC, please check the individual box above instead.',
      type: 'string',
      required: true,
      if: 'form.limited_liability_company',
    },
    {
      name: 'other_description',
      label: 'Enter the federal tax classification.',
      type: 'string',
      required: true,
      if: 'form.other',
    },
    {
      name: 'address',
      label:
        'Enter your address. This is where your information returns will be mailed.',
      type: 'string',
      required: true,
    },
    {
      name: 'city_state_zip',
      label: 'Enter your city, state and ZIP code.',
      type: 'string',
      required: true,
    },
    {
      name: 'ssn',
      label: 'Social security number.',
      type: 'ssn',
      if: `entity.${ContractorType.INDIVIDUAL}`,
      keys: [
        'social_security_number1',
        'social_security_number2',
        'social_security_number3',
      ],
    },
    {
      name: 'ein',
      label: 'Employer identification number.',
      type: 'ein',
      if: `entity.${ContractorType.ENTITY}`,
      keys: [
        'employer_identification_number1',
        'employer_identification_number2',
      ],
    },
    {
      name: 'signature_block',
      label: '',
      type: 'group',
      children: [
        {
          name: 'certify',
          label:
            'I certify that the details entered above are correct and complete.',
          type: 'checkbox',
          required: true,
        },
      ],
    },
  ] as TaxFormItem[],
  w8ben: [
    {
      name: 'personal_details',
      label: 'Beneficial Owner',
      type: 'group',
      children: [
        {
          name: 'name',
          label: 'Enter your full legal name.',
          type: 'string',
          required: true,
        },
        {
          name: 'citizenship_country_id',
          label: 'Enter your country of citizenship.',
          required: true,
          type: 'country',
        },
        {
          name: 'date_of_birth',
          label: 'Date of birth.',
          type: 'date',
          required: true,
        },
      ],
    },
    {
      name: 'permanent_residence_address',
      label: 'Permanent Residence Address.',
      type: 'group',
      children: [
        {
          name: 'address',
          label:
            'Enter your permanent residence address (include street, number, and apt. no if applicable).',
          type: 'string',
          required: true,
        },
        {
          name: 'city_state_province',
          label: 'Enter your city, state, and postal code.',
          type: 'string',
          required: true,
        },
        {
          name: 'country_id',
          label: 'Select your country.',
          required: true,
          type: 'country',
        },
      ],
    },
    {
      name: 'mailing_address',
      label: 'Mailing Address',
      type: 'group',
      children: [
        {
          name: 'mailing_address_same_as_address',
          label: 'Mailing address is same as permanent residence address.',
          type: 'checkbox',
        },
        {
          name: 'mailing_address',
          label: 'Enter your address.',
          type: 'string',
          ifNot: 'form.mailing_address_same_as_address',
        },
        {
          name: 'mailing_city_state_province',
          label: 'Enter your city, state, and postal code.',
          type: 'string',
          ifNot: 'form.mailing_address_same_as_address',
        },
        {
          name: 'mailing_country_id',
          label: 'Select your country.',
          type: 'country',
          ifNot: 'form.mailing_address_same_as_address',
        },
      ],
    },

    {
      name: 'tax_payer_details',
      label: 'Tax Payer Details',
      type: 'group',
      children: [
        {
          name: 'has_us_taxpayer_identification_number',
          label: 'I have a US taxpayer identification number (SSN or ITIN).',
          type: 'checkbox',
        },
        {
          name: 'us_taxpayer_identification_number',
          label: 'SSN or ITIN.',
          if: 'form.has_us_taxpayer_identification_number',
          type: 'string',
        },
        {
          name: 'foreign_tax_identification_number_not_required',
          label: 'Foreign tax identifying number is not required.',
          type: 'checkbox',
        },
        {
          name: 'foreign_tax_identification_number',
          label: 'Foreign tax identifying number.',
          ifNot: 'form.foreign_tax_identification_number_not_required',
          type: 'string',
        },
      ],
    },
    {
      name: 'signature_block',
      label: 'Contractor Signature',
      type: 'group',
      children: [
        {
          name: 'certify',
          label:
            'I certify that I have the capacity to sign for the person identified on line 1 of this form.',
          type: 'checkbox',
          required: true,
        },
      ],
    },
  ] as TaxFormItem[],
  w8bene: [
    {
      name: 'business_name',
      label: 'Enter the name of your company.',
      type: 'string',
      required: true,
    },
    {
      name: 'name_of_entity_receiving_payment',
      label:
        'Enter the name of disregarded entity receiving the payment (if applicable).',
      type: 'string',
    },
    {
      name: 'business_country_id',
      label: 'Select the country where the company is registered.',
      required: true,
      type: 'country',
    },
    {
      name: 'business_type',
      label: 'Select the type of your company.',
      required: true,
      type: 'select',
      children: [
        { label: 'Corporation', name: 'corporation' },
        { label: 'Partnership', name: 'partnership' },
        { label: 'Simple Trust ', name: 'simple_trust' },
        { label: 'Tax-exempt Organization', name: 'tax_exempt_organization' },
        { label: 'Complex Trust', name: 'complex_trust' },
        {
          label: 'Foreign Government - Controlled Entity',
          name: 'foreign_government_controlled_entity',
        },
        { label: 'Central Bank of Issue', name: 'central_bank_of_issue' },
        { label: 'Private Foundation', name: 'private_foundation' },
        { label: 'Estate', name: 'estate' },
        {
          label: 'Foreign Government - Integral Part',
          name: 'foreign_government_integral_part',
        },
        { label: 'Grantor Trust', name: 'grantor_trust' },
        { label: 'Disregarded Entity', name: 'disregarded_entity' },
        {
          label: 'International Organization',
          name: 'international_organization_entity_type',
        },
      ],
    },
    {
      name: 'permanent_residence_address',
      label: 'Permanent company address (do not use a PO-box)',
      type: 'group',
      children: [
        {
          name: 'address',
          label: 'Enter your company address.',
          type: 'string',
          required: true,
        },
        {
          name: 'city_state_province',
          label: 'Enter the company city, state, and postal code.',
          type: 'string',
          required: true,
        },
        {
          name: 'country_id',
          label: 'Select the company country.',
          required: true,
          type: 'country',
        },
      ],
    },
    {
      name: 'company_mailing_address',
      label: 'Company Mailing Address',
      type: 'group',
      children: [
        {
          name: 'mailing_address_same_as_address',
          label: 'Mailing address is same as permanent residence address.',
          type: 'checkbox',
        },
        {
          name: 'mailing_address',
          label: 'Enter the company mailing address.',
          type: 'string',
          ifNot: 'form.mailing_address_same_as_address',
        },
        {
          name: 'mailing_city_state_province',
          label: 'Enter the company city, state, and postal code.',
          type: 'string',
          ifNot: 'form.mailing_address_same_as_address',
        },
        {
          name: 'mailing_country_id',
          label: 'Select the company country.',
          type: 'country',
          ifNot: 'form.mailing_address_same_as_address',
        },
      ],
    },
    {
      name: 'has_us_taxpayer_identification_number',
      label: 'I have a US taxpayer identification number (TIN).',
      type: 'checkbox',
    },
    {
      name: 'us_taxpayer_identification_number',
      label: 'Enter the U.S. taxpayer identification number (TIN).',
      if: 'form.has_us_taxpayer_identification_number',
      type: 'string',
    },
    {
      name: 'foreign_tax_identification_number',
      label:
        'Enter the foreign TIN (Company’s tax number in the respective country).',
      type: 'string',
    },
    {
      name: 'part25_active_nffe',
      label: 'I certify that:',
      type: 'checkbox',
      required: true,
    },
    {
      name: 'certify_1',
      type: 'bullet',
      label:
        'The entity identified above is a foreign entity that is not a financial institution;',
    },
    {
      name: 'certify_2',
      type: 'bullet',
      label:
        'Less than 50% of such entity’s gross income for the preceding calendar year is passive income; and',
    },
    {
      name: 'certify_3',
      type: 'bullet',
      label:
        'Less than 50% of the assets held by such entity are assets that produce or are held for the production of passive income.',
    },
    {
      name: 'certify',
      label:
        'I certify that the details entered above are correct and complete and that I have the capacity to sign for the entity identified on line 1 of this form.',
      type: 'checkbox',
      required: true,
    },
    {
      name: 'name',
      label: 'Enter your full name.',
      type: 'string',
      required: true,
    },
  ] as TaxFormItem[],
};
