import DataLoader from 'dataloader';
import { singleton } from 'tsyringe';
import {
  ContractType,
  Currency,
  Role,
  SignatureType,
  ComplianceTypeModel,
} from '../../hooks/use-api';
import { Country, InvoiceItemType } from '../models';
import { ApiService } from './api';
import { AuthService } from './auth';

export interface CommonData {
  company_types: { id: number; name: string }[];
  invoice_items_types: InvoiceItemType[];
  roles: Role[];
  contract_types: ContractType[];
  signature_types: SignatureType[];
  compliance_types: ComplianceTypeModel[];
  contractor_invoice_items_types: InvoiceItemType[];
  global_roles: {
    created_at: string;
    description?: string;
    id: number;
    name: string;
    updated_at?: string;
  }[];
  invoice_statuses: { id: number; name: string }[];
}

export interface PublicCommonData {
  countries: Country[];
  timezones: { [timezone: string]: string };
  currencies: Currency[];
  global_roles: {
    created_at: string;
    description?: string;
    id: number;
    name: string;
    updated_at?: string;
  }[];
}

@singleton()
export class CommonDataService {
  private commonDataLoader = new DataLoader(() =>
    this.loadData().then(([publicData, commonData]) => [
      { ...publicData, ...commonData },
    ]),
  );

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
  ) {}

  private loadData() {
    let promises: Promise<CommonData | PublicCommonData>[] = [
      this.apiService
        .get<{ data: PublicCommonData }>('/api/public-common-data')
        .then(({ data }) => data),
    ];

    if (this.authService.isAuthenticated()) {
      promises = [
        ...promises,
        this.apiService
          .get<{ data: CommonData }>('/api/common-data')
          .then(({ data }) => data),
      ];
    }

    return Promise.all(promises).then(([commonData, publicData]) => {
      return [{ ...commonData, ...publicData }];
    });
  }

  get(): Promise<Partial<CommonData> & PublicCommonData> {
    return this.commonDataLoader.load(
      'FIXED_ID' + this.authService.isAuthenticated(),
    ) as Promise<CommonData & PublicCommonData>;
  }
}
