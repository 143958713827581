import { camelCase, snakeCase, upperCase } from 'lodash';
import { singleton } from 'tsyringe';
import { AuthService, container } from './index';
import { EnvService } from './env';
import { TokenService } from './token';
import Storage from '../db/storage';

export enum Method {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

const methodsWithFormDataBug: string[] = [
  Method.PUT,
  Method.PATCH,
  Method.DELETE,
];

@singleton()
export class ApiService {
  constructor(
    private tokenService: TokenService,
    private envService: EnvService,
  ) {}

  fetch<T = any>(endpoint: string, config: RequestInit = {}): Promise<T> {
    let { headers = {}, method, body, ...rest } = config;

    // Fix FormData bug with PUT/PATCH/DELETE
    // @see https://github.com/laravel/framework/issues/13457#issuecomment-239451567
    if (
      method &&
      body &&
      methodsWithFormDataBug.includes(upperCase(method)) &&
      body instanceof FormData
    ) {
      // Apply Request Method Spoofing to workaround the issue
      body.append('_method', method);

      method = Method.POST;
    }

    return fetch(`${this.envService.apiPath}${endpoint}`, {
      headers: {
        ...headers,
        ...this.sharedHeaders(),
      },
      method,
      body,
      ...rest,
    })
      .then((response) => {
        // All good, proceed
        if (response.ok) return response;

        // Error occured on login, ensure user is logged-out
        if (response.status >= 400 && endpoint.includes('/api/login')) {
          this.onFailure();
        }

        // Possible that the access token is expired, try to refresh it
        if (response.status === 401 && !endpoint.includes('/api/login')) {
          const auth = container.resolve(AuthService);

          return auth
            .refreshToken()
            .then(() => this.fetch(endpoint, config))
            .catch(this.onFailure);
        }

        try {
          return response.json().then((data) => {
            throw data;
          });
        } catch {
          throw Error(response.statusText);
        }
      })
      .then((response) => {
        try {
          return response.json() as Promise<T>;
        } catch (_e) {
          return response;
        }
      });
  }

  get<T = any>(endpoint: string, queryParams?: any) {
    if (queryParams) {
      endpoint += '?' + new URLSearchParams(queryParams).toString();
    }
    return this.fetch<T>(endpoint);
  }

  update<T = any>(
    endpoint: string,
    body: any,
    method: 'POST' | 'PATCH' | 'PUT',
    type: 'formdata' | 'json' | 'urlencoded' = 'formdata',
  ): Promise<T> {
    let data: FormData | string = type === 'json' ? JSON.stringify(body) : body;
    if (type === 'formdata') {
      data = new FormData();

      Object.keys(body).forEach((key) => {
        const value = body[key];
        (data as FormData).append(key, value);
      });
    }

    return this.fetch<T>(endpoint, {
      method,
      body: data,
      headers: {
        ...(type === 'urlencoded'
          ? {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            }
          : {}),
        ...(type === 'json'
          ? {
              'Content-Type': 'application/json;',
            }
          : {}),
      },
    });
  }

  post<T = any>(endpoint: string, body: any, type?: 'formdata' | 'json') {
    return this.update<T>(endpoint, body, Method.POST, type);
  }

  put<T = any>(endpoint: string, body: any, type?: 'formdata' | 'json') {
    return this.update<T>(endpoint, body, Method.PUT, type);
  }

  patch<T = any>(
    endpoint: string,
    body: any,
    type: 'formdata' | 'json' | 'urlencoded' = 'urlencoded',
  ) {
    var formBody = Object.keys(body)
      .map((key) => {
        let value = body[key];
        if (value instanceof Date) {
          value = value.toISOString();
        }
        return encodeURIComponent(key) + '=' + encodeURIComponent(value);
      })
      .join('&');

    return this.update<T>(endpoint, formBody, Method.PATCH, type);
  }

  delete<T = any>(endpoint: string, queryParams?: any) {
    return this.fetch<T>(endpoint, {
      method: Method.DELETE,
      body: new URLSearchParams(queryParams),
    });
  }

  createPayload(obj: any) {
    return Object.keys(obj).reduce(
      (all, current) => ({
        ...all,
        ...(obj[current] ? { [snakeCase(current)]: obj[current] } : {}),
      }),
      {},
    );
  }

  parseResponse<T extends {}, E = {}>(obj: E): T {
    return Object.keys(obj).reduce(
      (all, current) => ({
        ...all,
        ...((obj as any)[current]
          ? { [camelCase(current)]: (obj as any)[current] }
          : {}),
      }),
      {},
    ) as T;
  }

  sharedHeaders() {
    const token = this.tokenService.token;

    const selectedCompany = Storage.getItem('selectedCompany') || null;

    let headers = {};

    if (selectedCompany) {
      headers = {
        ...headers,
        'X-SELECTED-COMPANY': selectedCompany,
      };
    }

    if (token) {
      headers = {
        ...headers,
        Authorization: `Bearer ${this.tokenService.token}`,
      };
    }

    return headers;
  }

  onFailure(e: any = null) {
    const auth = container.resolve(AuthService);

    Storage.clear();

    auth.state.set({
      isAuthenticated: false,
      complete: true,
    });

    if (e) throw e;
  }
}
