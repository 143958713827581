import React from 'react';
import { RiEditBoxFill } from 'react-icons/ri';
import './index.scss';

export const UploadedDocument = ({
  children,
  actions,
}: {
  children?: React.ReactNode;
  actions?: React.ReactNode;
}) => {
  return (
    <div className="pg-UploadedDocument">
      <div className="pg-UploadedDocument__Content">
        <div className="pg-UploadedDocument__Icon">
          <RiEditBoxFill />
        </div>
        <div className="pg-UploadedDocument__Title">{children}</div>
      </div>
      <div className="pg-UploadedDocument__Actions">{actions}</div>
    </div>
  );
};
