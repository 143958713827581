import React from 'react';
import { useForm } from 'react-hook-form';
import { useApi } from '../../hooks/use-api';
import { useToaster } from '../../hooks/use-toaster';
import { match, password } from '../../shared';
import { Button } from '../button';
import { FormGroup } from '../form-group';
import { Input } from '../input';
import { Modal } from '../modal';
import './index.scss';

export const ChangePassword = ({ close }: { close: () => any }) => {
  const api = useApi();
  const toaster = useToaster();
  const { register, errors, handleSubmit, watch } = useForm();
  const [loading, setLoading] = React.useState(false);

  const newPassword = watch('password');

  const submit = (data: {
    oldPassword: string;
    password: string;
    passwordConfirmation: string;
  }) => {
    setLoading(true);
    api
      .patch('/api/change-password', {
        old_password: data.oldPassword,
        password: data.password,
        password_confirmation: data.passwordConfirmation,
      })
      .then(() => {
        toaster.success({ message: 'The password was changed successfully.' });
        close();
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Modal
        onClose={close}
        title="Change password"
        footer={
          <>
            <Button onClick={close} theme="secondary" outline>
              Cancel
            </Button>
            <Button type="submit" loading={loading}>
              Save
            </Button>
          </>
        }
      >
        <FormGroup label="Password" errors={errors.oldPassword} required>
          <Input
            name="oldPassword"
            type="password"
            placeholder="Your current password"
            autoComplete="current-password"
            ref={register({
              required: true,
            })}
          />
        </FormGroup>
        <FormGroup label="New password" errors={errors.password} required>
          <Input
            name="password"
            type="password"
            autoComplete="new-password"
            ref={register({
              ...password,
              required: true,
            })}
          />
        </FormGroup>
        <FormGroup
          label="Confirm new password"
          errors={errors.passwordConfirmation}
          required
        >
          <Input
            name="passwordConfirmation"
            type="password"
            autoComplete="new-password"
            ref={register({
              required: true,
              validate: (value) =>
                match(value, newPassword, 'The passwords do not match'),
            })}
          />
        </FormGroup>
      </Modal>
    </form>
  );
};
