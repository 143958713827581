import React from 'react';
import { startCase } from 'lodash';
import { Tab, Tabs } from '@blueprintjs/core';
import { useParams } from 'react-router';
// import { EDIT_TEMPLATE_PATH } from '../edit';
import { RouteConfigItem } from '../../../routes';
import { useApi, useEndpoint } from '../../../hooks';
import { ContractTemplate } from '../../../interfaces';
import { useToaster } from '../../../hooks/use-toaster';
import { ContractorType, Role } from '../../../constants';
import { ErrorList, PageTitle } from '../../../components';
import './index.scss';

const ContentPreview = ({ content = '' }: { content?: string | null }) => {
  return (
    <div className="col-12 mx-auto p-5 shadow">
      <div
        dangerouslySetInnerHTML={{
          __html: content || '',
        }}
      />
    </div>
  );
};

export const SHOW_TEMPLATE_PATH = '/templates/preview/:id';

export const SHOW_TEMPLATE_ROUTE: RouteConfigItem = {
  exact: true,
  protect: true,
  path: SHOW_TEMPLATE_PATH,
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  render: () => <ShowTemplate />,
};

export const ShowTemplate = () => {
  const { id } = useParams<{ id?: string }>();

  const api = useApi();
  const toaster = useToaster();
  // const history = useHistory();
  const endpoint = useEndpoint();
  const [template, setTemplate] = React.useState<ContractTemplate | null>(null);
  const [selectedTab, setSelectedTab] = React.useState<string>(
    ContractorType.INDIVIDUAL as string,
  );

  // const editTemplate = () => {
  //   if (id) {
  //     const path = EDIT_TEMPLATE_PATH.replace(':id', id);

  //     history.push(path);
  //   }
  // };

  const fetchTemplate = React.useCallback(() => {
    api
      .get(endpoint('templates.get', { id }))
      .then(({ data: { template } }) => setTemplate(template))
      .catch((e) => {
        const errors: string[] = Object.values(e?.errors || []);

        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, endpoint, id]);

  React.useEffect(() => {
    fetchTemplate();
  }, [fetchTemplate]);

  return (
    <>
      <PageTitle>Preview Template</PageTitle>

      <div className="row">
        {/* <div className="col-12 col-xl-9 mx-auto mb-3 px-0 text-end">
          <Button
            className="px-4"
            theme="warning"
            onClick={editTemplate}
            disabled={!id}
          >
            Edit
          </Button>
        </div> */}

        <div className="col-12 col-xl-9 mx-auto">
          {template?.same_content ? (
            <ContentPreview content={template?.content_individual} />
          ) : (
            <Tabs
              id="ContentTabs"
              onChange={(to) => setSelectedTab(to as string)}
              selectedTabId={selectedTab}
            >
              <Tab
                id={ContractorType.INDIVIDUAL}
                title={`Content (${startCase(ContractorType.INDIVIDUAL)})`}
                panel={
                  <ContentPreview content={template?.content_individual} />
                }
              />

              <Tab
                id={ContractorType.ENTITY}
                title={`Content (${startCase(ContractorType.ENTITY)})`}
                panel={<ContentPreview content={template?.content_entity} />}
              />
            </Tabs>
          )}
        </div>
      </div>
    </>
  );
};
