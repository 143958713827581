import React from 'react';
import { useApi } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';
import { CompanySettings as ComapnySettingsInterface } from '../../shared/services/company';
import {
  Button,
  ErrorList,
  FormGroup,
  FormSection,
  InfoTooltip,
  Input,
  SignaturePad,
  RadioButton,
} from '../';
import './index.scss';
import { TokenService } from '../../shared/services';
import { useService } from '../../shared/services/use-service';

export const CompanySettings = ({ companyId }: { companyId?: string }) => {
  const api = useApi();
  const toaster = useToaster();
  const { token } = useService(TokenService);
  const [editMode, setEditMode] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [autoInvite, setAutoInvite] = React.useState(false);
  const [autoSign, setAutoSign] = React.useState(false);
  const [sendInviteEmail, setSendInviteEmail] = React.useState(true);
  const [signRedirectUrl, setSignRedirectUrl] = React.useState('');
  const [signatureFile, setSignatureFile] = React.useState<
    string | File | null | undefined
  >();
  const [comapnySettings, setComapnySettings] =
    React.useState<ComapnySettingsInterface | null>(null);

  const toggleAutoSign = (e: any) => setAutoSign((prev) => !prev);

  const toggleAutoInvite = (e: any) => setAutoInvite((prev) => !prev);

  const toggleSendInviteEmail = (e: any) => setSendInviteEmail((prev) => !prev);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    setLoading(true);

    const data = {
      autoInvite,
      autoSign,
      sendInviteEmail,
      signRedirectUrl: signRedirectUrl || '',
      signatureFile: signatureFile || '',
    };

    api
      .put('/api/client/settings', data, 'formdata')
      .then(({ data: { message } }) => {
        toaster.success({ message });

        fetchCompanySettings();
      })
      .catch((e) => {
        const errors: string[] = Object.values(e?.errors || []);

        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCompanySettings = React.useCallback(() => {
    api.get('/api/client/settings').then(({ data }) => {
      setComapnySettings(data?.settings);
      setAutoSign(data?.settings?.auto_sign);
      setAutoInvite(data?.settings?.auto_invite);
      setSendInviteEmail(data?.settings?.send_invite_email);
      setSignRedirectUrl(data?.settings?.sign_redirect_url);

      setEditMode(false);
    });
  }, [api]);

  React.useEffect(() => {
    fetchCompanySettings();
  }, [companyId, fetchCompanySettings]);

  React.useEffect(() => {
    if (!autoSign) {
      setAutoInvite(false);
    } else {
      setAutoInvite(comapnySettings?.auto_invite || false);
    }
  }, [autoSign, comapnySettings?.auto_invite]);

  return (
    <form onSubmit={handleSubmit}>
      <FormSection
        title="Contract settings"
        actions={
          editMode ? (
            <>
              <Button type="submit" loading={loading}>
                Save
              </Button>
              <Button
                outline
                theme="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  setEditMode(false);
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button
              onClick={(e) => {
                e.preventDefault();
                setEditMode(true);
              }}
            >
              Edit
            </Button>
          )
        }
      >
        {/* Auto Sign */}
        <FormGroup
          label={
            <InfoTooltip
              content="Auto Sign"
              tooltip="Automatically sign contracts"
            />
          }
          inline
        >
          {editMode ? (
            <div className="pg-RadioInputs">
              <RadioButton
                name="auto_sign"
                checked={autoSign}
                onChange={toggleAutoSign}
              >
                Yes
              </RadioButton>
              <RadioButton
                name="auto_sign"
                checked={!autoSign}
                onChange={toggleAutoSign}
              >
                No
              </RadioButton>
            </div>
          ) : (
            <div className="d-flex">
              <div className="pg-Input form-control-plaintext">
                {comapnySettings?.auto_sign ? 'Yes' : 'No'}
              </div>
            </div>
          )}
        </FormGroup>

        {/* Signature */}
        {autoSign && (
          <FormGroup
            label={
              <InfoTooltip
                content="Signature"
                tooltip="When auto sign is enabled, this signature will be used."
              />
            }
            labelFor="signature"
            inline
            disabled={!autoSign}
          >
            {editMode ? (
              <SignaturePad
                onChange={(data) =>
                  setSignatureFile(data?.get('signature_file'))
                }
              />
            ) : (
              <div className="pg-Input form-control-plaintext">
                <img
                  className="signature-preview"
                  src={`${comapnySettings?.signature_url || ''}?auth=${token}`}
                  alt=""
                />
              </div>
            )}
          </FormGroup>
        )}

        {/* Auto Invite */}
        <FormGroup
          label={
            <InfoTooltip
              content="Auto Invite"
              tooltip="Automatically invite contractors upon contract creation. Auto sign must be enabled to use this feature."
            />
          }
          labelFor="auto_invite"
          inline
          disabled={!autoSign}
        >
          {editMode ? (
            <div className="pg-RadioInputs">
              <RadioButton
                name="auto_invite"
                checked={autoInvite}
                onChange={toggleAutoInvite}
                disabled={!autoSign}
              >
                Yes
              </RadioButton>
              <RadioButton
                name="auto_invite"
                checked={!autoInvite}
                onChange={toggleAutoInvite}
                disabled={!autoSign}
              >
                No
              </RadioButton>
            </div>
          ) : (
            <div className="d-flex">
              <div className="pg-Input form-control-plaintext">
                {comapnySettings?.auto_invite ? 'Yes' : 'No'}
              </div>
            </div>
          )}
        </FormGroup>

        {/* Redirect URL */}
        <FormGroup
          label={
            <InfoTooltip
              content="Sign Redirect URL"
              tooltip="Set the URL that should be sent to the contractor to sign the contract. If left blank, it will be redirected to Brysk."
            />
          }
          labelFor="sign_redirect_url"
          inline
        >
          {editMode ? (
            <Input
              type="text"
              id="sign_redirect_url"
              name="sign_redirect_url"
              value={signRedirectUrl}
              onChange={({ target }) => setSignRedirectUrl(target?.value || '')}
            />
          ) : (
            <p className="pg-Input form-control-plaintext">
              {comapnySettings?.sign_redirect_url ? (
                <a
                  href={comapnySettings?.sign_redirect_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {comapnySettings?.sign_redirect_url}
                </a>
              ) : (
                'N/A'
              )}
            </p>
          )}
        </FormGroup>

        {/* Contract Invite Email */}
        <FormGroup
          label={
            <InfoTooltip
              content="Invite Email"
              tooltip="Determine if Brysk should send invitation email to contractors."
            />
          }
          labelFor="send_invite_email"
          inline
        >
          {editMode ? (
            <div className="pg-RadioInputs">
              <RadioButton
                name="send_invite_email"
                checked={sendInviteEmail}
                onChange={toggleSendInviteEmail}
              >
                Yes
              </RadioButton>
              <RadioButton
                name="send_invite_email"
                checked={!sendInviteEmail}
                onChange={toggleSendInviteEmail}
              >
                No
              </RadioButton>
            </div>
          ) : (
            <div className="d-flex">
              <div className="pg-Input form-control-plaintext">
                {comapnySettings?.send_invite_email ? 'Yes' : 'No'}
              </div>
            </div>
          )}
        </FormGroup>
      </FormSection>
    </form>
  );
};
