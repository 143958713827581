import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { isNil } from 'lodash';
import { loadStripe } from '@stripe/stripe-js';
import { useFormContext } from 'react-hook-form';
import {
  BsFillExclamationCircleFill,
  BsFillCheckCircleFill,
} from 'react-icons/bs';

import { ListItem } from './list-item';
import VerifyButton, {
  VerificationTarget,
} from '../../../components/stripe/verify';
import { TaxInfo } from '../../compliance-documents/tax-info';
import { ErrorList, Modal, Button } from '../../../components';
import { useApi, useCommonData, useModal } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import { TokenService, useProfileService } from '../../../shared/services';
import { Contract } from '../../contracts';
import { TaxForm } from '../../../constants';
import { useService } from '../../../shared/services/use-service';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
const verificationSubmitUrl = `/api/verify/start`;

interface TaxFormStatus {
  missing: boolean;
  url: string | null;
}

const AdditionalRequirements = ({
  contract,
  setHasMissingRequirements,
}: {
  contract: Contract;
  setHasMissingRequirements: Dispatch<SetStateAction<boolean>>;
}) => {
  const api = useApi();
  const modal = useModal();
  const { token } = useService(TokenService);
  const toaster = useToaster();
  const common = useCommonData();
  const { watch } = useFormContext();
  const entityType = watch('entityType');
  const { profile } = useProfileService();
  const [taxFormValue, setTaxFormValue] = useState<any>({});
  const [filledTaxForm, setFilledTaxForm] = useState<TaxFormStatus | null>(
    null,
  );
  const [missingTaxForm, setMissingTaxForm] = useState<TaxForm | undefined>();
  const [taxFormToRemove, setTaxFormToRemove] = useState<TaxForm | undefined>();

  const checkTaxForms = useCallback(() => {
    return api
      .get(
        `/api/contractor/contract/${contract?.id}/check-tax-forms?contractor_type=${entityType}`,
      )
      .then(({ data }) => {
        const forms = data['taxForms'];

        const filledTaxForm = Object.keys(forms).find(
          (key) => forms[key]?.url,
        ) as TaxForm | undefined;

        const form = Object.keys(forms).find((key) => forms[key]?.missing) as
          | TaxForm
          | undefined;

        setMissingTaxForm(form);

        if (filledTaxForm) {
          setTaxFormToRemove(filledTaxForm);
          setFilledTaxForm(forms?.[filledTaxForm]);
        } else {
          setTaxFormToRemove(undefined);
          setFilledTaxForm(null);
        }

        return form;
      });
  }, [api, contract?.id, entityType]);

  const submitTaxForm = useCallback(
    (form: any, value: any) => {
      if (value.signature_file) {
        const signature = new File([value.signature_file], 'signature.png');
        const formData = new FormData();
        formData.append('signature_file', signature);
        Object.keys(value)
          .filter((key) => key !== 'signature_file')
          .forEach((key) => {
            if (!isNil(value[key]) && value[key] !== '') {
              if (key === 'date_of_birth') {
                formData.append(
                  key,
                  (value[key] as Date).toISOString().split('T')[0],
                );
              } else if (key === 'tax_classification') {
                formData.append(
                  key,
                  String(value['tax_classification']).toUpperCase(),
                );
              } else if (key === 'business_type') {
                formData.delete(key);

                formData.append(key, value['business_type']?.value);
              } else if (key === 'ssn') {
                [
                  'social_security_number1',
                  'social_security_number2',
                  'social_security_number3',
                ].forEach((key, index) => {
                  formData.append(key, value['ssn'][index]);
                });
              } else if (key === 'ein') {
                [
                  'employer_identification_number1',
                  'employer_identification_number2',
                ].forEach((key, index) => {
                  formData.append(key, value['ein'][index]);
                });
              } else {
                formData.append(key, value[key]);
              }
            }
          });
        api
          .fetch(`/api/contractor/generate-${form}`, {
            method: 'POST',
            body: formData,
          })
          .then(({ data }) => {
            toaster.success({ message: 'Tax form successfully submitted.' });

            checkTaxForms();
          })
          .catch(({ errors }) => {
            toaster.danger({
              message: <ErrorList errors={errors} />,
            });
          })
          .finally(() => modal.closeAllModals());
      }
    },
    [api, modal, toaster, checkTaxForms],
  );

  const openTaxFormModal = useCallback(
    (form: any) => {
      modal.openModal(() => (
        <Modal className="pg-ComplianceModal" size="lg">
          <TaxInfo
            // Reset signature to force user sign again after changes
            value={{
              ...taxFormValue,
              signature_file: null,
            }}
            renderForm={form}
            entityType={entityType}
            onSubmit={(value) => {
              setTaxFormValue(value);
              submitTaxForm(form, value);
            }}
            onClose={modal.closeAllModals}
          />
        </Modal>
      ));
    },
    [entityType, modal, submitTaxForm, taxFormValue],
  );

  const undoTaxForm = () => {
    const taxFormId =
      common?.compliance_types?.find((type) => type?.key === taxFormToRemove)
        ?.id || null;

    api
      .delete(`/api/contractor/tax-forms/${taxFormId}`)
      .then(() => {
        checkTaxForms().then((newMissingForm) => {
          openTaxFormModal(newMissingForm);
        });
      })
      .catch(({ errors }) => {
        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      });
  };

  useEffect(() => {
    checkTaxForms();
  }, [checkTaxForms]);

  useEffect(() => {
    const missingVerification =
      contract?.contractor_require_id_check && !profile?.identity_verified;

    const missingTax = !isNil(missingTaxForm);

    setHasMissingRequirements(missingTax || missingVerification);
  }, [
    setHasMissingRequirements,
    missingTaxForm,
    contract?.contractor_require_id_check,
    profile?.identity_verified,
  ]);

  if (
    !contract?.contractor_require_id_check &&
    !contract?.contractor_require_documents
  ) {
    return null;
  }

  return (
    <>
      <h5 className="mt-5">Additional Requirements</h5>
      <p>
        Please complete the requirements below to be able to proceed to
        signature.
      </p>

      {!!contract.contractor_require_id_check && (
        <ListItem
          labelSize="large"
          full
          label={
            <>
              <div>
                {profile?.identity_verified ? (
                  <BsFillCheckCircleFill style={{ color: '#17c671' }} />
                ) : (
                  <BsFillExclamationCircleFill style={{ color: '#f00' }} />
                )}
                Identity Verification
              </div>

              <div className="pg-ListItem__Help">
                Identity verification is required for you to be eligible to work
                under this contract.
              </div>
            </>
          }
        >
          {!profile?.identity_verified && (
            <VerifyButton
              submitUrl={verificationSubmitUrl}
              stripePromise={stripePromise}
              target={VerificationTarget.REDIRECT}
              theme="link"
            >
              Verify
            </VerifyButton>
          )}
        </ListItem>
      )}

      {!!contract.contractor_require_documents && (
        <ListItem
          labelSize="large"
          full
          label={
            <>
              <div>
                {isNil(missingTaxForm) ? (
                  <BsFillCheckCircleFill style={{ color: '#17c671' }} />
                ) : (
                  <BsFillExclamationCircleFill style={{ color: '#f00' }} />
                )}
                Tax Form
              </div>

              <div className="pg-ListItem__Help">
                The tax form is required for you to be eligible to work under
                this contract.
              </div>
            </>
          }
        >
          {!isNil(missingTaxForm) ? (
            <Button
              theme="link"
              onClick={() => openTaxFormModal(missingTaxForm)}
            >
              Complete
            </Button>
          ) : (
            filledTaxForm && (
              <div className="d-flex align-items-baseline">
                <a
                  href={`${filledTaxForm?.url || '#'}?auth=${token}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview
                </a>
                <Button theme="link" onClick={undoTaxForm}>
                  Remove & Redo
                </Button>
              </div>
            )
          )}
        </ListItem>
      )}
    </>
  );
};

export default AdditionalRequirements;
