import React, { MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { IoPencil, IoTrash } from 'react-icons/io5';
import { Tooltip2 } from '@blueprintjs/popover2';
import { SHOW_PRESET_PATH } from '../../pages';
import { Preset as IPreset } from '../../interfaces/presets';
import { EDIT_PRESET_PATH } from '../../pages/presets/edit';
import './index.scss';

export const Preset = ({
  preset,
  onDelete,
}: {
  preset: IPreset;
  onDelete: (presetId: number) => void;
}) => {
  const handleDelete = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      preset?.is_default ||
      (preset?.contracts_count && preset?.contracts_count > 0)
    ) {
      return;
    }

    onDelete(preset.id);
  };

  return (
    <Link
      to={SHOW_PRESET_PATH.replace(':id', String(preset.id))}
      className="pg-Preset"
    >
      <div className="pg-Preset__Body">
        <div className="pg-Preset__Body__Content">
          <p className="pg-Preset__Body__Content__Name">{preset.name}</p>

          <p className="pg-Preset__Body__Content__Description">
            {preset.description ? (
              <span>{preset.description}</span>
            ) : (
              <span className="empty">No description added.</span>
            )}
          </p>
        </div>

        <div className="pg-Preset__Body__Footer">
          {preset?.groups_count || 0} Documents
        </div>
      </div>

      {!preset.is_default && (
        <div className="pg-Preset__Actions">
          <Link
            to={EDIT_PRESET_PATH.replace(':id', String(preset.id))}
            className="pg-Preset__Actions__Action"
          >
            <IoPencil />
          </Link>

          <div
            onClick={handleDelete}
            className={`pg-Preset__Actions__Action Danger ${
              Number(preset?.contracts_count) > 0 ? 'disabled' : ''
            }`}
          >
            <Tooltip2
              placement="top"
              content={
                Number(preset?.contracts_count) > 0
                  ? "Preset cannot be deleted. It's being used to generate contracts"
                  : ''
              }
            >
              <IoTrash />
            </Tooltip2>
          </div>
        </div>
      )}
    </Link>
  );
};
