import React from 'react';
import { Steps } from './partials/steps';
import { Initial } from './partials/Initial';
import { CompanyDetailsStep } from './partials/company-details';
import { TeamMembersStep } from './partials/team-members';
import { useHistory } from 'react-router-dom';
import { HOME_PATH } from '../home';
import { useApi, useCompany } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';
import { Loader, PageTitle } from '../../components';
import './index.scss';
import { useService } from '../../shared/services/use-service';
import { ProfileService } from '../../shared/services';
import Storage from '../../shared/db/storage';

const steps = [
  { id: 'company_details', name: 'Company details' },
  { id: 'team_members', name: 'Team Members' },
];

const pageTitles: { [x: string]: string } = {
  0: 'Welcome to Brysk!',
  1: 'Add Company Details',
  2: 'Invite New Members',
};

export const ClientOnboarding = () => {
  const api = useApi();
  const history = useHistory();
  const toasterStore = useToaster();
  const profileService = useService(ProfileService);

  const {
    selectedCompany: { company_id: id },
  } = useCompany();
  const [company, setCompany] = React.useState<any>(null);
  const [activeStep, setActiveStep] = React.useState<number>(0);

  const updateOnboardingStatus = async (status: string) => {
    await api.post(
      `/api/client/company/${id}/onboarding`,
      {
        [status]: true,
      },
      'json',
    );

    setCompany((prev: any) => ({
      ...prev,
      [status]: true,
    }));
  };

  const onNext = async (status: string) => {
    await updateOnboardingStatus(status);

    const nextStep = activeStep + 1;

    setActiveStep(nextStep);
  };

  const completeOnboarding = async () => {
    await updateOnboardingStatus('invited_team_members');

    profileService.load();

    Storage.setItem('selectedCompanyOnboarded', `true`);

    history.replace(HOME_PATH);
  };

  const getCompany = React.useCallback(() => {
    api
      .get(`/api/client/company/${id}`)
      .then(({ data }) => {
        setCompany(data.company);
      })
      .catch(() => {
        toasterStore.danger({ message: 'Something went wrong' });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, id]);

  React.useEffect(() => {
    if (company?.onboarded) {
      history.replace(HOME_PATH);
    }
  }, [history, company?.onboarded]);

  React.useEffect(() => {
    getCompany();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompany]);

  React.useEffect(() => {
    let lastStep = 0;

    if (!company?.onboarded && !company?.started) lastStep = 0;
    if (!company?.onboarded && company?.started && !company?.filled_legal_info)
      lastStep = 1;
    if (
      !company?.onboarded &&
      company?.started &&
      company?.filled_legal_info &&
      !company?.invited_team_members
    )
      lastStep = 2;

    setActiveStep(Number(lastStep));
  }, [
    company?.filled_legal_info,
    company?.invited_team_members,
    company?.onboarded,
    company?.started,
  ]);

  return !company ? (
    <Loader fullscreen />
  ) : (
    <>
      <PageTitle>{pageTitles[`${activeStep}`] || ''}</PageTitle>

      <div className="Page_Onboarding">
        <div className="Content container py-4 row">
          <div className="col-12 col-xl-8 mx-auto">
            <Initial
              active={!company?.onboarded && !company?.started}
              onNext={() => onNext('started')}
            />

            <CompanyDetailsStep
              company={company}
              active={
                !company?.onboarded &&
                company?.started &&
                !company?.filled_legal_info
              }
              onNext={() => onNext('filled_legal_info')}
            />

            <TeamMembersStep
              cancelText="Skip"
              active={
                !company?.onboarded &&
                company?.started &&
                company?.filled_legal_info &&
                !company?.invited_team_members
              }
              onNext={completeOnboarding}
              onSkip={completeOnboarding}
            />
          </div>
        </div>

        <div className="Navigation">
          <Steps steps={steps} activeStep={activeStep - 1} />
        </div>
      </div>
    </>
  );
};
