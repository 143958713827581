import React, { createRef, useContext, useState } from 'react';
import { IoTrash } from 'react-icons/io5';
import { Button, FormGroup, MediaObject, Input } from '../../components';
import { Role } from '../../constants';
import { useModal } from '../../hooks';
import { useApi } from '../../hooks/use-api';
import { useToaster } from '../../hooks/use-toaster';
import { ContractDetailsContext } from './contract-details';
import { InviteModal } from './invite-modal';
import { SignedBy } from './signed-by';

export const ContractorSigner = ({ contract }: { contract: any }) => {
  const api = useApi();
  const modal = useModal();
  const toaster = useToaster();
  const contractContext = useContext(ContractDetailsContext);
  const [copied, setCopied] = useState(false);
  const inputRef = createRef<HTMLInputElement>();

  const timeoutRef = React.useRef<any>(null);

  const openModal = () => {
    modal
      .openModal((close, cancel) => (
        <InviteModal close={close} cancel={cancel} contractId={contract.id} />
      ))
      .result.then((res) => {
        contractContext.reload?.();
      });
  };

  const removeInvite = () => {
    api
      .delete(`/api/client/contract/${contract.id}/invite`)
      .then(() => {
        toaster.success({ message: 'Invite was successfully removed.' });
        contractContext.reload?.();
      })
      .catch(({ errors }) => {
        errors.forEach((message: string) => toaster.danger({ message }));
      });
  };

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="pg-Signer">
      {!contract.contractorSigner ? (
        <>
          <MediaObject className="pg-Signer__Content">
            <MediaObject
              align="center"
              media={
                <div className="pg-Signer__LeftContent">
                  <div className="pg-Signer__Label">Contractor signs here</div>
                </div>
              }
            >
              <div className="pg-Signer__Action">
                <Button
                  disabled={
                    contract.invitedContractor || !contract.clientSigner
                  }
                  onClick={openModal}
                >
                  {contract.invitedContractor || !contract.clientSigner
                    ? 'Invited'
                    : 'Invite'}
                </Button>
              </div>
            </MediaObject>
            <div>
              {contract.invitedContractor ? (
                <div style={{ borderTop: '1px solid #ddd', marginTop: '15px' }}>
                  <FormGroup style={{ marginTop: '20px' }} label="Public URL">
                    <div className="input-group">
                      <Input
                        ref={inputRef}
                        value={contract?.invitation_link || ''}
                        onFocus={(e) => (e.target as any).select()}
                      />
                      <span
                        className="input-group-text"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          inputRef.current?.focus();
                          inputRef.current?.select();
                          setCopied(true);
                          document.execCommand('copy');
                          timeoutRef.current = setTimeout(() => {
                            setCopied(false);
                          }, 1000);
                        }}
                      >
                        {copied ? 'Copied' : 'Copy URL'}
                      </span>
                    </div>
                  </FormGroup>
                  <div className="pg-Signer__InvitedContractor">
                    You have invited {contract.invitedContractor}
                    <Button
                      theme="link"
                      onClick={removeInvite}
                      title="Remove invite"
                    >
                      <IoTrash />
                    </Button>
                  </div>
                </div>
              ) : null}
            </div>
          </MediaObject>
        </>
      ) : (
        <SignedBy
          type={Role.CONTRACTOR}
          name={contract?.contractorSigner?.name}
          email={contract?.contractorSigner?.email}
        />
      )}
    </div>
  );
};
