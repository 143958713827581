import React from 'react';
import { Contract } from '..';
import { Async, Box, ErrorState, Loader, PageTitle } from '../../../components';
import { useApi } from '../../../hooks/use-api';
import { ContractListItem } from '../contract-list-item';

export const ContractorContracts = () => {
  const api = useApi();

  const promise = () =>
    api.get<{ data: Contract[] }>(`/api/contractor/contracts`);

  return (
    <>
      <PageTitle>Contracts</PageTitle>
      <Async
        promise={promise}
        pending={() => <Loader fullscreen />}
        rejected={() => <ErrorState title="Error loading contracts" />}
        fullfilled={({ data }) => (
          <Box className="col-md-12">
            {data.length ? (
              data.map((contract) => (
                <ContractListItem key={contract.id} contract={contract} />
              ))
            ) : (
              <div className="text-center">No contracts found</div>
            )}
          </Box>
        )}
      />
    </>
  );
};
