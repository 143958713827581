import { snakeCase, startCase } from 'lodash';
import React from 'react';
import Select from 'react-select';
import { useApi } from '../../hooks';

export const PermissionList = ({
  defaultValue,
  placeholder,
  onChange,
}: {
  defaultValue?: string[];
  placeholder?: string;
  onChange: (e: any) => void;
}) => {
  const api = useApi();
  const [allScopes, setAllScopes] = React.useState([]);
  const parsedDefaultValue = React.useMemo(() => {
    return (
      defaultValue?.map((v) => ({
        label: startCase(v),
        value: snakeCase(v),
      })) || []
    );
  }, [defaultValue]);

  const handleChange = (value: any) => {
    onChange(value);
  };

  React.useEffect(() => {
    api.get('/api/client/scopes').then(({ data }) => {
      setAllScopes(
        data?.scopes?.map((s: any) => ({
          value: s?.key,
          label: s?.label,
        })),
      );
    });
  }, [api]);

  return (
    <>
      <Select
        defaultValue={parsedDefaultValue}
        isMulti
        options={allScopes}
        onChange={handleChange}
        placeholder={placeholder || 'Type something and press enter...'}
      />
    </>
  );
};
