import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { IoAdd } from 'react-icons/io5';
import { Button } from '../../../components';
import { PayoneerNewAccount } from './payoneer-new-account';
import { PayoneerPaymentMethod } from '../../../interfaces';
import { PayoneerAccountList } from './payoneer-account-list';

export const PayoneerMethods = ({
  active,
  methods,
  refresh,
}: {
  active: boolean;
  methods: PayoneerPaymentMethod[];
  refresh: () => void;
}) => {
  const [formVisible, setFormVisible] = useState(false);

  const resetState = React.useCallback(() => {
    setFormVisible(false);

    refresh();
  }, [refresh]);

  if (!active) {
    return null;
  }

  return (
    <div>
      {!isEmpty(methods) && (
        <div className="mt-3 col-md-12">
          <PayoneerAccountList payoneerMethods={methods} />
        </div>
      )}

      {isEmpty(methods) || formVisible ? (
        <div className="col-md-12">
          <PayoneerNewAccount refresh={resetState} />
        </div>
      ) : (
        <div className="row mx-0">
          <Button
            theme="link"
            className="text-muted text-decoration-none border border-light"
            leftContent={<IoAdd />}
            onClick={() => setFormVisible(true)}
          >
            New Account
          </Button>
        </div>
      )}
    </div>
  );
};
