import React from 'react';
import { formatDistance } from 'date-fns';
import { isEmpty, sortBy } from 'lodash';
import ReactMarkdown from 'react-markdown';
import './index.scss';

interface NotificationData {
  message: string;
  [x: string]: any;
}

interface NotificationItem {
  id: string;
  type: string;
  data: NotificationData;
  created_at: string;
  read_at?: string;
}

interface PendingItem {
  id: string;
  reason: string;
  created_at: string;
}

const reasonLabels = {
  invoice_pending_approval: 'You have a **pending invoice** to **approve**',
  contract_pending_publishing: 'You have a **pending contract** to **publish**',
  contract_pending_client_signature:
    'You have a **pending contract** to **sign**',
  contract_pending_contractor_invite:
    'Contract is pending a **contractor invitation**',
  contract_pending_contractor_signature:
    'Contract is pending a **contractor signature**',
  document_pending_client_upload:
    'You have a **pending document** to **upload**',
  document_pending_contractor_upload: 'Pending contractor **document upload**',
};

export const ActivityTable = ({
  data = [],
  showMore = false,
}: {
  data: (NotificationItem | PendingItem)[];
  showMore?: boolean;
}) => {
  const hasRecords = React.useMemo(() => !isEmpty(data), [data]);
  const sortedData = React.useMemo(
    () => sortBy(data, 'created_at').reverse(),
    [data],
  );

  return (
    <div className="Activities">
      <div className="Activities__List">
        {!hasRecords && (
          <h6 className="Activities__List__Empty mt-5"> No activity</h6>
        )}

        {sortedData?.map((item: any) => (
          <div key={item.id} className="Activities__List__Item">
            <div className="Activities__List__Item__Content">
              <div className="Activities__List__Item__Content__Label">
                <ReactMarkdown>
                  {item?.data?.message
                    ? item?.data?.message
                    : item?.reason
                    ? (reasonLabels as any)[item.reason]
                    : ''}
                </ReactMarkdown>
              </div>
            </div>

            <div className="Activities__List__Item__Time">
              {formatDistance(new Date(item.created_at), new Date(), {
                addSuffix: true,
              })}
            </div>
          </div>
        ))}

        {hasRecords && showMore && (
          <p className="Activities__List__More">View more</p>
        )}
      </div>
    </div>
  );
};
