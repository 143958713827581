import React, { MouseEvent, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { IoPencil, IoTrash } from 'react-icons/io5';
import { Controller, useForm } from 'react-hook-form';

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import {
  restrictToHorizontalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';

import {
  SortableContext,
  horizontalListSortingStrategy,
  arrayMove,
} from '@dnd-kit/sortable';

import {
  OrderMap as IOrderMap,
  PresetGroup as IPresetGroup,
  PresetGroupTemplate as IPresetGroupTemplate,
} from '../../interfaces/presets';

import { PresetGroupTemplate } from '../preset-group-template';

import './index.scss';
import { Tag } from '../tag';
import { AddTemplateButton } from '../add-template-button';
import { EDIT_PRESET_GROUP_PATH } from '../../pages';
import { Link } from 'react-router-dom';

const sortByTemplateOrder = (
  a: IPresetGroupTemplate,
  b: IPresetGroupTemplate,
) => a.template_order - b.template_order;

export const PresetGroup = ({
  group,
  isEditable,
  onDelete,
  saveOrder,
  updateSettings,
}: {
  group: IPresetGroup;
  isEditable: boolean;
  onDelete: (groupId: number) => void;
  saveOrder: (orderMap: IOrderMap[]) => void;
  updateSettings: any;
}) => {
  const { control } = useForm();
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 16,
      },
    }),
  );

  const [presetGroupTemplates, setPresetGroupTemplates] = useState<
    IPresetGroupTemplate[]
  >(group?.preset_group_templates || []);

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (!isEditable || !active || !over) return;

    if (active.id !== over.id) {
      const oldIndex = presetGroupTemplates.findIndex(
        (t) => t.id === active.id,
      );

      const newIndex = presetGroupTemplates.findIndex((t) => t.id === over.id);

      const reorderedItems = arrayMove(
        presetGroupTemplates,
        oldIndex,
        newIndex,
      );

      const orderMap = reorderedItems.map((i, order) => ({
        id: i.id,
        template_order: order + 1,
      }));

      setPresetGroupTemplates(reorderedItems);

      saveOrder(orderMap);
    }
  };

  const onSettingsChange = (e: any, key: string, onChange: any) => {
    if (!isEditable) return;

    const isChecked = e.target.checked;

    onChange?.(isChecked ? 1 : 0);

    updateSettings(group.id, key, isChecked);
  };

  const handleDelete = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (isEditable) {
      onDelete(group.id);
    }
  };

  useEffect(() => {
    const templates = [...(group?.preset_group_templates || [])];

    templates.sort(sortByTemplateOrder);

    setPresetGroupTemplates(templates);
  }, [group.preset_group_templates]);

  return (
    <div className="pg-PresetGroup">
      <div className="pg-PresetGroup__Name">
        <div className="pg-PresetGroup__Name__Content">
          <div className="title">
            <span className="me-2">{group.name}</span>
            {Boolean(group?.should_merge) && (
              <Tag
                size="small"
                status="secondary"
                tooltip="Will be appended to the uploaded contract"
              >
                <small>Merged</small>
              </Tag>
            )}
          </div>
          <p className="description">
            {group?.description || 'No description added'}
          </p>
        </div>

        <div className="pg-PresetGroup__Name__Tag">
          <Tag size="small" status="primary">
            <small>{group?.document_type?.name}</small>
          </Tag>
        </div>
      </div>

      <div className="pg-PresetGroup__Templates">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
        >
          <SortableContext
            items={presetGroupTemplates}
            strategy={horizontalListSortingStrategy}
          >
            {presetGroupTemplates.map(
              (presetGroupTemplate: IPresetGroupTemplate) => (
                <PresetGroupTemplate
                  isEditable={isEditable}
                  key={presetGroupTemplate.id}
                  id={presetGroupTemplate.id}
                  presetGroupTemplate={presetGroupTemplate}
                />
              ),
            )}
          </SortableContext>
        </DndContext>

        {isEditable && <AddTemplateButton group={group} />}
      </div>

      {!isEmpty(presetGroupTemplates) && isEditable && (
        <div className="pg-PresetGroup__Notes">
          <small>
            * Drag to reorder the templates order in the generated document
          </small>
        </div>
      )}

      <div className="pg-PresetGroup__Footer">
        <div className="pg-PresetGroup__Footer__Options">
          <div className="pg-PresetGroup__Footer__Options__Option">
            <Controller
              name="require_client_signature"
              control={control}
              defaultValue={Boolean(group.require_client_signature)}
              render={(props) => (
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="require_client_signature"
                    checked={Boolean(props.value)}
                    disabled={!isEditable}
                    onChange={(e) =>
                      onSettingsChange(
                        e,
                        'require_client_signature',
                        props.onChange,
                      )
                    }
                  />

                  <label
                    className="form-check-label"
                    htmlFor="require_client_signature"
                  >
                    Require Client Signature
                  </label>
                </div>
              )}
            />
          </div>

          <div className="pg-PresetGroup__Footer__Options__Option">
            <Controller
              name="require_contractor_signature"
              control={control}
              defaultValue={Boolean(group.require_contractor_signature)}
              render={(props) => (
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="require_contractor_signature"
                    checked={Boolean(props.value)}
                    disabled={!isEditable}
                    onChange={(e) =>
                      onSettingsChange(
                        e,
                        'require_contractor_signature',
                        props.onChange,
                      )
                    }
                  />

                  <label
                    className="form-check-label"
                    htmlFor="require_contractor_signature"
                  >
                    Require Contractor Signature
                  </label>
                </div>
              )}
            />
          </div>
        </div>

        {isEditable && (
          <div className="pg-PresetGroup__Footer__Actions">
            <Link
              to={EDIT_PRESET_GROUP_PATH.replace(':id', String(group.id))}
              className="pg-PresetGroup__Footer__Actions__Action"
            >
              <IoPencil />
            </Link>
            <div
              onClick={handleDelete}
              className="pg-PresetGroup__Footer__Actions__Action Danger"
            >
              <IoTrash />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
