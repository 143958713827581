import { useMemo } from 'react';
import { Contract } from '.';
import { BoxProps, Box } from '../../components';
import { useCommonData } from '../../hooks';
import { LabelValueGroup, LabelValueItem } from './label-value';
import { LabelValueRow } from './label-value-row';

export const OtherSpecifics2 = ({
  contract,
  boxProps,
}: {
  contract: Contract;
  boxProps?: BoxProps;
}) => {
  const common = useCommonData();

  const isPayAsYouGo = useMemo(() => {
    return (
      common?.contract_types
        ?.find((item) => item.id === contract.contract_type_id)
        ?.name.toLowerCase() === 'pay as you go'
    );
  }, [contract.contract_type_id, common]);

  const whoCanAddWork = useMemo(() => {
    if (isPayAsYouGo) {
      if (contract.contractor_can_submit && contract.client_can_submit) {
        return 'Both parties';
      } else if (contract.contractor_can_submit) {
        return 'Contractor';
      } else if (contract.client_can_submit) {
        return 'Client';
      }
    }
  }, [contract, isPayAsYouGo]);

  return (
    <LabelValueGroup>
      {whoCanAddWork ? (
        <LabelValueItem label="Who can add work">
          {whoCanAddWork}
        </LabelValueItem>
      ) : null}
      {contract.special_clause ? (
        <LabelValueItem label="Special clause">
          {contract.special_clause}
        </LabelValueItem>
      ) : null}
      {!whoCanAddWork && !contract.special_clause ? (
        <LabelValueItem
          label={<i>There are no other specifics to display.</i>}
        />
      ) : null}
    </LabelValueGroup>
  );
};

export const OtherSpecifics = ({
  contract,
  boxProps,
}: {
  contract: Contract;
  boxProps?: BoxProps;
}) => {
  const common = useCommonData();

  const isPayAsYouGo = useMemo(() => {
    return (
      common?.contract_types
        ?.find((item) => item.id === contract.contract_type_id)
        ?.name.toLowerCase() === 'pay as you go'
    );
  }, [contract.contract_type_id, common]);

  const whoCanAddWork = useMemo(() => {
    if (isPayAsYouGo) {
      if (contract.contractor_can_submit && contract.client_can_submit) {
        return 'Both parties';
      } else if (contract.contractor_can_submit) {
        return 'Contractor';
      } else if (contract.client_can_submit) {
        return 'Client';
      }
    }
  }, [contract, isPayAsYouGo]);

  return (
    <Box {...boxProps}>
      <h6 className="pg-ContractDetails__ItemTitle">Other specifics</h6>
      {whoCanAddWork ? (
        <LabelValueRow label="Who can add work">{whoCanAddWork}</LabelValueRow>
      ) : null}
      {contract.special_clause ? (
        <LabelValueRow label="Special clause">
          {contract.special_clause}
        </LabelValueRow>
      ) : null}
      {!whoCanAddWork && !contract.special_clause ? (
        <LabelValueRow
          inline
          label={<i>There are no other specifics to display.</i>}
        />
      ) : null}
    </Box>
  );
};
