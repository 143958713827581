import { Contract } from './index';
import {
  Box,
  Button,
  CommonDataDisplay,
  DocumentUrlList,
  ErrorState,
  Input,
  Loader,
  MediaObject,
  PageTitle,
  RouterTabs,
  Text,
} from '../../components';
import { useApi, useAsync, useModal } from '../../hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { PaymentDetails2 } from './payment-details';
import { GeneralInfo2 } from './general-info';
import { CountryCompliance2 } from './country-compliance';
import { OtherSpecifics2 } from './other-specifics';
import {
  AccountType,
  TokenService,
  useProfileService,
} from '../../shared/services';
import { map, startCase } from 'lodash';
import { ComplianceTable, Signer } from '../index';
import { ApiResponse, ApprovePayrollModal, Invoice } from '../invoices';
import { currencyFormatter } from '../../shared/helpers';
import './active-contract.scss';
import { ContractDetailsHeader } from './components';
import { Role } from '../../constants';
import { useService } from '../../shared/services/use-service';

const InvoiceItemInfo = ({
  invoice,
  item,
  onAdd,
}: {
  invoice: any;
  item: any;
  onAdd: any;
}) => {
  const api = useApi();
  const { actAsClient } = useProfileService();
  const [quantity, setQuantity] = useState<any>();
  const [showInput, setShowInput] = useState<any>();

  const submitUrl = React.useMemo(
    () =>
      actAsClient
        ? `/api/client/invoices/${invoice.id}/items/${item.id}`
        : `/api/contractor/invoices/${invoice.id}/items/${item.id}`,
    [actAsClient, invoice.id, item.id],
  );

  const canEdit = React.useMemo(
    () => invoice?.status !== 'Approved' && item?.is_editable,
    [invoice?.status, item?.is_editable],
  );

  const request = () => {
    api
      .patch(submitUrl, {
        quantity,
        invoice_item_type_id: item.invoice_item_type_id,
      })
      .then(() => {
        onAdd?.();
      });
  };

  return (
    <div className="pg-InvoiceItemInfo">
      <div className="pg-InvoiceItemInfo__Info">
        <div>
          <b>Amount</b>
          <span>{item.amount}</span>
        </div>
        <div>
          <b>Quantity</b>
          <span>{item.quantity}</span>
        </div>
        <div>
          <b>Type</b>
          <span>
            <CommonDataDisplay
              id={item.invoice_item_type_id}
              type="invoice_items_types"
            />
          </span>
        </div>
      </div>
      {canEdit && showInput && (
        <div className="pg-InvoiceItemInfo__Input">
          <Input
            type="number"
            placeholder="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
          <Button theme="light" outline onClick={request}>
            Add
          </Button>
          <Button theme="light" outline onClick={() => setShowInput(false)}>
            Cancel
          </Button>
        </div>
      )}
      {canEdit && !showInput && (
        <Button theme="light" outline onClick={() => setShowInput(true)}>
          Add quantity
        </Button>
      )}
    </div>
  );
};

export const InvoiceItems = ({
  invoice,
  reload,
}: {
  invoice: any;
  reload: () => Promise<any>;
}) => {
  return (
    <div>
      <h5 style={{ margin: '20px 0' }}>Invoice items</h5>
      {invoice?.invoice_items?.map((item: any) => (
        <InvoiceItemInfo
          key={item.id}
          invoice={invoice}
          item={item}
          onAdd={reload}
        />
      ))}
    </div>
  );
};

const ActiveContractSigner = ({
  data,
  type,
  contract,
}: {
  data?: Signer;
  type?: AccountType;
  contract?: Contract;
}) => {
  return (
    <Box className="pg-ActiveContractSigner">
      <Text
        theme="secondary"
        bold
        className="pg-ActiveContractSigner__SignedBy"
      >
        Signed by {startCase(type)}
      </Text>
      <MediaObject>
        <div className="pg-ActiveContractSigner__Content">
          <div className="d-flex">
            <div className="pg-ActiveContractSigner__Name">{data?.name}</div>
            <div className="pg-ActiveContractSigner__Email">{data?.email}</div>
          </div>
          {(type === Role.CLIENT || type === Role.TEAM_MEMBER) && (
            <div className="pg-ActiveContractSigner__Company">
              {contract?.company_name}
            </div>
          )}
        </div>
      </MediaObject>
      <div className="pg-ActiveContractSigner__RightContent">
        <div style={{ fontFamily: 'Kristi', fontSize: '2rem' }}>
          {data?.name}
        </div>
      </div>
    </Box>
  );
};

const ActiveContractInvoices = ({ id }: { id: number }) => {
  const api = useApi();
  const modal = useModal();
  const profile = useProfileService();

  const getTotal = (invoices: Invoice[] = []) => {
    return invoices.reduce(
      (total: number, { gross_pay }: any) => (total += gross_pay),
      0,
    );
  };

  const openApprovalModal = (data: Invoice[]) => {
    modal.openModal((close, cancel) => (
      <ApprovePayrollModal
        close={close}
        cancel={cancel}
        data={data}
        selected={data.map(({ id }) => id)}
      />
    ));
  };

  const request = useCallback(
    () =>
      api
        .get<ApiResponse<'invoices', Invoice[]>>(
          `/api/client/contract/${id}/invoices`,
        )
        .then(({ data }) => data?.invoices || []),
    [api, id],
  );

  const req = useAsync(request);

  return (
    <>
      {req.status === 'error' ? (
        <ErrorState title="Error loading invoices" />
      ) : req.status === 'pending' ? (
        <Loader fullscreen />
      ) : req.value?.length ? (
        <div className="row justify-content-center">
          <div className="col-lg-12 col-xl-6">
            <Box>
              <div className="pg-ActiveContractInvoices__Content">
                <div className="pg-ActiveContractInvoices__Total">
                  <div className="pg-ActiveContractInvoices__TotalCount">
                    {currencyFormatter(
                      getTotal(req.value),
                      req.value[0].currency_iso,
                    )}
                  </div>

                  <div className="pg-ActiveContractInvoices__TotalLabel">
                    Total in {req.value[0].currency_iso}
                  </div>
                </div>
              </div>
              <div className="pg-ActiveContractInvoices__Details">
                <h6>Invoice details</h6>
                <div>
                  {req.value.map((invoice) => (
                    <div className="pg-ActiveContractInvoices__InvoiceItem">
                      <div className="pg-ActiveContractInvoices__InvoiceItem__Header">
                        <div className="pg-ActiveContractInvoices__InvoiceItem__Total">
                          {currencyFormatter(
                            invoice.gross_pay,
                            invoice.currency_iso,
                          )}
                        </div>

                        {profile.actAsClient && (
                          <div className="pg-ActiveContractInvoices__Action">
                            <Button
                              onClick={() => openApprovalModal([invoice])}
                            >
                              Pay Invoice
                            </Button>
                          </div>
                        )}
                      </div>

                      <div className="pg-ActiveContractInvoices__InvoiceItemSection">
                        <div className="pg-ActiveContractInvoices__InvoiceItemLabel">
                          Gross salary:{' '}
                        </div>
                        <div className="pg-ActiveContractInvoices__InvoiceItemValue">
                          {currencyFormatter(
                            invoice.gross_salary,
                            invoice.currency_iso,
                          )}
                        </div>
                      </div>
                      <div className="pg-ActiveContractInvoices__InvoiceItemSection">
                        <div className="pg-ActiveContractInvoices__InvoiceItemLabel">
                          Other compensation:{' '}
                        </div>
                        <div className="pg-ActiveContractInvoices__InvoiceItemValue">
                          {currencyFormatter(
                            invoice.other_compensation,
                            invoice.currency_iso,
                          )}
                        </div>
                      </div>
                      <div className="pg-ActiveContractInvoices__InvoiceItemSection">
                        <div className="pg-ActiveContractInvoices__InvoiceItemLabel">
                          Gross pay:{' '}
                        </div>
                        <div className="pg-ActiveContractInvoices__InvoiceItemValue">
                          {currencyFormatter(
                            invoice.gross_pay,
                            invoice.currency_iso,
                          )}
                        </div>
                      </div>
                      <InvoiceItems reload={req.execute} invoice={invoice} />
                    </div>
                  ))}
                </div>
              </div>
            </Box>
          </div>
        </div>
      ) : (
        <div className="row justify-content-center">
          <div className="col-md-6">
            <Box className="text-center">No invoices found</Box>
          </div>
        </div>
      )}
    </>
  );
};

export const ActiveContract = ({ contract }: { contract: Contract }) => {
  const api = useApi();
  const { token } = useService(TokenService);
  const [loading, setLoading] = useState(true);
  const [compliance, setCompliance] = useState<any>();
  const [documents, setDocuments] = useState<any>([]);

  useEffect(() => {
    api
      .get(`/api/contractor/contract/${contract.id}/compliance-documents`)
      .then(({ data: { complianceDocuments: data } }) => data)
      .then((compliance) => {
        // const signer = contract.contractorSigner?.id;
        setCompliance(
          Object.keys(compliance).map((key) => compliance[key]) as any,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [api, contract.company_id, contract.id]);

  useEffect(() => {
    setLoading(true);

    api
      .get(`/api/client/contract/${contract.id}/generated-pdf`)
      .then(({ data }) => {
        setDocuments(data?.documents || []);

        setLoading(false);
      });
  }, [api, contract.id]);

  return (
    <>
      {loading ? (
        <Loader fullscreen />
      ) : (
        <>
          <PageTitle>
            <ContractDetailsHeader
              contract={contract}
              actions={
                <Button theme="danger" outline>
                  Terminate contract
                </Button>
              }
            />
          </PageTitle>
          <div className="pg-ActiveContract container">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-12">
                <RouterTabs
                  items={[
                    {
                      label: 'Contract Details',
                      url: '/details',
                      render: () => (
                        <>
                          <div className="pg-ActiveContract__Signers">
                            <Text
                              as="h3"
                              theme="dark"
                              className="pg-ContractDetails__SectionTitle"
                            >
                              Signatures
                            </Text>
                            <ActiveContractSigner
                              contract={contract}
                              type={Role.CLIENT}
                              data={contract?.clientSigner}
                            />
                            <ActiveContractSigner
                              contract={contract}
                              type={Role.CONTRACTOR}
                              data={contract.contractorSigner}
                            />
                          </div>
                          <div className="pg-ActiveContract__Details">
                            <div className="pg-ContractDetails__Section">
                              <Text
                                as="h3"
                                theme="dark"
                                className="pg-ContractDetails__SectionTitle"
                              >
                                General Info
                              </Text>
                              <GeneralInfo2 contract={contract} />
                            </div>
                            <div className="pg-ContractDetails__Section">
                              <Text
                                as="h3"
                                theme="dark"
                                className="pg-ContractDetails__SectionTitle"
                              >
                                Payment details
                              </Text>
                              <PaymentDetails2 contract={contract} />
                            </div>
                            <div className="pg-ContractDetails__Section">
                              <Text
                                as="h3"
                                theme="dark"
                                className="pg-ContractDetails__SectionTitle"
                              >
                                Country compliance
                              </Text>
                              <CountryCompliance2 contract={contract} />
                            </div>
                            <div className="pg-ContractDetails__Section">
                              <Text
                                as="h3"
                                theme="dark"
                                className="pg-ContractDetails__SectionTitle"
                              >
                                Other specifics
                              </Text>
                              <OtherSpecifics2 contract={contract} />
                            </div>
                          </div>
                        </>
                      ),
                    },
                    {
                      label: 'Documents',
                      url: '/documents',
                      render: () => (
                        <div className="row justify-content-center">
                          <div className="col-xl-8">
                            {map(documents, (urls, key) => (
                              <DocumentUrlList
                                key={key}
                                title={key}
                                urls={urls}
                              />
                            ))}
                          </div>

                          <div className="w-75 text-center">
                            <hr />
                          </div>

                          {!!compliance?.length && (
                            <div className="col-xl-8 my-3">
                              <h5 className="mb-3">Compliance documents</h5>
                              <Box>
                                <ComplianceTable
                                  token={token}
                                  hideFilters
                                  hideFooter
                                  data={Object.keys(compliance).reduce(
                                    (all, current) =>
                                      [
                                        ...all,
                                        { ...compliance[current], id: current },
                                      ] as any,
                                    [],
                                  )}
                                />
                              </Box>
                            </div>
                          )}
                        </div>
                      ),
                    },
                    {
                      label: 'Invoices',
                      url: '/invoices',
                      render: () => <ActiveContractInvoices id={contract.id} />,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
