import React from 'react';
import { Button } from '../..';
import { IDENTITY_VERIFICATION_ERROR } from '../../../constants';
import { useApi } from '../../../hooks/use-api';
import { useToaster } from '../../../hooks/use-toaster';

export enum VerificationTarget {
  REDIRECT = 'redirect',
  MODAL = 'modal',
}

const VerifyButton = ({
  stripePromise,
  target,
  redirectUrl = null,
  children,
  disabled = false,
  submitUrl,
  ...props
}: {
  stripePromise: any;
  target: VerificationTarget;
  redirectUrl?: string | null | undefined;
  children: any;
  disabled?: boolean;
  submitUrl: string;
  [x: string]: any;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const [stripe, setStripe] = React.useState<any>(null);
  const defaultRedirectUrl = React.useMemo(() => window.location.href, []);

  const fetchClientSecret = () => {
    const url = redirectUrl || defaultRedirectUrl;

    const data =
      target === VerificationTarget.MODAL
        ? {}
        : {
            return_url: url,
            refresh_url: url,
          };

    return api.post(submitUrl, data).then((res) => res);
  };

  const handleClick = async (event: any) => {
    // Block native event handling.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    // Call your backend to create the VerificationSession.
    const { data } = await fetchClientSecret();

    if (target === VerificationTarget.REDIRECT) {
      return window.location.replace(data.verify_url);
    }

    // Show the verification modal.
    const { error } = await stripe.verifyIdentity(data.client_secret);

    if (error) {
      toaster.danger({
        timeout: 10000,
        title: 'Something went wrong!',
        message: IDENTITY_VERIFICATION_ERROR[error.code],
      });
    } else {
      toaster.success({
        timeout: 10000,
        title: 'Thanks for submitting your identity document',
        message:
          'We are processing your verification documents and you will be notified once it is done.',
      });
    }
  };

  React.useEffect(() => {
    async function load() {
      const stripeInstance = await stripePromise;
      setStripe(stripeInstance);
    }

    load();
  }, [stripePromise]);

  return (
    <Button disabled={!stripe || disabled} onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

export default VerifyButton;
