import React from 'react';
import { formatDistance } from 'date-fns';
import { Action, FormGroup, Table, Td, Th, Tr } from '../../../components';
import { Popover2 } from '@blueprintjs/popover2';
import { Menu, MenuItem, Position } from '@blueprintjs/core';
import { useToaster } from '../../../hooks/use-toaster';
import { useApi, useModal } from '../../../hooks';
import { ComplianceDocumentTypes } from '../../../constants';
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import { Dropzone } from '../dropzone';
import { ComplianceDocument } from '../../../interfaces';
import './index.scss';

export type DocumentTypeKey =
  typeof ComplianceDocumentTypes[keyof typeof ComplianceDocumentTypes];

const labels = {
  [ComplianceDocumentTypes.IdentityVerification]: 'Passport or National ID',
  [ComplianceDocumentTypes.LicensesPermits]:
    'Relevant sector specific licenses and/or permits(Optional)',
  [ComplianceDocumentTypes.AdditionalCompliance]:
    'Any additional relevant documents(Optional)',
};

export const ComplianceDocumentsList = ({
  docs,
  reload,
}: {
  docs: Record<string, ComplianceDocument[]>;
  reload: () => void;
}) => {
  const api = useApi();
  const modal = useModal();
  const toaster = useToaster();

  const remove = (id: number) => {
    modal
      .confirmModal({
        title: 'Delete document',
        message: 'Are you sure you want to delete this document?',
        theme: 'danger',
      })
      .result.then(() => {
        api
          .delete(`/api/contractor/documents/${id}`)
          .then(() => {
            reload();
            toaster.success({ message: 'Document was deleted' });
          })
          .catch((e) => {
            toaster.danger({ message: 'Failed to delete document' });
          });
      })
      .catch(() => {});
  };

  return (
    <>
      <h6 className="pg-ComplianceSection__Title">Compliance Documents</h6>

      <div className="pg-ComplianceSection__Content">
        {(Object.keys(docs) as DocumentTypeKey[]).map((key) => (
          <div key={key}>
            <FormGroup label={labels[key]}>
              {docs[key].length ? (
                <Table>
                  <thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Uploaded</Th>
                      <Th isAction></Th>
                    </Tr>
                  </thead>
                  <tbody>
                    {docs[key].map((item) => (
                      <Tr key={item.id}>
                        <Td>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            download
                            href={item.url}
                          >
                            {item.name}
                          </a>
                        </Td>
                        <Td title={new Date(item.createdAt).toString()}>
                          {formatDistance(
                            new Date(item.createdAt),
                            new Date(),
                            { addSuffix: true },
                          )}
                        </Td>
                        <Td isAction>
                          <Popover2
                            minimal
                            position={Position.BOTTOM_RIGHT}
                            transitionDuration={-1}
                            content={
                              <Menu>
                                <MenuItem
                                  text="Download"
                                  target="_blank"
                                  download
                                  href={item.url}
                                ></MenuItem>
                                <MenuItem
                                  text="Remove"
                                  onClick={() => remove(item.id)}
                                ></MenuItem>
                              </Menu>
                            }
                          >
                            <Action>
                              <IoEllipsisHorizontalSharp />
                            </Action>
                          </Popover2>
                        </Td>
                      </Tr>
                    ))}
                  </tbody>
                </Table>
              ) : null}
              <Dropzone
                message="Click here or drag file to upload (max. file size: 20mb)"
                onChange={reload}
                type={key}
              />
            </FormGroup>
          </div>
        ))}
      </div>
    </>
  );
};
