import './table-cell.scss';
import classNames from 'classnames';

export const Td = ({
  isAction,
  className,
  alignRight,
  ...props
}: React.HTMLProps<HTMLTableCellElement> & {
  isAction?: boolean;
  alignRight?: boolean;
}) => (
  <td
    className={classNames(
      'pg-TableCell',
      {
        'pg-TableCell--action': isAction,
        'pg-TableCell--align-right': alignRight,
      },
      className,
    )}
    {...props}
  />
);
