import React, { useState } from 'react';
import { Role } from '../../constants';
import { useApi } from '../../hooks/use-api';
import { RouteConfigItem } from '../../routes';
import { CustomTableProps } from '../../types';
import { useCompany } from '../../hooks/use-company';
import { TokenService } from '../../shared/services';
import { CompanyMustBeOnboarded } from '../../middlewares';
import { useService } from '../../shared/services/use-service';
import { ClientComplianceColumns } from '../../table-definition';
import { Box, CustomTable, LayoutContent, PageTitle } from '../../components';
import './index.scss';

export const COMPLIANCE_PATH = '/compliance';

export const COMPLIANCE_ROUTE: RouteConfigItem = {
  path: COMPLIANCE_PATH,
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  middlewares: [CompanyMustBeOnboarded],
  render: () => <Compliance />,
};

export const ComplianceTable = ({
  data,
  token,
  ...props
}: {
  data: any;
  token: string | null;
} & Partial<CustomTableProps>) => {
  const columns = React.useMemo(
    () => ClientComplianceColumns({ token }),
    [token],
  );

  return <CustomTable rows={data} columns={columns} {...props} />;
};

export const Compliance = () => {
  const api = useApi();
  const { selectedCompany } = useCompany();
  const { token } = useService(TokenService);
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const columns = React.useMemo(
    () => ClientComplianceColumns({ token }),
    [token],
  );

  const fetchCompliance = React.useCallback(() => {
    setIsLoading(true);

    api
      .get<any>(
        `/api/client/company/${selectedCompany?.company_id}/compliance/active-contractors`,
      )
      .then(({ data }) => {
        setDocuments(
          Object.keys(data).reduce(
            (all, current) =>
              [...all, { ...data[current], id: current }] as any,
            [],
          ),
        );
      })
      .finally(() => setIsLoading(false));
  }, [api, selectedCompany?.company_id]);

  React.useEffect(() => {
    fetchCompliance();
  }, [fetchCompliance]);

  return (
    <>
      <PageTitle>Compliance Documents</PageTitle>

      <LayoutContent>
        <Box>
          <CustomTable loading={isLoading} rows={documents} columns={columns} />
        </Box>
      </LayoutContent>
    </>
  );
};
