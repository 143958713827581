import React, { useImperativeHandle, forwardRef } from 'react';
import { useAsync } from '../../hooks/use-async';

export interface AsyncProps<T> {
  promise: () => Promise<T>;
  pending: () => React.ReactNode;
  fullfilled: (data: T) => React.ReactNode;
  rejected: (error: string | null) => React.ReactNode;
}

export interface AsyncRef {
  reload: () => Promise<void>;
}

export const Async = forwardRef(
  <T extends unknown>(
    { promise, pending, fullfilled, rejected }: AsyncProps<T>,
    ref: React.Ref<AsyncRef | undefined>,
  ) => {
    const promiseCall = React.useMemo(() => promise, [promise]);

    const { status, error, value, execute } = useAsync(promiseCall);

    useImperativeHandle(ref, () => ({
      reload: execute,
    }));

    if (status === 'error') {
      return <>{rejected(error)}</>;
    } else if (status === 'pending') {
      return <>{pending()}</>;
    } else if (status === 'success') {
      return <>{fullfilled(value as T)}</>;
    } else {
      return <></>;
    }
  },
) as <T extends unknown>(
  props: AsyncProps<T> & { ref?: React.Ref<AsyncRef | undefined> },
) => JSX.Element;
