import React from 'react';
import ReactSelect from 'react-select';
import { Country as ICountry } from '../../interfaces';
import { CommonDataService } from '../../shared/services/common-data';
import { useService } from '../../shared/services/use-service';
import { InputPlaceholder } from '../input-placeholder';

interface CountryOption {
  label: string;
  value: number | string;
}

export const CountrySelect = ({
  value,
  onChange,
  readOnly,
  defaultCountries,
  isClearable = false,
  valueAs = 'id',
}: {
  value?: number;
  onChange?: (value?: number | string) => any;
  readOnly?: boolean;
  isClearable?: boolean;
  defaultCountries?: ICountry[];
  valueAs?: string;
}) => {
  const commonData = useService(CommonDataService);
  const [countries, setCountries] = React.useState<CountryOption[]>([]);

  React.useEffect(() => {
    if (defaultCountries) {
      setCountries(
        defaultCountries?.map(({ id, country_name }) => ({
          label: country_name,
          value: valueAs === 'name' ? country_name : id,
        })) || [],
      );
    } else {
      commonData.get().then(({ countries }) => {
        setCountries(
          countries?.map(({ id, country_name }) => ({
            label: country_name,
            value: valueAs === 'name' ? country_name : id,
          })) || [],
        );
      });
    }
  }, [defaultCountries, commonData, valueAs]);

  return !readOnly ? (
    <ReactSelect
      isClearable={isClearable}
      options={countries}
      value={countries.find((country) => country.value === value)}
      onChange={(selected) => onChange?.(selected?.value)}
    />
  ) : (
    <InputPlaceholder>
      {countries.find((country) => country.value === value)?.label || 'N/A'}
    </InputPlaceholder>
  );
};
