import { Contract } from '.';
import { Box, BoxProps, CommonDataDisplay } from '../../components';
import { LabelValueGroup, LabelValueItem } from './label-value';
import { LabelValueRow } from './label-value-row';

export const CountryCompliance2 = ({ contract }: { contract: Contract }) => (
  <LabelValueGroup>
    <LabelValueItem label="Compliance documents">
      Brysk contracts and compliance documents requests are customised based on
      the contractor's residency and tax registration.
    </LabelValueItem>
    <LabelValueItem label="Contractor's Country">
      <CommonDataDisplay type="countries" id={contract.contractor_country_id} />
    </LabelValueItem>
  </LabelValueGroup>
);

export const CountryCompliance = ({
  contract,
  boxProps,
}: {
  contract: Contract;
  boxProps?: BoxProps;
}) => {
  return (
    <Box {...boxProps}>
      <h6 className="pg-ContractDetails__ItemTitle">Country compliance</h6>
      <LabelValueRow label="Compliance documents">
        Brysk contracts and compliance documents requests are customised based
        on the contractor's residency and tax registration.
      </LabelValueRow>
      <LabelValueRow inline label="Contractor's Country">
        <CommonDataDisplay
          type="countries"
          id={contract.contractor_country_id}
        />
      </LabelValueRow>
    </Box>
  );
};
