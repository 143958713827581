import React from 'react';
import Select from 'react-select';
import { useApi } from '../../hooks';

export interface WebhookEvent {
  id: number;
  key: string;
  name: string;
  created_at: string;
  updated_at: string;
}

export const WebhookEventsList = ({
  defaultValue,
  placeholder,
  onChange,
}: {
  defaultValue?: WebhookEvent[];
  placeholder?: string;
  onChange: (e: any) => void;
}) => {
  const api = useApi();
  const [allEvents, setAllEvents] = React.useState([]);
  const parsedDefaultValue = React.useMemo(() => {
    return (
      defaultValue?.map((v) => ({
        label: v?.name,
        value: v?.id,
      })) || []
    );
  }, [defaultValue]);

  const handleChange = (value: any) => {
    onChange(value);
  };

  React.useEffect(() => {
    api.get('/api/client/webhook-events').then(({ data }) => {
      setAllEvents(
        data?.events?.map((s: any) => ({
          value: s?.id,
          label: s?.name,
        })),
      );
    });
  }, [api]);

  return (
    <>
      <Select
        defaultValue={parsedDefaultValue}
        isMulti
        options={allEvents}
        onChange={handleChange}
        placeholder={placeholder || 'Type something and press enter...'}
      />
    </>
  );
};
