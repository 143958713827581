import React from 'react';
import _ from 'lodash';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  CountrySelect,
  FormGroup,
  InfoTooltip,
  Input,
  Text,
} from '../../../components';
import { PaymentMethod } from '../../../constants';
import { useApi, useEndpoint } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import { Country as ICountry } from '../../../interfaces';
import { useProfileService } from '../../../shared/services';
import { alphaNumericCustomPunctOnly } from '../../../shared';

export const WireNewAccount = ({ refresh }: { refresh: () => any }) => {
  const api = useApi();
  const toaster = useToaster();
  const endpoint = useEndpoint();
  const { profile } = useProfileService();
  const { watch, control, register, handleSubmit, errors, setError, setValue } =
    useForm();
  const formData = watch();
  const [loading, setLoading] = React.useState(false);

  const [svbCountries, setSvbCountries] = React.useState<ICountry[]>([]);

  const submit = async () => {
    setLoading(true);

    api
      .post(
        '/api/payment/methods',
        {
          payment_method_type: PaymentMethod.WIRE,
          billable_id: profile?.id,
          billable_type: 'user',
          beneficiary_name: formData?.beneficiary_name,
          account_number: formData?.account_number,
          swift_code: formData?.swift_code,
          beneficiary_address1: formData?.beneficiary_address1,
          beneficiary_address2: formData?.beneficiary_address2,
          beneficiary_city: formData?.beneficiary_city,
          beneficiary_zip: formData?.beneficiary_zip,
          beneficiary_country: formData?.beneficiary_country,
          beneficiary_state: formData?.beneficiary_state,
          bank_name: formData?.bank_name,
          bank_address1: formData?.bank_address1,
          bank_address2: formData?.bank_address2,
          bank_city: formData?.bank_city,
          bank_zip: formData?.bank_zip,
          bank_country: formData?.bank_country,
          bank_state: formData?.bank_state,
          primary: true,
        },
        'json',
      )
      .then(() => {
        toaster.success({ message: 'Bank account added.' });

        window.scrollTo(0, 0);

        refresh();
      })
      .catch(({ errors }) => {
        if (errors) {
          Object.keys(errors).map((key) =>
            setError(key, {
              type: 'manual',
              message: _.head(errors[key]) || ' is invalid',
            }),
          );
        }

        toaster.danger({
          message: 'There was an error while adding the bank account.',
        });
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    api.get(endpoint('common.svbCountries')).then(({ data: { countries } }) => {
      setSvbCountries(
        countries.map((c: any) => ({
          id: c,
          country_name: c,
        })),
      );
    });
  }, [api, endpoint]);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <FormGroup
            required
            label="Account Name"
            errors={errors?.beneficiary_name}
          >
            <Input
              name="beneficiary_name"
              ref={register({ ...alphaNumericCustomPunctOnly, required: true })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup
            required
            label="Account Number or IBAN"
            errors={errors?.account_number}
          >
            <Input
              name="account_number"
              ref={register({
                required: true,
                validate: {
                  correctFormat: (val) => {
                    const regex = new RegExp(/^[a-zA-Z0-9]{1,35}$/g);

                    return regex.test(val) || 'Invalid Account Number or IBAN';
                  },
                },
              })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup
            required
            label={
              <InfoTooltip
                content="SWIFT Code or ABA Number"
                tooltip={
                  <>
                    <Text as="p" theme="white" underline bold>
                      SWIFT / BIC codes should contain:
                    </Text>
                    <Text as="p" align="center" theme="white" bold>
                      XXXX-XX-XX-XXX
                    </Text>
                    <ul>
                      <li>a 4-letter bank code (Required),</li>
                      <li>a 2-letter country code (Required),</li>
                      <li>a 2-letter or number location code (Required),</li>
                      <li>a 3-letter or number branch code (optional)</li>
                    </ul>
                    <hr />
                    <Text as="p" theme="white" underline bold>
                      ABA Routing Number should contain:
                    </Text>
                    <Text as="p" align="center" theme="white" bold>
                      XXXX-YYYY-C
                    </Text>
                    <ul>
                      <li>
                        XXXX is Federal Reserve Routing Symbol (Required),
                      </li>
                      <li>YYYY is ABA Institution Identifier (Required),</li>
                      <li>C is the check digit (Required),</li>
                    </ul>
                  </>
                }
                usePortal={false}
              />
            }
            errors={errors?.swift_code}
          >
            <Input
              name="swift_code"
              placeholder="EX: XXXX-XX-XX-XXX"
              maxLength={35}
              onBlur={(e) => {
                const value = e.target.value;

                const sanitizedValue = value
                  ?.replaceAll(/[^a-zA-Z0-9]/g, '')
                  ?.toUpperCase();

                setValue('swift_code', sanitizedValue || '');
              }}
              ref={register({
                required: true,
                validate: {
                  minOfEight: (val) =>
                    val?.length >= 8 ||
                    'Invalid SWIFT Code or ABA Number length',
                  maxOfEleven: (val) =>
                    val?.length <= 11 ||
                    'Invalid SWIFT Code or ABA Number length',
                  correctFormat: (val) => {
                    const regex = new RegExp(/^[A-Z0-9-]{8,11}$/g);

                    return (
                      regex.test(val) ||
                      'Invalid SWIFT Code or ABA Number format'
                    );
                  },
                },
              })}
            />
          </FormGroup>
        </div>

        <div className="col-md-12">
          <FormGroup
            required
            label="Address 1"
            errors={errors?.beneficiary_address1}
          >
            <Input
              name="beneficiary_address1"
              ref={register({ ...alphaNumericCustomPunctOnly, required: true })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-12">
          <FormGroup label="Address 2" errors={errors?.beneficiary_address2}>
            <Input
              name="beneficiary_address2"
              ref={register({
                ...alphaNumericCustomPunctOnly,
                required: false,
              })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup required label="City" errors={errors?.beneficiary_city}>
            <Input
              name="beneficiary_city"
              ref={register({ ...alphaNumericCustomPunctOnly, required: true })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup
            required
            label="Zip code/Post code"
            errors={errors?.beneficiary_zip}
          >
            <Input
              name="beneficiary_zip"
              ref={register({ ...alphaNumericCustomPunctOnly, required: true })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup
            label="Country"
            errors={errors?.beneficiary_country}
            required
          >
            <Controller
              name="beneficiary_country"
              control={control}
              rules={{ ...alphaNumericCustomPunctOnly, required: true }}
              defaultValue=""
              render={(props) => (
                <CountrySelect
                  value={props.value}
                  onChange={props.onChange}
                  valueAs="name"
                  defaultCountries={svbCountries}
                />
              )}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup label="State" errors={errors?.beneficiary_state}>
            <Input
              name="beneficiary_state"
              ref={register({
                ...alphaNumericCustomPunctOnly,
                required: false,
              })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-12">
          <FormGroup required label="Bank Name" errors={errors?.bank_name}>
            <Input
              name="bank_name"
              ref={register({ ...alphaNumericCustomPunctOnly, required: true })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-12">
          <FormGroup
            required
            label="Bank Address 1"
            errors={errors?.bank_address1}
          >
            <Input
              name="bank_address1"
              ref={register({ ...alphaNumericCustomPunctOnly, required: true })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-12">
          <FormGroup label="Bank Address 2" errors={errors?.bank_address2}>
            <Input
              name="bank_address2"
              ref={register({
                ...alphaNumericCustomPunctOnly,
                required: false,
              })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup required label="Bank City" errors={errors?.bank_city}>
            <Input
              name="bank_city"
              ref={register({ ...alphaNumericCustomPunctOnly, required: true })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup label="Bank Zip code/Post code" errors={errors?.bank_zip}>
            <Input
              name="bank_zip"
              ref={register({
                ...alphaNumericCustomPunctOnly,
                required: false,
              })}
              maxLength={35}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup
            label="Bank Country"
            errors={errors?.bank_country}
            required
          >
            <Controller
              name="bank_country"
              control={control}
              rules={{ ...alphaNumericCustomPunctOnly, required: true }}
              defaultValue=""
              render={(props) => (
                <CountrySelect
                  value={props.value}
                  onChange={props.onChange}
                  valueAs="name"
                  defaultCountries={svbCountries}
                />
              )}
            />
          </FormGroup>
        </div>

        <div className="col-md-6">
          <FormGroup label="Bank State" errors={errors?.bank_state}>
            <Input
              name="bank_state"
              ref={register({
                ...alphaNumericCustomPunctOnly,
                required: false,
              })}
              maxLength={35}
            />
          </FormGroup>
        </div>
        <div className="col-md-12 d-flex justify-content-end">
          <Button
            theme="link"
            className="text-primary text-decoration-none border border-primary"
            loading={loading}
            onClick={handleSubmit(submit)}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};
