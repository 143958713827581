import { Button, ButtonTheme } from '../button';
import { Modal } from '../modal';

export const ConfirmModal = ({
  close,
  cancel,
  theme = 'primary',
  title,
  message,
  cancelBtn,
  confirmBtn,
}: {
  close: () => any;
  cancel: () => any;
  theme?: ButtonTheme;
  title: React.ReactNode;
  message: React.ReactNode;
  cancelBtn: React.ReactNode;
  confirmBtn: React.ReactNode;
}) => {
  return (
    <Modal
      onClose={cancel}
      title={title}
      footer={
        <>
          {!!cancelBtn && (
            <Button onClick={cancel} theme="secondary" outline>
              {cancelBtn}
            </Button>
          )}
          {!!confirmBtn && (
            <Button theme={theme} onClick={close}>
              {confirmBtn}
            </Button>
          )}
        </>
      }
    >
      {message}
    </Modal>
  );
};
