import React from 'react';
import { ApiService } from '../../shared/services';
import {
  CommonData,
  CommonDataService,
  PublicCommonData,
} from '../../shared/services/common-data';
import { useService } from '../../shared/services/use-service';

type Country = {
  id: number;
  country_name: string;
  states: { id: number; state_name: string }[];
};

export interface Role {
  id: number;
  description: string;
  name: string;
}

export interface ContractType {
  created_at: string;
  id: number;
  invoicing_time_frames: {
    created_at: string;
    id: number;
    name: string;
    pivot: { contract_type_id: number; time_frame_id: number };
    contract_type_id: number;
    time_frame_id: number;
    updated_at?: string;
  }[];
  name: string;
  rate_time_frames: {
    created_at: string;
    id: number;
    name: string;
    pivot: { contract_type_id: number; time_frame_id: number };
    updated_at?: string;
  }[];
  updated_at?: string;
}

export interface Currency {
  created_at?: string;
  id: number;
  iso: string;
  name: string;
  updated_at?: string;
  symbol: string;
}

export interface SignatureType {
  id: number;
  created_at: string;
  name: string;
  updated_at?: string;
}

export interface ComplianceTypeModel {
  id: number;
  key: string;
  name: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
}

interface InvoiceItemType {
  created_at: string;
  description: string;
  id: number;
  name: string;
  updated_at: string;
}

export interface CommonDataResponse {
  company_types: { id: number; name: string }[];
  invoice_items_types: InvoiceItemType[];
  roles: Role[];
  contract_types: ContractType[];
  signature_types: SignatureType[];
  global_roles: {
    created_at: string;
    description?: string;
    id: number;
    name: string;
    updated_at?: string;
  }[];
}

export interface PublicCommonDataResponse {
  countries: Country[];
  timezones: { [timezone: string]: string };
  currencies: Currency[];
  global_roles: {
    created_at: string;
    description?: string;
    id: number;
    name: string;
    updated_at?: string;
  }[];
}

export interface PaymentMethodResponse {
  brand: string;
  customer_id: string;
  funding: string;
  id: string;
  last4: string;
  created_at: string;
  country: string;
  exp_month: number;
  exp_year: number;
}

export function useApi() {
  const apiService = useService(ApiService);

  return apiService;
}

export function useCommonData() {
  const commonDataService = useService(CommonDataService);
  const [commonData, setCommonData] = React.useState<
    (Partial<CommonData> & Partial<PublicCommonData>) | undefined
  >();

  React.useEffect(() => {
    commonDataService.get().then((data) => setCommonData(data));
  }, [commonDataService]);

  return commonData;
}
