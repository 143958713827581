import React, { useState } from 'react';
import { Link, Redirect, RouteProps, useHistory } from 'react-router-dom';
import { AuthPageLayout, FormGroup, Input } from '../../components';
import { Button } from '../../components/button';
import { useAuth } from '../../hooks';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import { FORGOT_PASSWORD_PATH } from '../forgot-password';
import { useToaster } from '../../hooks/use-toaster';
import { REGISTER_PATH } from '../register';
import { HOME_PATH } from '../home';
import Storage from '../../shared/db/storage';
import './index.scss';

export const LOGIN_PATH = '/login';

export const LOGIN_ROUTE: RouteProps & { protect: boolean } = {
  protect: false,
  path: LOGIN_PATH,
  render: (props) => {
    const queryParams = queryString.parse(props?.location?.search);
    const email = (queryParams?.email as string) || '';
    return <Login email={email} />;
  },
};

export const Login = ({ email }: { email?: string }) => {
  const auth = useAuth();
  const toaster = useToaster();
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = ({
    email: username,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    const redirectTo = Storage.getItem('redirectTo') || HOME_PATH;

    auth
      .login({ username, password })
      .then(() => {
        history.replace(redirectTo);
      })
      .catch(() => {
        setLoading(false);
        toaster.danger({ message: 'Invalid username or password.' });
      });
  };

  // Wait for the login request to be resolved
  if (auth?.isAuthenticated && !loading) {
    return <Redirect to="/" />;
  }

  return (
    <AuthPageLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="pg-AuthTabs">
          <li className="pg-AuthTabs__Item">
            <span
              className="pg-AuthTabs__ItemLink pg-AuthTabs__ItemLink--active"
              aria-current="page"
            >
              Login
            </span>
          </li>
          <li className="pg-AuthTabs__Item">
            <Link to={REGISTER_PATH} className="pg-AuthTabs__ItemLink">
              Register
            </Link>
          </li>
        </ul>
        <FormGroup label="Email" labelFor="email" errors={errors.email}>
          <Input
            type="text"
            id="email"
            name="email"
            defaultValue={email}
            placeholder="example@domain.com"
            ref={register({ required: true })}
          />
        </FormGroup>
        <FormGroup
          label="Password"
          labelFor="password"
          errors={errors.password}
        >
          <Input
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            ref={register({ required: true })}
          />
        </FormGroup>
        <div className="pg-Login__Actions">
          <Link to={FORGOT_PASSWORD_PATH}>Forgot password?</Link>
          <Button loading={loading} type="submit">
            Login
          </Button>
        </div>
      </form>
    </AuthPageLayout>
  );
};
