import React from 'react';
import './index.scss';

export const ObscuredContent = ({
  placeholder = 'Click here to show',
  children,
}: {
  placeholder?: string;
  children: any;
}) => {
  const [show, setShow] = React.useState(false);

  return (
    <p className="secret m-0">
      <span className={show ? '' : 'obscure'}>{children}</span>
      <span
        className={show ? 'd-none' : 'show_link'}
        onClick={() => setShow((prev) => !prev)}
      >
        {placeholder}
      </span>
    </p>
  );
};
