import { useState } from '@hookstate/core';
import { CompanyService } from '../../shared/services/company';
import { useService } from '../../shared/services/use-service';

export function useCompany() {
  const companyService = useService(CompanyService);
  const state = useState(companyService.state);

  return {
    companies: state.value.companies,
    selectedCompany: state.value.selected,
    selectCompany: companyService.select,
  };
}
