import {
  container as tsyringeContainer,
  DependencyContainer,
  InjectionToken,
} from 'tsyringe';
import { ApiService } from './api';
import { AuthService } from './auth';
import { CommonDataService } from './common-data';
import { CompanyService } from './company';
import { EnvService } from './env';
import { ProfileService } from './profile';
import { TokenService } from './token';

export type Services =
  | AuthService
  | ProfileService
  | CompanyService
  | EnvService
  | ApiService
  | TokenService
  | CommonDataService;

export const container: Omit<DependencyContainer, 'resolve'> & {
  resolve: <T extends Services>(token: InjectionToken<T>) => T;
} = {
  ...tsyringeContainer,
  resolve: (token) => {
    return tsyringeContainer.resolve(token);
  },
};
