import React from 'react';
import { Role } from '../../constants';
import { CompanyMustBeOnboarded } from '../../middlewares';
import { RouteConfigItem } from '../../routes';
import { ContractWrapper, ContractProvider } from '../contract-wrapper';

export const CREATE_CONTRACT_PATH = '/create-contract';

export const CREATE_CONTRACT_ROUTE: RouteConfigItem = {
  path: `${CREATE_CONTRACT_PATH}/:step?`,
  permissions: ['write.contracts'],
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  middlewares: [CompanyMustBeOnboarded],
  render: ({
    match: {
      params: { step },
    },
  }: any) => <CreateContract step={step} />,
  exact: false,
};

export const CreateContract = ({ step }: { step?: string }) => {
  return (
    <ContractProvider>
      <ContractWrapper step={step} />
    </ContractProvider>
  );
};
