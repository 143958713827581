import React from 'react';
import { useApi } from '../../../hooks/use-api';
import { useCompany } from '../../../hooks/use-company';
import { Box, Button } from '../../../components';
import AchPayment from './AchPayment';
import { useHistory } from 'react-router-dom';
import { PAYMENT_HISTORY_PATH } from '../../payment-history';
import { useToaster } from '../../../hooks/use-toaster';
import { PaymentMethod } from '../../../constants';
import { StripeBankPaymentMethod } from '../../../interfaces';

const ListSources = ({ ids }: { ids: string[] }) => {
  const api = useApi();
  const { selectedCompany } = useCompany();
  const history = useHistory();
  const toaster = useToaster();
  const [sources, setSources] = React.useState<Array<StripeBankPaymentMethod>>(
    [],
  );
  const [addNew, setAddNew] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const toggleAddNew = () => setAddNew((prev) => !prev);

  const fetchSources = React.useCallback(() => {
    api
      .get(
        `/api/payment/methods?payment_method_type=${PaymentMethod.STRIPE_BANK}&billable_type=company&billable_id=${selectedCompany?.company_id}`,
      )
      .then(({ data: { paymentMethods } }) => {
        setSources(paymentMethods);
      });
  }, [api, selectedCompany?.company_id]);

  const payWithBankAccount = (source: any) => {
    api
      .post(
        `/api/client/company/${selectedCompany?.company_id}/payments/transaction`,
        {
          invoice_id: ids,
          billable_id: selectedCompany?.company_id,
          billable_type: 'company',
          payment_method_type: PaymentMethod.STRIPE_BANK,
          payment_method_id: source.id,
        },
        'json',
      )
      .then(({ data }) => {
        setSuccess(data.message);
        history.push(PAYMENT_HISTORY_PATH);
        toaster.success({ message: data.message });
      })
      .catch(({ errors }) => {
        setError(errors.message || errors[0]);
      });
  };

  React.useEffect(() => {
    fetchSources();
  }, [fetchSources]);

  return (
    <>
      {/* Replace this with dismissable alerts */}
      {error && (
        <p className="error-wrapper" onClick={() => setError(null)}>
          {error}
        </p>
      )}

      {success && (
        <p className="success-wrapper" onClick={() => setSuccess(null)}>
          {success}
        </p>
      )}

      {!addNew && (
        <div className="pg-ach-method-list">
          {sources.map((source) => (
            <Box
              key={source.id}
              bordered
              className="mb-3 px-4 py-2 d-flex justify-content-between align-items-center"
            >
              <div>
                <p className="mb-0 card-num text-dark">
                  <span className="hidden-digits">•••• •••• •••• </span>
                  <span>{source?.details?.last4 || 'XXXX'}</span>
                </p>
                <p className="text-secondary mb-0">
                  <small>{source?.details?.bank_name || ''}</small>
                </p>
              </div>

              <Button onClick={() => payWithBankAccount(source)}>Pay</Button>
            </Box>
          ))}
        </div>
      )}

      {addNew && <AchPayment ids={ids} />}

      <div className="d-flex p-4  justify-content-center align-items-center">
        {addNew && (
          <Button theme="warning" onClick={toggleAddNew}>
            Cancel
          </Button>
        )}
        {!addNew && (
          <Button onClick={toggleAddNew}>Pay with new account</Button>
        )}
      </div>
    </>
  );
};

export default ListSources;
