import classNames from 'classnames';
import './index.scss';

export const LayoutContent = ({
  className,
  children,
  size = 'small',
  ...props
}: Omit<React.HTMLProps<HTMLDivElement>, 'size'> & {
  size?: 'small' | 'large';
}) => (
  <div className="pg-LayoutContent">
    <div
      className={classNames('row justify-content-center', className)}
      {...props}
    >
      <div>{children}</div>
    </div>
  </div>
);
