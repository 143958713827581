import { CommonDataDisplay, Tag, TagProps } from '..';

export const ContractTypeTag = ({
  typeId,
  ...props
}: { typeId: number } & TagProps) => (
  <CommonDataDisplay
    type="contract_types"
    id={typeId}
    render={(value) => (!!value ? <Tag {...props}>{value}</Tag> : <></>)}
  />
);
