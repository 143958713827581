import { Tooltip2 } from '@blueprintjs/popover2';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonProps, Button } from '../button';
import './index.scss';

export const Action = ({
  tooltip,
  to,
  children,
  className,
  ...props
}: ButtonProps & { tooltip?: string; to?: LinkProps['to'] }) => {
  let button = (
    <Button
      className={classNames(className, 'pg-Action')}
      theme="link"
      {...props}
    >
      {children}
    </Button>
  );

  if (to) {
    button = (
      <Link className={classNames(className, 'pg-Action btn btn-link')} to={to}>
        {children}
      </Link>
    );
  }

  return tooltip ? (
    <Tooltip2 placement="top" content={tooltip}>
      {button}
    </Tooltip2>
  ) : (
    button
  );
};
