import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { formatDistance } from 'date-fns';
import { Popover2 } from '@blueprintjs/popover2';
import { IoEllipsisHorizontalSharp } from 'react-icons/io5';
import { Menu, MenuItem, Position } from '@blueprintjs/core';
import { useApi } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import { Action, Loader, Modal, Table, Td, Th, Tr } from '../../../components';

export const IdentityDocumentsList = ({
  docs,
  reload,
}: {
  docs: any[];
  reload: () => void;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  const previewFile = async (e: any, id: number) => {
    e.preventDefault();

    setIsLoading(true);

    // Check if the previous URL (if any) is valid or expired
    // If the url is still valid, use it
    // Otherwise, generate a new link
    const { data } = await api.get(`/api/verify/files/${id}`);

    setPreviewImage(data.url);

    setIsLoading(false);
  };

  const resetPreview = () => {
    setPreviewImage(null);
  };

  const redactFile = (id: any) => {
    return api.delete(`/api/verify/${id}/redact`).then(({ data }) => {
      reload();

      toaster.success({
        timeout: 10000,
        message: data.message,
      });
    });
  };

  if (isLoading) return <Loader fullscreen />;

  return (
    <>
      <h6 className="pg-ComplianceSection__Title">
        Identity Verification Documents
      </h6>

      <div className="pg-ComplianceSection__Content">
        {isEmpty(docs) ? (
          <p className="pg-ComplianceSection__Content__Empty">
            No documents added yet
          </p>
        ) : (
          <Table>
            <thead>
              <Tr>
                <Th>Name</Th>
                <Th>Verified</Th>
                <Th isAction></Th>
              </Tr>
            </thead>
            <tbody>
              {docs.map((item: any) => (
                <Tr key={item.file_id}>
                  <Td>
                    <Link
                      to={'#'}
                      onClick={(e) => previewFile(e, item.file_id)}
                    >
                      {item.file_name}.{item.ext}
                    </Link>
                  </Td>

                  <Td title={new Date(item.created_at).toString()}>
                    {formatDistance(new Date(item.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </Td>

                  <Td isAction>
                    <Popover2
                      minimal
                      position={Position.BOTTOM_RIGHT}
                      transitionDuration={-1}
                      content={
                        <Menu>
                          <MenuItem
                            text="Remove"
                            onClick={() => redactFile(item.verification_id)}
                          ></MenuItem>
                        </Menu>
                      }
                    >
                      <Action>
                        <IoEllipsisHorizontalSharp />
                      </Action>
                    </Popover2>
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>

      {previewImage && (
        <Modal
          title="Verification Document"
          size="lg"
          showClose
          onClose={resetPreview}
          closeOnBackdropClick
        >
          <img
            alt="Identity verification document"
            src={previewImage}
            className="d-block img-thumbnail"
            style={{
              margin: 'auto',
            }}
          />
        </Modal>
      )}
    </>
  );
};
