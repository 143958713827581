import React from 'react';
import { isEmpty, isNil } from 'lodash';
import { IoAdd } from 'react-icons/io5';
import { useApi, useCompany } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';
import { Button, ErrorList, Loader } from '..';
import { EmailRole, InviteMember } from '../invite-member';

export const TeamMembers = ({
  onNext,
  onSkip,
  nextText = 'Invite',
  cancelText = 'Cancel',
}: {
  nextText?: string;
  cancelText?: string;
  onNext: () => void;
  onSkip: () => void;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const { selectedCompany } = useCompany();
  const [loading, setLoading] = React.useState(false);
  const [emails, setEmails] = React.useState<EmailRole[]>([
    {
      id: 1,
      email: '',
      title: '',
      role: null,
    },
  ]);

  const hasInvalidEmails = React.useMemo(
    () =>
      !isEmpty(
        emails.filter(
          (e) =>
            isNil(e.email) ||
            isEmpty(e.email) ||
            isNil(e.title) ||
            isEmpty(e.title) ||
            isNil(e.role),
        ),
      ),
    [emails],
  );

  const addNewEmail = () => {
    setEmails((prev: EmailRole[]) => [
      ...prev,
      {
        id: (prev.length || 0) + 1,
        email: '',
        title: '',
        role: null,
      },
    ]);
  };

  const updateEmail = (id: string | number, key: string, value: any) => {
    const updatedEmails = emails.map((e) => {
      if (e.id !== id) {
        return e;
      }

      return {
        ...e,
        [key]: value,
      };
    });

    setEmails(updatedEmails);
  };

  const removeEmail = (id: string | number) => {
    const updatedList = emails.filter((e) => e.id !== id);

    setEmails((prev: EmailRole[]) =>
      updatedList.map((e, i) => ({ ...e, id: i + 1 })),
    );
  };

  const sendInvite = () => {
    setLoading(true);

    api
      .post(
        `/api/client/company/${selectedCompany?.company_id}/team`,
        { emails },
        'json',
      )
      .then(() => {
        onNext();
      })
      .catch(({ errors }) => {
        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      })
      .finally(() => setLoading(false));
  };

  return loading ? (
    <Loader fullscreen />
  ) : (
    <>
      <div className="border-bottom border-light pb-3">
        <p className="text-secondary">
          Enter the email addresses of the users you'd like to invite, and
          choose the role they should have.
        </p>

        {emails.map(({ id, email, title, role }: EmailRole) => (
          <InviteMember
            key={id}
            id={id}
            email={email}
            title={title}
            role={role}
            onChange={updateEmail}
            canDelete={emails?.length > 1}
            onRemove={removeEmail}
          />
        ))}

        <Button
          theme="success"
          className="mt-3"
          leftContent={<IoAdd />}
          onClick={addNewEmail}
          outline
        >
          Add Member
        </Button>
      </div>

      <div className="d-flex justify-content-end pt-3">
        <Button outline theme="secondary" className="px-4" onClick={onSkip}>
          {cancelText}
        </Button>
        <Button
          theme="primary"
          type="submit"
          className="px-4"
          onClick={sendInvite}
          disabled={hasInvalidEmails}
        >
          {nextText}
        </Button>
      </div>
    </>
  );
};
