import React from 'react';
import { Role } from '../../constants';
import { ClientContracts } from './client-contracts';
import { RouteConfigItem } from '../../routes';
import { useProfileService } from '../../shared/services';
import { ContractorContracts } from './contractor-contracts';
import { CompanyMustBeOnboarded } from '../../middlewares';

export interface Signer {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
  name: string;
}

export interface Contract {
  clientSignDateTime?: string;
  clientSigner?: Signer;
  contract_type_id: number;
  contractorSignDateTime?: string;
  contractorSigner?: Signer;
  currency_id: number;
  id: any;
  invitedContractor?: string;
  invoicing_time_frame_id: number;
  name: string;
  rate: number;
  rate_time_frame_id: number;
  start_date: string;
  scope_of_work: string;
  first_payment_date: string;
  termination_date?: string;
  days_of_notice: number;
  special_clause: string;
  client_can_submit: boolean;
  contractor_can_submit: boolean;
  use_own: boolean;
  contractor_country_id: number;
  contractor_require_documents: boolean;
  contractor_require_id_check: boolean;
  additionalFiles: string[];
  userContractFiles: string[];
  company_id: string;
  company_name: string;
  status: string;
  is_editable: boolean;
}

export const CONTRACTS_PATH = '/contracts';

export const CONTRACTS_ROUTE: RouteConfigItem = {
  path: `${CONTRACTS_PATH}`,
  permissions: ['read.contracts'],
  middlewares: [CompanyMustBeOnboarded],
  render: () => <ContractsRoot />,
};

const ContractsRoot = () => {
  const { accountType } = useProfileService();

  return accountType === Role.CONTRACTOR ? (
    <ContractorContracts />
  ) : (
    <ClientContracts />
  );
};
