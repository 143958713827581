import { useRef } from 'react';
import { useDetectOutsideClick } from '../../hooks/use-click-outside';

export const Dropdown = ({
  children,
  target,
  direction = 'down',
  className,
}: {
  children: (close: () => any) => React.ReactNode;
  target: React.ReactNode;
  direction?: 'up' | 'down';
  className?: string;
}) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const onClick = () => setIsActive(!isActive);

  return (
    <div className={`pg-Dropdown drop${direction} ${className || ''}`}>
      <span className="pg-Dropdown__Target" onClick={onClick}>
        {target}
      </span>
      <div
        className={`pg-Dropdown__Content dropdown-menu  ${
          isActive ? 'show' : ''
        }`}
        ref={dropdownRef}
      >
        {children(() => setIsActive(false))}
      </div>
    </div>
  );
};
