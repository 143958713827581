import classNames from 'classnames';
import './header.scss';

export const OnboardingHeader = ({
  children,
  subtitle,
  size = 'large',
}: {
  children: React.ReactNode;
  subtitle?: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
}) => (
  <div
    className={classNames(
      'pg-OnboardingHeader',
      `pg-OnboardingHeader--${size}`,
    )}
  >
    <div className="pg-OnboardingHeader__Title">{children}</div>
    <div className="pg-OnboardingHeader__Subtitle">{subtitle}</div>
  </div>
);
