import React from 'react';
import { RouteProps, useHistory, useLocation } from 'react-router-dom';
import { AuthPageLayout, FormGroup, Input } from '../../components';
import { Button } from '../../components/button';
import { useAuth } from '../../hooks';
import { useForm } from 'react-hook-form';
import { email, match, maxLength, password } from '../../shared';
import { useApi } from '../../hooks/use-api';
import queryString from 'query-string';
import { useToaster } from '../../hooks/use-toaster';
import { HOME_PATH } from '../home';

export const RESET_PASSWORD_PATH = '/reset-password';

export const RESET_PASSWORD_ROUTE: RouteProps & { protect: boolean } = {
  protect: false,
  path: RESET_PASSWORD_PATH,
  render: () => <ResetPassword />,
};

export const ResetPassword = () => {
  const auth = useAuth();
  const api = useApi();
  const toaster = useToaster();
  const location = useLocation();
  const history = useHistory();
  const { register, handleSubmit, watch, errors } = useForm();

  const passwordField = watch('password');

  React.useEffect(() => {
    if (auth?.isAuthenticated) {
      history.replace(HOME_PATH);
    }
  }, [auth?.isAuthenticated, history]);

  const onSubmit = (data: {
    email: string;
    password: string;
    passwordConfirmation: string;
    firstName: string;
    lastName: string;
  }) => {
    const { token } = queryString.parse(location.search);
    api
      .post('/api/reset-password', api.createPayload({ ...data, token }))
      .then(() => {
        return auth
          .login({ username: data.email, password: data.password })
          .then(() => {
            toaster.success({
              message: 'Password was updated successfully.',
            });
            history.replace(HOME_PATH);
          });
      })
      .catch(() => {
        toaster.danger({ message: 'An error occured while processing your request.' });
      });
  };

  return (
    <AuthPageLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup
          label="Email"
          labelFor="email"
          errors={errors.email}
          required
        >
          <Input
            type="text"
            id="email"
            name="email"
            placeholder="example@domain.com"
            ref={register({
              required: true,
              maxLength: maxLength(255, 'Email'),
              validate: email,
            })}
          />
        </FormGroup>
        <FormGroup
          label="Password"
          labelFor="password"
          errors={errors.password}
          required
        >
          <Input
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            ref={register({ ...password, required: true })}
          />
        </FormGroup>
        <FormGroup
          label="Confirm password"
          labelFor="passwordConfirmation"
          errors={errors.passwordConfirmation}
          required
        >
          <Input
            type="password"
            id="passwordConfirmation"
            name="passwordConfirmation"
            placeholder="Confirm password"
            ref={register({
              required: true,
              validate: (value) =>
                match(value, passwordField, 'The passwords do not match'),
            })}
          />
        </FormGroup>
        <div className="d-flex justify-content-end">
          <Button type="submit">Reset password</Button>
        </div>
      </form>
    </AuthPageLayout>
  );
};
