import React from 'react';
import { useApi } from '../../../hooks/use-api';
import { useCompany } from '../../../hooks/use-company';
import { PlaidButton } from '../../../components/plaid-button';
import { Box } from '../../../components';
import { PlaidAccount } from 'react-plaid-link';
import { useHistory } from 'react-router-dom';
import { useToaster } from '../../../hooks/use-toaster';
import { PAYMENT_METHODS_PATH } from '../..';

const AddAchMethod = ({
  onSuccess,
}: {
  onSuccess: (accountToCharge: PlaidAccount) => void;
}) => {
  const api = useApi();
  const { selectedCompany } = useCompany();
  const history = useHistory();
  const toaster = useToaster();
  const [success, setSuccess] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const storeBankAccount = (token: string) => {
    return api
      .post(
        `/api/payment/stripe/create-bank-account`,
        {
          stripe_token: token,
          billable_type: 'company',
          billable_id: selectedCompany.company_id,
        },
        'json',
      )
      .then(({ data }) => {
        history.push(PAYMENT_METHODS_PATH);
        toaster.success({ message: data.message });
        setSuccess(data.message);
      })
      .catch(({ errors }) => {
        setError(errors.message);
      });
  };

  const onSuccessCallback = async (
    token: string,
    accountToCharge: PlaidAccount,
  ) => {
    storeBankAccount(token);
    onSuccess(accountToCharge);
  };

  return (
    <>
      {error && <p className="error-wrapper">{error}</p>}

      {success && <p className="success-wrapper">{success}</p>}

      <Box>
        <p>To proceed, please select an option below.</p>

        <PlaidButton onSuccessCallback={onSuccessCallback} />
      </Box>
    </>
  );
};

export default AddAchMethod;
