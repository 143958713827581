export const Table = ({
  children,
  borderless,
}: {
  children: React.ReactNode;
  borderless?: boolean;
}) => {
  return (
    <table
      className={`table ${borderless ? 'table-borderless' : 'table-hover'}`}
    >
      {children}
    </table>
  );
};

export * from './table-row';
export * from './table-header';
export * from './table-cell';
