import React from 'react';
import ReactSelect from 'react-select';
import { CommonDataService } from '../../shared/services/common-data';
import { useService } from '../../shared/services/use-service';
import { InputPlaceholder } from '../input-placeholder';

export const CompanyTypeSelect = ({
  value,
  onChange,
  readOnly,
}: {
  value?: number;
  onChange?: (value?: number) => any;
  readOnly?: boolean;
}) => {
  const commonData = useService(CommonDataService);
  const [companyTypes, setCompanyTypes] = React.useState<
    { label: string; value: number }[]
  >([]);

  React.useEffect(() => {
    commonData.get().then(({ company_types }) => {
      setCompanyTypes(
        company_types?.map(({ id, name }) => ({
          label: name,
          value: id,
        })) || [],
      );
    });
  }, [commonData]);

  return !readOnly ? (
    <ReactSelect
      isClearable={false}
      options={companyTypes}
      value={companyTypes.find((companyType) => companyType.value === value)}
      onChange={(selected) => onChange?.(selected?.value)}
    />
  ) : (
    <InputPlaceholder>
      {companyTypes.find((companyType) => companyType.value === value)?.label ||
        'N/A'}
    </InputPlaceholder>
  );
};
