import React from 'react';
import { head } from 'lodash';
import { useForm } from 'react-hook-form';
import { useApi } from '../../../hooks';
import { PaymentMethod } from '../../../constants';
import { useToaster } from '../../../hooks/use-toaster';
import { useProfileService } from '../../../shared/services';
import { Button, FormGroup, Input } from '../../../components';

export const PayoneerNewAccount = ({ refresh }: { refresh: () => any }) => {
  const api = useApi();
  const toaster = useToaster();
  const { profile } = useProfileService();
  const { watch, errors, register, handleSubmit, setError } = useForm();
  const [loading, setLoading] = React.useState(false);
  const formData = watch();

  const submit = async () => {
    setLoading(true);

    await api
      .post(
        '/api/payment/methods',
        {
          payment_method_type: PaymentMethod.PAYONEER,
          billable_id: profile?.id,
          billable_type: 'user',
          email: formData?.email,
          primary: true,
        },
        'json',
      )

      .then(() => {
        toaster.success({ message: 'Payoneer account added.' });

        window.scrollTo(0, 0);

        refresh();
      })
      .catch(({ errors }) => {
        if (errors) {
          Object.keys(errors).map((key) =>
            setError(key, {
              type: 'manual',
              message: head(errors[key]) || ' is invalid',
            }),
          );
        }

        toaster.danger({
          message: 'There was an error while adding the Payoneer account.',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="row">
      <div className="col-md-12 mt-3">
        <FormGroup required label="Email" errors={errors?.email}>
          <Input name="email" ref={register({ required: true })} />
        </FormGroup>
      </div>
      <div className="col-md-12 d-flex justify-content-end">
        <Button
          theme="link"
          className="text-primary text-decoration-none border border-primary"
          loading={loading}
          onClick={handleSubmit(submit)}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
