import { head } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  CompanyTypeSelect,
  CountrySelect,
  StateSelect,
  DatePicker,
  FormGroup,
  Input,
  useStates,
  Button,
  Text,
} from '../../../components';
import { useApi } from '../../../hooks';
import { ContractorType } from '../../../constants';
import { useToaster } from '../../../hooks/use-toaster';
import { email } from '../../../shared';
import { OnboardingActions } from '../components/actions';
import { OnboardingFormGroup } from '../components/form-group';

export const EntityDetails = ({
  actions,
  onNext,
  onPrev,
}: {
  actions?: React.ReactNode;
  onNext?: () => any;
  onPrev?: () => any;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const {
    setError,
    register,
    errors,
    control,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
  } = useFormContext();
  const companyCertified = watch('company.certified');
  const companyOfficialTitle = watch('company.official_title');
  const companyCountryId = watch('company.country_id');
  const individualCountryId = watch('individual.country_id');
  const type = watch('entityType');
  const value = watch();
  const states = useStates(companyCountryId);
  const individualStates = useStates(individualCountryId);
  const [individualEmail, setIndividualEmail] = useState('');
  const [userProfileLoading, setUserProfileLoading] = useState(true);

  const onSubmit = async () => {
    try {
      await api
        .patch('/api/profile', api.createPayload(value.individual))
        .catch((e) => {
          if (e?.errors) {
            Object.keys(e?.errors).map((key) =>
              setError(`individual.${key}`, {
                type: 'manual',
                message: head(e?.errors[key]) || ' is invalid',
              }),
            );
          }

          throw e;
        });
      if (type === ContractorType.ENTITY) {
        await api
          .post('/api/contractor/company', api.createPayload(value.company))
          .catch((e) => {
            if (e?.errors) {
              Object.keys(e?.errors).map((key) =>
                setError(`company.${key}`, {
                  type: 'manual',
                  message: head(e?.errors[key]) || ' is invalid',
                }),
              );
            }

            throw e;
          });
      }
      onNext?.();
    } catch ({ errors }) {
      toaster.danger({ message: 'Something went wrong' });
    }
  };

  useEffect(() => {
    clearErrors();
  }, [clearErrors, type]);

  useEffect(() => {
    api.get(`/api/profile`).then(({ data: { user } }) => {
      const legalEntity: any = head(user?.entities);

      setIndividualEmail(user?.email || '');

      setValue('company', {
        name: legalEntity?.name,
        notice_email: legalEntity?.notice_email,
        registration_number: legalEntity?.registration_number,
        vat_id: legalEntity?.vat_id,
        company_type_id: legalEntity?.company_type_id,
        country_id: legalEntity?.country_id,
        incorporation_state_id: legalEntity?.incorporation_state_id,
        street: legalEntity?.street,
        city: legalEntity?.city,
        state_id: legalEntity?.state_id,
        zip_code: legalEntity?.zip_code,
        official_title: legalEntity?.official_title,
        certified: false,
      });

      setValue('individual', {
        firstName: user?.first_name,
        lastName: user?.last_name,
        email: user?.email,
        country_id: user?.country_id,
        timezone_id: user?.timezone_id,
        date_of_birth: user?.date_of_birth
          ? new Date(user?.date_of_birth)
          : null,
        phoneNumber: user?.phone_number,
        city: user?.city,
        state_id: user?.state_id,
        street: user?.street,
        zip_code: user?.zip_code,
      });
      setUserProfileLoading(false);
    });
  }, [api, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {type === ContractorType.ENTITY && (
        <OnboardingFormGroup title="Add your Company details">
          <div className="row">
            <div className="col-md-12">
              <FormGroup
                label="Legal company name"
                labelFor="name"
                errors={errors?.company?.name}
                required
              >
                <Input
                  type="text"
                  id="name"
                  name="company.name"
                  ref={register({ required: true })}
                />
              </FormGroup>
            </div>
            <div className="col-md-12">
              <FormGroup
                label="Notice email"
                labelFor="notice_email"
                errors={errors?.company?.notice_email}
                required
              >
                <Input
                  type="text"
                  id="notice_email"
                  name="company.notice_email"
                  ref={register({ required: true, validate: email })}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup
                label="Registration number"
                labelFor="registration_number"
                errors={errors?.company?.registration_number}
              >
                <Input
                  type="text"
                  id="registration_number"
                  name="company.registration_number"
                  ref={register({ required: false })}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup
                label="VAT ID"
                labelFor="vat_id"
                errors={errors?.company?.vat_id}
              >
                <Input
                  type="text"
                  id="vat_id"
                  name="company.vat_id"
                  ref={register({ required: false })}
                />
              </FormGroup>
            </div>

            <div className="col-md-6">
              <FormGroup
                label="Entity type"
                labelFor="company_type_id"
                errors={errors?.company?.company_type_id}
                required
              >
                <Controller
                  name="company.company_type_id"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={(props) => (
                    <CompanyTypeSelect
                      value={props.value}
                      onChange={props.onChange}
                    />
                  )}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup
                label="Country"
                labelFor="country_id"
                errors={errors?.company?.country_id}
                required
              >
                <Controller
                  name="company.country_id"
                  control={control}
                  rules={{ required: true }}
                  defaultValue=""
                  render={(props) => (
                    <CountrySelect
                      value={props.value}
                      onChange={props.onChange}
                    />
                  )}
                />
              </FormGroup>
            </div>

            <div className="col-md-6" hidden={!states?.length}>
              <FormGroup
                label="State of Incorporation"
                labelFor="incorporation_state_id "
                errors={errors?.company?.incorporation_state_id}
                required
              >
                <Controller
                  name="company.incorporation_state_id"
                  control={control}
                  rules={{ required: !!states.length }}
                  defaultValue=""
                  render={(props) => (
                    <StateSelect
                      countryId={companyCountryId}
                      value={props.value}
                      onChange={props.onChange}
                    />
                  )}
                />
              </FormGroup>
            </div>

            <div className="col-md-6">
              <FormGroup
                label="City"
                labelFor="city"
                errors={errors?.company?.city}
                required
              >
                <Input
                  type="text"
                  id="city"
                  name="company.city"
                  ref={register({ required: true })}
                />
              </FormGroup>
            </div>

            <div className="col-md-6">
              <FormGroup
                label="Street"
                labelFor="street"
                errors={errors?.company?.street}
                required
              >
                <Input
                  type="text"
                  id="street"
                  name="company.street"
                  ref={register({ required: true })}
                />
              </FormGroup>
            </div>

            <div className="col-md-6" hidden={!states?.length}>
              <FormGroup label="State" labelFor="state_id">
                <Controller
                  name="company.state_id"
                  control={control}
                  defaultValue=""
                  render={(props) => (
                    <StateSelect
                      countryId={companyCountryId}
                      value={props.value}
                      onChange={props.onChange}
                    />
                  )}
                />
              </FormGroup>
            </div>

            <div className="col-md-6">
              <FormGroup
                label="Zip code/Post code"
                labelFor="zip_code"
                errors={errors?.company?.zip_code}
                required
              >
                <Input
                  type="text"
                  id="zip_code"
                  name="company.zip_code"
                  ref={register({ required: true })}
                />
              </FormGroup>
            </div>
          </div>
        </OnboardingFormGroup>
      )}

      {!userProfileLoading && (
        <OnboardingFormGroup
          title="Add your Personal details"
          actions={
            <OnboardingActions>
              <Button theme="light" outline onClick={onPrev}>
                Back
              </Button>
              <Button
                theme="primary"
                type="submit"
                disabled={
                  type === ContractorType.ENTITY &&
                  (!companyCertified || !companyOfficialTitle)
                }
              >
                Next
              </Button>
            </OnboardingActions>
          }
        >
          <div className="row">
            <div className="col-md-6">
              <FormGroup
                required
                label="First name"
                errors={errors.individual?.firstName}
              >
                <Input
                  name="individual.firstName"
                  ref={register({ required: true })}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup
                required
                label="Last name"
                errors={errors.individual?.lastName}
              >
                <Input
                  name="individual.lastName"
                  ref={register({ required: true })}
                />
              </FormGroup>
            </div>
            <div className="col-md-12">
              <FormGroup label="Email" labelFor="individualEmail">
                <Input
                  type="email"
                  id="individualEmail"
                  name="individual.email"
                  className="border rounded bg-light"
                  readOnly
                  defaultValue={individualEmail}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup
                label="I'm a citizen of"
                labelFor="country"
                errors={errors.individual?.country_id}
                required
              >
                <Controller
                  name="individual.country_id"
                  control={control}
                  defaultValue=""
                  rules={{ required: 'Citizenship country is required' }}
                  render={(props) => (
                    <CountrySelect
                      value={props.value}
                      onChange={props.onChange}
                    />
                  )}
                />
              </FormGroup>
            </div>
            {/* Conditional rendreing will not register the input field */}
            <div className="col-md-6" hidden={!individualStates?.length}>
              <FormGroup label="State" labelFor="stateId">
                <Controller
                  name="individual.state_id"
                  control={control}
                  defaultValue=""
                  render={(props) => (
                    <StateSelect
                      countryId={individualCountryId}
                      value={props.value}
                      onChange={props.onChange}
                    />
                  )}
                />
              </FormGroup>
            </div>

            <div className="col-md-6">
              <FormGroup
                label="City"
                labelFor="individual_city"
                errors={errors?.individual?.city}
                required
              >
                <Input
                  type="text"
                  id="individual_city"
                  name="individual.city"
                  ref={register({ required: true })}
                />
              </FormGroup>
            </div>

            <div className="col-md-6">
              <FormGroup
                label="Street"
                labelFor="street"
                errors={errors.individual?.street}
                required
              >
                <Input
                  type="text"
                  id="street"
                  name="individual.street"
                  ref={register({ required: true })}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup
                label="Zip code/Post code"
                labelFor="zipCode"
                errors={errors.individual?.zip_code}
                required
              >
                <Input
                  type="text"
                  id="zipCode"
                  name="individual.zip_code"
                  ref={register({ required: true })}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup label="Date of birth" labelFor="dateOfBirth">
                <Controller
                  name="individual.date_of_birth"
                  control={control}
                  defaultValue={value?.individual?.date_of_birth}
                  render={(props) => {
                    return (
                      <DatePicker
                        value={props.value}
                        onChange={props.onChange}
                      />
                    );
                  }}
                />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup label="Phone Number" labelFor="phoneNumber">
                <Input
                  type="phone"
                  id="phoneNumber"
                  name="individual.phoneNumber"
                  placeholder="Phone Number"
                  ref={register}
                />
              </FormGroup>
            </div>

            {type === ContractorType.ENTITY && (
              <>
                <div className="my-2">
                  <hr />
                </div>

                <div className="col-12">
                  <FormGroup
                    label="Your official title at the company"
                    labelFor="official_title"
                    required
                  >
                    <Text italic as="p">
                      Note: This should be an executive title, like CEO, General
                      Manager etc.
                    </Text>

                    <Input
                      type="text"
                      id="official_title"
                      name="company.official_title"
                      placeholder="Official Title"
                      ref={register}
                    />
                  </FormGroup>
                </div>

                <div className="col-12">
                  <Controller
                    name="company.certified"
                    control={control}
                    rules={{ required: true }}
                    render={(props: {
                      value: boolean;
                      onChange: (value: number) => any;
                    }) => (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="company_certified"
                          checked={!!props.value}
                          onChange={(e) =>
                            props.onChange(e.target.checked ? 1 : 0)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="company_certified"
                        >
                          I certify that I am an official representative of the
                          company, authorized to sign legal documents.
                        </label>
                      </div>
                    )}
                  />
                </div>
              </>
            )}
          </div>
        </OnboardingFormGroup>
      )}
    </form>
  );
};
