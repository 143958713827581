import React, { useContext } from 'react';
import { FormGroupContext } from '../form-group';
import './index.scss';

export const Input = React.forwardRef(
  (
    {
      className,
      readOnly,
      hasErrors,
      onChange,
      wrapper,
      children,
      ...props
    }: Omit<React.HTMLProps<HTMLInputElement>, 'onChange'> & {
      hasErrors?: boolean;
      onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any;
      wrapper?: boolean;
      children?: React.ReactNode;
    },
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const { hasErrors: hasErrorsContext } = useContext(FormGroupContext);
    if (wrapper) {
      return (
        <div
          className={`pg-Input ${className || ''} ${
            readOnly ? 'form-control-plaintext' : 'form-control'
          } ${hasErrors || hasErrorsContext ? 'is-invalid' : ''}`}
        >
          {children}
        </div>
      );
    }
    return (
      <input
        ref={ref}
        className={`pg-Input ${className || ''} ${
          readOnly ? 'form-control-plaintext' : 'form-control'
        } ${hasErrors || hasErrorsContext ? 'is-invalid' : ''}`}
        readOnly={readOnly}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e)}
        {...props}
      />
    );
  },
);
