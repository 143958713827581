import { addDays, addSeconds } from 'date-fns';
import { singleton } from 'tsyringe';
import Storage from '../db/storage';

export enum TokenStatus {
  VALID,
  REFRESH,
  INVALID,
}

export interface TokenPayload {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

@singleton()
export class TokenService {
  token: string | null = Storage.getItem('token');
  refreshToken: string | null = Storage.getItem('refreshToken');

  set(data: TokenPayload) {
    this.token = data.access_token;
    this.refreshToken = data.refresh_token;

    const now = new Date();
    const refreshAt = addSeconds(
      new Date(),
      data.expires_in - data.expires_in / 6,
    ); // 10 mins before token expiration
    const expiresAt = addSeconds(new Date(), data.expires_in);

    Storage.setItem('token', data.access_token);
    Storage.setItem('refreshToken', data.refresh_token);
    Storage.setItem('refreshTokenExpiresAt', `${addDays(now, 1).getTime()}`);
    Storage.setItem('tokenExpiresAt', `${expiresAt.getTime()}`);
    Storage.setItem('tokenRefreshAt', `${refreshAt.getTime()}`);
    Storage.setItem('tokenReceivedAt', `${now.getTime()}`);
  }

  getState() {
    const refreshAt = Number(Storage.getItem('tokenRefreshAt'));
    const expiresAt = Number(Storage.getItem('tokenExpiresAt'));

    if (!isNaN(refreshAt) && this.token) {
      var now = Date.now();
      if (
        (now >= refreshAt && now < expiresAt) ||
        (this.refreshToken &&
          this.getRefreshTokenExpireTime().getTime() < new Date().getTime())
      ) {
        return TokenStatus.REFRESH;
      }
      if (now > expiresAt) {
        return TokenStatus.INVALID;
      }
      return TokenStatus.VALID;
    }
    return TokenStatus.INVALID;
  }

  clear() {
    Storage.removeItem('token');
    Storage.removeItem('refreshToken');
    Storage.removeItem('tokenExpiresAt');
    Storage.removeItem('tokenRefreshAt');
    Storage.removeItem('tokenReceivedAt');
    Storage.removeItem('accountType');
  }

  getRefreshTime() {
    return new Date(Number(Storage.getItem('tokenRefreshAt')));
  }

  getRefreshTokenExpireTime() {
    return new Date(Number(Storage.getItem('refreshTokenExpiresAt')));
  }
}
