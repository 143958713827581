import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormGroup, Input, Modal } from '..';
import { useApi } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';
import { ErrorList } from '../error-list';
import { InfoTooltip } from '../info-tooltip';
import { WebhookEvent, WebhookEventsList } from '../webhook-events-list';

interface WebhookSubscriber {
  id: number;
  key: string;
  name: string;
  redirect_url: string;
  company_id: number;
  created_at: string;
  updated_at: string;
  events: WebhookEvent[];
}

export const EditWebhookSubscriberModal = ({
  subscriber,
  close,
  refresh,
}: {
  subscriber: WebhookSubscriber;
  close: () => any;
  refresh: () => any;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const { register, control, errors, handleSubmit } = useForm({
    defaultValues: {
      ...subscriber,
      events: subscriber?.events?.map((v: any) => v?.id) || [],
    },
  });
  const [loading, setLoading] = React.useState(false);

  const submit = (data: { name: string }) => {
    setLoading(true);
    api
      .put(`/api/client/webhook-subscribers/${subscriber.id}`, data, 'json')
      .then(() => {
        toaster.success({ message: 'Webhook subscriber updated.' });
        refresh();
        close();
      })
      .catch((e) => {
        toaster.danger({
          message: (
            <ErrorList
              errors={e?.errors}
              fallback="There was an issue updating subscriber."
            />
          ),
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Modal
        onClose={close}
        title="Edit Webhook Subscriber"
        footer={
          <>
            <Button onClick={close} theme="secondary" outline>
              Cancel
            </Button>
            <Button type="submit" loading={loading}>
              Save
            </Button>
          </>
        }
      >
        <FormGroup label="Subscriber Name" errors={errors.name}>
          <Input name="name" placeholder="Subscriber name" ref={register()} />
        </FormGroup>

        <FormGroup label="Redirect URL" errors={errors.redirect_url} required>
          <Input
            name="redirect_url"
            placeholder="Redirect URL"
            ref={register({
              required: true,
            })}
          />
        </FormGroup>

        <FormGroup
          label={
            <InfoTooltip
              usePortal={false}
              content="Events"
              tooltip="Webhook events to subscribe"
            />
          }
          errors={errors?.events}
        >
          <Controller
            name="events"
            defaultValue={subscriber?.events || []}
            control={control}
            render={(props) => (
              <WebhookEventsList
                placeholder="Select events to subscribe"
                defaultValue={subscriber?.events || []}
                onChange={(values) => {
                  props.onChange(values.map((v: any) => v?.value));
                }}
              />
            )}
          />
        </FormGroup>
      </Modal>
    </form>
  );
};
