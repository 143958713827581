import { createState } from '@hookstate/core';
import { singleton } from 'tsyringe';
import { ProfileService } from './profile';
import { ApiService } from './api';
import { TokenPayload, TokenService, TokenStatus } from './token';
import Storage from '../db/storage';

export interface AuthState {
  isAuthenticated?: boolean;
  complete?: boolean;
}

@singleton()
export class AuthService {
  // Refresh token timer
  timeout: any = null;

  state = createState<AuthState>({
    isAuthenticated: false,
    complete: false,
  });

  constructor(
    private tokenService: TokenService,
    private apiService: ApiService,
    private profileService: ProfileService,
  ) {
    if (this.isAuthenticated()) {
      this.profileService.load().then(() => {
        this.state.set(() => ({
          complete: true,
          isAuthenticated: this.isAuthenticated(),
        }));
        this.setRefreshToken();
      });
    } else if (tokenService.getState() === TokenStatus.REFRESH) {
      this.refreshToken().then(() => {
        this.state.set(() => ({
          complete: true,
          isAuthenticated: this.isAuthenticated(),
        }));
      });
    } else {
      Storage.clear();

      this.state.set(() => ({
        complete: true,
        isAuthenticated: false,
      }));
    }
  }

  isAuthenticated() {
    return this.tokenService.getState() !== TokenStatus.INVALID;
  }

  login(payload: { username?: string; password?: string }) {
    return this.apiService.post('/api/login', payload).then(({ data }) => {
      this.tokenService.set(data);

      this.setRefreshToken();

      return this.profileService.load().then(() => {
        this.state.set({
          isAuthenticated: true,
          complete: true,
        });
        return data;
      });
    });
  }

  logout() {
    return this.apiService.post('/api/logout', {}).then(() => {
      this.tokenService.clear();

      this.state.set(() => ({
        complete: true,
        isAuthenticated: false,
      }));
    });
  }

  refreshToken() {
    return this.apiService
      .post('/api/refresh-token', {
        refresh_token: this.tokenService.refreshToken,
      })
      .then(({ data }) => {
        this.tokenService.set(data);

        this.setRefreshToken();

        return this.profileService.load().then(() => {
          this.state.set(() => ({ complete: true, isAuthenticated: true }));

          return data;
        });
      });
  }

  setRefreshToken() {
    const now = new Date().getTime();

    const refreshAt = this.tokenService.getRefreshTime().getTime();

    this.timeout = setTimeout(() => {
      if (this.tokenService.getState() === TokenStatus.REFRESH) {
        this.refreshToken();
        clearTimeout(this.timeout);
        return;
      }
    }, refreshAt - now);
  }

  setLoginData(data: TokenPayload) {
    this.tokenService.set(data);

    this.setRefreshToken();
  }

  setState(state: AuthState) {
    this.state.set({
      ...this.state.value,
      ...state,
    });
  }
}
