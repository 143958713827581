import { isNil, snakeCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { Select } from '../../components/select';
import {
  Modal,
  Input,
  DatePicker,
  Button,
  FormGroup,
  CountrySelect,
  TextArea,
} from '../../components';
import { useApi } from '../../hooks/use-api';
import { useCompany } from '../../hooks/use-company';
import { useToaster } from '../../hooks/use-toaster';
import { useContractTypes } from '../../hooks';
import { email } from '../../shared';
import { isAfter, isBefore, isDate, isFuture } from 'date-fns';

const defaultValues: any = {
  contractor_name: '',
  contractor_email: '',
  contractor_country_id: '',
  contract_type_id: '',
  scope_of_work: '',
  start_date: '',
  termination_date: '',
  first_payment_date: '',
};

export const CopyContract = ({
  onClose,
  contractId,
}: {
  onClose: () => any;
  contractId: any;
}) => {
  const api = useApi();
  const history = useHistory();
  const toaster = useToaster();
  const { register, handleSubmit, errors, control, watch, reset, getValues } =
    useForm({ defaultValues });

  const formValue = watch();
  const [contract, setContract] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const { contractTypes } = useContractTypes();
  const { selectedCompany } = useCompany();

  useEffect(() => {
    api
      .get(`/api/client/contract/${contractId}`)
      .then(({ data: { contract } }) => {
        setContract(contract);
      });
  }, [api, contractId]);

  useEffect(() => {
    reset({
      contractor_name: contract.contractor_name,
      contractor_email: contract.contractor_email,
      contractor_country_id: contract.contractor_country_id,
      contract_type_id: contract.contract_type_id,
      scope_of_work: contract.scope_of_work,
      start_date: new Date(contract.start_date),
      termination_date: new Date(contract.termination_date),
      first_payment_date: new Date(contract.first_payment_date),
    });
  }, [reset, contract]);

  const submit = (data: any) => {
    setLoading(true);

    const form: any = JSON.parse(
      JSON.stringify({
        ...contract,
        ...data,
        companyId: selectedCompany?.company_id,
        contract_type_id: Number(data.contractType),
      }),
    );

    const body = new FormData();

    Object.keys(form).forEach((key) => {
      if (!isNil(form[key])) {
        body.append(snakeCase(key), form[key]);
      }
    });

    api
      .fetch('/api/client/contract', { method: 'POST', body })
      .then(({ data: { contract } }) => {
        onClose();
        setLoading(false);
        toaster.success({
          message: 'New contract was created successfully.',
        });
        history.push(`/contracts/${contract.id}`);
      })
      .catch(() => {
        setLoading(false);
        toaster.danger({
          message: 'There was an error processing your request.',
        });
      });
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Modal
        title="Duplicate contract"
        showClose
        size="lg"
        onClose={onClose}
        footer={
          <>
            <Button onClick={onClose} theme="secondary" outline>
              Cancel
            </Button>
            <Button loading={loading} type="submit">
              Duplicate
            </Button>
          </>
        }
      >
        <div className="row">
          <div className="col-md-6">
            <FormGroup
              label="Contractor Name"
              errors={errors.contractor_name}
              required
            >
              <Input
                placeholder="Contractor Name"
                name="contractor_name"
                ref={register({ required: true })}
              />
            </FormGroup>
          </div>

          <div className="col-md-6">
            <FormGroup
              label="Contractor Email"
              errors={errors.contractor_email}
              required
            >
              <Input
                placeholder="Contractor Email"
                name="contractor_email"
                ref={register({ required: true, validate: email })}
              />
            </FormGroup>
          </div>

          <div className="col-md-6">
            <FormGroup
              label="Contractor country of residence"
              errors={errors.contractor_country_id}
              required
            >
              <Controller
                name="contractor_country_id"
                defaultValue=""
                control={control}
                rules={{
                  required: `Contractor's country of residence is required`,
                }}
                render={(props) => (
                  <CountrySelect
                    value={props.value}
                    onChange={props.onChange}
                  />
                )}
              />
            </FormGroup>
          </div>

          <div className="col-md-6">
            <FormGroup
              label="Contract Type"
              errors={errors.contract_type_id}
              required
            >
              <Controller
                name="contract_type_id"
                defaultValue={1}
                control={control}
                rules={{
                  validate: {
                    selected: (value) =>
                      isNil(value) || value === ''
                        ? 'Contract type is required'
                        : undefined,
                  },
                }}
                render={(props) => (
                  <Select
                    onChange={(event: any) => {
                      props.onChange(event.target.value);
                    }}
                    value={props.value}
                  >
                    <option disabled>Select contract type</option>
                    {contractTypes.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Select>
                )}
              />
            </FormGroup>
          </div>
        </div>

        <FormGroup label="Scope of Work" errors={errors.scope_of_work} required>
          <TextArea
            placeholder="You can start by writing down the role description, responsibilities, and/or deliverables..."
            name="scope_of_work"
            ref={register({ required: true })}
          />
        </FormGroup>

        <div className="row">
          <div className="col-md-4">
            <FormGroup label="Start Date" errors={errors.start_date} required>
              <Controller
                name="start_date"
                defaultValue=""
                control={control}
                rules={{
                  validate: (value) => {
                    if (isNil(value)) return 'Start date is required';
                    const dateValue = new Date(value);

                    return (
                      (isDate(dateValue) && isFuture(dateValue)) ||
                      'Start date must be after today'
                    );
                  },
                }}
                render={(props) => (
                  <DatePicker
                    placeholder="MM/DD/YYYY"
                    value={!isNil(props.value) ? new Date(props.value) : null}
                    onChange={props.onChange}
                  />
                )}
              />
            </FormGroup>
          </div>
          <div className="col-md-4">
            <FormGroup
              label="End Date"
              errors={errors.termination_date}
              required
            >
              <Controller
                name="termination_date"
                defaultValue=""
                control={control}
                rules={{
                  validate: (value) => {
                    if (isNil(value)) return 'End date is required';

                    const startDate = new Date(getValues('start_date'));
                    const dateValue = new Date(value);

                    return (
                      (isDate(dateValue) && isAfter(dateValue, startDate)) ||
                      `End date must be after the start date ${startDate.toLocaleDateString()}`
                    );
                  },
                }}
                render={(props) => (
                  <DatePicker
                    placeholder="MM/DD/YYYY"
                    value={props.value ? new Date(props.value) : null}
                    onChange={props.onChange}
                  />
                )}
              />
            </FormGroup>
          </div>

          <div className="col-md-4">
            <FormGroup
              label="First payment date"
              errors={errors.first_payment_date}
              required
            >
              <Controller
                name="first_payment_date"
                defaultValue=""
                control={control}
                rules={{
                  validate: {
                    afterStartDate: (value) => {
                      if (isNil(value)) return 'First start date is required';

                      const startDate = new Date(formValue.start_date);
                      const dateValue = new Date(value);

                      return (
                        (isDate(dateValue) && isAfter(dateValue, startDate)) ||
                        `First payment date must be after the start date ${startDate.toLocaleDateString()}`
                      );
                    },
                    beforeTerminationDate: (value) => {
                      if (isNil(value)) return 'First start date is required';

                      const endDate = new Date(formValue.termination_date);
                      const dateValue = new Date(value);

                      return (
                        (isDate(dateValue) && isBefore(dateValue, endDate)) ||
                        `First payment date must be before the end date ${endDate.toLocaleDateString()}`
                      );
                    },
                  },
                }}
                render={(props) => (
                  <DatePicker
                    value={props.value ? new Date(props.value) : null}
                    onChange={props.onChange}
                  />
                )}
              />
            </FormGroup>
          </div>
        </div>
      </Modal>
    </form>
  );
};
