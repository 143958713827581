import React from 'react';
import { useApi, PaymentMethodResponse } from '../../../hooks/use-api';
import { useCompany } from '../../../hooks/use-company';
import { Box, Button } from '../../../components';
import CardPayment from '../../invoices/Card/CardPayment';
import { useHistory } from 'react-router-dom';
import { PAYMENT_HISTORY_PATH } from '../../payment-history';
import { useToaster } from '../../../hooks/use-toaster';
import { PaymentMethod } from '../../../constants';
import './ListCards.scss';
import classNames from 'classnames';

const ListCards = ({ ids }: { ids: string[] }) => {
  const api = useApi();
  const history = useHistory();
  const toaster = useToaster();
  const { selectedCompany } = useCompany();
  const [cards, setCards] = React.useState<Array<any>>([]);
  const [addNew, setAddNew] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<string | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const toggleAddNew = () => setAddNew((prev) => !prev);

  const payWithCard = (paymentMethod: PaymentMethodResponse) => {
    api
      .post(
        `/api/client/company/${selectedCompany?.company_id}/payments/transaction`,
        {
          invoice_id: ids,
          billable_id: selectedCompany?.company_id,
          billable_type: 'company',
          payment_method_type: PaymentMethod.STRIPE_CARD,
          payment_method_id: paymentMethod.id,
        },
        'json',
      )
      .then(({ data }) => {
        history.push(PAYMENT_HISTORY_PATH);
        toaster.success({ message: data.message });
        setSuccess(data.message);
      })
      .catch(({ errors }) => {
        setError(errors?.message || errors?.[0] || 'Something went wrong');
      });
  };

  const fetchCards = React.useCallback(() => {
    api
      .get(
        `/api/payment/methods?payment_method_type=${PaymentMethod.STRIPE_CARD}&billable_type=company&billable_id=${selectedCompany?.company_id}`,
      )
      .then(({ data: { paymentMethods } }) => {
        setCards(paymentMethods);
      });
  }, [api, selectedCompany?.company_id]);

  React.useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  return (
    <>
      {/* Replace this with dismissable alerts */}
      {error && (
        <p className="error-wrapper" onClick={() => setError(null)}>
          {error}
        </p>
      )}

      {success && (
        <p className="success-wrapper" onClick={() => setSuccess(null)}>
          {success}
        </p>
      )}

      {!addNew && (
        <div className="pg-ach-method-list">
          {cards.map((card) => (
            <Box
              key={card.id}
              bordered
              className="mb-3 px-4 py-2 d-flex justify-content-between align-items-center"
            >
              <div>
                <p className="mb-0 card-num text-dark">
                  <span className="hidden-digits">•••• •••• •••• </span>
                  <span>{card?.details?.last4 || 'XXXX'}</span>
                </p>
                <p className="text-secondary mb-0">
                  <small>
                    Expires at: {card?.details?.exp_month || ''}/
                    {card?.details?.exp_year || ''}
                  </small>
                </p>
              </div>

              <Button onClick={() => payWithCard(card)}>Pay</Button>
            </Box>
          ))}
        </div>
      )}

      {addNew && <CardPayment company={selectedCompany?.company_id} />}

      <div
        className={classNames('pg-ListCards__Actions', {
          'pg-ListCards__Actions--align-right': addNew,
        })}
      >
        {addNew ? (
          <Button onClick={toggleAddNew} theme="secondary" outline>
            Cancel
          </Button>
        ) : (
          <Button onClick={toggleAddNew}>Pay using another card</Button>
        )}
      </div>
    </>
  );
};

export default ListCards;
