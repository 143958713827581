import React from 'react';
import { IoCheckmark, IoClose, IoPencil } from 'react-icons/io5';
import { Invoice, InvoiceItem } from '.';
import {
  LabelValue,
  CommonDataDisplay,
  Button,
  InvoiceItemTypeSelect,
  Input,
  TextArea,
} from '../../components';
import { useApi, useCommonData } from '../../hooks/use-api';
import { currencyFormatter } from '../../shared/helpers';
import { Tooltip2 } from '@blueprintjs/popover2';
import { PopoverPosition } from '@blueprintjs/core';
import './invoice-items.scss';
import { useToaster } from '../../hooks/use-toaster';

export const InvoiceItems = ({
  invoice,
  onItemUpdate,
}: {
  invoice: Invoice;
  onItemUpdate?: () => any;
}) => {
  const commonData = useCommonData();
  const api = useApi();
  const toaster = useToaster();
  const [editItem, setEditItem] = React.useState<
    { id?: string; value?: number; type?: number; note?: string } | undefined
  >();

  const defautltInvoiceTypes = React.useMemo(
    () =>
      commonData?.invoice_items_types?.filter(
        ({ id }) =>
          !commonData.contractor_invoice_items_types
            ?.map(({ id }) => id)
            .includes(id),
      ),
    [commonData],
  );

  const addedInvoiceItems = React.useMemo(() => {
    return invoice.invoice_items?.filter(({ invoice_item_type_id }) => {
      return !defautltInvoiceTypes
        ?.map(({ id }) => id)
        .includes(invoice_item_type_id);
    });
  }, [invoice, defautltInvoiceTypes]);

  const edit = (item?: InvoiceItem) => {
    setEditItem(
      item
        ? {
            id: item.id,
            value: item.amount,
            type: item.invoice_item_type_id,
            note: item.note,
          }
        : undefined,
    );
  };

  const onSave = () => {
    api
      .patch(`/api/client/invoices/${invoice.id}/items/${editItem?.id}`, {
        invoice_item_type_id: editItem?.type,
        note: editItem?.note,
        quantity: parseFloat(editItem?.value as any),
      })
      .then(() => {
        toaster.success({ message: 'Updated invoice item' });
      })
      .finally(() => {
        onItemUpdate?.();
      });
  };

  const onDelete = (id: string) => {
    api
      .delete(`/api/client/invoices/${invoice.id}/items/${id}`)
      .then(() => {
        toaster.success({ message: 'Deleted invoice item' });
      })
      .finally(() => {
        onItemUpdate?.();
      });
  };

  return (
    <div>
      {defautltInvoiceTypes?.map((invoiceType) => (
        <LabelValue inline label={invoiceType.name}>
          {currencyFormatter(
            invoice.invoice_items
              ?.filter(
                ({ invoice_item_type_id }: any) =>
                  invoice_item_type_id === invoiceType.id,
              )
              .reduce((total: any, { amount }: any) => total + amount, 0),
            invoice.currency_iso,
          )}
        </LabelValue>
      ))}
      {addedInvoiceItems?.length ? <hr /> : null}
      {addedInvoiceItems?.map((invoiceItem) =>
        editItem?.id === invoiceItem.id ? (
          <div className="pg-InvoiceItem__EditContainer">
            <div className="pg-InvoiceItem__BasicInfo">
              <InvoiceItemTypeSelect
                value={editItem.type}
                onChange={(type) => setEditItem((val) => ({ ...val, type }))}
              />

              <Input
                placeholder={`Amount (${invoice.currency_iso})`}
                value={editItem.value}
                onChange={({ target: { value } }: any) =>
                  setEditItem((val) => ({ ...val, value }))
                }
              />
            </div>
            <div className="pg-InvoiceItem__Note">
              <TextArea
                placeholder="Note"
                value={editItem.note}
                onChange={(note) => setEditItem((val) => ({ ...val, note }))}
              />
            </div>

            <div className="pg-InvoiceItem__EditActions">
              <Tooltip2 position={PopoverPosition.TOP} content={`Save`}>
                <Button
                  outline
                  small
                  theme="secondary"
                  onClick={() => onSave()}
                >
                  <IoCheckmark />
                </Button>
              </Tooltip2>
              <Tooltip2 position={PopoverPosition.TOP} content={`Cancel`}>
                <Button
                  outline
                  small
                  theme="secondary"
                  onClick={() => edit(undefined)}
                >
                  <IoClose />
                </Button>
              </Tooltip2>
            </div>
          </div>
        ) : (
          <LabelValue
            className="pg-InvoiceItem"
            inline
            label={
              <CommonDataDisplay
                id={invoiceItem.invoice_item_type_id}
                type="invoice_items_types"
              />
            }
          >
            {currencyFormatter(invoiceItem.amount, invoice.currency_iso)}
            <div className="pg-InvoiceItem__Actions">
              {!editItem ? (
                <>
                  <Tooltip2 position={PopoverPosition.TOP} content={`Edit`}>
                    <Button
                      outline
                      small
                      theme="secondary"
                      onClick={() => edit(invoiceItem)}
                    >
                      <IoPencil />
                    </Button>
                  </Tooltip2>
                  <Tooltip2 position={PopoverPosition.TOP} content={`Delete`}>
                    <Button
                      outline
                      small
                      theme="secondary"
                      onClick={() => onDelete(invoiceItem.id)}
                    >
                      <IoClose />
                    </Button>
                  </Tooltip2>
                </>
              ) : null}
            </div>
          </LabelValue>
        ),
      )}
    </div>
  );
};
