import React from 'react';
import { isNil } from 'lodash';
import { useHistory } from 'react-router';
import { BiPlus } from 'react-icons/bi';
import { useToaster } from '../../hooks/use-toaster';
import { CREATE_TEMPLATE_PATH, SHOW_PRESET_PATH } from '../../pages';
import { useApi, useEndpoint, useModal } from '../../hooks';
import { PresetGroup as IPresetGroup } from '../../interfaces/presets';
import { TemplateSelect, FormGroup, Button, Modal, ErrorList } from '../';
import './index.scss';

export const AddTemplateButtonModal = ({
  group,
  onClose,
  onConfirm,
}: {
  group: IPresetGroup;
  onConfirm: (data?: any) => any;
  onClose: () => any;
}) => {
  const history = useHistory();

  const [selectedTemplateId, setSelectedTemplateId] = React.useState<
    string | null
  >(null);

  const goToCreateTemplate = () => {
    onClose?.();

    history.push(
      `${CREATE_TEMPLATE_PATH}?presetId=${group.preset_id}&groupId=${group.id}`,
    );
  };

  const onTemplateSelection = (value?: number | string) => {
    if (isNil(value)) return;

    setSelectedTemplateId(String(value));
  };

  const onConfirmation = () => {
    onClose?.();

    onConfirm?.(selectedTemplateId);
  };

  return (
    <Modal
      onClose={onClose}
      footer={
        <div className="w-100 d-flex align-items-center justify-content-between">
          <Button theme="secondary" outline onClick={onClose}>
            Cancel
          </Button>

          <Button onClick={onConfirmation}>Add Selected Template</Button>
        </div>
      }
    >
      <>
        <div className="mt-3 mb-5">
          <FormGroup label="Select Existing Template">
            <TemplateSelect onChange={onTemplateSelection} />
          </FormGroup>
        </div>

        <div className="pg-AddTemplateButton__Redirect">
          <Button theme="link" onClick={goToCreateTemplate}>
            <small>Create a new template instead?</small>
          </Button>
        </div>
      </>
    </Modal>
  );
};

export const AddTemplateButton = ({ group }: { group: IPresetGroup }) => {
  const api = useApi();
  const toaster = useToaster();
  const history = useHistory();
  const modalStore = useModal();
  const endpoint = useEndpoint();

  const handleErrors = (e: any) => {
    const errors: string[] = Object.values(e?.errors || []);

    toaster.danger({
      message: <ErrorList errors={errors} />,
    });
  };

  const syncTemplates = (templateId: string | null) => {
    if (templateId) {
      api
        .post(endpoint('preset_group_template.sync'), {
          group_id: group.id,
          preset_id: group.preset_id,
          template_id: templateId,
        })
        .then(() => {
          history.push('/success');

          history.replace(
            SHOW_PRESET_PATH.replace(':id', String(group.preset_id)),
          );
        })
        .catch(handleErrors);
    }
  };

  const addTemplate = () => {
    modalStore.openModal((close) => (
      <AddTemplateButtonModal
        group={group}
        onClose={close}
        onConfirm={syncTemplates}
      />
    ));
  };

  return (
    <div className="pg-PresetGroup__Templates__AddNew" onClick={addTemplate}>
      <BiPlus />
      <span>Add Template</span>
    </div>
  );
};
