import React from 'react';
import { IoSaveOutline } from 'react-icons/io5';
import { Button } from '../../../../components';
import { useApi, useCompany } from '../../../../hooks';
import { useToaster } from '../../../../hooks/use-toaster';
import { CompanyDetails } from '../../../../shared/services/company';
import { CompanyProfileForm } from '../../../../components/company-profile';
import { useService } from '../../../../shared/services/use-service';
import { ProfileService } from '../../../../shared/services';

export const CompanyDetailsStep = ({
  company,
  active,
  onNext,
}: {
  company: CompanyDetails;
  active: boolean;
  onNext: () => void;
}) => {
  const api = useApi();
  const toasterStore = useToaster();
  const profileService = useService(ProfileService);
  const {
    selectedCompany: { company_id: id },
  } = useCompany();
  const [loading, setLoading] = React.useState(false);

  const save = (data: any) => {
    setLoading(true);
    api
      .patch(`/api/client/company/${id}`, api.createPayload(data))
      .then(() => {
        profileService.load();
        onNext();
      })
      .catch(() =>
        toasterStore.danger({ message: 'Failed to save company settings.' }),
      )
      .finally(() => setLoading(false));
  };

  if (!active) return null;

  return (
    <div>
      <CompanyProfileForm company={company} onSubmit={save} editable>
        <div className="d-flex justify-content-end mt-4 align-items-center">
          <Button
            type="submit"
            className="px-3"
            leftContent={<IoSaveOutline />}
            disabled={loading}
          >
            Save
          </Button>
        </div>
      </CompanyProfileForm>
    </div>
  );
};
