import React from 'react';
import { Link, Redirect, RouteProps } from 'react-router-dom';
import { AuthPageLayout, FormGroup, Input } from '../../components';
import { Button } from '../../components/button';
import { useAuth } from '../../hooks';
import { useForm } from 'react-hook-form';
import { useApi } from '../../hooks/use-api';
import { useToaster } from '../../hooks/use-toaster';
import { LOGIN_PATH } from '../login';

export const FORGOT_PASSWORD_PATH = '/forgot-password';

export const FORGOT_PASSWORD_ROUTE: RouteProps & { protect: boolean } = {
  protect: false,
  path: FORGOT_PASSWORD_PATH,
  render: () => <ForgotPassword />,
};

export const ForgotPassword = () => {
  const auth = useAuth();
  const api = useApi();
  const toaster = useToaster();
  const { register, handleSubmit, errors } = useForm();
  const [success, setSuccess] = React.useState(false);

  if (auth.isAuthenticated) {
    return <Redirect to="/" />;
  }

  const onSubmit = (data: { email: string }) => {
    api
      .post('/api/forgot-password', data)
      .then(() => setSuccess(true))
      .catch(() => {
        toaster.danger({ message: 'An error occured while processing your request.' });
      });
  };

  return (
    <AuthPageLayout>
      {success ? (
        <div>
          An email with reset instructions has been sent to the provided email
          address. <Link to={LOGIN_PATH}>Back to login.</Link>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h4>Reset your password</h4>
          <div>
            Enter the email address associated with your account and we'll send
            you a link to reset your password.
          </div>
          <hr />
          <FormGroup
            label="Email"
            labelFor="email"
            errors={errors.email}
            required
          >
            <Input
              type="text"
              id="email"
              name="email"
              placeholder="example@domain.com"
              ref={register({ required: true })}
            />
          </FormGroup>
          <div className="d-flex justify-content-end">
            <Button type="submit">Send link</Button>
          </div>
        </form>
      )}
    </AuthPageLayout>
  );
};
