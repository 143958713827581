import React from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import { isEmpty, isNil } from 'lodash';
import {
  Action,
  Button,
  ErrorList,
  Table,
  Td,
  Th,
  Tr,
} from '../../../components';
import { SHOW_TEMPLATE_PATH } from '../show';
import { ContractTemplate } from '../../../interfaces';
import { useToaster } from '../../../hooks/use-toaster';
import { useApi, useEndpoint, useModal } from '../../../hooks';
import { EDIT_TEMPLATE_PATH } from '../edit';
import './templates-table.scss';

export const TemplatesTable = ({
  templates,
  refresh,
}: {
  templates: ContractTemplate[];
  refresh: () => void;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const modalStore = useModal();
  const endpoint = useEndpoint();
  const history = useHistory();

  const previewTemplate = (id: any) => {
    const path = SHOW_TEMPLATE_PATH.replace(':id', id);

    history.push(path);
  };

  // const editTemplate = (id: any) => {
  //   const path = EDIT_TEMPLATE_PATH.replace(':id', id);

  //   history.push(path);
  // };

  // const deleteTemplate = (id: any) => {
  //   modalStore
  //     .confirmModal({
  //       title: 'Delete template',
  //       message: <p>Are you sure you want to remove this template?</p>,
  //       confirmBtn: 'Delete',
  //       theme: 'danger',
  //     })
  //     .result.then(() => {
  //       api
  //         .delete(endpoint('templates.delete', { id }))
  //         .then(() => {
  //           toaster.success({
  //             message: 'Template has been removed successfully.',
  //           });
  //         })
  //         .catch(({ errors }) => {
  //           toaster.danger({
  //             message: <ErrorList errors={errors} />,
  //           });
  //         })
  //         .finally(() => {
  //           refresh();
  //         });
  //     })
  //     .catch(() => {});
  // };

  return (
    <div className="c-Templates">
      <div className="c-Templates__List">
        {isEmpty(templates) ? (
          <h6 className="pg-DashboardItem__Empty mt-5">
            No templates added yet.
          </h6>
        ) : (
          <Table>
            <thead>
              <Tr>
                <Th>Name</Th>
                <Th>Country</Th>
                <Th>Added at</Th>
                <Th></Th>
              </Tr>
            </thead>

            <tbody>
              {templates.map((template) => (
                <Tr key={template.id}>
                  <Td>{template?.name || 'N/A'}</Td>

                  <Td>{template?.country?.country_name || 'N/A'}</Td>

                  <Td>
                    {template?.created_at
                      ? format(new Date(template?.created_at), 'MMMM do, yyyy')
                      : 'N/A'}
                  </Td>
                  <Td isAction>
                    <Button
                      className="mx-1"
                      small
                      onClick={() => previewTemplate(template.id)}
                    >
                      Preview
                    </Button>

                    {/* {!isNil(template?.company_id) && (
                      <>
                        <Button
                          small
                          theme="warning"
                          onClick={() => editTemplate(template.id)}
                          className="text-white mx-1"
                        >
                          Edit
                        </Button>

                        <Action
                          small
                          theme="danger"
                          className="mx-1"
                          onClick={() => deleteTemplate(template.id)}
                          disabled={Number(template?.contracts_count) > 0}
                          tooltip={
                            Number(template?.contracts_count) > 0
                              ? "Template cannot be deleted. It's being used to generate contracts"
                              : ''
                          }
                        >
                          Delete
                        </Action>
                      </>
                    )} */}
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};
