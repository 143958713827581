import React from 'react';
import { resolveEndpoint } from '../../api';

export function useEndpoint() {
  const endpoint = React.useCallback(
    (key: string, bindings: any = {}) => resolveEndpoint(key, bindings),
    [],
  );

  return endpoint;
}
