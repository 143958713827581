import { useCallback, useMemo } from 'react';
import { useCommonData } from '..';

export const useRateTimeFrame = (typeId?: number, timeFrameId?: number) => {
  const common = useCommonData();
  const getTimeFrame = useCallback(
    (typeId?: number, timeFrameId?: number) => {
      return common?.contract_types
        ?.find((contract) => contract.id === typeId)
        ?.rate_time_frames.find((timeFrame) => timeFrame.id === timeFrameId)
        ?.name;
    },
    [common],
  );

  const timeFrame = useMemo(() => {
    return getTimeFrame(typeId, timeFrameId);
  }, [getTimeFrame, typeId, timeFrameId]);

  return {
    value: timeFrame,
    getTimeFrame,
  };
};
