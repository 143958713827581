import React, { useCallback, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { Role } from '../../../constants';
import { RouteConfigItem } from '../../../routes';
import { useApi, useDocumentTypes, useEndpoint } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import { SHOW_PRESET_PATH } from '../../presets';
import { ErrorList, Loader, PageTitle } from '../../../components';
import {
  Button,
  FormGroup,
  Input,
  TextArea,
  BackButton,
} from '../../../components';
import { PresetGroup as IPresetGroup } from '../../../interfaces/presets';
import { groupBy } from 'lodash';
import { DocumentType as IDocumentType } from '../../../interfaces';
import Select from 'react-select';

export const EDIT_PRESET_GROUP_PATH = '/preset-group/:id/edit';

export const EDIT_PRESET_GROUP_ROUTE: RouteConfigItem = {
  exact: true,
  protect: true,
  path: EDIT_PRESET_GROUP_PATH,
  roles: [Role.CLIENT, Role.TEAM_MEMBER],
  render: () => <EditPresetGroup />,
};

export const EditPresetGroup = () => {
  const api = useApi();
  const toaster = useToaster();
  const history = useHistory();
  const endpoint = useEndpoint();
  const documentTypes = useDocumentTypes();
  const { id } = useParams<{ id?: string }>();
  const [loading, setLoading] = React.useState(false);
  const { control, register, errors, handleSubmit } = useForm();
  const [presetGroup, setPresetGroup] = React.useState<IPresetGroup | null>(
    null,
  );

  const documentTypesOptions = useMemo(() => {
    const groups = groupBy(documentTypes, 'category.key');

    return Object.entries(groups)?.map(([group, types]) => ({
      label: `${group} Documents`,
      options: types?.map((t: IDocumentType) => ({
        label: t.name,
        value: t.id,
      })),
    }));
  }, [documentTypes]);

  const handleErrors = useCallback(
    (e: any) => {
      const errors: string[] = Object.values(e?.errors || []);

      toaster.danger({
        message: <ErrorList errors={errors} />,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const goBack = () => history.goBack();

  const saveChanges = (data: any) => {
    setLoading(true);

    api
      .put(
        endpoint('preset_group.update', { id }),
        {
          ...data,
          preset_id: presetGroup?.preset_id,
          document_type_id: data.document_type.value || null,
        },
        'json',
      )
      .then(goBack)
      .catch(handleErrors)
      .finally(() => setLoading(false));
  };

  const fetchPresetGroup = useCallback(() => {
    setLoading(true);

    api
      .get(endpoint('preset_group.get', { id }))
      .then(({ data }) => setPresetGroup(data?.presetGroup))
      .catch(handleErrors)
      .finally(() => setLoading(false));
  }, [api, endpoint, handleErrors, id]);

  React.useEffect(() => {
    fetchPresetGroup();
  }, [fetchPresetGroup]);

  if (loading || !presetGroup) {
    return <Loader fullscreen />;
  }

  return (
    <div className="container">
      <BackButton
        to={SHOW_PRESET_PATH.replace(':id', String(presetGroup?.preset_id))}
      />

      <form onSubmit={handleSubmit(saveChanges)}>
        <PageTitle>Edit Preset Document</PageTitle>

        <FormGroup label="Document Name" errors={errors.name} required>
          <Input
            name="name"
            placeholder="Try a unique name"
            ref={register({ required: true })}
            defaultValue={presetGroup?.name || ''}
          />
        </FormGroup>

        <FormGroup
          label="Document Type"
          errors={errors?.document_type}
          required
        >
          <Controller
            name="document_type"
            control={control}
            rules={{ required: true }}
            defaultValue={
              {
                label: presetGroup.document_type?.name,
                value: presetGroup.document_type_id,
              } || ''
            }
            render={(props) => (
              <Select
                options={documentTypesOptions}
                value={props.value}
                onChange={props.onChange}
                placeholder="Select document type..."
              />
            )}
          />
        </FormGroup>

        <FormGroup label="Document Description" errors={errors.description}>
          <TextArea
            placeholder="Enter some descriptive text..."
            name="description"
            ref={register()}
            defaultValue={presetGroup?.description || ''}
          />
        </FormGroup>

        <Button onClick={goBack} theme="secondary" outline>
          Cancel
        </Button>
        <Button type="submit" loading={loading}>
          Save
        </Button>
      </form>
    </div>
  );
};
