import { isEmpty } from 'lodash';
import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { CONTRACTOR_PERMISSIONS_KEY } from '../../constants';
import { useAuth, useCompany } from '../../hooks';
import { HOME_PATH, LOGIN_PATH } from '../../pages';
import { RouteConfigItem } from '../../routes';
import Storage from '../../shared/db/storage';
import { useProfileService } from '../../shared/services';

export const ProtectedRoute = ({
  render,
  permissions,
  roles,
  middlewares,
  ...rest
}: RouteConfigItem) => {
  const auth = useAuth();
  const location = useLocation();
  const history = useHistory();
  const { can, accountType, actAsClient } = useProfileService();
  const { selectedCompany } = useCompany();

  const goToRoute = (props: RouteComponentProps<any, any, unknown>) => {
    Storage.removeItem('redirectTo');
    return render?.(props);
  };

  const redirectToLogin = () => {
    Storage.setItem(
      'redirectTo',
      `${location.pathname || HOME_PATH}${location.search || ''}`,
    );

    return (
      <Redirect
        to={{
          pathname: LOGIN_PATH,
          state: { from: location.pathname },
        }}
      />
    );
  };

  const redirectToHome = () => (
    <Redirect
      to={{
        pathname: HOME_PATH,
      }}
    />
  );

  const userHasPermissions = React.useMemo(() => {
    // This route has no permissions specified
    // ignore permissions check
    if (!permissions) return true;

    return (
      permissions
        .map((per) => {
          const [operation, area] = per.split('.');

          return actAsClient
            ? can(operation, selectedCompany.company_id, area)
            : can(operation, CONTRACTOR_PERMISSIONS_KEY, area);
        })
        .filter((s) => s === false).length === 0
    );
  }, [permissions, actAsClient, can, selectedCompany.company_id]);

  const userHasRoles = React.useMemo(() => {
    // This route has no roles specified
    // ignore roles check
    if (!roles || isEmpty(roles)) return true;

    return roles.includes(accountType || '');
  }, [accountType, roles]);

  const applyMiddlewares = React.useCallback(() => {
    // This route has no middlewares specified
    // ignore middlewares check

    if (!middlewares || isEmpty(middlewares)) return;

    for (const middleware of middlewares) {
      const middlewareInstance = new middleware();
      const response = middlewareInstance.handle();
      const redirectTo = middlewareInstance.redirect();

      if (!response && redirectTo) {
        if (redirectTo) {
          history.replace(redirectTo);
        }
      }
    }
  }, [history, middlewares]);

  React.useEffect(() => {
    applyMiddlewares();
  }, [applyMiddlewares]);

  // No logged in user
  if (!auth?.isAuthenticated) {
    return redirectToLogin();
  }

  return userHasPermissions && userHasRoles ? (
    <Route {...rest} render={(props) => goToRoute(props)} />
  ) : (
    redirectToHome()
  );
};
