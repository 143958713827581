import React from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { Button } from '../../../components';

const SetupForm = ({
  isCardAdded,
  setIsCardAdded,
  isOpen,
  onCancel,
  onCardAdded,
}: {
  isCardAdded?: boolean;
  setIsCardAdded?: React.Dispatch<React.SetStateAction<boolean>>;
  isOpen?: boolean;
  onCancel?: () => any;
  onCardAdded?: () => any;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = React.useState<
    string | null | undefined
  >(null);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // This might take couple seconds
    // Show a loader and prevent the form from being submitted during that time

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    });

    // Checking for any errors
    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (e.g., payment
      // details incomplete)
      setErrorMessage(error.message);
    }

    // We get no error but need to check the status of the SetupIntent
    // As it might be `succeeded`, `processing`, ...etc
    if (setupIntent) {
      // Card payment method is added successfully
      // no need to call our server to add that payment method
      // because it will be handled by the reaceived webhook from Stripe
      setIsCardAdded?.(setupIntent.status === 'succeeded');
      onCardAdded?.();
    }
  };

  // Display currently added payment methods
  // and offer to the user to add a new payment method

  return (
    <form onSubmit={handleSubmit}>
      {isOpen && <PaymentElement />}

      <div className="d-flex pt-2 justify-content-end align-items-center">
        {isOpen && (
          <>
            <Button onClick={onCancel} theme="secondary" outline>
              Cancel
            </Button>
            <Button disabled={!stripe} type="submit">
              Submit
            </Button>
          </>
        )}
      </div>

      {/* Show error message to your customers */}
      {errorMessage && <div className="error-wrapper">{errorMessage}</div>}
    </form>
  );
};

export default SetupForm;
