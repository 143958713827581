import { validate as isEmail } from 'isemail';

export const email = { isEmail: (value: string) => isEmail(value) };

/**
 * Allow A-Z, a-z, digits only.
 */
export const alphaNumericOnly = {
  pattern: {
    value: /^[a-zA-Z0-9\s]+$/,
    message: 'Only alpha-numeric characters are allowed.',
  },
};

/**
 * Allow A-Z, a-z, digits, and some special chars [`.`, `,`, `-`, `/`]
 */
export const alphaNumericCustomPunctOnly = {
  pattern: {
    value: /^[a-zA-Z0-9\s.\-/,]+$/,
    message: 'Only characters, digits, and (".", ",", "-", "/") are allowed.',
  },
};

export const maxLength = (value: number, fieldName: string = 'Field') => ({
  value,
  message: `${fieldName} must not exceed ${value} characters`,
});

export const minLength = (value: number, fieldName: string = 'Field') => ({
  value,
  message: `${fieldName} must be at least ${value} characters`,
});

export const match = (
  value: string,
  matchValue: string,
  message = 'The fields do not match',
) => {
  return value !== matchValue ? message : undefined;
};

export const password = {
  minLength: {
    value: 8,
    message: 'The password must be at least 8 characters.',
  },
  pattern: {
    value: new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
    ),
    message: `The password must contain at least one lowercase letter, uppercase letter, digit and a special character`,
  },
};

export const getErrorMessages = (
  errors: { message?: string; type: string },
  fieldName: string = 'This field',
) => {
  const messages = {
    required: `${fieldName} is required`,
    isEmail: `Please enter a valid email address.`,
    maxLength: `Invalid field length.`,
  };

  return errors?.message || (messages as any)[errors?.type];
};
