import ReactSelect from 'react-select';
import React, { useContext } from 'react';
import { has, isArray, isEmpty, isString } from 'lodash';
import { Controller, UseFormMethods } from 'react-hook-form';
import {
  CountrySelect,
  DatePicker,
  FormGroup,
  Input,
  RadioButton,
  SsnInput,
  EinInput,
} from '../../../components';
import { ContractorType } from '../../../constants';
import { FormGeneratorContext } from '../tax-info';
import './form-generator.scss';

const LABELESS_TYPES = ['checkbox', 'bullet'];

export interface TaxFormItem {
  name?: string;
  label?: string;
  group?: string;
  required?: boolean;
  type?:
    | 'string'
    | 'select'
    | 'radio_group'
    | 'group'
    | 'country'
    | 'checkbox'
    | 'radio'
    | 'bullet'
    | 'date'
    | 'ssn'
    | 'ein';
  children?: TaxFormItem[];
  if?: string;
  ifNot?: string;
  keys?: string[];
}

export const FormGenerator = ({
  form,
  onChange,
  entityType,
}: {
  form?: TaxFormItem[];
  onChange?: (value?: any) => any;
  entityType?: ContractorType;
}) => {
  const context = useContext(FormGeneratorContext) as UseFormMethods;
  const formValue = context?.watch();

  React.useEffect(() => {
    onChange?.(formValue);
  }, [formValue, onChange]);

  const shouldItemRender = (item: TaxFormItem) => {
    if (item.if || item.ifNot) {
      if (item.if?.startsWith('form.')) {
        return formValue[item.if.split('.')[1]];
      }
      if (item.ifNot?.startsWith('form.')) {
        return !formValue[item.ifNot.split('.')[1]];
      }
      if (item.if?.startsWith('entity.')) {
        const check = item.if.split('.')[1];
        return entityType === check;
      }
      if (item.ifNot?.startsWith('entity.')) {
        const check = item.ifNot.split('.')[1];
        return entityType !== check;
      }
    }

    return true;
  };

  return (
    <FormGeneratorContext.Provider value={context}>
      {form?.map((item) =>
        shouldItemRender(item) ? (
          <>
            <div>
              {item.type && item.type !== 'group' ? (
                <FormGroup
                  labelFor={item.name}
                  label={
                    !LABELESS_TYPES.includes(item.type) ? item.label : null
                  }
                  errors={item.name ? context.errors[item.name] : undefined}
                  ignoreLabelOnError
                >
                  {item.type === 'bullet' && (
                    <div className="form-generator-bullet">{item.label}</div>
                  )}

                  {item.type === 'select' && (
                    <Controller
                      name={item.name!}
                      rules={{ required: item.required }}
                      control={context.control}
                      render={(props) => (
                        <ReactSelect
                          onChange={props.onChange}
                          value={props.value}
                          options={item?.children?.map((child) => ({
                            label: child.label,
                            value: child.name,
                          }))}
                        />
                      )}
                    />
                  )}

                  {item.type === 'string' && (
                    <Input
                      id={item.name}
                      name={item.name}
                      ref={context.register({ required: item.required })}
                    />
                  )}

                  {item.type === 'country' && (
                    <Controller
                      name={item.name!}
                      rules={{ required: item.required }}
                      control={context.control}
                      render={(props) => (
                        <CountrySelect
                          onChange={props.onChange}
                          value={props.value}
                        />
                      )}
                    />
                  )}

                  {item.type === 'checkbox' && (
                    <Controller
                      name={item.name!}
                      control={context.control}
                      rules={{ required: item.required }}
                      render={(props) => (
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id={item.name}
                            checked={!!props.value}
                            onChange={(e) =>
                              props.onChange(e.target.checked ? 1 : 0)
                            }
                          />
                          <label
                            className="pg-FormGroup__Label form-label"
                            htmlFor={item.name}
                          >
                            {item.label}
                          </label>
                        </div>
                      )}
                    />
                  )}

                  {item.type === 'date' && (
                    <Controller
                      name={item.name!}
                      control={context.control}
                      rules={{ required: item.required }}
                      render={(props) => (
                        <DatePicker
                          value={props.value}
                          onChange={props.onChange}
                        />
                      )}
                    />
                  )}

                  {item.type === 'radio_group' && (
                    <div className="form-generator-radio-group">
                      {item.children?.length ? (
                        <>
                          {item.children?.map((child) => (
                            <Controller
                              control={context.control}
                              name={child.name!}
                              render={() => (
                                <>
                                  <label className="form-generator-radio">
                                    <RadioButton
                                      value={child.name}
                                      name={item.name}
                                      onChange={(event) => {
                                        item?.children?.forEach((child) => {
                                          context.setValue(child.name!, 0);
                                        });

                                        context.setValue(
                                          child.name!,
                                          event.target.checked ? 1 : 0,
                                        );
                                      }}
                                    />
                                    <span className="form-generator-radio-label">
                                      {child.label}
                                    </span>
                                  </label>
                                  {child.children?.length ? (
                                    <FormGenerator
                                      form={child.children}
                                      entityType={entityType}
                                    />
                                  ) : null}
                                </>
                              )}
                            />
                          ))}

                          {has(context?.errors, item?.name || '') && (
                            <div className="pg-FormGroup__Errors text-danger">
                              This field is required
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  )}

                  {item.type === 'ssn' && (
                    <Controller
                      name={item.name!}
                      rules={{
                        validate: {
                          required: (value: string[]) =>
                            isArray(value) &&
                            !isEmpty(value) &&
                            value.every(
                              (val) => isString(val) && !isEmpty(val),
                            ),
                        },
                      }}
                      control={context.control}
                      render={(props) => (
                        <SsnInput
                          value={props.value}
                          onChange={(value) => props.onChange(value, item.keys)}
                        />
                      )}
                    />
                  )}

                  {item.type === 'ein' && (
                    <Controller
                      name={item.name!}
                      rules={{
                        validate: {
                          required: (value: string[]) =>
                            isArray(value) &&
                            !isEmpty(value) &&
                            value.every(
                              (val) => isString(val) && !isEmpty(val),
                            ),
                        },
                      }}
                      control={context.control}
                      render={(props) => (
                        <EinInput
                          value={props.value}
                          onChange={(value) => props.onChange(value, item.keys)}
                        />
                      )}
                    />
                  )}
                </FormGroup>
              ) : null}
            </div>

            {item.children?.length && item.type === 'group' ? (
              <div className="pg-TaxForm__Section">
                {item?.label && (
                  <p className="pg-TaxForm__SectionTitle">{item?.label}</p>
                )}

                <FormGenerator form={item.children} entityType={entityType} />
              </div>
            ) : null}
          </>
        ) : null,
      )}
    </FormGeneratorContext.Provider>
  );
};
