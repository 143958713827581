import React from 'react';
import errorIcon from '../../images/warning-error.svg';
import { CustomException } from '../../interfaces';
import './index.scss';

export const ExpectionHandler = ({
  error,
}: {
  error: CustomException | null;
}) => {
  return (
    <div className="pg-Exception">
      <img
        className="pg-Exception__Icon"
        src={errorIcon}
        alt="Something went wrong"
      />

      <h1 className="pg-Exception__Title">
        {error?.title?.() || 'Something Went Wrong!'}
      </h1>

      <p className="pg-Exception__Content">{error?.message || ''}</p>

      <p className="pg-Exception__Caption">{error?.caption?.() || ''}</p>
    </div>
  );
};
