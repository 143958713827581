import React from 'react';
import {
  Action,
  FormSection,
  Table,
  Tag,
  Td,
  Th,
  Tr,
  ObscuredContent,
  AddAppKeyModal,
  EditAppKeyModal,
} from '..';
import { IoPencil, IoTrash } from 'react-icons/io5';
import { useModal } from '../../hooks';
import { useApi } from '../../hooks/use-api';
import { useToaster } from '../../hooks/use-toaster';
import { isEmpty, startCase } from 'lodash';
import { InfoTooltip } from '../info-tooltip';
import './index.scss';

export const AuthClients = () => {
  const api = useApi();
  const toaster = useToaster();
  const modalStore = useModal();
  const [apps, setApps] = React.useState<any>([]);

  const fetchApps = React.useCallback(() => {
    api.get('/api/linked-apps').then(({ data: { apps } }) => {
      setApps(apps);
    });
  }, [api]);

  const createApp = () => {
    modalStore.openModal((close) => (
      <AddAppKeyModal close={close} refresh={fetchApps} />
    ));
  };

  const revokeApp = (id: string) => {
    api
      .delete(`/api/linked-apps/${id}`)
      .then(({ data }) => {
        fetchApps();

        toaster.success({
          message: data,
        });
      })
      .catch(() => {
        toaster.danger({
          message: 'There was an error revoking app key.',
        });
      });
  };

  const handleRevoke = (id: string) => {
    modalStore
      .confirmModal({
        theme: 'danger',
        title: 'Revoke Application Key?',
        message:
          'This operation cannot be reversed and all apps that uses this key will no longer have access.',
        confirmBtn: 'Revoke',
      })
      .result.then(() => {
        revokeApp(id);
      })
      .catch(() => {});
  };

  const handleEdit = (app: any) => {
    modalStore.openModal((close) => (
      <EditAppKeyModal app={app} close={close} refresh={fetchApps} />
    ));
  };

  React.useEffect(() => {
    fetchApps();
  }, [fetchApps]);

  return (
    <FormSection
      title="Linked Apps"
      info="The list of third-party applications that have access to your account"
      actions={
        <Action type="button" theme="primary" onClick={createApp}>
          New App
        </Action>
      }
    >
      <Table>
        <thead>
          <Tr>
            <Th width="20%">Name</Th>
            <Th width="20%">Key</Th>
            <Th width="20%">
              <InfoTooltip
                content="Allowed IPs"
                tooltip="Only used to validate APIs requests"
              />
            </Th>
            <Th width="20%">
              <InfoTooltip
                content="Allowed Domains"
                tooltip="Only used to validate iframes"
              />
            </Th>
            <Th width="20%">Permissions</Th>
            <Th></Th>
          </Tr>
        </thead>
        <tbody>
          {isEmpty(apps) && (
            <Tr>
              <Td colSpan={6}>
                <p className="text-center p-4 text-gray mb-0">
                  You don't have any linked apps yet.
                </p>
              </Td>
            </Tr>
          )}
          {apps?.map((app: any) => (
            <Tr key={app?.id}>
              <Td>{app?.name}</Td>

              <Td>
                <ObscuredContent>{app?.key}</ObscuredContent>
              </Td>

              <Td>
                {isEmpty(app?.allowed_ips) && <>No restrictions</>}
                {app?.allowed_ips?.map((ip: any) => (
                  <Tag key={ip}>{ip}</Tag>
                ))}
              </Td>

              <Td>
                {isEmpty(app?.allowed_domains) && <>No restrictions</>}
                {app?.allowed_domains?.map((domain: any) => (
                  <Tag key={domain}>{domain}</Tag>
                ))}
              </Td>

              <Td>
                {isEmpty(app?.scopes) && <>All permissions are granted</>}
                {app?.scopes?.map((scope: any) => (
                  <Tag key={scope}>{startCase(scope)}</Tag>
                ))}
              </Td>

              <Td isAction>
                <div className="d-flex">
                  <Action tooltip="Edit" onClick={() => handleEdit(app)}>
                    <IoPencil />
                  </Action>

                  <Action
                    tooltip="Revoke"
                    onClick={() => handleRevoke(app?.id)}
                  >
                    <IoTrash />
                  </Action>
                </div>
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </FormSection>
  );
};
