import classNames from 'classnames';
import { FaArrowRight } from 'react-icons/fa';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import './steps.scss';

export type Step = {
  name: string;
};

export const DottedSteps = ({
  steps,
  activeStep = 0,
}: {
  steps: Step[];
  activeStep?: number;
}) => {
  return (
    <div className={classNames('pg-Steps pg-Steps--dotted')}>
      {steps.map((item, index) => (
        <div
          key={index}
          className={classNames('pg-StepItem', {
            'pg-StepItem--next': activeStep < index,
            'pg-StepItem--completed': activeStep > index,
            'pg-StepItem--active': activeStep === index,
          })}
        >
          <div className="pg-StepItem__Icon">
            {activeStep > index && <IoCheckmarkCircleOutline />}
            {activeStep === index && <span className="pg-Steps__ActiveIcon" />}
            {activeStep < index && <span className="pg-Steps__NextIcon" />}
          </div>
          <div className="pg-StepItem__Index"></div>
          <div className="pg-StepItem__Name">{item.name}</div>
        </div>
      ))}
    </div>
  );
};

export const Steps = ({
  steps,
  activeStep = 0,
  type = 'horizontal',
}: {
  steps: Step[];
  activeStep?: number;
  type?: 'horizontal' | 'vertical';
}) => {
  return (
    <div className={classNames('pg-Steps', `pg-Steps--${type}`)}>
      {steps.map((item, index) => (
        <div
          key={index}
          className={classNames('pg-StepItem', {
            'pg-StepItem--next': activeStep < index,
            'pg-StepItem--completed': activeStep > index,
            'pg-StepItem--active': activeStep === index,
          })}
        >
          <div className="pg-StepItem__Icon">
            {activeStep > index && <IoCheckmarkCircleOutline />}
            {activeStep === index && <FaArrowRight />}
          </div>
          <div className="pg-StepItem__Index"></div>
          <div className="pg-StepItem__Name">{item.name}</div>
        </div>
      ))}
    </div>
  );
};
