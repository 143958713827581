import './layout.scss';

export const OnboardingLayout = ({
  header,
  sidebar,
  children,
}: {
  header?: React.ReactNode;
  sidebar?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <div className="pg-OnboardingLayout">
      <div className="pg-OnboardingLayout__Header">{header}</div>
      <div className="pg-OnboardingLayout__Content">
        <div className="pg-OnboardingLayout__Sidebar">{sidebar}</div>
        <div className="pg-OnboardingLayout__Body">{children}</div>
      </div>
    </div>
  );
};
