import { Button } from '../button';
import './index.scss';

export const ButtonPicker = ({
  options,
  value,
  onChange,
}: {
  options?: { label: string; value: any }[];
  value?: any;
  onChange?: (value: any) => any;
}) => {
  return (
    <div className="pg-ButtonPicker">
      {options?.map((item) => (
        <Button
          key={item.value}
          outline
          theme={value === item.value ? 'secondary' : 'light'}
          onClick={() => onChange?.(item.value)}
        >
          {item.label}
        </Button>
      ))}
    </div>
  );
};
