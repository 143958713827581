import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  usePlaidLink,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptionsWithLinkToken,
  PlaidAccount,
} from 'react-plaid-link';
import { useApi } from '../../hooks';
import { Box } from '../../components';

export const PlaidButton = ({
  onSuccessCallback,
}: {
  onSuccessCallback: (token: string, accountToCharge: PlaidAccount) => void;
}) => {
  const api = useApi();
  const [linkToken, setLinkToken] = useState('');

  const onSuccess = useCallback(
    async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      const { accounts } = metadata;

      const accountToCharge = accounts[0];

      const { id: accountId } = accountToCharge;

      api
        .post(`/api/payment/plaid/stripe-token`, {
          publicToken,
          accountId,
        })
        .then(({ data }) => {
          onSuccessCallback(data.stripBankAccountToken, accountToCharge);
        });
    },
    [api, onSuccessCallback],
  );

  const config: PlaidLinkOptionsWithLinkToken = useMemo(
    () => ({
      token: linkToken,
      onSuccess, // https://plaid.com/docs/link/web/#onsuccess
      onExit: () => {}, // https://plaid.com/docs/link/web/#onexit
      onEvent: () => {}, // https://plaid.com/docs/link/web/#onevent
    }),
    [linkToken, onSuccess],
  );

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    api.get('/api/payment/plaid/link-token').then(({ data }) => {
      const { linkToken } = data;

      setLinkToken(linkToken);
    });
  }, [api]);

  return (
    <Box bordered className="mb-3 p-5" onClick={() => ready && open()}>
      <h5 className="mb-2">Log in via Plaid</h5>
      <div className="text-muted">
        We will direct you to a new authentication flow
      </div>
    </Box>
  );
};
