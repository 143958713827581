import React from 'react';
import { IoRefresh } from 'react-icons/io5';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from '..';
import './index.scss';

export const DrawSignature = ({
  onChange,
}: {
  onChange?: (data?: string) => any;
}) => {
  const signatureRef = React.createRef<SignatureCanvas>();
  const [hasSignature, setHasSignature] = React.useState(false);

  const setSignature = async (hasSignature?: boolean) => {
    const base64 = signatureRef.current?.getTrimmedCanvas().toDataURL();

    if (signatureRef.current?.isEmpty() || !base64) {
      return;
    }

    if (!hasSignature) {
      return onChange?.(undefined);
    }

    onChange?.(base64);
  };

  return (
    <div
      className={`pg-SignaturePad ${
        hasSignature ? 'pg-SignaturePad--notEmpty' : 'pg-SignaturePad--empty'
      }`}
    >
      {!hasSignature && (
        <div className="pg-SignaturePad__Placeholder">Sign here</div>
      )}

      <div className="pg-SignaturePad__Canvas" onMouseUp={() => setSignature()}>
        <SignatureCanvas
          clearOnResize={false}
          onBegin={() => setHasSignature(true)}
          onEnd={() => setSignature(true)}
          ref={signatureRef}
        />
      </div>

      {hasSignature && (
        <div className="pg-SignaturePad__Clear">
          <Button
            title="Clear"
            theme="link"
            onClick={() => {
              signatureRef.current?.clear();
              setHasSignature(false);
              onChange?.(undefined);
            }}
          >
            <IoRefresh />
          </Button>
        </div>
      )}
    </div>
  );
};
