import React from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from '../../components';
import { useApi } from '../../hooks';
import { RouteWithPermissionsProps } from '../../routes';
import { ContractWrapper, ContractProvider } from '../contract-wrapper';
import { Contract } from '../contracts';

export const EDIT_CONTRACT_PATH = '/edit-contract';

export const EDIT_CONTRACT_ROUTE: RouteWithPermissionsProps = {
  path: `${EDIT_CONTRACT_PATH}/:id/:step?`,
  permissions: ['write.contracts'],
  render: ({
    match: {
      params: { id, step },
    },
  }: any) => {
    return <EditContract step={step} id={id} />;
  },
};

export const EditContract = ({
  contract,
  step,
  id,
}: {
  contract?: Contract;
  step?: string;
  id?: string;
}) => {
  const api = useApi();
  const history = useHistory();
  const [contractToEdit, setContractToEdit] = React.useState<any>(null);

  React.useEffect(() => {
    if (id && !contract) {
      api.get(`/api/client/contract/${id}`).then(({ data: { contract } }) => {
        if (!contract?.is_editable) {
          return history.replace(`/contracts/${contract.id}`);
        }

        setContractToEdit(contract);
      });
    }
  }, [api, id, history, contract]);

  React.useEffect(() => {
    if (!!contract) {
      setContractToEdit(contract);
    }
  }, [contract]);

  return contractToEdit ? (
    <ContractProvider>
      <ContractWrapper step={step} contract={contractToEdit} />
    </ContractProvider>
  ) : (
    <Loader fullscreen />
  );
};
