import React from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { Onboarding } from './talent';
import { Role } from '../../constants';
import { NOT_FOUND_PATH } from '../not-found';
import { RouteConfigItem } from '../../routes';
import './index.scss';

export const TALENT_ONBOARDING_PATH = '/talent-onboarding';

export const TALENT_ONBOARDING_ROUTE: RouteConfigItem = {
  protect: true,
  path: TALENT_ONBOARDING_PATH,
  roles: [Role.CONTRACTOR],
  render: (props) => {
    const queryParams = queryString.parse(props?.location?.search);
    const contractId = (queryParams?.contract_id as string) || '';
    return <TalentOnboarding contractId={contractId} />;
  },
};

export const TalentOnboarding = ({ contractId }: { contractId?: string }) => {
  const history = useHistory();

  React.useEffect(() => {
    if (!contractId) {
      history.replace(NOT_FOUND_PATH);
    }
  }, [contractId, history]);

  return <Onboarding />;
};
