import React from 'react';
import classNames from 'classnames';
import './index.scss';

const AlertThemeClasses = {
  primary: 'alert-primary',
  secondary: 'alert-secondary',
  success: 'alert-success',
  danger: 'alert-danger',
  warning: 'alert-warning',
  info: 'alert-info',
  light: 'alert-light',
  dark: 'alert-dark',
  link: 'alert-link',
} as const;

export type AlertTheme = keyof typeof AlertThemeClasses;

export interface AlertProps extends React.HTMLProps<HTMLDivElement> {
  theme?: keyof typeof AlertThemeClasses;
}

export const Alert = ({
  theme = 'primary',
  children,
  className,
  ...props
}: AlertProps) => (
  <div
    className={classNames(
      'pg-Alert alert',
      {
        [AlertThemeClasses[theme]]: theme,
      },
      className,
    )}
    {...props}
  >
    {children}
  </div>
);
