import React from 'react';
import { RouteProps } from 'react-router-dom';
import {
  Action,
  Button,
  ChangePassword,
  CountrySelect,
  CreateCompany,
  DatePicker,
  FormGroup,
  FormSection,
  Input,
  LayoutContent,
  PageTitle,
  RadioButton,
  StateSelect,
  Table,
  Td,
  Th,
  TimezoneSelect,
  Tr,
  useStates,
} from '../../components';
import { IoPencil } from 'react-icons/io5';
import { useModal } from '../../hooks';
import { useForm, Controller } from 'react-hook-form';
import { useApi } from '../../hooks/use-api';
import { email, maxLength } from '../../shared';
import { useToaster } from '../../hooks/use-toaster';
import { useService, useServiceState } from '../../shared/services/use-service';
import { ProfileService } from '../../shared/services';
import { Role } from '../../constants';
import { EditOfficialTitle } from '../../components/edit-official-title';
import './index.scss';

export const USER_SETTINGS_PATH = '/user-settings';

export const USER_SETTINGS_ROUTE: RouteProps = {
  path: USER_SETTINGS_PATH,
  render: () => <UserSettings />,
};

export const UserSettings = () => {
  // const companyStore = useCompany();
  // const history = useHistory();
  const api = useApi();
  const toaster = useToaster();
  const modalStore = useModal();
  const { reset, register, control, handleSubmit, errors, watch } = useForm();
  const [editMode, setEditMode] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const countryId = watch('countryId');
  const states = useStates(countryId);

  const profileService = useService(ProfileService);
  const { value: profile } = useServiceState(ProfileService);

  const editOfficialTitle = (companyId: string, title?: string | null) => {
    if (!profile) return;

    modalStore
      .openModal((close) => (
        <EditOfficialTitle
          close={close}
          company={companyId}
          title={title || ''}
          user={profile.id}
        />
      ))
      .result.then(() => {
        profileService.load();
      });
  };

  const save = (data: any) => {
    setLoading(true);
    api
      .patch('/api/profile', api.createPayload(data))
      .then(() => {
        setEditMode(false);
        profileService.load();
        // toaster.success({ message: 'Settings were saved successfully.' });
      })
      .finally(() => setLoading(false));
  };

  // const selectCompanyAndEdit = (id: string) => {
  //   companyStore.selectCompany(id);
  //   history.push(ORGANIZATION_SETTINGS_PATH);
  // };

  // const deactivateCompany = (id: string, name: string) => {
  //   modalStore
  //     .confirmModal({
  //       title: 'Deactivate company',
  //       message: `Are you sure you want to deactivate ${name}?`,
  //       confirmBtn: 'Deactivate',
  //     })
  //     .result.then(() => {
  //       api.patch(`/api/client/company/${id}/deactivate`, {}).then(() => {
  //         toaster.success({ message: 'Company was deactivated successfully.' });
  //         profileService.load();
  //       });
  //     })
  //     .catch();
  // };

  // const activate = (id: string) => {
  //   api.patch(`/api/client/company/${id}/activate`, {}).then(() => {
  //     toaster.success({ message: 'Company was activated successfully.' });
  //     profileService.load();
  //   });
  // };

  const changePassword = () => {
    modalStore.openModal((close) => <ChangePassword close={close} />);
  };

  const toggleContractorType = () => {
    api
      .post(`/api/profile/toggle`, {})
      .then(() => {
        toaster.success({ message: 'Account type was changed successfully.' });
        profileService.load().then(() => setEditMode(false));
      })
      .catch(() => {
        toaster.danger({
          message: 'There was an error updating the account type.',
        });
      });
  };

  const createEntity = () => {
    modalStore
      .openModal((close, cancel) => (
        <CreateCompany isContractorCompany cancel={cancel} close={close} />
      ))
      .result.then(() => {
        profileService.load().then(() => setEditMode(false));
      });
  };

  React.useEffect(() => {
    reset({
      firstName: profile?.first_name,
      lastName: profile?.last_name,
      email: profile?.email,
      countryId: profile?.country_id,
      timezoneId: profile?.timezone_id,
      dateOfBirth: profile?.date_of_birth
        ? new Date(profile?.date_of_birth)
        : null,
      phoneNumber: profile?.phone_number,
      city: profile?.city,
      state_id: profile?.state_id,
      street: profile?.street,
      zipCode: profile?.zip_code,
    });
  }, [
    profile?.city,
    profile?.country_id,
    profile?.date_of_birth,
    profile?.email,
    profile?.first_name,
    profile?.last_name,
    profile?.phone_number,
    profile?.state_id,
    profile?.street,
    profile?.timezone_id,
    profile?.zip_code,
    reset,
  ]);

  return (
    <>
      <PageTitle>Settings</PageTitle>
      <LayoutContent>
        <form onSubmit={handleSubmit(save)}>
          <FormSection
            title="User settings"
            actions={
              editMode ? (
                <>
                  <Button type="submit" loading={loading}>
                    Save
                  </Button>
                  <Button
                    outline
                    theme="secondary"
                    onClick={(e) => {
                      e.preventDefault();
                      reset();
                      setEditMode(false);
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setEditMode(true);
                  }}
                >
                  Edit
                </Button>
              )
            }
          >
            {profileService.getAccountType(profile) === Role.CONTRACTOR ? (
              <FormGroup label="Account" labelFor="account" inline>
                {editMode ? (
                  <div className="pg-RadioInputs">
                    <RadioButton
                      name="accountType"
                      checked={!profile?.isContractorEntity}
                      onChange={toggleContractorType}
                    >
                      Individual
                    </RadioButton>
                    <RadioButton
                      name="accountType"
                      checked={profile?.isContractorEntity}
                      onChange={createEntity}
                    >
                      Company
                    </RadioButton>
                  </div>
                ) : (
                  <div className="d-flex">
                    <div className="pg-Input form-control-plaintext">
                      {profile?.isContractorEntity ? 'Company' : 'Individual'}
                    </div>
                  </div>
                )}
              </FormGroup>
            ) : null}
            <FormGroup
              label="First Name"
              labelFor="firstName"
              inline
              errors={errors.firstName}
              required
            >
              <Input
                type="text"
                id="firstName"
                name="firstName"
                readOnly={!editMode}
                ref={register({ required: true })}
              />
            </FormGroup>
            <FormGroup
              label="Last Name"
              labelFor="lastName"
              inline
              errors={errors.lastName}
              required
            >
              <Input
                type="text"
                id="lastName"
                name="lastName"
                readOnly={!editMode}
                ref={register({ required: true })}
              />
            </FormGroup>
            <FormGroup
              label="Email"
              labelFor="email"
              inline
              errors={errors.email}
              required
            >
              <Input
                type="email"
                id="email"
                name="email"
                autoComplete="email"
                readOnly={!editMode}
                ref={register({
                  required: true,
                  maxLength: maxLength(255, 'Email'),
                  validate: email,
                })}
              />
            </FormGroup>
            <FormGroup label="Password" labelFor="password" inline>
              {editMode ? (
                <Button theme="link" onClick={changePassword}>
                  Change password
                </Button>
              ) : (
                <Input
                  type="password"
                  autoComplete="current-password"
                  id="password"
                  readOnly={!editMode}
                  placeholder="Password"
                  value="**********"
                />
              )}
            </FormGroup>
            <FormGroup
              label="I'm a citizen of"
              labelFor="country"
              inline
              errors={errors.countryId}
              required
            >
              <Controller
                name="countryId"
                control={control}
                defaultValue=""
                rules={{ required: 'Citizenship country is required' }}
                render={(props) => (
                  <CountrySelect
                    value={props.value}
                    onChange={props.onChange}
                    readOnly={!editMode}
                  />
                )}
              />
            </FormGroup>

            <FormGroup
              label="Street"
              labelFor="street"
              inline
              errors={errors.street}
              required
            >
              <Input
                type="text"
                id="street"
                name="street"
                ref={register({ required: true })}
                readOnly={!editMode}
              />
            </FormGroup>
            <FormGroup
              label="City"
              labelFor="city"
              inline
              errors={errors.city}
              required
            >
              <Input
                type="text"
                id="city"
                name="city"
                ref={register({ required: true })}
                readOnly={!editMode}
              />
            </FormGroup>
            {states.length ? (
              <FormGroup label="State" labelFor="stateId" inline>
                <Controller
                  name="stateId"
                  control={control}
                  defaultValue=""
                  render={(props) => (
                    <StateSelect
                      countryId={countryId}
                      value={props.value}
                      readOnly={!editMode}
                      onChange={props.onChange}
                    />
                  )}
                />
              </FormGroup>
            ) : null}
            <FormGroup
              label="Zip code/Post code"
              labelFor="zipCode"
              inline
              errors={errors.zipCode}
              required
            >
              <Input
                type="text"
                id="zipCode"
                name="zipCode"
                ref={register({ required: true })}
                readOnly={!editMode}
              />
            </FormGroup>

            <FormGroup label="Date of birth" labelFor="dateOfBirth" inline>
              <Controller
                name="dateOfBirth"
                control={control}
                defaultValue=""
                render={(props) => (
                  <DatePicker
                    value={props.value}
                    onChange={props.onChange}
                    readOnly={!editMode}
                  />
                )}
              />
            </FormGroup>
            <FormGroup label="Timezone" labelFor="timezone" inline>
              <Controller
                name="timezoneId"
                control={control}
                defaultValue=""
                render={(props) => (
                  <TimezoneSelect
                    value={props.value}
                    onChange={props.onChange}
                    readOnly={!editMode}
                  />
                )}
              />
            </FormGroup>
            <FormGroup label="Phone Number" labelFor="phoneNumber" inline>
              <Input
                type="phone"
                id="phoneNumber"
                name="phoneNumber"
                readOnly={!editMode}
                placeholder="Phone Number"
                ref={register}
              />
            </FormGroup>
          </FormSection>
        </form>

        {/* <FormSection
        title="Two-step authentication"
        info="Keep your account extra secure with a second authentication step."
        actions={
          <Button theme="secondary" outline>
            Add authentication step
          </Button>
        }
      >
        <List>
          <ListItem
            info="+389214636"
            actions={
              <>
                <div className="input-group">
                  <Button outline small theme="secondary">
                    Update...
                  </Button>
                  <Button outline small theme="secondary">
                    &times;
                  </Button>
                </div>
              </>
            }
          >
            SMS
          </ListItem>
        </List>
      </FormSection> */}

        <br />
        <br />

        <FormSection
          title="Accounts"
          info="The list of accounts to which you are a member"
        >
          <Table>
            <thead>
              <Tr>
                <Th>Id</Th>
                <Th>Account Name</Th>
                <Th>Official Title</Th>
                <Th>Role</Th>
                <Th></Th>
              </Tr>
            </thead>
            <tbody>
              {profile?.companies.map(
                ({
                  company_team_name,
                  company_id,
                  official_title,
                  role_name,
                  role_id,
                  deactivated,
                }) => (
                  <Tr key={company_id}>
                    <Td>{company_id}</Td>
                    <Td>{company_team_name}</Td>
                    <Td>{official_title}</Td>
                    <Td>{role_name}</Td>
                    <Td isAction>
                      <Action
                        tooltip={`Edit Official Title`}
                        onClick={() =>
                          editOfficialTitle(company_id, official_title)
                        }
                      >
                        <IoPencil />
                      </Action>
                    </Td>
                  </Tr>
                ),
              )}
            </tbody>
          </Table>
        </FormSection>
      </LayoutContent>
    </>
  );
};
