import React, { useContext, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { CommonDataService } from '../../shared/services/common-data';
import { useService } from '../../shared/services/use-service';
import { InputPlaceholder } from '../input-placeholder';
import './index.scss';

type CurrencyOption = { label: string; value: number; symbol: string };

export const useCurrencies = () => {
  const commonData = useService(CommonDataService);
  const [currencies, setCurrencies] = React.useState<CurrencyOption[]>([]);

  React.useEffect(() => {
    commonData.get().then(({ currencies }) => {
      setCurrencies(
        currencies?.map((currency) => ({
          label: currency.iso,
          value: currency.id,
          symbol: currency.symbol,
        })) || [],
      );
    });
  }, [commonData]);

  return currencies;
};

const CurrencySelectContainerContext = React.createContext<{
  onChange: (value: number | undefined, currencies: CurrencyOption[]) => any;
  selected: CurrencyOption | undefined;
}>({} as any);

export const CurrencySelectContainer = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [selected, setSelected] = useState<CurrencyOption | undefined>();

  const onChange = (
    selectedCurrency: number | undefined,
    currencies: CurrencyOption[],
  ) => {
    setSelected(currencies.find((item) => item.value === selectedCurrency));
  };

  return (
    <CurrencySelectContainerContext.Provider value={{ onChange, selected }}>
      <div className="pg-CurrencySelectContainer">
        <div className="pg-CurrencySelectContainer__Symbol">
          {selected?.symbol}
        </div>
        {children}
      </div>
    </CurrencySelectContainerContext.Provider>
  );
};

export const CurrencySelect = ({
  value,
  onChange,
  readOnly,
}: {
  value?: number;
  onChange?: (value?: number) => any;
  readOnly?: boolean;
}) => {
  const currencies = useCurrencies();
  const context = useContext(CurrencySelectContainerContext);

  useEffect(() => {
    context.onChange?.(value, currencies);
  }, [context, currencies, value]);

  return !readOnly ? (
    <ReactSelect
      classNamePrefix="pg-CurrencySelect"
      className="pg-ReactSelect"
      placeholder="Currency"
      isClearable={false}
      options={currencies}
      value={currencies.find((currency) => currency.value === value)}
      onChange={(selected) => {
        onChange?.(selected?.value);
      }}
    />
  ) : (
    <InputPlaceholder>
      {currencies.find((currency) => currency.value === value)?.label || 'N/A'}
    </InputPlaceholder>
  );
};
