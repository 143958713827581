import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup } from '../../../components';
import { WireDetails, WirePaymentMethod } from '../../../interfaces';
import { OnboardingRadioGroup } from './radio-group';

const BankAccountDetails = ({ details }: { details: WireDetails }) => {
  return (
    <>
      <p className="mb-0 fw-bold">{details?.bank_name}</p>
      <small className="text-muted">{details?.account_number}</small>
    </>
  );
};

export const WireAccountList = ({
  wireMethods = [],
}: {
  wireMethods?: WirePaymentMethod[];
}) => {
  const { control } = useFormContext();

  return (
    <FormGroup required label="Bank Accounts">
      <Controller
        name="selected_payment_method"
        control={control}
        defaultValue=""
        render={(props) => (
          <OnboardingRadioGroup
            mode="vertical"
            name="selected_payment_method"
            value={props.value}
            onChange={props.onChange}
            options={wireMethods.map((method) => ({
              label: <BankAccountDetails details={method?.details} />,
              value: method,
              key: method.hash,
            }))}
          />
        )}
      />
    </FormGroup>
  );
};
