import classNames from 'classnames';
import React from 'react';
import './index.scss';

export const SidebarContext = React.createContext<{
  isClosed?: boolean;
  setIsClosed?: any;
}>({});

export function useLayout() {
  return React.useContext(SidebarContext);
}

export const Layout = ({
  children,
  sidebar,
  className,
  ...props
}: React.HTMLProps<HTMLDivElement> & { sidebar?: React.ReactNode }) => {
  const [isClosed, setIsClosed] = React.useState(true);

  const handleWindowResize = React.useCallback(() => {
    if (window?.outerWidth >= 768 && isClosed) {
      setIsClosed(false);
    } else if (window?.outerWidth < 768 && !isClosed) {
      setIsClosed(true);
    }
  }, [isClosed]);

  React.useEffect(() => {
    setIsClosed(window?.outerWidth < 768);
  }, []);

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  return (
    <SidebarContext.Provider
      value={{
        isClosed,
        setIsClosed,
      }}
    >
      <div className={classNames('pg-Layout', className)} {...props}>
        {sidebar && !isClosed ? (
          <div className="pg-Layout__Sidebar">{sidebar}</div>
        ) : null}
        <div className="pg-Layout__Content">{children}</div>
      </div>
    </SidebarContext.Provider>
  );
};
