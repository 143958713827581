import React from 'react';
import { IoCopyOutline } from 'react-icons/io5';
import { TemplatePlaceholder as ITemplatePlaceholder } from '../../interfaces';
import './index.scss';

export const TemplatePlaceholder = ({
  placeholder,
}: {
  placeholder: ITemplatePlaceholder;
}) => {
  const [copied, setCopied] = React.useState(false);
  const timeoutRef = React.useRef<any>(null);

  const placeholderValue = React.useMemo(
    () =>
      placeholder?.start_separator +
      placeholder?.placeholder +
      placeholder?.end_separator,
    [
      placeholder?.end_separator,
      placeholder?.placeholder,
      placeholder?.start_separator,
    ],
  );

  const onClick = () => {
    setCopied(true);

    navigator?.clipboard?.writeText?.(placeholderValue);

    timeoutRef.current = setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className="placeholder">
      <span className="placeholder-label" onClick={onClick}>
        {placeholderValue}
      </span>

      {copied && (
        <span className="px-2">
          <IoCopyOutline />
          <small className="mx-1">Copied</small>
        </span>
      )}
    </div>
  );
};
