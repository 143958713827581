import { useMemo } from 'react';
import { Contract } from './index';
import { Box, BoxProps } from '../../components';
import {
  useCommonData,
  useInvoiceTimeFrame,
  useRateTimeFrame,
} from '../../hooks';
import { LabelValueRow } from './label-value-row';
import { LabelValueGroup, LabelValueItem } from './label-value';
import { format } from 'date-fns';

export const PaymentDetails2 = ({ contract }: { contract: Contract }) => {
  const common = useCommonData();
  const { value: rateTimeFrame } = useRateTimeFrame(
    contract.contract_type_id,
    contract.rate_time_frame_id,
  );
  const { value: invoiceTimeFrame } = useInvoiceTimeFrame(
    contract.contract_type_id,
    contract.invoicing_time_frame_id,
  );

  const isPayAsYouGo = useMemo(() => {
    return (
      common?.contract_types
        ?.find((item) => item.id === contract.contract_type_id)
        ?.name.toLowerCase() === 'pay as you go'
    );
  }, [contract.contract_type_id, common]);

  return (
    <LabelValueGroup>
      <LabelValueItem label="Rate">
        {contract.rate}/{rateTimeFrame}
      </LabelValueItem>
      {isPayAsYouGo && contract.invoicing_time_frame_id ? (
        <LabelValueItem label="Invoicing">{invoiceTimeFrame}</LabelValueItem>
      ) : null}
      <LabelValueItem label="First payment date">
        {format(new Date(contract.first_payment_date), 'MMMM do, yyyy')}
      </LabelValueItem>
    </LabelValueGroup>
  );
};

export const PaymentDetails = ({
  contract,
  boxProps,
}: {
  contract: Contract;
  boxProps?: BoxProps;
}) => {
  const common = useCommonData();
  const { value: rateTimeFrame } = useRateTimeFrame(
    contract.contract_type_id,
    contract.rate_time_frame_id,
  );
  const { value: invoiceTimeFrame } = useInvoiceTimeFrame(
    contract.contract_type_id,
    contract.invoicing_time_frame_id,
  );

  const isPayAsYouGo = useMemo(() => {
    return (
      common?.contract_types
        ?.find((item) => item.id === contract.contract_type_id)
        ?.name.toLowerCase() === 'pay as you go'
    );
  }, [contract.contract_type_id, common]);

  return (
    <Box {...boxProps}>
      <h6 className="pg-ContractDetails__ItemTitle">Payment details</h6>
      <LabelValueRow inline label="Rate">
        <div>
          <div className="text-end">{contract.rate}</div>
          <div>Per {rateTimeFrame}</div>
        </div>
      </LabelValueRow>
      {isPayAsYouGo && contract.invoicing_time_frame_id ? (
        <LabelValueRow inline label="Invoicing">
          {invoiceTimeFrame}
        </LabelValueRow>
      ) : null}
      <LabelValueRow inline label="First payment date">
        {format(new Date(contract.first_payment_date), 'MMMM do, yyyy')}
      </LabelValueRow>
    </Box>
  );
};
