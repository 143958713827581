import { Redirect, RouteProps } from 'react-router-dom';
import {
  USER_SETTINGS_ROUTE,
  ORGANIZATION_SETTINGS_ROUTE,
  LOGIN_ROUTE,
  HOME_ROUTE,
  ACCEPT_INVITE_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  COMPLIANCE_ROUTE,
  CONTRACTS_ROUTE,
  INVOICES_ROUTE,
  TAXES_ROUTE,
  CREATE_CONTRACT_ROUTE,
  EDIT_CONTRACT_ROUTE,
  HOME_PATH,
  NOT_FOUND_ROUTE,
  PAYMENT_METHODS_ROUTE,
  PAY_ROUTE,
  CONTRACT_DETAILS_ROUTE,
  PAYMENT_HISTORY_ROUTE,
  ONBOARDING_ROUTE,
  IFRAME_HANDLER_ROUTE,
  ERROR_PAGE_ROUTE,
  CREATE_TEMPLATE_ROUTE,
  // EDIT_TEMPLATE_ROUTE,
  SHOW_TEMPLATE_ROUTE,
  TEMPLATE_BUILDER_ROUTE,
  PRESETS_ROUTE,
  CREATE_PRESET_ROUTE,
  EDIT_PRESET_ROUTE,
  SHOW_PRESET_ROUTE,
  CREATE_PRESET_GROUP_ROUTE,
  EDIT_PRESET_GROUP_ROUTE
} from './pages';
import { COMPLIANCE_DOCUMENTS_ROUTE } from './pages/compliance-documents';
import { CONTRACT_PREVIEW_ROUTE } from './pages/contract-preview';
import { TALENT_ONBOARDING_ROUTE } from './pages/talent-onboarding';

export type ProtectedRouteProps = RouteProps & {
  protect?: boolean;
};

export type RouteWithPermissionsProps = RouteProps & {
  permissions?: string[];
};

export type RouteWithRolesProps = RouteProps & {
  roles?: string[];
};

export type RouteWithMiddlewaresProps = RouteProps & {
  middlewares?: any[];
};

export type RouteConfigItem = ProtectedRouteProps &
  RouteWithPermissionsProps &
  RouteWithMiddlewaresProps &
  RouteWithRolesProps;

export const routes: RouteConfigItem[] = [
  {
    exact: true,
    path: '/',
    children: <Redirect to={HOME_PATH} />,
  },
  HOME_ROUTE,
  USER_SETTINGS_ROUTE,
  ORGANIZATION_SETTINGS_ROUTE,
  LOGIN_ROUTE,
  REGISTER_ROUTE,
  RESET_PASSWORD_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  CONTRACT_PREVIEW_ROUTE,
  ACCEPT_INVITE_ROUTE,
  CREATE_CONTRACT_ROUTE,
  COMPLIANCE_ROUTE,
  COMPLIANCE_DOCUMENTS_ROUTE,
  CONTRACT_DETAILS_ROUTE,
  CONTRACTS_ROUTE,
  EDIT_CONTRACT_ROUTE,
  INVOICES_ROUTE,
  PAY_ROUTE,
  TAXES_ROUTE,
  PAYMENT_METHODS_ROUTE,
  PAYMENT_HISTORY_ROUTE,
  ONBOARDING_ROUTE,
  TALENT_ONBOARDING_ROUTE,

  TEMPLATE_BUILDER_ROUTE,
  CREATE_TEMPLATE_ROUTE,
  // EDIT_TEMPLATE_ROUTE,
  SHOW_TEMPLATE_ROUTE,

  PRESETS_ROUTE,
  CREATE_PRESET_ROUTE,
  CREATE_PRESET_GROUP_ROUTE,
  EDIT_PRESET_ROUTE,
  EDIT_PRESET_GROUP_ROUTE,
  SHOW_PRESET_ROUTE,

  IFRAME_HANDLER_ROUTE,
  ERROR_PAGE_ROUTE,
  NOT_FOUND_ROUTE,
].map((item: RouteConfigItem) => ({
  ...item,
  protect: item.protect ?? true,
  permissions: item?.permissions ?? [],
  roles: item?.roles ?? [],
  middlewares: item?.middlewares ?? [],
}));
