import React from 'react';
import { useHistory } from 'react-router-dom';
import { useApi } from '../../hooks/use-api';
import { Steps } from './steps';
import {
  isNil,
  snakeCase,
  isEmpty,
  isEqual,
  has,
  chain,
  isString,
} from 'lodash';
import { useCompany } from '../../hooks/use-company';
import { GeneralInfo } from './steps/general-info';
import { Compensation } from './steps/compensation';
import { PositionDetails } from './steps/position-details';
import { Compliance } from './steps/compliance';
import './index.scss';
import { useToaster } from '../../hooks/use-toaster';
import { Button, ErrorList, PageTitle } from '../../components';
import { useContractTypes, useModal } from '../../hooks';
import { IoClose } from 'react-icons/io5';
import { HiDocumentAdd } from 'react-icons/hi';
import { ContractStatus } from '../../constants';
import { CONTRACTS_PATH } from '..';

export const ContractContext = React.createContext<any>({});

export const useContractContext = () => {
  const context = React.useContext(ContractContext);

  if (context === undefined) {
    throw new Error(
      'useContractContext must be used within a ContractProvider',
    );
  }

  return context;
};

export const ContractProvider = ({ children }: { children: any }) => {
  const [contractData, setContractData] = React.useState<any>({});
  const [isDirty, setIsDirty] = React.useState<boolean>(false);

  const isEdit = React.useMemo(() => !!contractData?.id, [contractData?.id]);

  const updateData = React.useCallback(
    (key: string, value: any) => {
      const isNewValue = !isEqual(contractData[key], value);

      if (isNewValue) {
        setIsDirty(true);
      }

      return setContractData((prev: any) => ({ ...prev, [key]: value }));
    },
    [contractData, setContractData, setIsDirty],
  );

  const contextValue = React.useMemo(
    () => ({
      isDirty,
      contractData,
      updateData,
      setContractData,
      isEdit,
    }),
    [isDirty, contractData, setContractData, updateData, isEdit],
  );

  return (
    <ContractContext.Provider value={contextValue}>
      {children}
    </ContractContext.Provider>
  );
};

export const ContractSubtitle = ({
  name,
  contractType,
}: {
  name: string;
  contractType: number;
}) => {
  const { selectedContract } = useContractTypes(contractType);

  return (
    <>
      <span>{name}</span>
      <span className="pg-ContractListItem__Type">
        {selectedContract?.name}
      </span>
    </>
  );
};

export const SaveDraftButton = ({ onSubmit }: { onSubmit: any }) => {
  const { contractData } = useContractContext();

  const canSaveDraft = React.useMemo(() => {
    const requiredKeys = [
      'contractor_country_id',
      'contractor_email',
      'contractor_name',
      'use_own',
    ];

    return chain(requiredKeys)
      .filter(
        (key) =>
          !has(contractData, key) ||
          (isString(contractData[key]) && isEmpty(contractData[key])),
      )
      .isEmpty()
      .value();
  }, [contractData]);

  return canSaveDraft ? (
    <Button theme="light" outline onClick={onSubmit}>
      Save and continue later
    </Button>
  ) : null;
};

export const ContractWrapper = ({
  step,
  contract,
}: {
  step?: string;
  contract?: any;
}) => {
  const api = useApi();
  const modal = useModal();
  const history = useHistory();
  const toaster = useToaster();
  const { selectedCompany } = useCompany();
  const [loading, setLoading] = React.useState(false);
  const { contractData, setContractData, isEdit } = useContractContext();

  const route = React.useMemo(
    () => (contract?.id ? `edit-contract/${contract?.id}` : 'create-contract'),
    [contract?.id],
  );

  const steps = React.useMemo(
    () => [
      { id: 'general-info', name: 'General Info' },
      { id: 'position-details', name: 'Position Details' },
      { id: 'compensation', name: 'Compensation' },
      { id: 'compliance', name: 'Conditions & Compliance' },
    ],
    [],
  );

  const allowedStepsIds = React.useMemo(
    () => Object.keys(steps).map((s) => Number(s)),
    [steps],
  );

  const [activeStep, setActiveStep] = React.useState<number>(
    steps.findIndex(({ id }) => id === step) || 0,
  );

  const submitUrl = React.useMemo(
    () =>
      isEdit ? `/api/client/contract/${contract?.id}` : '/api/client/contract',
    [isEdit, contract?.id],
  );

  const submitMethod = React.useMemo(
    () => (isEdit ? 'PATCH' : 'POST'),
    [isEdit],
  );

  const canGoForward = React.useCallback(
    (step = activeStep) => step + 1 < steps.length,
    [activeStep, steps],
  );

  const canGoBack = React.useCallback(
    (step = activeStep) => step - 1 >= 0,
    [activeStep],
  );

  const nextStep = React.useCallback(() => {
    const newStep = canGoForward(activeStep) ? activeStep + 1 : activeStep;
    return history.replace(`/${route}/${steps[newStep].id}`);
  }, [activeStep, canGoForward, history, route, steps]);

  const prevStep = React.useCallback(() => {
    const newStep = canGoBack(activeStep) ? activeStep - 1 : activeStep;
    return history.replace(`/${route}/${steps[newStep].id}`);
  }, [activeStep, canGoBack, history, route, steps]);

  const update = React.useCallback(
    (data: any) => {
      setContractData((current: any) => ({ ...current, ...data }));
    },
    [setContractData],
  );

  const prepareContractData = React.useCallback(() => {
    return JSON.parse(
      JSON.stringify({
        ...contractData,
        companyId: selectedCompany?.company_id,
      }),
    );
  }, [contractData, selectedCompany?.company_id]);

  const transformArrayData = React.useCallback((key: string, form: any) => {
    const fileIds = form[key].map((f: any) => f?.id).filter((f: any) => f);

    return isEmpty(fileIds) ? '' : fileIds;
  }, []);

  const transformContractData = React.useCallback(() => {
    const form = prepareContractData();

    const body = new FormData();

    Object.keys(form).forEach((key) => {
      if (isNil(form[key])) return;

      if (key === 'userContractFiles')
        body.append('own_contract_file_id[]', transformArrayData(key, form));
      else if (key === 'additionalFiles')
        body.append('additional_file_id[]', transformArrayData(key, form));
      else body.append(snakeCase(key), form[key]);
    });

    return body;
  }, [prepareContractData, transformArrayData]);

  const submit = React.useCallback(() => {
    setLoading(true);

    const body = transformContractData();

    api
      .fetch(submitUrl, {
        method: submitMethod,
        body,
      })
      .then(({ data: { contract } }) => {
        setLoading(false);

        setContractData(contract);

        if (contract.status === ContractStatus.DRAFT) {
          return history.push(`/contracts`);
        } else {
          return history.push(`/contracts/${contract.id}`);
        }
      })
      .catch((e) => {
        setLoading(false);

        const errors: string[] = Object.values(e?.errors || []);

        toaster.danger({
          message: (
            <ErrorList
              errors={errors}
              fallback="There was an error processing your request."
            />
          ),
        });
      });
  }, [
    api,
    history,
    setContractData,
    submitMethod,
    submitUrl,
    toaster,
    transformContractData,
  ]);

  const stepControlProps = React.useMemo(
    () => ({
      onNext: (data: any) => {
        update(data);
        nextStep();
      },
      onPrev: (data: any) => {
        update(data);
        prevStep();
      },
      showPrev: canGoBack(),
    }),
    [canGoBack, nextStep, prevStep, update],
  );

  const activeStepComponent = React.useCallback(() => {
    const activeStepItem = steps[activeStep];

    switch (activeStepItem?.id) {
      case 'general-info':
        return <GeneralInfo stepControlProps={stepControlProps} />;
      case 'position-details':
        return <PositionDetails stepControlProps={stepControlProps} />;
      case 'compensation':
        return <Compensation stepControlProps={stepControlProps} />;
      case 'compliance':
        return (
          <Compliance
            stepControlProps={{
              ...stepControlProps,
              loading,
              nextText: (
                <>
                  <HiDocumentAdd /> {isEdit ? 'Save' : 'Create'}
                </>
              ),
              onNext: submit,
            }}
          />
        );
    }
  }, [activeStep, isEdit, loading, stepControlProps, steps, submit]);

  const close = () => {
    modal
      .confirmModal({
        title: 'Are you sure?',
        message: 'All unsaved changes will be discarded.',
      })
      .result.then(() => history.push(CONTRACTS_PATH))
      .catch(() => {});
  };

  React.useEffect(() => {
    setContractData(contract ?? {});
  }, [setContractData, contract]);

  React.useEffect(() => {
    const currentStepId = steps.findIndex(({ id }) => id === step);

    const initialStep: number = allowedStepsIds.includes(currentStepId)
      ? currentStepId
      : 0;

    return setActiveStep(initialStep);
  }, [allowedStepsIds, step, steps]);

  React.useEffect(() => {
    if (allowedStepsIds.includes(activeStep)) {
      history.replace(`/${route}/${steps[activeStep].id}`);
    }
  }, [history, route, activeStep, steps, allowedStepsIds]);

  return (
    <>
      <PageTitle
        bordered
        actions={
          <>
            <SaveDraftButton onSubmit={submit} />

            <Button onClick={close} theme="link">
              <IoClose />
            </Button>
          </>
        }
      >
        {isEdit ? 'Edit contract' : 'Add a new contract'}
      </PageTitle>

      <div className="row" style={{ minHeight: '100%' }}>
        <div className="col-md-3" style={{ borderRight: '1px solid #ddd' }}>
          <Steps type="vertical" steps={steps} activeStep={activeStep} />
        </div>

        <div className="col-md-9">
          <div className="row">
            <div className="col-md-12">{activeStepComponent()}</div>
          </div>
        </div>
      </div>
    </>
  );
};
