import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormGroup } from '../../../components';
import { PayoneerPaymentMethod } from '../../../interfaces';
import { OnboardingRadioGroup } from './radio-group';

export const PayoneerAccountList = ({
  payoneerMethods = [],
}: {
  payoneerMethods?: PayoneerPaymentMethod[];
}) => {
  const { control } = useFormContext();

  return (
    <FormGroup required label="Payoneer Accounts">
      <Controller
        name="selected_payment_method"
        control={control}
        defaultValue=""
        render={(props) => (
          <OnboardingRadioGroup
            mode="vertical"
            name="selected_payment_method"
            value={props.value}
            onChange={props.onChange}
            options={payoneerMethods.map((method) => ({
              label: method?.details?.email,
              value: method,
              key: method.hash,
            }))}
          />
        )}
      />
    </FormGroup>
  );
};
