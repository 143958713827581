import React, { useState } from 'react';
import './index.scss';
import { SidebarItem } from './sidebar-item';
import {
  IoPersonCircle,
  IoDocumentText,
  IoHome,
  IoAddCircle,
  IoCog,
  IoStar,
  IoReader,
  IoPeople,
} from 'react-icons/io5';
import { FaGavel, FaCreditCard } from 'react-icons/fa';
import {
  COMPLIANCE_PATH,
  CONTRACTS_PATH,
  HOME_PATH,
  INVOICES_PATH,
  ORGANIZATION_SETTINGS_PATH,
  PAYMENT_METHODS_PATH,
  PRESETS_PATH,
  TEMPLATE_BUILDER_PATH,
  USER_SETTINGS_PATH,
} from '../../pages';
import { useAuth } from '../../hooks';
import { Link } from 'react-router-dom';
import { Dropdown } from '../dropdown';
import { useCompany } from '../../hooks/use-company';
import { CompanySidebarSelect } from '../company-sidebar-select';
import logo from '../../images/logo-white.svg';
import { ContractorToggle } from '../contractor-toggle';
import { useProfileService } from '../../shared/services';
import { Collapse } from '@blueprintjs/core';
import { COMPLIANCE_DOCUMENTS_PATH } from '../../pages/compliance-documents';
import { PermissionArea } from '../../constants';
import { PAYMENT_HISTORY_PATH } from '../../pages/payment-history';
import { CREATE_CONTRACT_PATH } from '../../pages/create-contract';
import { useLayout } from '../layout';
import { HiTemplate } from 'react-icons/hi';

export interface ISidebarItem {
  label: React.ReactNode;
  to: string;
  icon?: React.ReactNode;
  order?: number;
}

const PeopleAccordion = ({ disabled = false }: { disabled?: boolean }) => {
  const [open, setOpen] = useState(false);
  const { canRead, canWrite } = useProfileService();
  const { selectedCompany, companies } = useCompany();

  const canReadContractsData = React.useMemo(
    () =>
      selectedCompany &&
      selectedCompany?.company_id &&
      canRead(selectedCompany.company_id, PermissionArea.CONTRACTS),
    [canRead, selectedCompany],
  );

  const canWriteContractsData = React.useMemo(
    () =>
      selectedCompany &&
      selectedCompany?.company_id &&
      companies?.length &&
      canWrite(selectedCompany.company_id, PermissionArea.CONTRACTS),
    [canWrite, companies, selectedCompany],
  );

  return (
    <>
      <SidebarItem
        icon={<IoPeople />}
        onClick={() => setOpen((open) => !open)}
        label="People"
        isOpen={open}
        isAccordion
        disabled={disabled}
      />
      <Collapse className="pg-SidebarItem__Accordion" isOpen={open}>
        <div className="pg-SidebarItem__AccordionContent">
          {canWriteContractsData && (
            <SidebarItem
              icon={<IoAddCircle />}
              to={CREATE_CONTRACT_PATH}
              label="Create a contract"
              disabled={disabled}
            />
          )}

          {canReadContractsData && (
            <SidebarItem
              icon={<IoDocumentText />}
              to={CONTRACTS_PATH}
              label="Contracts"
              disabled={disabled}
            />
          )}

          <SidebarItem
            icon={<FaGavel />}
            to={COMPLIANCE_PATH}
            label="Compliance"
            disabled={disabled}
          />

          <SidebarItem
            icon={<HiTemplate />}
            to={TEMPLATE_BUILDER_PATH}
            label="Templates"
            disabled={disabled}
          />

          <SidebarItem
            icon={<HiTemplate />}
            to={PRESETS_PATH}
            label="Presets"
            disabled={disabled}
          />
        </div>
      </Collapse>
    </>
  );
};

const PaymentsAccordion = ({ disabled = false }: { disabled?: boolean }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <SidebarItem
        icon={<FaCreditCard />}
        onClick={() => setOpen((open) => !open)}
        label="Payments"
        isOpen={open}
        isAccordion
        disabled={disabled}
      />
      <Collapse className="pg-SidebarItem__Accordion" isOpen={open}>
        <div className="pg-SidebarItem__AccordionContent">
          <SidebarItem
            icon={<IoReader />}
            to={INVOICES_PATH}
            label="Invoices"
            disabled={disabled}
          />
          <SidebarItem
            icon={<IoStar />}
            to={PAYMENT_METHODS_PATH}
            label="Payment Methods"
            disabled={disabled}
          />
          <SidebarItem
            icon={<IoStar />}
            to={PAYMENT_HISTORY_PATH}
            label="Payment History"
            disabled={disabled}
          />
        </div>
      </Collapse>
    </>
  );
};

export const Sidebar = () => {
  const { logout } = useAuth();
  const { setIsClosed } = useLayout();
  const { profile, canRead, canSwitchAccountType, actAsClient } =
    useProfileService();
  const { selectedCompany } = useCompany();

  const isCompanyOnboarded = React.useMemo(
    () => (actAsClient ? selectedCompany?.onboarded : true),
    [actAsClient, selectedCompany?.onboarded],
  );

  const canReadCompanyData = React.useMemo(
    () =>
      selectedCompany &&
      selectedCompany?.company_id &&
      canRead(selectedCompany.company_id, PermissionArea.COMPANIES),
    [canRead, selectedCompany],
  );

  const canReadFinancialData = React.useMemo(
    () =>
      selectedCompany &&
      selectedCompany?.company_id &&
      canRead(selectedCompany.company_id, PermissionArea.PAYMENTS),
    [canRead, selectedCompany],
  );

  return (
    <>
      <span className="pg-Sidebar__CloseBtn" onClick={() => setIsClosed(true)}>
        &times;
      </span>

      <div className="pg-Sidebar">
        <h3 className="pg-Sidebar__Logo mb-0">
          <a href="/" className="pg-Sidebar__LogoLink">
            <img
              className="pg-Sidebar__LogoImage"
              src={logo}
              alt="Brysk Logo"
            />
          </a>
        </h3>

        <div className="pg-Sidebar__CompanySelector">
          {actAsClient && (
            <CompanySidebarSelect disabled={!isCompanyOnboarded} />
          )}
        </div>

        <div className="pg-Sidebar__Content">
          {actAsClient ? (
            <>
              <SidebarItem icon={<IoHome />} to={HOME_PATH} label="Dashboard" />

              <PeopleAccordion disabled={!isCompanyOnboarded} />

              {canReadFinancialData && (
                <PaymentsAccordion disabled={!isCompanyOnboarded} />
              )}

              {canReadCompanyData && (
                <SidebarItem
                  icon={<IoCog />}
                  to={ORGANIZATION_SETTINGS_PATH}
                  label="Company Profile"
                  disabled={!isCompanyOnboarded}
                />
              )}
            </>
          ) : (
            <>
              <SidebarItem icon={<IoHome />} to={HOME_PATH} label="Dashboard" />
              <SidebarItem
                icon={<IoDocumentText />}
                to={CONTRACTS_PATH}
                label="Contracts"
              />
              <SidebarItem
                icon={<FaGavel />}
                to={COMPLIANCE_DOCUMENTS_PATH}
                label="Compliance"
              />
              <SidebarItem
                icon={<IoReader />}
                to={INVOICES_PATH}
                label="Invoices"
              />
            </>
          )}
        </div>

        <div className="pg-Sidebar__Footer">
          {canSwitchAccountType() ? <ContractorToggle /> : null}
          <Dropdown
            direction="up"
            target={
              <>
                <div className="pg-UserMenu">
                  <IoPersonCircle />
                  <div className="pg-UserMenu__Name">{profile?.name}</div>
                </div>
              </>
            }
          >
            {(close) => (
              <>
                {isCompanyOnboarded && (
                  <Link onClick={close} to={USER_SETTINGS_PATH}>
                    Settings
                  </Link>
                )}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="link"
                  style={{ cursor: 'pointer', textDecoration: 'none' }}
                  onClick={() => {
                    close();
                    logout();
                  }}
                >
                  Logout
                </a>
              </>
            )}
          </Dropdown>
        </div>
      </div>
    </>
  );
};
