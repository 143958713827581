import React, { useCallback } from 'react';
import { Button } from '../button';
import { TextSignature } from './text-signature';
import { DrawSignature } from './draw-signature';
import './index.scss';

export const SignaturePad = ({
  onChange,
}: {
  onChange?: (data: FormData | undefined | null) => any;
}) => {
  const [signatureType, setSignatureType] = React.useState<'draw' | 'text'>(
    'draw',
  );

  const toggleSignatureType = () => {
    if (signatureType === 'draw') {
      setSignatureType('text');
    } else if (signatureType === 'text') {
      setSignatureType('draw');
    }
  };

  const updateSignature = useCallback(
    async (base64Signature?: string) => {
      if (!base64Signature) {
        return onChange?.(undefined);
      }

      const blob = await fetch(base64Signature).then((res) => res.blob());

      if (blob) {
        const signature = new File([blob], 'signature.png');
        const formData = new FormData();

        formData.append('signature_file', signature);

        onChange?.(formData);
      }
    },
    [onChange],
  );

  return (
    <>
      <div className="mb-3 d-flex align-items-center">
        <p className="m-0">
          Sign by {signatureType === 'text' ? 'typing' : 'drawing'} your
          signature below.
        </p>
        <Button
          theme="link"
          className="no-outline-focus mx-1 p-0"
          onClick={toggleSignatureType}
        >
          {signatureType === 'text' ? 'Draw' : 'Type'} your signature
        </Button>
        <p className="m-0">instead.</p>
      </div>
      {signatureType === 'draw' && <DrawSignature onChange={updateSignature} />}

      {signatureType === 'text' && <TextSignature onChange={updateSignature} />}
    </>
  );
};
