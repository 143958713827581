import { OnboardingHeader } from '../components/header';
import { RadioBox } from '../components/radio-box';
import company from '../../../images/company.png';
import individual from '../../../images/individual.png';
import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import { ContractorType } from '../../../constants';

export const SelectEntity = () => {
  const { control } = useFormContext();

  return (
    <>
      <OnboardingHeader
        size="medium"
        subtitle="Select your Contractor Type in the section below. The selection will affect how you appear in contracts, tax documentation and payments."
      >
        Contractor type
      </OnboardingHeader>

      <Controller
        name="entityType"
        defaultValue={ContractorType.INDIVIDUAL}
        control={control}
        render={(props) => (
          <div className="row">
            <div className="col-6">
              <RadioBox
                name="entityType"
                checked={props.value === ContractorType.INDIVIDUAL}
                onChange={(checked) =>
                  props.onChange(checked ? ContractorType.INDIVIDUAL : '')
                }
              >
                <img src={individual} alt="Individual entity icon" />
                <h5>Individual</h5>
              </RadioBox>
            </div>

            <div className="col-6">
              <RadioBox
                name="entityType"
                checked={props.value === ContractorType.ENTITY}
                onChange={(checked) =>
                  props.onChange(checked ? ContractorType.ENTITY : '')
                }
              >
                <img src={company} alt="Company entity icon" />
                <h5>Company</h5>
              </RadioBox>
            </div>
          </div>
        )}
      />
    </>
  );
};
