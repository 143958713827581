import { singleton } from 'tsyringe';
import { WebStorageException } from '../../exceptions';

@singleton()
export default class Storage {
  static checkWebStoragePermissions() {
    try {
      window.sessionStorage.getItem('test_session_storage_access');
      window.localStorage.getItem('test_local_storage_access');
    } catch (error) {
      throw new WebStorageException();
    }
  }

  static isRunningInIframe() {
    this.checkWebStoragePermissions();

    return window.top !== window.self;
  }

  static setItem(key: string, value: string) {
    return this.isRunningInIframe()
      ? sessionStorage.setItem(key, value)
      : localStorage.setItem(key, value);
  }

  static getItem(key: string) {
    return this.isRunningInIframe()
      ? sessionStorage.getItem(key)
      : localStorage.getItem(key);
  }

  static removeItem(key: string) {
    return this.isRunningInIframe()
      ? sessionStorage.removeItem(key)
      : localStorage.removeItem(key);
  }

  static clear() {
    return this.isRunningInIframe()
      ? sessionStorage.clear()
      : localStorage.clear();
  }
}
