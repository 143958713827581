import './label-value-row.scss';

export const LabelValueRow = ({
  label,
  children,
  inline = false,
}: {
  label: React.ReactNode;
  children?: React.ReactNode;
  inline?: boolean;
}) => {
  return (
    <div
      className={`pg-LabelValueRow ${inline ? 'pg-LabelValueRow--inline' : ''}`}
    >
      <div className="pg-LabelValueRow__Label">{label}</div>
      <div className="pg-LabelValueRow__Value">{children}</div>
    </div>
  );
};
