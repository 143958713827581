import React from 'react';
import { DataGrid, GridFilterModel, GridSortModel } from '@mui/x-data-grid';
import { CustomTableProps } from '../../types';
import { ApiQueryParams } from '../../hooks/use-filters';
import { CustomQuickFilter } from '../custom-quick-filter';
import { DataGridProps } from '@mui/x-data-grid/models/props/DataGridProps';

export const CustomTable = ({
  rows,
  columns,
  onRowClick = () => {},
  hideFilters = false,
  hideFooter = false,
  density = 'standard',
  serverSide = false,
  filters,
  setFilters,
  totalRowCount = 0,
  customFilters = [],
  ...props
}: {
  totalRowCount?: number;
  filters?: ApiQueryParams;
  setFilters?: (f: Partial<ApiQueryParams>) => void;
  serverSide?: boolean;
  customFilters?: React.FC[];
} & CustomTableProps &
  Partial<DataGridProps>) => {
  /**
   * Handle current page changes.
   */
  const updatePage = React.useCallback(
    (newPage: number, details: any) => {
      setFilters?.({
        page: newPage,
      });
    },
    [setFilters],
  );

  /**
   * Handle page size changes.
   */
  const updatePageSize = React.useCallback(
    (newPageSize: number) => {
      setFilters?.({
        page: 0,
        pageSize: newPageSize,
      });
    },
    [setFilters],
  );

  /**
   * Handle columns sorting.
   */
  const handleSortModelChange = React.useCallback(
    (sortModel: GridSortModel) => {
      const sortParams = sortModel?.[0];

      setFilters?.({
        page: 0,
        sortBy: sortParams?.field || 'id',
        sortDir: sortParams?.sort || 'desc',
      });
    },
    [setFilters],
  );

  /**
   * Handle built-in filters (i.e. Search).
   */
  const handleFilterModelChange = React.useCallback(
    (filterModel: GridFilterModel) => {
      setFilters?.({
        page: 0,
        search: filterModel?.quickFilterValues?.join(' '),
      });
    },
    [setFilters],
  );

  const paginationProps: Partial<DataGridProps> = React.useMemo(() => {
    if (!serverSide)
      return {
        initialState: {
          pagination: {
            pageSize: 10,
          },
        },
      };

    return {
      page: filters?.page || 0,
      pageSize: filters?.pageSize || 10,
      rowCount: totalRowCount || 0,
    };
  }, [serverSide, filters?.page, filters?.pageSize, totalRowCount]);

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          autoHeight
          disableColumnMenu
          disableColumnSelector
          hideFooterSelectedRowCount
          onRowClick={onRowClick}
          rows={rows}
          columns={columns}
          headerHeight={56}
          rowHeight={67}
          density={density}
          hideFooter={hideFooter}
          rowsPerPageOptions={[10, 25, 50, 100]}
          components={{
            Toolbar: hideFilters ? null : CustomQuickFilter,
          }}
          componentsProps={{
            panel: {},
            toolbar: { customFilters, setFilters },
          }}
          pagination
          filterMode={serverSide ? 'server' : 'client'}
          sortingMode={serverSide ? 'server' : 'client'}
          paginationMode={serverSide ? 'server' : 'client'}
          onPageChange={updatePage}
          onPageSizeChange={updatePageSize}
          onSortModelChange={handleSortModelChange}
          onFilterModelChange={handleFilterModelChange}
          {...props}
          {...paginationProps}
        />
      </div>
    </div>
  );
};
