import { CustomException } from '../interfaces';

import { WebStorageExceptionMessage } from '../constants';

export class WebStorageException extends Error implements CustomException {
  constructor(message: string = WebStorageExceptionMessage) {
    super(message);

    this.name = 'WebStorageException';
  }

  caption() {
    return (
      <ul>
        <li>
          We don't store any sensitive information, nor accessing other websites
          data.
        </li>

        <li>
          We're using browser storage to provide you with the best experiance.
        </li>
      </ul>
    );
  }
}
