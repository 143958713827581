// import { Service } from 'typedi';
// import { injectable } from "inversify";
// import { provide } from "inversify-binding-decorators";
import { singleton } from 'tsyringe';

@singleton()
export class EnvService {
  apiPath = process.env.REACT_APP_API as string;

  stripeKey = process.env.REACT_APP_STRIPE_KEY as string;
}
