import React, { useContext, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Button,
  CommonDataDisplay,
  ErrorList,
  Loader,
  RateTimeFrame,
  SignaturePad,
} from '../../../components';

import { ContractorType, PAYMENT_METHOD_TYPE, Role } from '../../../constants';
import { useApi, useCommonData, useIframe } from '../../../hooks';
import { useToaster } from '../../../hooks/use-toaster';
import { Contract } from '../../contracts';
import { OnboardingActions } from '../components/actions';
import { ListItem } from '../components/list-item';
import {
  ENTITY_DETAILS_STEP,
  PAYMENT_DETAILS_STEP,
  TalentOnboardingContext,
} from '../talent';
import { CONTRACT_DETAILS_PATH } from '../../contracts/contract-details';
import { useHistory } from 'react-router-dom';
import AdditionalRequirements from '../components/additional-requirements';
import { useService } from '../../../shared/services/use-service';
import { TokenService } from '../../../shared/services';

export const SignContract = () => {
  const api = useApi();
  const history = useHistory();
  const toaster = useToaster();
  const common = useCommonData();
  const { token } = useService(TokenService);
  const { isIframe } = useIframe();
  const { handleSubmit, watch, control } = useFormContext();

  const certified = watch('certified');
  const entityType = watch('entityType');
  const companyName = watch('company.name');
  const lastName = watch('individual.lastName');
  const firstName = watch('individual.firstName');
  const paymentMethodType = watch('selected_payment_method.type');

  const [preview, setPreview] = useState('');
  const [loading, setLoading] = useState(true);
  const [contract, setContract] = useState<Contract>({} as Contract);
  const [signature, setSignature] = useState<FormData | null | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [hasMissingRequirements, setHasMissingRequirements] =
    useState<boolean>(true);

  const { contractId, goToStep } = useContext(TalentOnboardingContext);

  const signatureTypeId = React.useMemo(
    () =>
      common?.signature_types?.find(
        (signatureType) =>
          signatureType.name.toLowerCase() === `${Role.CONTRACTOR} signature`,
      )?.id,
    [common?.signature_types],
  );

  const contractorName = () => {
    if (entityType === ContractorType.INDIVIDUAL) {
      return `${firstName} ${lastName}`;
    }

    return companyName;
  };

  const onSubmit = () => {
    if (!signature) return;

    setIsSubmitting(true);

    const typeId = signatureTypeId?.toString();

    if (typeId) {
      signature.append('signature_type_id', typeId);
    }

    signature.append(
      'contractor_type',
      entityType || ContractorType.INDIVIDUAL,
    );

    api
      .fetch(`/api/client/contract/${contract.id}/sign`, {
        method: 'POST',
        body: signature,
      })
      .then((res) => {
        toaster.success({ message: 'Contract was signed successfully.' });

        if (isIframe) {
          const data = {
            status: true,
            channel: 'SIGN_CONTRACT_FRAME',
          };

          window.parent.postMessage(JSON.stringify(data), '*');
        } else {
          history.replace(CONTRACT_DETAILS_PATH?.replace(':id', contract.id));
        }
      })
      .catch(({ errors }) => {
        setIsSubmitting(false);

        toaster.danger({
          message: <ErrorList errors={errors} />,
        });
      });
  };

  useEffect(() => {
    api
      .get(`/api/client/contract/${contractId}`)
      .then(({ data: { contract } }) => setContract(contract))
      .finally(() => setLoading(false));
  }, [api, contractId]);

  useEffect(() => {
    api
      .get(
        `/api/client/contract/${contractId}/preview?contractor_type=${entityType}`,
      )
      .then((res) => {
        setPreview(res.data['contract-preview-url']);
      });
  }, [api, contractId, entityType]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {loading ? (
        <Loader fullscreen />
      ) : (
        <>
          <div className="row">
            <div className="col-12">
              <h5>Overview</h5>
              <ListItem
                label="Contractor"
                onEdit={() => goToStep(ENTITY_DETAILS_STEP.id)}
              >
                {contractorName()}
              </ListItem>
              <ListItem label="Amount">
                {contract.rate}{' '}
                <CommonDataDisplay
                  type="currencies"
                  dataKey="iso"
                  id={contract.currency_id}
                />{' '}
                per{' '}
                <span style={{ textTransform: 'lowercase' }}>
                  <RateTimeFrame
                    contractTypeId={contract.contract_type_id}
                    rateTimeFrameId={contract.rate_time_frame_id}
                  />
                </span>
              </ListItem>
              <ListItem
                label="Payment"
                onEdit={() => goToStep(PAYMENT_DETAILS_STEP.id)}
              >
                {PAYMENT_METHOD_TYPE[paymentMethodType]}
              </ListItem>
              {contract.start_date && (
                <ListItem label="Start date">
                  {format(new Date(contract.start_date), 'MMMM dd, yyyy')}
                </ListItem>
              )}
              {contract.termination_date && (
                <ListItem label="End date">
                  {format(new Date(contract.termination_date!), 'MMMM dd, yyyy')}
                </ListItem>
              )}

              <AdditionalRequirements
                contract={contract}
                setHasMissingRequirements={setHasMissingRequirements}
              />

              {preview && (
                <div className="col-12 mt-5">
                  <iframe
                    title="Contract Preview"
                    style={{
                      width: '100%',
                      height: '100%',
                      border: 'none',
                      minHeight: '100vh',
                    }}
                    src={`${preview}?auth=${token}`}
                  />
                </div>
              )}

              <div className="mt-5">
                <SignaturePad onChange={(data) => setSignature(data)} />
              </div>

              <div className="mt-5">
                <Controller
                  name="certified"
                  control={control}
                  rules={{ required: true }}
                  render={(props: {
                    value: boolean;
                    onChange: (value: number) => any;
                  }) => (
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="certified"
                        checked={!!props.value}
                        onChange={(e) =>
                          props.onChange(e.target.checked ? 1 : 0)
                        }
                      />
                      <label className="form-check-label" htmlFor="certified">
                        I certify that the information I have entred is accurate
                        and I acknowledge that it will be used for signing legal
                        documents
                      </label>
                    </div>
                  )}
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-md-5">
            <OnboardingActions>
              <Button
                theme="light"
                outline
                onClick={() => goToStep(PAYMENT_DETAILS_STEP.id)}
              >
                Back
              </Button>
              <Button
                type="submit"
                theme="primary"
                disabled={Boolean(
                  !certified ||
                    isSubmitting ||
                    !signature ||
                    hasMissingRequirements,
                )}
              >
                Sign
              </Button>
            </OnboardingActions>
          </div>
        </>
      )}
    </form>
  );
};
