import { Modal } from '../modal';
import './index.scss';
import { TeamMembers } from '../team-members-invite';
import { useToaster } from '../../hooks/use-toaster';

export const AddMembers = ({ close }: { close: () => any }) => {
  const toaster = useToaster();

  const successCallback = () => {
    toaster.success({
      message: `Invitation sent successfully.`,
    });
    close();
  };

  return (
    <Modal onClose={close} title="Invite Team Members" size="xl">
      <TeamMembers onNext={successCallback} onSkip={close} />
    </Modal>
  );
};
