import React from 'react';
import { IoTrash } from 'react-icons/io5';
import { Button, Input } from '../../components';
import { TeamMemberRoleSelect } from '../member-role-select';

export type EmailRole = {
  id: string | number;
  email?: string | null | undefined;
  title?: string | null | undefined;
  role?: number | null | undefined;
};

export const InviteMember = ({
  id,
  email,
  title,
  role,
  canDelete = false,
  onRemove,
  onChange,
}: EmailRole & {
  canDelete: boolean;
  onRemove: (id: string | number) => void;
  onChange: (id: string | number, key: string, value: any) => void;
}) => {
  return (
    <div className="row mx-0 mb-3">
      <div className="col-12 col-lg-5 my-1 px-1">
        <Input
          placeholder="e.g. example@domain.com"
          type="email"
          name="email"
          className="py-2"
          value={email || ''}
          onChange={({ target }) => onChange(id, 'email', target.value)}
        />
      </div>

      <div className="col-12 col-lg-3 my-1 px-1">
        <Input
          placeholder="Official Title"
          name="title"
          className="py-2"
          value={title || ''}
          onChange={({ target }) => onChange(id, 'title', target.value)}
        />
      </div>

      <div className="col-12 col-lg-3 my-1 px-1">
        <TeamMemberRoleSelect
          placeholder="Select role"
          value={role || -1}
          onChange={(value) => onChange(id, 'role', value)}
        />
      </div>

      {canDelete && (
        <div className="col-12 col-lg-1 my-1 px-1 text-end">
          <Button theme="danger" small onClick={() => onRemove(id)}>
            <IoTrash className="d-none d-lg-block" />
            <span className="d-block d-lg-none">Remove</span>
          </Button>
        </div>
      )}
    </div>
  );
};
