import { Link, useHistory } from 'react-router-dom';
import React, { FC, useCallback } from 'react';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { SHOW_PRESET_PATH, SHOW_TEMPLATE_PATH } from '../../pages';
import { PresetGroupTemplate as IPresetGroupTemplate } from '../../interfaces/presets';

import './index.scss';
import { IoTrash } from 'react-icons/io5';
import { Button } from '../button';
import { useApi, useEndpoint, useModal } from '../../hooks';
import { useToaster } from '../../hooks/use-toaster';
import { ErrorList } from '../error-list';

export const ItemTypes = {
  PRESET_GROUP: 'PRESET_GROUP',
};

export interface PresetGroupTemplateProps {
  id: any;
  isEditable: boolean;
  presetGroupTemplate: IPresetGroupTemplate;
}

const RANDOM_STRING =
  'Lorem ipsum dolor sit amet consectetur adipisicing elit.';

export const PresetGroupTemplate: FC<PresetGroupTemplateProps> = ({
  id,
  isEditable,
  presetGroupTemplate,
}) => {
  const api = useApi();
  const toaster = useToaster();
  const history = useHistory();
  const modalStore = useModal();
  const endpoint = useEndpoint();

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const generateRandomString = useCallback(() => {
    let randomStringRepetition = Math.max(Math.random() * 5, 3);

    return RANDOM_STRING.repeat(randomStringRepetition);
  }, []);

  const handleErrors = (e: any) => {
    const errors: string[] = Object.values(e?.errors || []);

    toaster.danger({
      message: <ErrorList errors={errors} />,
    });
  };

  const desyncTemplate = () => {
    api
      .post(endpoint('preset_group_template.desync'), {
        group_id: presetGroupTemplate.group_id,
        preset_id: presetGroupTemplate.preset_id,
        template_id: presetGroupTemplate.template_id,
      })
      .then(() => {
        history.push('/success');

        history.replace(
          SHOW_PRESET_PATH.replace(
            ':id',
            String(presetGroupTemplate.preset_id),
          ),
        );
      })
      .catch(handleErrors);
  };

  const confirmDelete = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    modalStore
      .confirmModal({
        theme: 'danger',
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this template?',
        confirmBtn: 'Yes, Delete',
      })
      .result.then(desyncTemplate)
      .catch(() => {});
  };

  return (
    <div
      className="pg-PresetGroupTemplate"
      ref={isEditable ? setNodeRef : null}
      style={style}
      {...attributes}
      {...listeners}
    >
      <p className="pg-PresetGroupTemplate__Title">
        <Link
          to={SHOW_TEMPLATE_PATH.replace(
            ':id',
            String(presetGroupTemplate?.template?.id),
          )}
        >
          {presetGroupTemplate?.template?.name}
        </Link>
      </p>

      <div className="pg-PresetGroupTemplate__Placeholder">
        {generateRandomString()}
      </div>

      {isEditable && (
        <div className="pg-PresetGroupTemplate__Detach" onClick={confirmDelete}>
          <Button theme="link">
            <IoTrash />
          </Button>
        </div>
      )}
    </div>
  );
};
