import React from 'react';
import { Button, Loader } from '../../components';
import { useApi } from '../../hooks';
import { ComplianceDocumentTypes } from '../../constants';
import { useToaster } from '../../hooks/use-toaster';
import './dropzone.scss';

export const Dropzone = ({
  value,
  onChange,
  type,
  message = 'Click here or drag file to upload',
}: {
  value?: { id: number; name: string };
  onChange?: (file?: { id: number; name: string }) => any;
  type: typeof ComplianceDocumentTypes[keyof typeof ComplianceDocumentTypes];
  message?: React.ReactNode;
}) => {
  const api = useApi();
  const toaster = useToaster();
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [loading, setLoading] = React.useState(false);

  const upload = React.useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement>,
    ): Promise<{ ids: number[]; files: File[] }> => {
      if (e.target.files?.length) {
        setLoading(true);
        const files = Array.from(e.target.files);
        const formData = new FormData();
        files.forEach((file, index) => {
          formData.append(`files[${index}][file]`, file);
          formData.append(`files[${index}][type]`, type);
        });

        return api
          .fetch('/api/contractor/documents', {
            method: 'POST',
            body: formData,
          })
          .then(({ data: { ids } }) => {
            if (fileInputRef.current) {
              fileInputRef.current.value = '';
            }
            setLoading(false);
            return { ids, files };
          })
          .catch((e) => {
            setLoading(false);
            toaster.danger({
              message:
                'Unsupported file type. Allowed file types: .doc, .docx and .pdf.',
            });
            throw e;
          });
      } else {
        return Promise.resolve({ ids: [], files: [] });
      }
    },
    [api, fileInputRef, toaster, type],
  );

  const onInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      upload(e).then(({ ids, files }) => {
        const file = { id: ids[0], name: files?.[0].name };
        onChange?.(file);
        toaster.success({
          message: 'File was successfully uploaded.',
        });
      });
    },
    [onChange, toaster, upload],
  );

  const removeFile = () => {
    onChange?.();
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div>
      {value?.name ? (
        <div className="pg-Dropzone__File">
          <span>{value?.name}</span>
          <Button
            theme="link"
            className="btn btn-link pg-Dropzone__FileRemove"
            onClick={removeFile}
          >
            Remove
          </Button>
        </div>
      ) : (
        <div className="pg-Dropzone">
          {loading ? (
            <Loader />
          ) : (
            <>
              {message}
              <input
                className="pg-Dropzone__Input"
                ref={fileInputRef}
                type="file"
                onChange={onInputChange}
                accept=".doc,.docx,.pdf"
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};
