import { Button } from '../../components';
import './step-control.scss';

export interface StepControlProps {
  onNext?: (data?: any) => any;
  onPrev?: (data?: any) => any;
  loading?: boolean;
  showNext?: boolean;
  showPrev?: boolean;
  nextText?: React.ReactNode;
  prevText?: string;
}

export const StepControl = ({
  onNext,
  onPrev,
  showNext = true,
  showPrev = true,
  nextText = 'Next',
  prevText = 'Back',
  loading,
  nextProps = {},
}: StepControlProps & { nextProps?: any }) => {
  return (
    <>
      <div className="pg-StepControl">
        <div>
          {showPrev ? (
            <Button theme="light" outline onClick={onPrev}>
              {prevText}
            </Button>
          ) : null}
        </div>
        {showNext ? (
          <div>
            <Button
              type="submit"
              onClick={onNext}
              loading={loading}
              {...nextProps}
            >
              {nextText}
            </Button>
          </div>
        ) : null}
      </div>
    </>
  );
};
