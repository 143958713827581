import React from 'react';
import { isEmpty } from 'lodash';
import { formatDistance } from 'date-fns';
import { Table, Tag, Td, Th, Tr } from '../../../components';

export const TaxFormsList = ({ docs }: { docs: any[] }) => {
  return (
    <>
      <h6 className="pg-ComplianceSection__Title">Tax Forms</h6>

      <div className="pg-ComplianceSection__Content">
        {isEmpty(docs) ? (
          <p className="pg-ComplianceSection__Content__Empty">
            No documents added yet
          </p>
        ) : (
          <Table>
            <thead>
              <Tr>
                <Th>Name</Th>
                <Th>Type</Th>
                <Th>PDF</Th>
                <Th>Created at</Th>
              </Tr>
            </thead>
            <tbody>
              {docs.map((item: any) => (
                <Tr key={item.id}>
                  <Td>{item.document_type_name}</Td>
                  <Td>
                    <Tag>{item.document_type_key}</Tag>
                  </Td>
                  <Td>
                    <a
                      href={item.file_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Preview
                    </a>
                  </Td>

                  <Td title={new Date(item.created_at).toString()}>
                    {formatDistance(new Date(item.created_at), new Date(), {
                      addSuffix: true,
                    })}
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};
