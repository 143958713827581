import React from 'react';
import classNames from 'classnames';
import './index.scss';

export const LabelValue = ({
  label,
  children,
  inline,
  className,
  ...props
}: Omit<React.HTMLProps<HTMLDivElement>, 'label'> & {
  label?: React.ReactNode;
  inline?: boolean;
}) => (
  <div
    className={classNames(
      'pg-LabelValue',
      { 'pg-LabelValue--inline': inline },
      className,
    )}
    {...props}
  >
    <div className="pg-LabelValue__Label">{label}</div>
    <div className="pg-LabelValue__Value">{children}</div>
  </div>
);
