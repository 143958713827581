import { Text } from '../../components';
import './label-value.scss';

export const LabelValueGroup = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <div className="pg-LabelValueGroup">{children}</div>;
};

export const LabelValueItem = ({
  label,
  children,
}: {
  label?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  return (
    <div className="pg-LabelValueItem">
      <Text theme="secondary" className="pg-LabelValueItem__Label">
        {label}
      </Text>{' '}
      <div className="pg-LabelValueItem__Value">
        <Text as="div" theme="light">
          {children}
        </Text>
      </div>
    </div>
  );
};
