import { formatDistance } from 'date-fns';
import React, { useRef } from 'react';
import { IoAddCircleOutline, IoPencil, IoTrash } from 'react-icons/io5';
import {
  AddMembers,
  EditMember,
  Button,
  FormSection,
  Table,
  Td,
  Th,
  Tr,
  PageTitle,
  LayoutContent,
  Loader,
  Action,
  Tag,
  AuthClients,
  CompanySettings,
  WebhookSettings,
} from '../../components';
import { useModal } from '../../hooks';
import { Role, useApi } from '../../hooks/use-api';
import { useCompany } from '../../hooks/use-company';
import { useToaster } from '../../hooks/use-toaster';
import { useService } from '../../shared/services/use-service';
import { CommonDataService } from '../../shared/services/common-data';
import { ProfileService, useProfileService } from '../../shared/services';
import { isNil } from 'lodash';
import { EditUserInvitation } from '../../components/edit-user-invitation';
import { RouteConfigItem } from '../../routes';
import { CompanyDetails, CompanyUser } from '../../shared/services/company';
import { Role as Roles } from '../../constants';
import { CompanyMustBeOnboarded } from '../../middlewares';
import { CompanyProfileForm } from '../../components/company-profile';

export const ORGANIZATION_SETTINGS_PATH = '/organization-settings';

export const ORGANIZATION_SETTINGS_ROUTE: RouteConfigItem = {
  path: ORGANIZATION_SETTINGS_PATH,
  render: () => <OrganizationSettings />,
  permissions: ['read.companies'],
  roles: [Roles.CLIENT, Roles.TEAM_MEMBER],
  middlewares: [CompanyMustBeOnboarded],
};

export const OrganizationSettings = () => {
  const api = useApi();
  const profileService = useService(ProfileService);
  const commonData = useService(CommonDataService);
  const { profile: currentUser } = useProfileService();
  const {
    selectedCompany: { company_id: id },
  } = useCompany();
  const buttonSubmitRef = useRef<HTMLButtonElement>(null);
  const modalStore = useModal();
  const toasterStore = useToaster();
  const [loading, setLoading] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const [companyloading, setCompanyLoading] = React.useState(true);
  const [company, setCompany] = React.useState<CompanyDetails>();
  const [roles, setRoles] = React.useState<Role[]>([]);

  const isCompanyOwner = (user: CompanyUser) => {
    return user.user_id === company?.creator_user_id;
  };

  const isTeamMember = (user: any) => {
    return !isNil(user.user_id);
  };

  const isCurrentUser = (user: CompanyUser) => {
    return user.user_id === currentUser?.id;
  };

  const getCompany = React.useCallback(() => {
    api
      .get(`/api/client/company/${id}`)
      .then(({ data }) => {
        setCompany(data.company);
        setCompanyLoading(false);
      })
      .catch(() => {
        toasterStore.danger({ message: 'Something went wrong' });
        setCompanyLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, id]);

  const save = (data: any) => {
    setLoading(true);

    api
      .patch(`/api/client/company/${id}`, api.createPayload(data))
      .then(() => {
        toasterStore.success({ message: 'Settings were saved successfully.' });
        setEditMode(false);
        profileService.load();
      })
      .catch(() =>
        toasterStore.danger({ message: 'Failed to save company settings.' }),
      )
      .finally(() => setLoading(false));
  };

  const addMember = () => {
    const modal = modalStore.openModal((close) => <AddMembers close={close} />);

    modal.result.then(() => {
      getCompany();

      profileService.load();
    });
  };

  const removeMember = (name: string, email: string, userId: number) => {
    modalStore
      .confirmModal({
        title: 'Remove user',
        message: `Are you sure you want to remove ${
          name || email
        } from this team?`,
        confirmBtn: 'Remove',
      })
      .result.then(() => {
        api
          .delete(`/api/client/company/${id}/team/${userId}`)
          .then(() => {
            getCompany();
            toasterStore.success({ message: 'User was removed.' });
          })
          .catch(({ errors }) => {
            toasterStore.danger({ message: <>{errors.join(<br />)}</> });
          });
      })
      .catch(() => {});
  };

  const cancelInvitation = (token: string | null) => {
    if (isNil(token)) return;

    modalStore
      .confirmModal({
        title: 'Cancel Invitation',
        message: `Are you sure you want to revoke the invitation? The invited person cannot join your team`,
        confirmBtn: 'Cancel invitation',
      })
      .result.then(() => {
        api
          .delete(`/api/client/user-invitation/${token}`)
          .then(({ data }) => {
            getCompany();
            toasterStore.success({ message: data.message });
          })
          .catch(({ errors }) => {
            toasterStore.danger({ message: <>{errors.join(<br />)}</> });
          });
      })
      .catch(() => {});
  };

  const editInvitation = (
    token: string | null,
    email: string,
    title: string,
    roleName: string,
  ) => {
    const roleId = roles.find(({ name }) => name === roleName)?.id;

    if (!isNil(roleId)) {
      modalStore
        .openModal((close) => (
          <EditUserInvitation
            close={close}
            user={{ token, email, title, role: roleId }}
          />
        ))
        .result.then(() => {
          getCompany();
        });
    }
  };

  const editMember = (user: any) => {
    const { full_name, email, title, role_name, user_id: id } = user;

    const roleId = roles.find(({ name }) => name === role_name)?.id;

    if (!isNil(roleId)) {
      modalStore
        .openModal((close) => (
          <EditMember
            close={close}
            user={{ fullName: full_name, email, title, role: roleId, id }}
            editRole={!isCompanyOwner(user)}
          />
        ))
        .result.then(() => {
          getCompany();
        });
    }
  };

  const renderActions = (user: any) => {
    // User has a pending invitation
    if (isNil(user.user_id) || !!user.pending_invite) {
      return (
        <>
          <Action
            tooltip="Edit invitation"
            onClick={() =>
              editInvitation(user.token, user.email, user.title, user.role_name)
            }
          >
            <IoPencil />
          </Action>

          <Action
            tooltip="Cancel Invitation"
            onClick={() => cancelInvitation(user?.token)}
          >
            <IoTrash />
          </Action>
        </>
      );
    }

    // User is a team member
    return (
      <>
        <Action tooltip="Edit member" onClick={() => editMember(user)}>
          <IoPencil />
        </Action>
        <Action
          tooltip="Remove member"
          onClick={() => removeMember(user.full_name, user.email, user.user_id)}
        >
          <IoTrash />
        </Action>
      </>
    );
  };

  const triggerFormSubmit = () => {
    buttonSubmitRef?.current?.click();
  };

  const getLastLogin = (user: CompanyUser) => {
    if (!user?.last_login) return 'N/A';

    const lastLoginText = formatDistance(
      new Date(user.last_login),
      new Date(),
      {
        addSuffix: true,
      },
    );

    return <div className="badge bg-success">{lastLoginText}</div>;
  };

  React.useEffect(() => {
    commonData.get().then(({ roles }) => {
      setRoles(roles || []);
    });
  }, [commonData]);

  React.useEffect(() => {
    if (id) {
      getCompany();
    }
  }, [getCompany, id]);

  return (
    <div className="overflow-hidden w-100">
      <PageTitle>Company profile</PageTitle>

      <LayoutContent>
        <FormSection
          title="Company Details"
          actions={
            editMode ? (
              <>
                <Button loading={loading} onClick={triggerFormSubmit}>
                  Save
                </Button>
                <Button
                  outline
                  theme="secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    setEditMode(false);
                  }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setEditMode(true);
                }}
              >
                Edit
              </Button>
            )
          }
        >
          <CompanyProfileForm
            company={company}
            onSubmit={save}
            editable={editMode}
          >
            <button className="d-none" type="submit" ref={buttonSubmitRef}>
              Submit
            </button>
          </CompanyProfileForm>
        </FormSection>

        <div className="my-5">
          <CompanySettings companyId={id} />
        </div>

        <FormSection
          title="Team"
          actions={
            <Button
              theme="secondary"
              outline
              leftContent={<IoAddCircleOutline />}
              onClick={addMember}
            >
              New member
            </Button>
          }
        >
          {companyloading ? (
            <Loader fullscreen />
          ) : (
            <Table>
              <thead>
                <Tr>
                  <Th>Team member</Th>
                  <Th>Official Title</Th>
                  <Th>Role</Th>
                  <Th>Last login</Th>
                  <Th></Th>
                  <Th></Th>
                </Tr>
              </thead>
              <tbody>
                {company?.users.length ? (
                  company?.users?.map((user) => (
                    <Tr key={user.user_id || user.token}>
                      <Td>
                        {user.full_name}
                        <div className="text-muted">{user.email}</div>
                      </Td>

                      <Td>{user?.title || '-'}</Td>

                      <Td>{user.role_name}</Td>

                      <Td>{getLastLogin(user)}</Td>

                      <Td>
                        {isCurrentUser(user) && <Tag status="warning">You</Tag>}

                        {isCompanyOwner(user) && (
                          <Tag status="primary">Owner</Tag>
                        )}

                        {!isTeamMember(user) && (
                          <Tag status="secondary">Invitation Sent</Tag>
                        )}
                      </Td>
                      <Td isAction>{renderActions(user)}</Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={4} className="text-center">
                      No team members
                    </Td>
                  </Tr>
                )}
              </tbody>
            </Table>
          )}
        </FormSection>

        <div className="overflow-auto my-5">
          <AuthClients />
        </div>

        <div className="overflow-auto my-5">
          <WebhookSettings />
        </div>
      </LayoutContent>
    </div>
  );
};
